/** @format */
import { pick, union } from "lodash";
import { combineReducers } from "redux";
import { createAction } from "redux-actions";

import {
  consumePayload,
  expand,
  updateCollection
} from "../../collections/module";
import { Location, Organization } from "../../collections/schema";
import {
  createAsyncAction,
  handleActions,
  wrapPromiseToThunk
} from "../../utils/redux-actions";
import { fetchApi } from "../../utils/request";
import { booleanReducer, requestErrorReducer } from "../utils/reducers";
import { reportError } from "./error";

export const MOUNT_POINT = "organizationLocations";

const USER_WRITABLE_PROPERTIES = [
  "name",
  "lat",
  "lng",
  "formatted_address",
  "google_place_id",
  "additional_info"
];

const FETCH_LOCATIONS = createAsyncAction(
  "ORGANIZATION_LOCATIONS/FETCH_LOCATIONS"
);
const FETCH_LOCATION = createAsyncAction(
  "ORGANIZATION_LOCATIONS/FETCH_LOCATION"
);
const ADD_LOCATION = createAction("ORGANIZATION_LOCATIONS/ADD_LOCATION");
const CREATE_LOCATION = createAsyncAction(
  "ORGANIZATION_LOCATIONS/CREATE_LOCATION"
);
const CREATE_LOCATION_ERROR_CLEAR = createAction(
  "ORGANIZATION_LOCATIONS/CREATE_LOCATION_ERROR_CLEAR"
);
const UPDATE_LOCATION = createAsyncAction(
  "ORGANIZATION_LOCATIONS/UPDATE_LOCATION"
);
const UPDATE_LOCATION_ERROR_CLEAR = createAction(
  "ORGANIZATION_LOCATIONS/UPDATE_LOCATION_ERROR_CLEAR"
);

export const addLocation = location => dispatch => {
  dispatch(consumePayload(location, Location));
  dispatch(updateCollection(Organization, "locations", location.id));
  dispatch(ADD_LOCATION(location));
};

export const clearCreateLocationError = () => dispatch =>
  dispatch(CREATE_LOCATION_ERROR_CLEAR());

export const clearUpdateLocationError = () => dispatch =>
  dispatch(UPDATE_LOCATION_ERROR_CLEAR());

export const fetchLocations = wrapPromiseToThunk(
  FETCH_LOCATIONS,
  ({ dispatch }, organizationId) =>
    fetchApi(`/organizations/${organizationId}/locations`).then(({ body }) => {
      body.forEach(location => dispatch(addLocation(location)));
      return body;
    }),
  reportError
);

export const fetchLocation = wrapPromiseToThunk(
  FETCH_LOCATION,
  ({ dispatch }, locationId) =>
    fetchApi(`/locations/${locationId}`).then(({ body }) => {
      dispatch(addLocation(body));
      return body;
    }),
  reportError
);

export const createLocation = wrapPromiseToThunk(
  CREATE_LOCATION,
  ({ dispatch }, organizationId, location) =>
    fetchApi(`/organizations/${organizationId}/locations`, {
      method: "post",
      json: pick(location, USER_WRITABLE_PROPERTIES)
    }).then(({ body }) => dispatch(addLocation(body))),
  reportError
);

export const updateLocation = wrapPromiseToThunk(
  UPDATE_LOCATION,
  ({ dispatch }, location) =>
    fetchApi(`/locations/${location.id}`, {
      method: "put",
      json: pick(location, USER_WRITABLE_PROPERTIES)
    }).then(() => dispatch(consumePayload(location, Location))),
  reportError
);

const locations = handleActions(
  {
    [ADD_LOCATION]: (state, { payload }) => union(state, [payload.id])
  },
  []
);

export const reducer = combineReducers({
  locations,
  errors: combineReducers({
    locationsFetch: requestErrorReducer(FETCH_LOCATIONS),
    locationFetch: requestErrorReducer(FETCH_LOCATION),
    createLocation: requestErrorReducer(CREATE_LOCATION, {
      clearAction: CREATE_LOCATION_ERROR_CLEAR
    }),
    updateLocation: requestErrorReducer(UPDATE_LOCATION, {
      clearAction: UPDATE_LOCATION_ERROR_CLEAR
    })
  }),
  isFetchingLocations: booleanReducer(FETCH_LOCATIONS),
  isFetchingLocation: booleanReducer(FETCH_LOCATION),
  isCreatingLocation: booleanReducer(CREATE_LOCATION),
  isUpdatingLocation: booleanReducer(UPDATE_LOCATION)
});

export const getIsFetchingLocations = state =>
  state[MOUNT_POINT].isFetchingLocations;

export const getIsFetchingLocation = state =>
  state[MOUNT_POINT].isFetchingLocation;

export const getIsCreatingLocation = state =>
  state[MOUNT_POINT].isCreatingLocation;

export const getIsUpdatingLocation = state =>
  state[MOUNT_POINT].isUpdatingLocation;

export const getLocations = state =>
  expand(Location, state[MOUNT_POINT].locations, {}, state);

export const getLocation = (state, id) => {
  try {
    return expand(Location, id, {}, state);
  } catch (err) {
    return null;
  }
};

export const getLocationsFetchError = state =>
  state[MOUNT_POINT].errors.locationsFetch;

export const getLocationFetchError = state =>
  state[MOUNT_POINT].errors.locationFetch;

export const getCreateLocationError = state =>
  state[MOUNT_POINT].errors.createLocation;

export const getUpdateLocationError = state =>
  state[MOUNT_POINT].errors.updateLocation;
