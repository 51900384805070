/** @format */
import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import { Button, Icon } from "vogue";

const SlackAccountStatus = ({ authUrl, className, isValid }) => {
  let status;
  if (isValid) {
    status = <span>Active</span>;
  } else {
    status = (
      <Button
        modifiers={["secondary"]}
        href={authUrl}
        className="IntegrationAccountStatus-reconnect"
      >
        Reconnect
      </Button>
    );
  }

  return (
    <div
      className={classNames(
        "ZoomAccountStatus",
        {
          "IntegrationAccountStatus--connected": isValid,
          "IntegrationAccountStatus--disconnected": !isValid
        },
        className
      )}
    >
      <Icon
        name={isValid ? "green-check" : "error"}
        color={isValid ? "green" : "red"}
        className="IntegrationAccountStatus-icon"
      />
      {status}
    </div>
  );
};

SlackAccountStatus.propTypes = {
  authUrl: PropTypes.string.isRequired,
  isValid: PropTypes.bool.isRequired,
  className: PropTypes.string
};

export default SlackAccountStatus;
