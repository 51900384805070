/** @format */
import PropTypes from "prop-types";
import React from "react";
import createReactClass from "create-react-class";
import GenericError from "./components/shared/generic-error.react.js";

const BaseView = createReactClass({
  propTypes: {
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.element),
      PropTypes.element
    ]).isRequired
  },

  render() {
    return (
      <div>
        {this.props.children}
        <GenericError />
      </div>
    );
  }
});

export default BaseView;
