import { createAction } from 'redux-actions';
import { normalize, denormalize, schema as normalizrSchema } from 'normalizr';
import invariant from 'invariant';
import { cloneDeep, flatten } from 'lodash';
import { handleActions, createAsyncAction, wrapPromiseToThunk } from '../utils/redux-actions';
import { fetchApi, generateFieldExpansion } from '../utils/request';
import keyToSchema, {
  relationships,
  getRelationshipName,
  schemaWithRelationships,
} from './schema';


const assignAccessors = (target, ...sources) => {
  // Copies accessors from source objects to target.
  // For our use case we use it to copy getters and setters.
  // modified from https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects
  // /Object/assign#copying_accessors
  const descriptors = {};
  sources.forEach(source => {
    Object.keys(Object.getOwnPropertyDescriptors(source))
    .reduce((acc, key) => {
      acc[key] = Object.getOwnPropertyDescriptor(source, key);
      return acc;
    }, descriptors);
  });
  Object.defineProperties(target, descriptors);
  return target;
};


// N.B. This function can overflow max stack size if you pass in an extremely
// large object. For example, a fully expanded job object. For this reason,
// try to only pass in objects that are not yet expanded (but have accesors).
const stripAccessors = (target) => {
  if (typeof target === 'object' && target !== null) {
    if (Array.isArray(target)) {
      return target.map(x => stripAccessors(x));
    } else {
      return Object.keys(target)
      .reduce((acc, key) => {
        if (Object.getOwnPropertyDescriptor(target, key).writable) {
          acc[key] = stripAccessors(target[key]);
        }
        return acc;
      }, {});
    }
  } else {
    return target;
  }
};

const addRelationshipProperty = (getState, record, relationship, key) => {
  // Preserve relationship IDs
  if (!record._relationships) {
    record._relationships = {};
  }

  // TODO: use defined backref instead of guessing _id
  let foundId;
  if (
    record[relationship] && typeof record[relationship] !== 'object' ||
    (
      Array.isArray(record[relationship]) &&
      record[relationship].length &&
      typeof record[relationship][0] !== 'object'
    ) ||
    Array.isArray(record[relationship]) && record[relationship].length === 0
  ) {
    foundId = record[relationship];
  }
  const existing = getState().collections[key][record.id];
  const existingRelationship = existing ? existing._relationships[relationship] : null;
  record._relationships[relationship] = (
    foundId ||
    record[`${ relationship }_id`] ||
    existingRelationship
  );

  Object.defineProperty(record, relationship, {
    get() {
      const relatedCollection = getState().collections[
        relationships[key][relationship].scheme.key
      ];
      let related;
      if (relationships[key][relationship].isArray) {
        related = this._relationships[relationship] ? this._relationships[relationship].map(id => relatedCollection[id]) : null;
      } else {
        related = relatedCollection[this._relationships[relationship]];
      }

      // FIXME: when we figure out properly batching renders, turn this warning back on
      // const notConsumedMessage = (
      //   `Cannot access relationship ${ relationship } on ${ key } instance. ` +
      //   'Are you specifying the relationship in your field expansion?'
      // );
      // warning(related, notConsumedMessage);
      return related;
    },

    set() {
      invariant(false, `Setting relationship ${ relationship } on ${ key } is not supported`);
    },
  });

  Object.defineProperty(record, relationship, { enumerable: false });

  // this is to make Redux debugger and various other serializing agents happy.
  // They do *not* like it when you pass in infinitely recursing data structures.
  record.toJSON = () => {
    const blob = {};
    const descriptors = Object.getOwnPropertyDescriptors(record);
    for (let name in descriptors) {
      if (descriptors[name].writable) {
        blob[name] = record[name];
      }
    }
    return blob;
  };
};

// WARNING: dot access circumvents traditional redux flow of data by directly
// accessing the redux store. Ideally we should not need to do this.
const addRelationshipProperties = (getState, record, key) => {
  Object.keys(relationships[key] || {})
  .reduce((acc, relationship) => {
    addRelationshipProperty(getState, acc, relationship, key);
    return acc;
  }, record);
};

const batchConsumeRecord = createAction('BATCH_CONSUME_RECORD');
const FETCH_IF_NEEDED = createAsyncAction('COLLECTIONS/FETCH_IF_NEEDED');
const FETCHING = createAction('COLLECTIONS/FETCHING');
const UNFETCHED = createAction('COLLECTIONS/UNFETCHED');

/**
 * Renormalize is the equivalent of a denormalize then another normalize.
 * It finds all related entities on a resource being consumed and
 * and updates their relationship to match the change from the root,
 * then adds those entities to the returned entities list.
 * Supports one-to-one and one-to-many relationships
 *
 * E.G. (one-to-many) Consuming a GoogleAccount (id=1), and need to update
 * the Contact (google_account_id=2).
 * Changes Contact.google_accounts from [2] to [2, 1].
 *
 * E.G. (one-to-one) Consuming a GoogleAccount (id=1), and need to update
 * the EmailAddress (google_account_id=2).
 * Changes EmailAddress.google_account from 2 to 1.
 *
 * If the related resource is not in collections or the entities
 * provided, there's nothing to be updated, so relationship is ignored.
 *
 * Does not support:
 *
 * Implied relationships:
 * E.G. Consuming a GoogleAccount, it has an EmailAddress and a Contact.
 * We cannot infer that the EmailAddress belongs to the Contact, but could
 * potentially define a way of specifying dependent relationships.
 *
 * Updated/Deleted relationships:
 * E.G. Moving an email from one customer to another by consuming the new
 * EmailAddress.
 * We could potentially infer that the old relationship must be removed by
 * finding the type of relationship through the schema types, but for now
 * these types of updates are generally done by the action itself.
 *
 * Many-to-One and Many-to-Many relationships:
 * Because this is specifically implemented for our API, and our API does not
 * return array ids (contact: {email_address_ids: [1, 2, 3]}) with resources,
 * we do not need to support denormalizing arrays of ids from the root entity.
 *
 * Multiple relationships between the same two schema:
 * E.G. contact.default_email and contact_email_addresses. If there are
 * multiple relationships specified, we select the first one.
 */
export const renormalize = (id, entities, rootSchema, state) => {
  let clonedEntities = cloneDeep(entities);

  // find root entity
  const rootEntity = entities[rootSchema.key][id];

  const validRelationshipNames = Object.keys(relationships[rootSchema.key] || {});

  const relationshipsToUpdate = Object.keys(rootEntity)
    .map(key => {
      // calculate name of the relationship that could be referenced by this key
      let relationshipName = key;
      const idIndex = key.indexOf('_id');
      if (idIndex !== -1) {
        relationshipName = key.slice(0, idIndex);
      }

      return [key, relationshipName];
    })
    .filter(([key, relationshipName]) => (
      // filter out keys that don't map to a defined relationship or
      // aren't defined on the root entity
      validRelationshipNames.includes(relationshipName) && rootEntity[key]
      // TODO: (oliver) also include backref matches to account for
      // asymmetrical relationship names
    ));

  relationshipsToUpdate.forEach(([key, relationshipName]) => {
    const relatedCollectionName = relationships[rootSchema.key][relationshipName].scheme.key;

    const relatedIds = flatten([rootEntity[key]]);
    relatedIds.forEach(relatedId => {
      const relatedResource = {
        ...(state.collections[relatedCollectionName] && state.collections[relatedCollectionName][relatedId]),
        ...(entities[relatedCollectionName] && entities[relatedCollectionName][relatedId]),
      };  // merge updates if any

      const clonedRelatedResource = cloneDeep(relatedResource);
      const reverseRelationship = getRelationshipName(keyToSchema[relatedCollectionName], rootSchema);
      if (!reverseRelationship) {
        return;
      }

      if (
        keyToSchema[relatedCollectionName].schema[reverseRelationship]
        instanceof normalizrSchema.Array
      ) {
        if (!clonedRelatedResource[reverseRelationship]) {
          clonedRelatedResource[reverseRelationship] = [id];
        } else if (
          clonedRelatedResource[reverseRelationship].indexOf(id) === -1
        ) {
          // add our id to the related resource if not already there
          clonedRelatedResource[reverseRelationship].push(id);
        }
      } else {
        // else overwrite its id with our own
        clonedRelatedResource[reverseRelationship] = id;
      }

      if (!clonedEntities[relatedCollectionName]) {
        clonedEntities[relatedCollectionName] = {};
      }
      clonedEntities[relatedCollectionName][relatedId] = clonedRelatedResource;
    });
  });

  return clonedEntities;
};

export const exoValidateExpansions = (expansions, obj, path) => {
  if (obj === undefined || obj === null) {
    throw new Error(`Could not expand ${ path.join('.') }.`);
  }

  Object.entries(expansions).forEach(([key, subtree]) => {
    const related = obj[key];

    if (related instanceof Array) {
      related.forEach((relatedItem, i) => exoValidateExpansions(
        subtree,
        relatedItem,
        [...path, `${key}[${i}]`],
      ));
    } else {
      exoValidateExpansions(subtree, related, [...path, key]);
    }
  });
};

const validateExpansions = (expansions, obj, path) => {
  if (obj === undefined) {
    throw new Error(`Could not expand ${ path.join('.') }.`);
  }

  Object.entries(expansions).forEach(([key, subtree]) => {
    const related = obj[key];

    if (related instanceof Array) {
      related.forEach((relatedItem, i) => validateExpansions(
        subtree,
        relatedItem,
        [...path, `${key}[${i}]`],
      ));
    } else {
      validateExpansions(subtree, related, [...path, key]);
    }
  });
};

export const expand = (rootSchema, id, expansions, state) => {
  const clone = schemaWithRelationships(rootSchema, expansions);

  if (Array.isArray(id)) {
    const objs = denormalize(
      id,
      [clone],
      state.collections,
    );

    objs.forEach((obj, idx) => {
      validateExpansions(expansions, obj, [`${rootSchema.key}.${id[idx]}`]);
    });

    return objs;
  } else {
    const { [rootSchema.key]: obj } = denormalize(
      { [rootSchema.key]: id },
      { [rootSchema.key]: clone },
      state.collections,
    );

    validateExpansions(expansions, obj, [`${rootSchema.key}.${id}`]);

    return obj;
  }
};

export const addNormalizedRelationships = (entities, state) => {
  Object.entries(entities).forEach(([schemaKey, schemaEntities]) => {
    Object.values(schemaEntities).forEach(record => {
      Object.entries(relationships[schemaKey]).forEach(([name, relationship]) => {
        if (!relationship.isArray && relationship.foreignKey && record[name] === undefined) {
          record[name] = record[relationship.foreignKey];
        }

        if (relationship.backref && record[name]) {
          const backref = relationships[relationship.scheme.key][relationship.backref];
          if (!backref.isArray) {
            const relatedIds = relationship.isArray ? record[name] : [record[name]];
            relatedIds.forEach(relatedId => {
              let relatedRecord;
              if (entities[relationship.scheme.key] && entities[relationship.scheme.key][relatedId]) {
                relatedRecord = entities[relationship.scheme.key][relatedId];
              } else {
                relatedRecord = state.collections[relationship.scheme.key][relatedId];
              }

              if (relatedRecord && relatedRecord[relationship.backref] === undefined) {
                entities[relationship.scheme.key] = {
                  ...(entities[relationship.scheme.key] || {}),
                  [relatedId]: {
                    ...relatedRecord,
                    [relationship.backref]: record.id,
                  },
                };
              }
            });
          }
        }
      });
    });
  });

  return entities;
};

export const consumePayload = (payload, rootSchema) => {
  return (dispatch, getState) => {
    const isExo = document.getElementById('exo-client');
    const stripped = stripAccessors(payload);
    const { entities } = normalize(stripped, rootSchema);
    const updatedEntities = isExo ? renormalize(
      payload.id, entities, rootSchema, getState(),
    ) : addNormalizedRelationships(entities, getState());

    // process each collection (i.e. `contacts`) from the normalized payload
    const batchedRecords = [];
    Object.entries(updatedEntities).forEach(([schemaKey, schemaEntities]) => {
      // within the collection, process each record
      Object.entries(schemaEntities).forEach(([id, record]) => {
        // HACK: do not turn on dot access for endo yet
        if (isExo) {
          addRelationshipProperties(getState, record, schemaKey);
        }
        batchedRecords.push({ key: schemaKey, id, record });
      });
    });
    return dispatch(batchConsumeRecord(batchedRecords));
  };
};

export const updateCollection = (rootSchema, collectionName, itemId, deleted = false) => {
  return (dispatch, getState) => {
    const state = getState();
    const relationship = relationships[rootSchema.key][collectionName];

    const collectionItem = state.collections[relationship.scheme.key][itemId];
    const relatedId = collectionItem[relationship.backref];
    const relatedRecord = state.collections[rootSchema.key][relatedId];
    if (!relatedRecord) {
      return;  // The related record hasn't been fetched
    }

    const collection = relatedRecord[collectionName];
    if (!collection) {
      return;  // The collection hasn't been fetched
    }

    if (deleted !== collection.includes(itemId)) {
      return;  // The item has already been added/removed to/from the collection
    }

    if (deleted) {
      dispatch(batchConsumeRecord([{
        key: rootSchema.key,
        id: relatedId,
        record: {
          ...relatedRecord,
          [collectionName]: collection.filter(i => i !== itemId),
        },
      }]));
    } else {
      dispatch(batchConsumeRecord([{
        key: rootSchema.key,
        id: relatedId,
        record: {
          ...relatedRecord,
          [collectionName]: [
            ...collection,
            itemId,
          ],
        },
      }]));
    }
  };
};

const getInitialFetchState = (key) => {
  return {
    fetchState: 'UNFETCHED',
    relationships: Object.keys(keyToSchema[key].schema).reduce((obj, relationship) => {
      return {
        ...obj,
        [relationship]: {
          fetchState: 'UNFETCHED',
        },
      };
    }, {}),
  };
};

const fetchStateMatches = (schema, id, expansions, state, targetFetchStates, matchAny) => {
  const instance = state.collections[schema.key][id];
  const fetchState = state.collections.fetchState[schema.key][id] || getInitialFetchState(schema.key);
  if (targetFetchStates.includes(fetchState.fetchState) === matchAny) {
    return matchAny;
  }

  for (const [relationship, relatedExpansions] of Object.entries(expansions)) {
    const relatedFetchState = fetchState.relationships[relationship];
    if (targetFetchStates.includes(relatedFetchState.fetchState) === matchAny) {
      return matchAny;
    }

    let relatedIds = instance ? instance[relationship] : null;
    let relatedSchema = schema.schema[relationship];

    if (relatedIds) {
      if (relatedSchema instanceof normalizrSchema.Array) {
        relatedSchema = relatedSchema.schema;
      } else {
        relatedIds = [instance[relationship]];
      }

      for (const relatedId of relatedIds) {
        if (fetchStateMatches(
          relatedSchema,
          relatedId,
          relatedExpansions,
          state,
          targetFetchStates,
          matchAny,
        ) === matchAny) {
          return matchAny;
        }
      }
    }
  }

  return !matchAny;
};

const isFetched = (schema, id, expansions, state) => (
  fetchStateMatches(schema, id, expansions, state, ['FETCHED'], false)
);
const isFetching = (schema, id, expansions, state) => (
  !isFetched(schema, id, expansions, state) &&
  fetchStateMatches(schema, id, expansions, state, ['FETCHED', 'FETCHING'], false)
);
const isUnfetched = (schema, id, expansions, state) => (
  fetchStateMatches(schema, id, expansions, state, ['UNFETCHED'], true)
);

export const fetchIfNeeded = (reportError) => wrapPromiseToThunk(
  FETCH_IF_NEEDED,
  ({ dispatch, getState }, schema, id, expansions) => {
    if (isUnfetched(schema, id, expansions, getState())) {
      const fields = generateFieldExpansion(expansions);
      dispatch(FETCHING({
        key: schema.key,
        id,
        fetchStateRelationships: Object.keys(expansions),
      }));
      return fetchApi(`/${ schema.key }/${ encodeURIComponent(id) }?fields=${ fields }`).then(({ body }) => {
        dispatch(consumePayload(body, schema));
      }).catch(e => {
        dispatch(UNFETCHED({
          key: schema.key,
          id,
          fetchStateRelationships: Object.keys(expansions),
        }));
        throw e;
      });
    } else {
      return new Promise((resolve) => resolve());
    }
  },
  reportError,
);

const applyFetchState = (state, fetchState, key, id, fetchStateRelationships) => {
  const previous = state[key][id] || getInitialFetchState(key);

  return {
    ...state,
    [key]: {
      ...state[key],
      [id]: {
        fetchState,
        relationships: fetchStateRelationships.reduce((obj, relationship) => {
          return {
            ...obj,
            [relationship]: { fetchState },
          };
        }, previous.relationships),
      },
    },
  };
};

/**
 * shape:
 * {
 *   contacts: {
 *     632633: { ... },
 *     289502: { ... },
 *   },
 *   user: {
 *     ...,
 *   },
 * }
 */
export const reducer = handleActions({
  [batchConsumeRecord]: (state, { payload }) => {
    return payload
    .map(({ key, id, record }) => {
      const updatedRecord = {
        ...state[key][id],
        ...record,
      };
      assignAccessors(updatedRecord, record);

      return { key, id, record: updatedRecord };
    })
    .reduce((aggregate, { key, id, record }) => {
      const fetchStateRelationships = Object.keys(keyToSchema[key].schema).filter(
        e => record[e] !== undefined
      );

      return {
        ...aggregate,
        [key]: {
          ...aggregate[key],
          [id]: record,
        },
        fetchState: applyFetchState(aggregate.fetchState, 'FETCHED', key, id, fetchStateRelationships),
      };
    }, { ...state });
  },
  [FETCHING]: (state, { payload: { key, id, fetchStateRelationships } }) => ({
  ...state,
  fetchState: applyFetchState(state.fetchState, 'FETCHING', key, id, fetchStateRelationships),
  }),
  [UNFETCHED]: (state, { payload: { key, id, fetchStateRelationships } }) => ({
    ...state,
    fetchState: applyFetchState(state.fetchState, 'UNFETCHED', key, id, fetchStateRelationships),
  }),
}, Object.keys(keyToSchema).reduce((baseMap, key) => {
  baseMap[key] = {};
  baseMap.fetchState[key] = {};
  return baseMap;
}, { fetchState: {} }));

export const getIsUnfetched = (schema, id, expansions, state) => {
  return isUnfetched(schema, id, expansions, state);
};

export const getIsFetching = (schema, id, expansions, state) => {
  return isFetching(schema, id, expansions, state);
};

export const getIfFetched = (schema, id, expansions, state) => {
  if (isFetched(schema, id, expansions, state)) {
    return expand(schema, id, expansions, state);
  }

  return null;
};
