/** @format */
import { connect } from "react-redux";

import Icims from "../../components/dashboard/integrations/icims.react";
import {
  configureIcims,
  fetchIcims,
  getIsConfiguringIcims,
  getIsLoadingIcims,
  getIcimsDetails
} from "../../modules/integrations";
import createInitiallyLoadingContainer from "../create-initially-loading-container.react";

const mapStateToProps = state => ({
  icimsDetails: getIcimsDetails(state),
  isLoading: getIsLoadingIcims(state),
  isConfiguringIcims: getIsConfiguringIcims(state)
});

const mapDispatchToProps = dispatch => ({
  onLoad() {
    return dispatch(fetchIcims());
  },

  onConfigureIcims(icimsCustomerId, icimsClientId, icimsClientSecret) {
    return dispatch(configureIcims(icimsCustomerId, icimsClientId, icimsClientSecret));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(createInitiallyLoadingContainer(Icims));
