/** @format */
import PropTypes from "prop-types";

import React from "react";

const ProgressBar = ({ progress }) => (
  <div className="OnboardingProgressBar">
    <div
      className="OnboardingProgressBar-completedBar"
      style={{ width: `${progress}%` }}
    />
  </div>
);

ProgressBar.propTypes = { progress: PropTypes.number.isRequired };

export default ProgressBar;
