require('es6-promise').polyfill();
import 'isomorphic-fetch';
import querystring from 'querystring';
import BluebirdPromise from 'bluebird';
import superagent from 'superagent-bluebird-promise';
import config from '../config';
import PackageJson from '../../package';
import { setLocationHref } from './window';
const packageVersion = PackageJson.version;
import { PromiseRejectionSentinelError } from './promise-rejection-sentinel-error';
import { debounce } from 'lodash';
import parse from 'parse-link-header';

// because we require this file when running server tests (implicitly via requiring collection-store),
// raven-js attempts to read a property on window which fails. the `null`.
if (typeof window !== 'undefined') {
  require('raven-js');
}

const Request = superagent.Request;

superagent.parse['application/vnd.clara.v1+json'] = JSON.parse;

const oldPromise = Request.prototype.promise;

function isApiRequest(req) {
  return req.url.includes(config.api.host);
}

function loggedOut(error) {
  const req = error.res.req;
  return error.status === 401 && error.body.type === 'unauthorized' && isApiRequest(req);
}

function exoPatch() {
  function newPromise() {
    if (isApiRequest(this)) {
      this.withCredentials();
      this.set('Client-Version', `exo:${ packageVersion }`);
      this.set('Accept', 'application/json');
      if (window.FEATURE_FLAGS) {
        this.set(
          'Feature-Flags',
          window.FEATURE_FLAGS.join(','),
        );
      }
    }

    return oldPromise.apply(this, arguments).catch(superagent.SuperagentPromiseError, (error) => {

      // superagent-bluebird-promise wraps potentially non-http errors in its own wrapper
      // anyways, which can mask other useful errors (i.e. network timeouts)
      //
      // https://github.com/KyleAMathews/superagent-bluebird-promise/blob/master/index.js#L74
      if (!error.status && error.originalError instanceof Error) {
        throw error.originalError;
      }

      if (loggedOut(error)) {

        // TODO(stephen): would be nice to have an in-exo handling flow for this

        const currentUrl = encodeURIComponent(window.location.toString());
        window.location = `${ config.api.host }/auth/login?next=${ currentUrl }`;
      } else {
        throw error;
      }
    });
  }

  if (typeof window !== 'undefined') {
    Request.prototype.promise = newPromise;
  }
}

function APIResponseError(res, body) {
  this.name = 'APIResponseError';
  this.type = body.type;
  this.body = body;
  this.message = body.message;
  this.res = res;
  this.stack = (new Error()).stack;
}

APIResponseError.prototype = Object.create(Error.prototype);
APIResponseError.prototype.constructor = APIResponseError;

function fetchApi(url, options = {}) {
  const headers = options.headers || new Headers();
  headers.append('Accept', 'application/json');

  const apiOptions = {
    redirectOnUnauthorized: true,
    ...options,
    credentials: 'include',
    headers: headers,
  };

  if (apiOptions.json) {
    headers.append('Content-Type', 'application/json');
    apiOptions.body = JSON.stringify(apiOptions.json);
    delete apiOptions.json;
  }

  const { redirectOnUnauthorized } = apiOptions;
  delete apiOptions.redirectOnUnauthorized;

  // If paging details are provided modify request accordingly.
  let page = 1;
  let perPage = 10;
  if (options.page || options.perPage) {
    page = options.page || page;
    perPage = options.perPage || perPage;
    delete apiOptions.page;
    delete apiOptions.perPage;

    const pagingParams = `page=${ page }&per_page=${ perPage }`;
    const paramJoiner = url.indexOf('?') === -1 ? '?' : '&';
    url = url + paramJoiner + pagingParams;
  }

  return fetch(`${ config.api.host }${ url }`, apiOptions).then((res) => {
    const buildPagingDict = (linkHeader, pagingDict) => {
      const links = parse(linkHeader);
      const linkKeys = ['last', 'first', 'prev', 'next'];

      return linkKeys.reduce((acc, key) => {
        if (links[key]) {
          return { ...acc, [key]: parseInt(links[key].page, 10) };
        }
        return acc;
      }, pagingDict);
    };

    const handleBody = (body) => {
      if (!res.ok) {
        const error = new APIResponseError(res, body);

        if (res.status === 401 && body.type === 'unauthorized') {
          if (redirectOnUnauthorized) {
            setLocationHref('/login');
          }
          throw new PromiseRejectionSentinelError('unauthorized', error);
        }

        throw error;
      }

      let paging;
      if (res.headers.get('Link')) {
        paging = buildPagingDict(res.headers.get('Link'),  { current: page, perPage });
      }

      return { body, res, paging };
    };

    // `PUT` requests return a 204 NO CONTENT when successful.
    if (res.status === 204) {
      return {};
    }

    const contentType = res.headers.get('Content-Type');
    if (contentType && contentType.includes('application/json')) {
      return res.json().then(handleBody);
    } else {
      return res.text().then(handleBody);
    }
  });
}

const taskPromises = {};

const checkTasks = debounce(() => {
  const taskIds = Object.keys(taskPromises);

  if (taskIds.length > 0) {
    fetchApi(`/tasks/results?${querystring.stringify({
        task_ids: taskIds.join(','),
      })}`,
      {
        method: "get"
      }
    ).then((response) => {
      response.body.results.forEach(result => {
        const promise = taskPromises[result.task_id];
        if (promise) { // Guard against already-deleted promise race condition
          if (result.state === 'SUCCESS') {
            promise.resolve(result.result);
            delete taskPromises[result.task_id];
          } else if (result.error) {
            promise.reject(new Error(result.error));
            delete taskPromises[result.task_id];
          } else {
            promise.retries -= 1;
            if (promise.retries === 0) {
              promise.reject(new Error('Failed to get task results'));
              delete taskPromises[result.task_id];
            }
          }
        }
      });

      if (Object.keys(taskPromises).length > 0) {
        setTimeout(checkTasks, 1000);
      }
    });
  }
}, 100);

function getTaskResult(taskId, retries = 30) {
  let taskResolve;
  let taskReject;
  taskPromises[taskId] = taskPromises[taskId] || {
    promise: new BluebirdPromise((resolve, reject) => {
      taskResolve = resolve;
      taskReject = reject;
    }),
    resolve: taskResolve,
    reject: taskReject,
    retries: retries,
  };

  checkTasks();

  return taskPromises[taskId].promise;
}

function generateFieldExpansion(expansions) {
  // Serializes the expansion object format expected by the collections module
  // into the string format expected by API
  return Object.entries(expansions).map(([key, subExpansions]) => {
    const sub = generateFieldExpansion(subExpansions);
    if (sub === '') {
      return key;
    } else {
      return `${ key }{${ sub }}`;
    }
  }).join(',');
}

export default superagent;
export { exoPatch, APIResponseError, fetchApi, getTaskResult, generateFieldExpansion };
