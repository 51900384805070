/** @format */
import _ from "lodash";
import Raven from "raven-js";
import { consumePayload, expand } from "../../collections/module";
import { createAction } from "redux-actions";
import {
  handleActions,
  createAsyncAction,
  wrapPromiseToThunk
} from "../../utils/redux-actions";
import { REPORT_ERROR } from "./error";
import { fetchApi } from "../../utils/request";
import {
  Assistant,
  Contact,
  Customer,
  Organization,
  User
} from "../../collections/schema";
import fetchGroup from "../utils/fetch-group";
import callAnalytics from "../../utils/segment";
import { PromiseRejectionSentinelError } from "../../utils/promise-rejection-sentinel-error";
import { SET_FEATURE_FLAG } from "./feature-flags";

export const MOUNT_POINT = "session";

export const CLEAR_CURRENT_USER = createAction("SESSION/CLEAR_CURRENT_USER");
const SET_CURRENT_USER = createAction("SESSION/SET_CURRENT_USER");
const LOGOUT = createAsyncAction("SESSION/LOGOUT");
const FETCH_CURRENT_USER = createAsyncAction("SESSION/FETCH_CURRENT_USER");

export const fetchCurrentUser = wrapPromiseToThunk(
  FETCH_CURRENT_USER,
  ({ dispatch }, redirectOnUnauthorized = true) => {
    const userFields = `
    customer{
      contact{
        google_accounts{email_address},
        microsoft_accounts{email_address},
        company,email_addresses,
        default_email,
        virtual_details,
      },
      active_assistant{
        contact{
          microsoft_accounts,
          default_email,
        }
      },
      email_address_verifications,
      organization{
        billing_group{
          billing_point_of_contact{default_email}
        }
      }
    }
    `.replace(/[\s\n]*/g, "");
    return fetchApi(`/users/me?fields=${userFields}`, {
      redirectOnUnauthorized
    })
      .then(({ body }) => {
        _.union(
          _.get(body, "customer.feature_flags", []),
          _.get(body, "customer.organization.feature_flags", [])
        ).forEach(flag => dispatch(SET_FEATURE_FLAG(flag)));

        if (body.customer) {
          if (body.customer.organization) {
            delete body.customer.organization.feature_flags;
          }
        }

        const userId = body.id;
        const userEmail = body.email;
        Raven.setUserContext({
          id: userId,
          email: userEmail
        });
        dispatch(consumePayload(body, User));
        callAnalytics("identify", userId, { email: userEmail });

        const stripeCustomerId = _.get(
          body,
          "customer.organization.billing_group.stripe_customer_id"
        );
        if (stripeCustomerId && window.profitwell) {
          window.profitwell("user_id", stripeCustomerId);
        }

        const contactName = _.get(body, "customer.contact.name");
        if (contactName && window.zE && window.zE.identify) {
          window.zE.identify({
            name: contactName,
            email: userEmail
          });
        }

        dispatch(SET_CURRENT_USER(userId));
        return userId;
      })
      .catch(error => {
        if (
          !redirectOnUnauthorized &&
          error instanceof PromiseRejectionSentinelError
        ) {
          return null;
        } else {
          throw error;
        }
      });
  },
  REPORT_ERROR
);

export const logout = wrapPromiseToThunk(
  LOGOUT,
  ({ dispatch }) =>
    fetchApi("/auth/logout", { method: "post" }).then(() => {
      Raven.setUserContext();
      dispatch(CLEAR_CURRENT_USER());
    }),
  REPORT_ERROR
);

export const reducer = handleActions(
  {
    [FETCH_CURRENT_USER]: {
      begin: state => ({ ...state, fetchGroup: state.fetchGroup.startFetch() }),
      next: state => ({
        ...state,
        fetchGroup: state.fetchGroup.completeFetch()
      }),
      throw: state => ({
        ...state,
        fetchGroup: state.fetchGroup.completeFetch()
      })
    },
    [SET_CURRENT_USER]: (state, action) => ({
      ...state,
      currentUserId: action.payload
    }),
    [CLEAR_CURRENT_USER]: state => ({ ...state, currentUserId: null })
  },
  {
    fetchGroup: fetchGroup(false),
    currentUserId: null
  }
);

export const getIsLoading = state => state[MOUNT_POINT].fetchGroup.isFetching;

export const getCurrentUser = state => {
  const currentUserId = state[MOUNT_POINT].currentUserId;
  return currentUserId ? expand(User, currentUserId, {}, state) : null;
};

export const getCurrentCustomer = (state, expansions = {}) => {
  const currentUser = getCurrentUser(state);
  return currentUser
    ? expand(Customer, currentUser.customer, expansions, state)
    : null;
};

export const getCurrentCustomerContact = state => {
  const currentCustomer = getCurrentCustomer(state);
  return currentCustomer
    ? expand(Contact, currentCustomer.contact, {}, state)
    : null;
};

export const getActiveAssistant = state => {
  const currentCustomer = getCurrentCustomer(state);
  if (!currentCustomer || !currentCustomer.active_assistant) return null;

  return expand(Assistant, currentCustomer.active_assistant, {}, state);
};

export const getActiveAssistantContact = state => {
  const currentCustomer = getCurrentCustomer(state);
  if (!currentCustomer || !currentCustomer.active_assistant) return null;

  return expand(
    Assistant,
    currentCustomer.active_assistant,
    { contact: {} },
    state
  ).contact;
};

export const getCurrentBillingGroup = state => {
  const currentCustomer = getCurrentCustomer(state);
  if (!currentCustomer) return null;

  return expand(
    Organization,
    currentCustomer.organization,
    {
      billing_group: {
        billing_point_of_contact: {
          default_email: {}
        }
      }
    },
    state
  ).billing_group;
};

export const getCurrentCustomerProfile = state => {
  const currentCustomer = getCurrentCustomer(state);
  if (!currentCustomer) return null;
  const contact = expand(
    Contact,
    currentCustomer.contact,
    { company: {} },
    state
  );

  const assistant = expand(
    Assistant,
    currentCustomer.active_assistant,
    { contact: ""},
    state
  )

  return {
    fullName: contact.name,
    company: _.get(contact, "company.name"),
    assistantEmail: assistant.contact.default_email
  };
};

export const getActiveZoomAccount = state => {
  const currentCustomer = getCurrentCustomer(state);
  if (!currentCustomer) return null;

  return expand(Organization, currentCustomer.organization, {}, state)
    .active_zoom_account;
};

export const getActiveMicrosoftTeamsBool = state => {
  const currentCustomer = getCurrentCustomer(state);
  if (!currentCustomer) return null;
  return expand(Organization, currentCustomer.organization, {}, state)
   .active_microsoft_teams_bool;
};

export const getActiveSlack = state => {
  const currentCustomer = getCurrentCustomer(state);
  if (!currentCustomer) return null;

  return expand(Organization, currentCustomer.organization, {}, state)
    .active_slack_account;
};

export const getIsCoderpadActive = state => {
  const currentCustomer = getCurrentCustomer(state);
  if (!currentCustomer) return null;

  return !!expand(Organization, currentCustomer.organization, {}, state)
    .coderpad_api_key_last_4;
};

export const getIsHackerrankActive = state => {
  const currentCustomer = getCurrentCustomer(state);
  if (!currentCustomer) return null;

  return !!expand(Organization, currentCustomer.organization, {}, state)
    .hackerrank_api_key_last_4;
};

export const getIsMicrosoft = state => {
  const currentCustomer = getCurrentCustomer(state, {
    contact: {
      microsoft_accounts: {}
    }
  });

  return currentCustomer.contact.microsoft_accounts.length > 0;
};

export const getIsGoogle = state => {
  const currentCustomer = getCurrentCustomer(state, {
    contact: {
      google_accounts: {}
    }
  });

  return currentCustomer.contact.google_accounts.length > 0;
}
