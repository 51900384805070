/** @format */
import React, { Component } from "react";
import SharePopup from "./share-popup.react";
import { Link } from "react-router-dom";
import config from "../../../config";

class TopBanner extends Component {
  constructor(props) {
    super(props);

    this.state = {
      shareOpen: false,
      optionsOpen: false,
      shareLink: this.props.shareLink,
      isGuest: this.props.isGuest,
      liked: this.props.liked
    }

    this.toggleEdit = this.toggleEdit.bind(this)
    this.toggleShare = this.toggleShare.bind(this)
    this.handleShare = this.handleShare.bind(this)
    this.handleToggleLiked = this.handleToggleLiked.bind(this)
  }

  componentDidUpdate(prevProps) {
    if (this.props.liked !== prevProps.liked) {
      this.setState({
        liked: this.props.liked
      })
    }
  }

  toggleEdit() {
    this.setState({
      editOpen: !this.state.editOpen
    })
  }

  handleShare() {
    this.props.generateBypassLink(this.props.slug)

    this.setState({
      shareOpen: !this.state.shareOpen
    })
  }

  toggleShare() {
    this.setState({
      shareOpen: !this.state.shareOpen
    })
  }

  handleToggleLiked() {
    this.props.toggleLiked(this.props.uuid)
    this.setState({
      liked: !this.state.liked
    })
  }

  renderTitle() {
    if(this.state.isGuest){
      return(
        <a className="TopBanner-guestLogo" href={config.services.site.host}>
          <img className="TopBanner-fullHeight" src="/images/clara-logo-v2.svg" />
        </a>
      )
    } else {
      return(
        <div className="TopBanner-title">
          {this.props.title}
        </div>
      )
    }
  }

  renderShare() {
    if (this.state.shareOpen) {
      return (
        <div className="TopBanner-sharePopupContainer">
          <SharePopup 
            shareLink={this.props.shareLink}
            toggleShare={this.toggleShare}
          />
        </div>
      )
    }
    return null
  }

  render() {
    return (
      <div className={this.props.isLong ? "TopBanner-long" : "TopBanner"}>
        <div className="TopBanner-titleContainer">
          {(!this.state.isGuest && this.props.onBack) &&
            <Link className="TopBanner-backButton" to={this.props.onBack}>
              <img src="/images/side-navigation-left.svg" />
            </Link>
          }
          {this.renderTitle()}
        </div>
        <div className="TopBanner-controlContainer">
          {(!this.state.isGuest && this.props.toggleLiked) && (
            this.state.liked ? 
              <div className="TopBanner-controlButton" onClick={() => this.handleToggleLiked(this.props.uuid)}>
                <img className="" src="/images/liked.svg" />
              </div>
              :
              <div className="TopBanner-controlButton" onClick={() => this.handleToggleLiked(this.props.uuid)}>
                <img className="" src="/images/title-heart.svg" />
              </div>
          )}

          {(
            this.props.title === "Library" &&
            (
              window.location.pathname.includes("library/my-meetings") ||
              window.location.pathname.includes("library/team-meetings")
            )
          ) &&
            <input className="TopBanner-searchBar"
              placeholder="Search by keywords, titles, etc..." 
              id="search"
              autoComplete="off"
              onKeyDown={this.props.handleOnKeyDown}
            />
          }

          {(!this.state.isGuest && this.props.generateBypassLink) && 
            <div className="TopBanner-shareButtonContainer">
              {this.state.shareOpen ?
                <div className="TopBanner-shareButtonActive" onClick={this.handleShare}>
                  <div className="TopBanner-shareIconActive" />
                  <div className="TopBanner-shareTextActive">
                    Share
                  </div>
                </div>
                :
                <div className="TopBanner-shareButton" onClick={this.handleShare}>
                  <div className="TopBanner-shareIcon" />
                  <div className="TopBanner-shareText">
                    Share
                  </div>
                </div>
              }
              {this.renderShare()}
            </div>
          }
        </div>
      </div>
    )
  }
}

TopBanner.propTypes = {
};

export default TopBanner;
