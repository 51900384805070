/** @format */
import { connect } from "react-redux";

import GSuiteConferenceRooms from "../../components/dashboard/integrations/gsuite.react";
import {
  fetchConferenceRooms,
  fetchConnectedEmailAddress,
  getAllConferenceRooms,
  getAvailableResources,
  getConnectedEmailAddress,
  getIsLoading
} from "../../modules/conference-rooms";
import {
  addScopeAndSyncResources,
  updateResources
} from "../../modules/conference-rooms-configuration";
import { REPORT_ERROR } from "../../modules/error";
import {
  getActiveAssistantContact,
  getIsLoading as getIsLoadingSession
} from "../../modules/session";
import { isCustomDomainClara } from "../../utils/assistant";
import createInitiallyLoadingContainer from "../create-initially-loading-container.react";

const mapStateToProps = state => {
  return {
    hasConferenceRooms: isCustomDomainClara(
      getActiveAssistantContact(state).default_email
    ),
    isLoading: getIsLoading(state) || getIsLoadingSession(state),
    isConfigLoading: state.conferenceRoomsConfiguration.fetchGroup.isFetching,
    conferenceRooms: getAllConferenceRooms(state),
    connectedEmailAddress: getConnectedEmailAddress(state),
    availableResources: getAvailableResources(state)
  };
};

const mapDispatchToProps = (dispatch, { customer }) => ({
  onLoad() {
    dispatch(fetchConferenceRooms(customer.contact_id));
    dispatch(fetchConnectedEmailAddress());
  },

  onAuthError(errorMessage) {
    return dispatch(REPORT_ERROR(new Error(errorMessage)));
  },

  onAddScopeAndSyncResources(code) {
    return dispatch(addScopeAndSyncResources(code));
  },

  onUpdateResources(resources) {
    return dispatch(updateResources(resources));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(createInitiallyLoadingContainer(GSuiteConferenceRooms));
