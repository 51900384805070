/** @format */
import { handleActions } from "../../../utils/redux-actions";
import { APIResponseError } from "../../../utils/request";

const getRequestId = error => {
  if (error instanceof APIResponseError && error.res && error.res.headers) {
    return error.res.headers.get("request-id");
  }
};

const getErrorMessage = error => {
  if (error instanceof APIResponseError && error.body && error.body.message) {
    return error.body.message;
  } else if (error && error.message) {
    return error.message;
  }
};

/**
 * Creates a reducer for capturing errors encountered by an asynchronous action.
 *
 * Use this when you need to save errors encountered by an asynchronous action in your state tree.
 *
 * Arguments:
 *   action - Asynchronous action from which to reduce errors.
 *   options.clearAction - Action to reset reducer to initial state.
 *   options.defaultErrorMessage - Error message to fallback on if none can be extracted.
 */
export default (action, options = {}) => {
  const {
    clearAction,
    defaultErrorMessage = "An unknown error occurred, please try again."
  } = options;

  const initialState = null;
  return handleActions(
    {
      [action]: {
        next: () => initialState,
        throw: (state, { payload: error }) => ({
          requestId: getRequestId(error),
          message: getErrorMessage(error) || defaultErrorMessage
        })
      },
      [clearAction]: () => initialState
    },
    initialState
  );
};
