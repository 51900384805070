/** @format */
import PropTypes from "prop-types";

import React, { Component } from "react";
import TextButton from "../../../shared/text-button.react";
import { ResourceSyncedCalendarShape } from "../../../../utils/shapes";

export default class SelectAllButton extends Component {
  constructor(props) {
    super(props);

    this._unselectAll = props.onChange.bind(this, []);
    this._selectAll = props.onChange.bind(
      this,
      props.availableResources.map(r => r.provider_id)
    );
  }
  render() {
    if (
      this.props.availableResources.length ===
      this.props.selectedResourceIds.length
    ) {
      return <TextButton onClick={this._unselectAll}>Unselect All</TextButton>;
    } else {
      return <TextButton onClick={this._selectAll}>Select All</TextButton>;
    }
  }
}
SelectAllButton.propTypes = {
  availableResources: PropTypes.arrayOf(ResourceSyncedCalendarShape).isRequired,
  onChange: PropTypes.func.isRequired,
  selectedResourceIds: PropTypes.arrayOf(PropTypes.string).isRequired
};
SelectAllButton.displayName = "SelectAllButton";
