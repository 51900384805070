import Moment from 'moment-timezone';
import { extendMoment } from 'moment-range';
const moment = extendMoment(Moment);

import ChannelUtils from './channel';
import EmailMessageJobUtils from './email-message-job';

import { max } from 'lodash';
import ContractorUtils from './contractor';
import { createZonedMoment } from './time';
import EscalationUtils from './escalation';
import ParticipantUtils from './participant';
import { VIRTUAL_KIND_NEEDS_CALLER } from './virtual';
const EscalationLevels = EscalationUtils.EscalationLevels;


class Job {
  static zonedMoment(job) {
    // returning a bound moment from this getter so that downstream users can
    // cache an instance if they like and avoid computing timezone many
    // times.
    return createZonedMoment(job.meeting_timezone || 'UTC');
  }

  static moment(job, ...args) {
    // returning a bound moment from this getter so that downstream users can
    // cache an instance if they like and avoid computing timezone many
    // times.
    return this.zonedMoment(job)(...args);
  }

  static localizedRange(job, range) {
    const castedRange = {
      start: null,
      end: null,
    };
    if (range.start && range.end) {
      // cast moment to customer's timezone, dropping conversion.
      // This is necessary because datepicker automatically returns the
      // moment in the browser's timezone, incorrectly.
      // Also converts to iso8601.
      // Also adds 24 hrs to range.end because datepicker returns the
      // beginning of the day, not the end.

      let timezone = job.primary_customer ? (
        job.primary_customer.contact.timezone
      ) : job.assistant.contact.timezone;
      if (!timezone) {
        timezone = 'UTC';
      }

      castedRange.start = moment.tz(
        moment.isMoment(range.start) ? range.start.format('YYYY-MM-DDT00:00:00') : range.start,
        timezone
      ).format();
      castedRange.end = moment.tz(
        moment.isMoment(range.end) ? range.end.format('YYYY-MM-DDT00:00:00') : range.end,
        timezone
      ).add(1, 'day').format();
    }

    return castedRange;
  }

  static lastClaraResponse(job) {
    /** Returns the last email message job on a job that is from
    Clara. If there is no response from Clara on the job, null is returned.
    */
    const claraEmailMessageJobs = job.job_email_messages.filter(
      emj => EmailMessageJobUtils.isFromAssistant(emj)
    );
    if (claraEmailMessageJobs.length > 0) {
      return max(claraEmailMessageJobs, emj => emj.id);
    }
  }

  static nonClaraEmailMessageJobs(job) {
    return job.job_email_messages.filter(
      emj => !EmailMessageJobUtils.isFromAssistant(emj)
    );
  }

  static lastNonClaraResponse(job) {
    /** Returns the last email message job on a job that is not from
    Clara. If there is no response from a non-Clara on the job, null is returned.
    */
    const nonClaraEmailMessageJobs = Job.nonClaraEmailMessageJobs(job);
    if (nonClaraEmailMessageJobs.length > 0) {
      return max(nonClaraEmailMessageJobs, emj => emj.id);
    }
  }

  static channelName(job) {
    const channelType = this.channelType(job);
    if (!channelType) {
      return '';
    }

    const category = channelType === 'inPerson' ? this.locationCategory(job) : null;
    const displayName = ChannelUtils.channelTypeDisplayData[channelType].displayName;
    const locationLabel = ChannelUtils.locationCategoryDisplayData[category];

    return `${ displayName }${ locationLabel ? `\u00a0(${ locationLabel.displayName })` : '' }`;
  }

  static channelType(job) {
    return job.high_level_channel_type === 'virtual' ?
      (job.virtual_detail && job.virtual_detail.kind) ||
      job.virtual_category ||
      job.high_level_channel_type :
      job.high_level_channel_type;
  }

  static channelSummary(job, participants, primary_attendee, guestAttendee) {
    const channelType = this.channelType(job);

    if (channelType === 'inPerson') {
      return this.referenceAddress(job) || 'TBD';
    } else if (job.virtual_detail) {
      const caller = job.caller;
      if (caller === 'customer') {
        const customerName = primary_attendee ? ParticipantUtils.contextualName(
          primary_attendee,
          participants,
        ) : 'Customer';

        return `${ customerName } to call`;
      } else {
        if (!VIRTUAL_KIND_NEEDS_CALLER.has(job.virtual_detail.kind)) {
          return '';
        }

        const participantName = guestAttendee ? ParticipantUtils.contextualName(
          guestAttendee,
          participants,
        ) : 'Participant';

        return `${ participantName } to call`;
      }
    } else if (job.custom_virtual_detail) {
      return job.custom_virtual_detail.value;
    } else {
      return 'TBD';
    }
  }

  static workStatus(job) {
    const state = job.state;
    const escalation_level = job.escalation_level;

    if (state === 'MERGED') {
      return 'Merged';
    }
    if (state === 'PROCESSED') {
      return 'Complete';
    }
    if (state === 'WAITING') {
      return 'Snoozed';
    }

    // if escalation is still being processed,
    // show 'ESCALATED' instead of state
    if (escalation_level !== 'NORMAL') {
      const level = EscalationLevels[escalation_level] ? EscalationLevels[escalation_level].label : escalation_level;
      return `Escalated to ${ level }`;
    }
    if (state === 'READY_FOR_PROCESSING') {
      return 'In Queue';
    }
    if (state === 'PROCESSING') {
      return 'Assigned';
    }
  }

  static referenceAddress(job) {
    return job.location && (
      this.parsedLocation(job).formatted_address || this.parsedLocation(job).address
    );
  }

  static isSandboxed(job) {
    if (!job.assigned_contractor) {
      return false;
    }

    return ContractorUtils.isSandboxed(job.assigned_contractor);
  }

  static activeJobSession(job) {
    if (!job.assigned_contractor) {
      return;
    }

    if (job.job_sessions.length === 0) {
      return;
    }

    const last_session = max(job.job_sessions, 'id');

    if (last_session.contractor.id === job.assigned_contractor.id) {
      return last_session;
    }
  }

  static uncanceledEvents(job) {
    return job.events.filter(evt => !evt.canceled);
  }

  static confirmedEvent(job) {
    return this.uncanceledEvents(job).find(evt => evt.kind === 'confirmed');
  }

  static allConfirmedEvents(job) {
    return this.uncanceledEvents(job).filter(
      evt => [
        'confirmed',
        'confirmed_split',
        'confirmed_external',
      ].includes(evt.kind)
    );
  }

  static parsedLocation(job) {
    // rip out after https://github.com/clara-labs/clara-api/pull/3227 ships
    //    and replace existing use sites with job.location
    if (typeof job.location === 'string') {
      return JSON.parse(job.location);
    } else {
      return job.location || {};
    }
  }

  static locationCategory(job) {
    const location = this.parsedLocation(job);
    if (location) {
      return location.category;
    }
  }

  static threadedMessages(job) {
    return job.job_email_messages.sort((a, b) => {
      if (a.email_message.header_date === b.email_message.header_date) {
        return 0;
      }
      return a.email_message.header_date > b.email_message.header_date ? 1 : -1;
    })
    .reduce((buckets, jobEmailMessage) => {
      const message = jobEmailMessage.email_message;
      const threadId = jobEmailMessage.thread_id;
      if (!threadId) {
        buckets[jobEmailMessage.header_message_id] = [message];
      } else {
        if (!buckets[threadId]) {
          buckets[threadId] = [];
        }

        buckets[threadId].push(message);
      }

      return buckets;
    }, {});
  }

  static threadIndexForMessageId(job, header_message_id) {
    if (!header_message_id) {
      return null;
    }

    const threads = this.threadedMessages(job);
    const threadIds = Object.keys(threads);
    const emailsByHeaderMessageId = this.emailsByHeaderId(job);

    const thread = threadIds.find(id => threads[id].includes(emailsByHeaderMessageId[header_message_id]));
    return threadIds.indexOf(thread);
  }

  static emailsByHeaderId(job) {
    return job.job_email_messages.reduce((memo, jem) => {
      memo[jem.email_message.header_message_id] = jem.email_message;
      return memo;
    }, {});
  }
}

export default Job;
