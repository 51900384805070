/** @format */
import PropTypes from "prop-types";
import React, { Component } from "react";
import { Icon } from "vogue";
import { connect } from "react-redux";

import { verifyEmailToken } from "../modules/verify-email";
import Header from "./shared/header.react";
import FlexLoader from "./shared/flex-loader.react";
import querystringParse from "../../utils/querystring";

export class VerifyEmail extends Component {
  UNSAFE_componentWillMount() {
    const token = querystringParse().token;
    this.props.verifyEmailToken(token);
  }

  render() {
    const { loading, successful } = this.props;

    if (loading || successful === null) {
      return (
        <div className="VerifyEmail">
          <Header />
          <FlexLoader />
        </div>
      );
    }

    const headerLabel = successful ? "Success!" : "Uh oh!";
    const subtext = successful ? (
      <p className="VerifyEmail-subtext">
        Email successfully verified.&nbsp;
        <a className="VerifyEmail-link" href="/">
          Return to your dashboard.
        </a>
      </p>
    ) : (
      <p className="VerifyEmail-subtext">
        Unable to verify your email. Please try again or email&nbsp;
        <a className="VerifyEmail-link" href="mailto:support@claralabs.com">
          support@claralabs.com
        </a>.
      </p>
    );

    const [iconName, color] = successful
      ? ["green-check", "green"]
      : ["declined", "red"];
    return (
      <div className="VerifyEmail">
        <Header alwaysVisible={true} />
        <div className="VerifyEmail-container">
          <div className="VerifyEmail-card">
            <Icon name={iconName} color={color} className="VerifyEmail-icon" />
            <h1>{headerLabel}</h1>
            {subtext}
          </div>
        </div>
      </div>
    );
  }
}

VerifyEmail.propTypes = {
  loading: PropTypes.bool.isRequired,
  verifyEmailToken: PropTypes.func.isRequired,
  successful: PropTypes.bool
};

const mapStateToProps = state => {
  const {
    verifyEmail: { fetchGroup, successful }
  } = state;
  return {
    loading: fetchGroup.isFetching,
    successful
  };
};

const mapDispatchToProps = dispatch => ({
  verifyEmailToken(token) {
    return dispatch(verifyEmailToken(token));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VerifyEmail);
