/** @format */
import PropTypes from "prop-types";

import React from "react";
import { connect } from "react-redux";

import {
  getIsSettingSendEmailReceipts,
  setSendEmailReceipts
} from "../../../modules/team";
import { PreferenceContentBlock } from "../../layout/block.react";
import { BooleanPreference } from "../preferences/boolean.react";

const SendEmailReceipts = ({ isLoading, onToggle, sendEmailReceipts, bordered }) => (
  <PreferenceContentBlock
    title="Email receipts"
    subtitle="Should Clara send you a receipt every time you're charged?"
    supportLink="https://support.claralabs.com/article/101-how-can-i-view-my-invoice"
    bordered={bordered}
  >
    <BooleanPreference
      checked={sendEmailReceipts}
      isLoading={isLoading}
      onToggle={onToggle}
    />
  </PreferenceContentBlock>
);

SendEmailReceipts.propTypes = {
  onToggle: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  sendEmailReceipts: PropTypes.bool.isRequired,
  billingGroupId: PropTypes.number.isRequired
};

const mapStateToProps = state => ({
  isLoading: getIsSettingSendEmailReceipts(state)
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onToggle(checked) {
    return dispatch(setSendEmailReceipts(ownProps.billingGroupId, checked));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SendEmailReceipts);
