/** @format */
import PropTypes from "prop-types";

import React from "react";
import { Icon } from "vogue";

import IntegrationPageBase from "./page-base.react";

const Greenhouse = ({ greenhouseDetails: { isEnabled } }) => {
  const subtitle =
    "Bring Clara in with an email, and let it handle all your schedule coordination. " +
    "Once it schedules the interview, Clara will sync the details in your " +
    "candidateâs profile.";

  let content;
  if (!isEnabled) {
    content = (
      <div>
        Greenhouse is not setup for your team, contact{" "}
        <a href="mailto:support@claralabs.com">customer support</a> to start
        using Greenhouse with Clara.
      </div>
    );
  } else {
    content = [
      <div key="details">
        <div>Greenhouse syncing is active for your team.</div>
        <div className="GreenhouseIntegration-configurationDetails">
          Contact <a href="mailto:support@claralabs.com">customer support</a> to
          make changes to your team's Greenhouse setup.
        </div>
      </div>,
      <div
        key="status"
        className="GreenhouseIntegration-status GreenhouseIntegration-status--connected"
      >
        <Icon
          name="green-check"
          color="green"
          className="GreenhouseIntegration-statusIcon"
        />
        <span>Active</span>
      </div>
    ];
  }

  return (
    <IntegrationPageBase
      title="Greenhouse"
      subtitle={subtitle}
      image="/images/greenhouse-logo.png"
    >
      {content}
    </IntegrationPageBase>
  );
};

Greenhouse.propTypes = {
  greenhouseDetails: PropTypes.shape({
    isEnabled: PropTypes.bool.isRequired
  }).isRequired
};

export default Greenhouse;
