/** @format */
import classNames from "classnames";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { Link } from "react-router-dom";

import FeatureFlagged from "../../../containers/feature-flagged.react";
import { FEATURE_FLAGS } from "../../../utils/feature-flags";

export default class CustomerMenu extends Component {
  constructor(props) {
    super(props);

    this.state = { menuOpen: false };

    this._onClickComponent = this._onClickComponent.bind(this);
    this._onClickOutside = this._onClickOutside.bind(this);
    this._setWrapperRef = this._setWrapperRef.bind(this);
  }

  componentDidMount() {
    document.addEventListener("mousedown", this._onClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this._onClickOutside);
  }

  render() {
    const { company, customerIsAdmin, fullName, onLogout } = this.props;

    const componentClasses = classNames("CustomerMenu", {
      "CustomerMenu--open": this.state.menuOpen
    });

    const visibleContainerClasses = classNames(
      "CustomerMenu-visibleContainer",
      {
        "CustomerMenu-visibleContainer--open": this.state.menuOpen
      }
    );

    const menuClasses = classNames("CustomerMenu-menu", {
      "CustomerMenu-menu--open": this.state.menuOpen
    });

    return (
      <div className={componentClasses} ref={this._setWrapperRef}>
        <div
          className={visibleContainerClasses}
          onClick={this._onClickComponent}
        >
          <div className="CustomerMenu-customerDetails">
            <div className="CustomerMenu-name">{fullName}</div>
            <div className="CustomerMenu-company">{company}</div>
          </div>
          <div className="CustomerMenu-caretContainer">
            <div className="CustomerMenu-caret" />
          </div>
        </div>
        <div className={menuClasses}>
          <Link
            to="/profile"
            className="CustomerMenu-menuItem"
            onClick={this._onClickComponent}
          >
            Profile
          </Link>
          <div className="CustomerMenu-menuHeading">Team</div>
          <Link
            to="/team"
            className="CustomerMenu-menuItem"
            onClick={this._onClickComponent}
          >
            Members
          </Link>
          <FeatureFlagged featureFlag={FEATURE_FLAGS.NEW_LOCATIONS}>
            <Link
              to="/organization/locations"
              className="CustomerMenu-menuItem"
              onClick={this._onClickComponent}
            >
              Locations
            </Link>
          </FeatureFlagged>
          {customerIsAdmin && (
            <Link
              to="/settings/integrations"
              className="CustomerMenu-menuItem"
              onClick={this._onClickComponent}
            >
              Integrations
            </Link>
          )}
          <Link
            to="/billing"
            className="CustomerMenu-menuItem"
            onClick={this._onClickComponent}
          >
            Billing
          </Link>
          <div className="CustomerMenu-menuHeading">Resources</div>
          <a
            href="https://support.claralabs.com"
            target="_blank"
            className="CustomerMenu-menuItem"
            onClick={this._onClickComponent}
          >
            Help Desk
          </a>
          <Link
            to="/referral"
            className="CustomerMenu-menuItem"
            onClick={this._onClickComponent}
          >
            Referral
          </Link>
          <a className="CustomerMenu-menuItem" href="#" onClick={onLogout}>
            Logout
          </a>
        </div>
      </div>
    );
  }

  _onClickComponent() {
    this.setState({ menuOpen: !this.state.menuOpen });
  }

  _onClickOutside(event) {
    if (this._wrapperRef && !this._wrapperRef.contains(event.target)) {
      this.setState({ menuOpen: false });
    }
  }

  _setWrapperRef(node) {
    this._wrapperRef = node;
  }
}

CustomerMenu.propTypes = {
  company: PropTypes.string,
  customerIsAdmin: PropTypes.bool.isRequired,
  fullName: PropTypes.string.isRequired,
  onLogout: PropTypes.func.isRequired
};
