/** @format */

import React from "react";

import config from "../../../../config";
import { SlackAccountShape } from "../../../utils/shapes";

import TextButton from "../../shared/text-button.react";
import IntegrationPageBase from "./page-base.react";
import SlackAccountDetail from "./slack/slack-account-detail.react";
import SlackAccountStatus from "./slack/slack-account-status.react";

const Slack = ({ getActiveSlack }) => {
  const authUrl = `${
    config.api.host
  }/integrations/register/slack/`;

  const props = {
    title: "Slack",
    subtitle:
        "Let Clara broadcast your web meetings to Slack.",
    image: "/images/slack-logo.png",
    className: "SlackIntegration"
  };

  
  if (!getActiveSlack) {
    return (
      <IntegrationPageBase {...props}>
        <TextButton href={authUrl}>Connect Slack Workspace</TextButton>
      </IntegrationPageBase>
    );
  }

  return (
    <IntegrationPageBase {...props}>
      <div className="SlackIntegration-content">
        <SlackAccountDetail slackAccount={getActiveSlack}/>
        <SlackAccountStatus
          isValid={true}
          authUrl={authUrl}
          className="SlackIntegration-accountStatus"
        />
      </div>
    </IntegrationPageBase>
  );
};

Slack.propTypes = {
  activeSlackAccount: SlackAccountShape,
};

export default Slack;
