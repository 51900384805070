/** @format */
import PropTypes from "prop-types";

import React from "react";

import config from "../../../../config";
import { DEFAULT_MAP_ZOOM, MAP_MARKER_ICON } from "./index";

const StaticMap = ({ address, height, width, className }) => {
  const markerIcon = `icon:${MAP_MARKER_ICON}`;
  const marker = `${markerIcon}|${address}`;
  const mapUrl =
    "https://maps.googleapis.com/maps/api/staticmap" +
    `?center=${encodeURIComponent(address)}` +
    `&maptype=roadmap&zoom=${DEFAULT_MAP_ZOOM}` +
    `&size=${width}x${height}` +
    `&markers=${encodeURIComponent(marker)}` +
    `&key=${config.google.browserKey}`;

  return (
    <div
      style={{ "background-image": `url(${mapUrl})` }}
      className={className}
    />
  );
};

StaticMap.propTypes = {
  address: PropTypes.string.isRequired,
  className: PropTypes.string,
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired
};

export default StaticMap;
