/** @format */
import PropTypes from "prop-types";

import React, { Component } from "react";
import { Icon, TextField } from "vogue";

import { ResourceSyncedCalendarShape } from "../../../../utils/shapes";
import AvailableResourcesList from "./available-resources-list.react";

export default class AvailableResources extends Component {
  constructor(props) {
    super(props);

    this.state = { query: "" };

    this._onQueryChange = this._onQueryChange.bind(this);
  }

  render() {
    const { onChange, selectedResourceIds } = this.props;
    const { query } = this.state;

    const availableResources = this.props.availableResources.filter(
      resource =>
        (resource.name || "").toLowerCase().indexOf(query.toLowerCase()) !==
          -1 ||
        (resource.description || "")
          .toLowerCase()
          .indexOf(query.toLowerCase()) !== -1
    );

    let listContent;
    if (availableResources.length > 0) {
      listContent = (
        <AvailableResourcesList
          availableResources={availableResources}
          selectedResourceIds={selectedResourceIds}
          onChange={onChange}
        />
      );
    } else {
      listContent = (
        <div className="AvailableResources-noResults">
          No resources matched "{query}"
          <div className="AvailableResources-helperText">
            Please try again with different keywords.
          </div>
        </div>
      );
    }

    return (
      <div className="AvailableResources">
        <div className="AvailableResources-inputContainer">
          <div className="AvailableResources-iconContainer">
            <Icon name="search" className="AvailableResources-icon" />
          </div>
          <TextField
            className="AvailableResources-input"
            value={query}
            onChange={this._onQueryChange}
            placeholder="Search by name or description"
            spellCheck={false}
          />
        </div>
        {listContent}
      </div>
    );
  }

  _onQueryChange({ target: { value } }) {
    this.setState({ query: value });
  }
}

AvailableResources.propTypes = {
  availableResources: PropTypes.arrayOf(ResourceSyncedCalendarShape).isRequired,
  onChange: PropTypes.func.isRequired,
  selectedResourceIds: PropTypes.arrayOf(PropTypes.string).isRequired
};
