/** @format */
import PropTypes from "prop-types";

import React, { Component } from "react";
import { Modal } from "vogue";

class PartyModal extends Component {
  render() {
    const modalActions = [
      {
        key: "close",
        label: "Close",
        handler: this.props.onClose
      }
    ];

    let planSpecificCopy;
    if (this.props.seatType === "exec") {
      planSpecificCopy = (
        <p>
          You&#39;re now on the Executive Plan. Here are some things you can
          optionally set up:
          <ul>
            <li>
              <a
                className="PartyModal-link"
                target="_blank"
                href="https://support.claralabs.com/article/222-how-do-i-host-clara-on-my-domain"
              >
                Host Clara on your domain and change Clara&#39;s name
              </a>
            </li>
          </ul>
        </p>
      );
    } else if (this.props.seatType === "professional") {
      planSpecificCopy = (
        <p>
          You&#39;re on the Professional Plan. Here are some things you can
          optionally set up now:
          <ul>
            <li>
              <a
                className="PartyModal-link"
                target="_blank"
                href="https://support.claralabs.com/article/222-how-do-i-host-clara-on-my-domain"
              >
                Host Clara on your domain
              </a>
            </li>
          </ul>
        </p>
      );
    }

    return (
      <Modal
        actions={modalActions}
        open={this.props.isOpen}
        onRequestClose={this.props.onClose}
      >
        <div className="PartyModal">
          <img className="PartyModal-check" src="/images/check-grad.svg" />
          <h3 className="PartyModal-header">Success!</h3>
          <div className="PartyModal-message">
            <p>
              Congratulations on officially adding Clara to your team. We&#39;re
              excited to continue helping you reclaim time and focus on the
              things that matter.
            </p>
            {planSpecificCopy}
            <p>Welcome to the Clara family!</p>
          </div>
        </div>
      </Modal>
    );
  }
}
PartyModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  seatType: PropTypes.string
};

export default PartyModal;
