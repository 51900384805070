/** @format */
import { find } from "lodash";
import PropTypes from "prop-types";
import React from "react";
import { LOCATION_NAME_MAPPING } from "../../../../utils/location";
import { LocationShape } from "../../../utils/shapes";
import { SelectPreference } from "../../shared/attribute-select.react";

const DefaultLocationCategory = ({ selectedOption, locations }) => {
  const locationOptions = Object.entries(LOCATION_NAME_MAPPING).map(
    ([key, name]) => ({
      label: name,
      value: key
    })
  );

  const options = [
    ...locationOptions,
    { label: "Ask participant(s)", value: "ask_participant", disabled: false }
  ];

  const optionsWithDisabled = options.map(option => ({
    // Defining this property before the object spread operation is intentional to allow the value
    // for `disabled` to be overwritten for `ask_participant`.
    disabled: !find(
      locations,
      location => location.category === option.value && location.is_default
    ),
    ...option
  }));

  return (
    <SelectPreference
      selectedOption={selectedOption}
      attribute="default_location_category"
      options={optionsWithDisabled}
    />
  );
};

DefaultLocationCategory.propTypes = {
  selectedOption: PropTypes.string.isRequired,
  locations: PropTypes.arrayOf(LocationShape).isRequired
};

export default DefaultLocationCategory;
