/** @format */
import moment from "moment-timezone";

export const rruleTimeToMoment = rruleTime => {
  // XX:YY:ZZ format to moment
  const hour = rruleTime.split(":")[0];
  const minute = rruleTime.split(":")[1];
  const m = moment();
  m.hour(hour);
  m.minute(minute);
  m.second(0);
  m.millisecond(0);

  return m;
};

export const momentToSplitFormat = m => {
  // moment to { hour, minute, period } format
  return {
    hour: m.format("h"),
    minute: m.format("mm"),
    period: m.format("A")
  };
};

export const splitFormatToRruleTime = parsedTime => {
  // { hour, minute, period } to XX:YY:ZZ format
  // This is the inverse of the chain
  // this._rruleTimeToMoment(this._momentToSplitFormat())
  const m = moment(
    `${parsedTime.hour}:${parsedTime.minute} ${parsedTime.period}`,
    "h:mm A"
  );
  return m.format("HH:mm:ss");
};
