/** @format */
import PropTypes from "prop-types";

import React from "react";

import Header from "../dashboard/header/index.react";
import { Block, Content } from "../layout/block.react";
import ProgressBar from "./progress-bar.react";

const OnboardingView = ({ activeStep, children, stepCount }) => (
  <div className="OnboardingView">
    <Header hideCustomerMenu={true} />
    <div className="OnboardingView-scrollContainer">
      <div className="OnboardingView-content">
        <div className="OnboardingView-mainContent">
          <Block className="OnboardingView-block">
            <Content>
              <ProgressBar progress={(activeStep / stepCount) * 100} />
              <div className="OnboardingView-blockContent">{children}</div>
            </Content>
          </Block>
        </div>
      </div>
    </div>
  </div>
);

OnboardingView.propTypes = {
  children: PropTypes.node.isRequired,
  activeStep: PropTypes.number.isRequired,
  stepCount: PropTypes.number.isRequired
};

export default OnboardingView;
