/** @format */
import { combineReducers } from "redux";
import {
  handleActions,
  createAsyncAction,
  wrapPromiseToThunk
} from "../../utils/redux-actions";
import { fetchApi } from "../../utils/request";
import { reportError } from "./error";
import { handleFetchGroupActions } from "../utils/fetch-group";
import { consumePayload, expand } from "../../collections/module";
import { MeetingType } from "../../collections/schema";
import { HighLevelChannelType } from "../../utils/enums";

export const MOUNT_POINT = "meeting_types";

const FETCH_DEFAULT_MEETING_TYPES = createAsyncAction(
  "MEETING_TYPES/FETCH_DEFAULT_MEETING_TYPES"
);

const UPDATE_ATTRIBUTE = createAsyncAction(
  "MEETING_TYPES/UPDATE_ATTRIBUTE",
  x => x,
  (payload, args) => ({ id: args[0], key: args[1] }) // use meta to pass through key
);

export const fetchDefaultMeetingTypes = wrapPromiseToThunk(
  FETCH_DEFAULT_MEETING_TYPES,
  ({ dispatch }, contactId) => {
    return fetchApi(
      `/meeting_types?mandate=is_default:true,contact_id:${contactId}`
    ).then(({ body }) => {
      body.forEach(mt => dispatch(consumePayload(mt, MeetingType)));
      return { meetingTypeIds: body.map(mt => mt.id) };
    });
  },
  reportError
);

export const updateMeetingTypeAttribute = wrapPromiseToThunk(
  UPDATE_ATTRIBUTE,
  ({ dispatch, getState }, meetingTypeId, key, value) => {
    const meetingType = expand(MeetingType, meetingTypeId, {}, getState());

    return fetchApi(`/meeting_types/${meetingTypeId}`, {
      method: "put",
      json: {
        [key]: value
      }
    }).then(({ body }) => {
      dispatch(consumePayload({ ...meetingType, [key]: value }, MeetingType));
      return body;
    });
  },
  reportError
);

const defaultMeetingTypes = handleActions(
  {
    [FETCH_DEFAULT_MEETING_TYPES]: {
      next: (state, action) => action.payload.meetingTypeIds
    }
  },
  []
);

const inflightAttributes = handleActions(
  {
    [UPDATE_ATTRIBUTE]: {
      begin: (state, action) => ({
        ...state,
        [action.meta.id]: [...(state[action.meta.id] || []), action.meta.key]
      }),
      next: (state, action) => ({
        ...state,
        [action.meta.id]: state[action.meta.id].filter(
          attr => attr !== action.meta.key
        )
      }),
      throw: (state, action) => ({
        ...state,
        [action.meta.id]: state[action.meta.id].filter(
          attr => attr !== action.meta.key
        )
      })
    }
  },
  {}
);
export const reducer = combineReducers({
  defaultMeetingTypes,
  inflightAttributes,
  fetchGroups: combineReducers({
    defaultMeetingTypes: handleFetchGroupActions(FETCH_DEFAULT_MEETING_TYPES)
  })
});

export const getIsLoadingDefaultMeetingTypes = state =>
  state[MOUNT_POINT].fetchGroups.defaultMeetingTypes.isFetching;

export const getDefaultMeetingTypes = state =>
  expand(MeetingType, state[MOUNT_POINT].defaultMeetingTypes, {}, state);

export const getDefaultVirtualMeetingType = state =>
  getDefaultMeetingTypes(state).find(
    mt => mt.high_level_channel_type === HighLevelChannelType.virtual
  );

export const getDefaultInPersonMeetingType = state =>
  getDefaultMeetingTypes(state).find(
    mt => mt.high_level_channel_type === HighLevelChannelType.inPerson
  );

export const getInflightAttributes = (state, meetingTypeId) =>
  state[MOUNT_POINT].inflightAttributes[meetingTypeId] || [];
