/** @format */
import { mapKeys, camelCase, get, reduce } from "lodash";
import { combineReducers } from "redux";

import { consumePayload, expand } from "../../collections/module";
import { Organization } from "../../collections/schema";
import {
  handleActions,
  createAsyncAction,
  wrapPromiseToThunk
} from "../../utils/redux-actions";
import { fetchApi } from "../../utils/request";
import { requestErrorReducer } from "../utils/reducers";
import { handleFetchGroupActions } from "../utils/fetch-group";
import { reportAndSetError, reportError } from "./error";

export const MOUNT_POINT = "integrations";

const FETCH_SUMMARY = createAsyncAction("INTEGRATIONS/FETCH_SUMMARY");
const FETCH_LEVER = createAsyncAction("INTEGRATIONS/FETCH_LEVER");
const CONFIGURE_LEVER = createAsyncAction("INTEGRATIONS/CONFIGURE_LEVER");
const FETCH_CODERPAD = createAsyncAction("INTEGRATIONS/FETCH_CODERPAD");
const CONFIGURE_CODERPAD = createAsyncAction("INTEGRATIONS/CONFIGURE_CODERPAD");
const FETCH_ICIMS = createAsyncAction("INTEGRATIONS/FETCH_ICIMS");
const CONFIGURE_ICIMS = createAsyncAction("INTEGRATIONS/CONFIGURE_ICIMS");
const FETCH_HACKERRANK = createAsyncAction("INTEGRATIONS/FETCH_HACKERRANK");
const CONFIGURE_HACKERRANK = createAsyncAction(
  "INTEGRATIONS/CONFIGURE_HACKERRANK"
);
const DEAUTHORIZE_ZOOM = createAsyncAction("INTEGRATIONS/DEAUTHORIZE_ZOOM");

export const fetchSummary = wrapPromiseToThunk(
  FETCH_SUMMARY,
  () => fetchApi("/endo/integrations/summary").then(({ body }) => body),
  reportAndSetError
);

export const fetchLever = wrapPromiseToThunk(
  FETCH_LEVER,
  () => fetchApi("/endo/integrations/lever").then(({ body }) => body),
  reportAndSetError
);

export const configureLever = wrapPromiseToThunk(
  CONFIGURE_LEVER,
  ({ dispatch }, leverApiKey) =>
    fetchApi("/endo/integrations/actions/configure_lever", {
      method: "post",
      json: { lever_api_key: leverApiKey }
    }).then(({ body: { organization, ...rest } }) => {
      dispatch(consumePayload(organization, Organization));
      return rest;
    }),
  reportAndSetError
);

export const fetchCoderpad = wrapPromiseToThunk(
  FETCH_CODERPAD,
  () => fetchApi("/endo/integrations/coderpad").then(({ body }) => body),
  reportAndSetError
);

export const configureCoderpad = wrapPromiseToThunk(
  CONFIGURE_CODERPAD,
  ({ dispatch }, coderpadApiKey) =>
    fetchApi("/endo/integrations/actions/configure_coderpad", {
      method: "post",
      json: { coderpad_api_key: coderpadApiKey }
    }).then(({ body: { organization, ...rest } }) => {
      dispatch(consumePayload(organization, Organization));
      return rest;
    }),
  reportAndSetError
);

export const fetchIcims = wrapPromiseToThunk(
  FETCH_ICIMS,
  () => fetchApi("/endo/integrations/icims").then(({ body }) => body),
  reportAndSetError
);

export const configureIcims = wrapPromiseToThunk(
  CONFIGURE_ICIMS,
  ({ dispatch }, icimsCustomerId, icimsClientId, icimsClientSecret) =>
    fetchApi("/endo/integrations/actions/configure_icims", {
      method: "post",
      json: { 
        icims_customer_id: icimsCustomerId,
        icims_client_id: icimsClientId,
        icims_client_secret: icimsClientSecret
      }
    }).then(({ body: { organization, ...rest } }) => {
      dispatch(consumePayload(organization, Organization));
      return rest;
    }),
  reportAndSetError
);

export const fetchHackerrank = wrapPromiseToThunk(
  FETCH_HACKERRANK,
  () => fetchApi("/endo/integrations/hackerrank").then(({ body }) => body),
  reportAndSetError
);

export const configureHackerrank = wrapPromiseToThunk(
  CONFIGURE_HACKERRANK,
  ({ dispatch }, hackerrankApiKey) =>
    fetchApi("/endo/integrations/actions/configure_hackerrank", {
      method: "post",
      json: { hackerrank_api_key: hackerrankApiKey }
    }).then(({ body: { organization, ...rest } }) => {
      dispatch(consumePayload(organization, Organization));
      return rest;
    }),
  reportAndSetError
);

export const deauthorizeZoom = wrapPromiseToThunk(
  DEAUTHORIZE_ZOOM,
  ({ dispatch, getState }, zoomAccountId, organizationId) =>
    fetchApi(`/zoom_accounts/${zoomAccountId}/actions/deauthorize`, {
      method: "post"
    }).then(() => {
      // HACK(gavin): Get the state of the requested Organization. We won't have to do this if the
      // ZoomAccount is in the collections store or if its state is fully managed by this
      // integrations module.
      const organization = expand(Organization, organizationId, {}, getState());
      return dispatch(
        consumePayload(
          { ...organization, active_zoom_account: null },
          Organization
        )
      );
    }),
  reportError
);

const summary = handleActions(
  {
    [FETCH_SUMMARY]: {
      next: (_, action) =>
        reduce(
          action.payload,
          (acc, value, key) => ({
            ...acc,
            [camelCase(key)]: mapKeys(value, (__, subKey) => camelCase(subKey))
          }),
          {}
        )
    },
    [DEAUTHORIZE_ZOOM]: {
      next: state => ({
        ...state,
        zoom: {
          isEnabled: false,
          isValid: false
        }
      })
    }
  },
  {}
);

const lever = handleActions(
  {
    [FETCH_LEVER]: {
      next: (_, action) => mapKeys(action.payload, (__, key) => camelCase(key))
    },
    [CONFIGURE_LEVER]: {
      next: (_, action) => mapKeys(action.payload, (__, key) => camelCase(key))
    }
  },
  {}
);

const coderpad = handleActions(
  {
    [FETCH_CODERPAD]: {
      next: (_, action) => mapKeys(action.payload, (__, key) => camelCase(key))
    },
    [CONFIGURE_CODERPAD]: {
      next: (_, action) => mapKeys(action.payload, (__, key) => camelCase(key))
    }
  },
  {}
);

const icims = handleActions(
  {
    [FETCH_ICIMS]: {
      next: (_, action) => mapKeys(action.payload, (__, key) => camelCase(key))
    }, 
    [CONFIGURE_ICIMS]: {
      next: (_, action) => mapKeys(action.payload, (__, key) => camelCase(key))
    }, 
  },
  {}
);

const hackerrank = handleActions(
  {
    [FETCH_HACKERRANK]: {
      next: (_, action) => mapKeys(action.payload, (__, key) => camelCase(key))
    },
    [CONFIGURE_HACKERRANK]: {
      next: (_, action) => mapKeys(action.payload, (__, key) => camelCase(key))
    }
  },
  {}
);

const fetchGroups = combineReducers({
  fetchSummary: handleFetchGroupActions(FETCH_SUMMARY),
  fetchLever: handleFetchGroupActions(FETCH_LEVER),
  configureLever: handleFetchGroupActions(CONFIGURE_LEVER),
  fetchCoderpad: handleFetchGroupActions(FETCH_CODERPAD),
  configureCoderpad: handleFetchGroupActions(CONFIGURE_CODERPAD),
  fetchIcims: handleFetchGroupActions(FETCH_ICIMS),
  configureIcims: handleFetchGroupActions(CONFIGURE_ICIMS),
  fetchHackerrank: handleFetchGroupActions(FETCH_HACKERRANK),
  configureHackerrank: handleFetchGroupActions(CONFIGURE_HACKERRANK),
  deauthorizeZoom: handleFetchGroupActions(DEAUTHORIZE_ZOOM)
});

const errors = combineReducers({
  deauthorizeZoom: requestErrorReducer(DEAUTHORIZE_ZOOM)
});

export const reducer = combineReducers({
  summary,
  fetchGroups,
  lever,
  coderpad,
  icims,
  hackerrank,
  errors
});

export const getIsLoadingSummary = state =>
  state[MOUNT_POINT].fetchGroups.fetchSummary.isFetching;

export const getIsLoadingLever = state =>
  state[MOUNT_POINT].fetchGroups.fetchLever.isFetching;

export const getIsConfiguringLever = state =>
  state[MOUNT_POINT].fetchGroups.configureLever.isFetching;

export const getIsLoadingCoderpad = state =>
  state[MOUNT_POINT].fetchGroups.fetchCoderpad.isFetching;

export const getIsConfiguringCoderpad = state =>
  state[MOUNT_POINT].fetchGroups.configureCoderpad.isFetching;

  export const getIsLoadingIcims = state =>
  state[MOUNT_POINT].fetchGroups.fetchIcims.isFetching;

export const getIsConfiguringIcims = state =>
  state[MOUNT_POINT].fetchGroups.configureIcims.isFetching;

export const getIsLoadingHackerrank = state =>
  state[MOUNT_POINT].fetchGroups.fetchHackerrank.isFetching;

export const getIsConfiguringHackerrank = state =>
  state[MOUNT_POINT].fetchGroups.configureHackerrank.isFetching;

export const getSummary = state => state[MOUNT_POINT].summary;

export const getLeverDetails = state => state[MOUNT_POINT].lever;

export const getCoderpadDetails = state => state[MOUNT_POINT].coderpad;

export const getIcimsDetails = state => state[MOUNT_POINT].icims;

export const getHackerrankDetails = state => state[MOUNT_POINT].hackerrank;

export const getGreenhouseDetails = state =>
  get(state[MOUNT_POINT], "summary.greenhouse", null);

export const getGoogleMeetDetails = state =>
  get(state[MOUNT_POINT], "summary.googleMeet", null);

export const getIsDeauthorizingZoom = state =>
  state[MOUNT_POINT].fetchGroups.deauthorizeZoom.isFetching;

export const getDeauthorizeZoomError = state =>
  state[MOUNT_POINT].errors.deauthorizeZoom;
