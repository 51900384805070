/** @format */
export function computeStatus(customer) {
  if (customer.onboarding) {
    return "incomplete";
  } else if (customer.offboarding_start) {
    return "inactive";
  } else {
    return "active";
  }
}
