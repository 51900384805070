/** @format */

/* eslint-disable no-undef */
/* eslint-disable no-labels */
/* eslint-disable no-unused-vars */
/* eslint-disable no-use-before-define */
import React, { useState, useEffect, useRef } from "react";
import moment from "moment";

export const GroupTimepicker = (props) => {
  const [tz, setTz] = useState(props.localTimezone)
  const [participants, setParticipants] = useState([
    "Alex",
    "Beatriz",
    "Phil",
    // "Dima",
    // "Pooja",
    // "Matt",
  ])
  const [selectedParticipants, setSelectedParticipants] = useState([])
  const [coordinating, setCoordinating] = useState(false)

  const [timeMatrix, setTimeMatrix] = useState([])

  const [infoDisplayed, setInfoDisplayed] = useState(false)
  // HACK (Alex): timeMatrix is a 2D array which does not trigger a re-render when updated.
  // Flipping this boolean is a quick way to re-render everytime the timeMatrix is updated.
  const [timeMatrixChanged, setTimeMatrixChanged] = useState(false)

  // returns e.g. "1 hour 30 minutes"    
  const _formatDurationString = (duration) => {
    if (duration < 60) {
      return `${duration} minutes`
    }

    let durationString = `${parseInt(duration / 60, 10)} ${duration < 120 ? " hour " : " hours "} `

    if (duration % 60 !== 0) {
      durationString += duration % 60 + (duration % 60 === 1 ? " minute" : " minutes")
    }

    return durationString
  }

  const _renderMeetingTitle = () => {
    // if the participant's name is known, return the full title
    if (
      props.externalParticipantNames.length &&
      props.externalParticipantNames[0] !== null
    ) {
      return props.title
    }

    return `Meeting with ${props.internalParticipantNames[0]}`
  }

  const clearTimeSelections = () => {
    let emptyTimeMatrix = new Array(selectedParticipants.length + 1).fill(0).map(() => new Array(5).fill(0))
    setTimeMatrix(emptyTimeMatrix)
    setTimeMatrixChanged(!timeMatrixChanged)
  }

  const selectParticipant = (participant) => {
    setSelectedParticipants([...selectedParticipants, participant])
    let updatedTimeMatrix = new Array(selectedParticipants.length + 1).fill(0).map(() => new Array(5).fill(0))
    setTimeMatrix(updatedTimeMatrix)
    setTimeMatrixChanged(!timeMatrixChanged)
  }

  const selectTime = (row, col) => {
    let updatedTimeMatrix = timeMatrix

    updatedTimeMatrix[row][col] = updatedTimeMatrix[row][col] === 1 ? 0 : 1
  
    setTimeMatrix(updatedTimeMatrix)
    setTimeMatrixChanged(!timeMatrixChanged)
  }

  if (coordinating) {
    return (
      <div className="Timepicker">
        <div className="Timepicker-row">
          <div className="GroupTimepicker-participantSelect">
            <div className="GroupTimepicker-meetingDetails">
              <div className="GroupTimepicker-voteTimerContainer">
                <div
                  className="GroupTimepicker-voteTimer"
                  title="Voting ends in 1 day, 8 hours, and 27 minutes."
                >
                  {"1 day 8 hrs left"}
                </div>
              </div>
              <div className="GroupTimepicker-title">
                {_renderMeetingTitle()}
              </div>
              <div className="GroupTimepicker-additionalDetails">
                <div
                  className="GroupTimepicker-timeDetails"
                  onMouseOver={() => setInfoDisplayed(true)}
                  onMouseLeave={() => setInfoDisplayed(false)}
                >
                  <img src="/images/group-timepicker-info.svg" />
                  {infoDisplayed &&
                    <div className="GroupTimepicker-timeDetailsPopup">
                      <div className="Timepicker-infoRow">
                        <img className="Timepicker-infoIcon" src="/images/timepicker-clock.svg" />
                        <div className="Timepicker-infoText">
                          {_formatDurationString(props.duration)}
                        </div>
                      </div>
                      <div className={"Timepicker-infoRow"}>
                        <div className="Timepicker-timezoneInfo">
                          <img className="Timepicker-infoIcon" src="/images/timepicker-timezone.svg" />
                          <div className="Timepicker-infoText">
                            {props.localTimezone}
                          </div>
                        </div>
                      </div>
                    </div>
                  }
                </div>
              </div>
            </div>
            <table className="GroupTimepicker-coordinationTable">
              <tr className="GroupTimepicker-suggestedTimeRow">
                <div className="GroupTimepicker-rowLeftSide" />
                <div className="GroupTimepicker-rowRightSide">
                  {[1, 2, 3, 4, 5].map((suggestedTime, index) => {
                    return (
                      <th className="GroupTimepicker-suggestedTime">
                        <div className="GroupTimepicker-suggestedTimeTop">
                          <div className={index % 2 === 0 ?
                            "GroupTimepicker-suggestedTimeTextRed"
                            :
                            "GroupTimepicker-suggestedTimeTextGreen"
                          }>
                            {"3/6 Votes"}
                          </div>
                        </div>
                        <div className="GroupTimepicker-suggestedTimeBottom">
                          <div className="GroupTimepicker-suggestedTimeText">
                            {"Mon (Aug 15)"}
                          </div>
                          <div className="GroupTimepicker-suggestedTimeText">
                            {"2:00 PM"}
                          </div>
                        </div>
                      </th>
                    )
                  })}
                </div>
              </tr>
              <div>
              <div className="GroupTimepicker-participantTimeGrid">
                {selectedParticipants.map((participant, row) => {
                  return (
                    <tr className="GroupTimepicker-coordinationRow">
                      <div className="GroupTimepicker-rowLeftSide">
                        <td className="GroupTimepicker-coordinationName">
                          {participant}
                        </td>
                      </div>
                      <div className="GroupTimepicker-rowRightSide">
                        {[1, 2, 3, 4, 5].map((suggestedTimeButton, col) => {
                          return (
                            <td
                              className={
                                parseInt(timeMatrix[row][col], 10) === 1 ?
                                  "GroupTimepicker-suggestedTimeButtonSelected"
                                  :
                                  "GroupTimepicker-suggestedTimeButton"
                              }
                              onClick={() => selectTime(row, col)}
                            >
                              {/* {suggestedTimeButton} */}
                            </td>
                          )
                        })}
                      </div>
                    </tr>
                  )
                })}
                {participants.map((participant) => {
                  if (!selectedParticipants.includes(participant)) {
                    return (
                      <tr className="GroupTimepicker-coordinationRowDark">
                        <div className="GroupTimepicker-rowLeftSide">
                          <td className="GroupTimepicker-coordinationName">
                            {participant}
                          </td>
                        </div>
                        <div className="GroupTimepicker-rowRightSide">
                          {[1, 2, 3, 4, 5].map((suggestedTimeButton, index) => {
                            return (
                              <td
                                className={index % 2 === 0 ?
                                  "GroupTimepicker-suggestedTimeButtonDisabledEmpty"
                                  :
                                  "GroupTimepicker-suggestedTimeButtonDisabledGreen"
                                }
                                title={"You are not coordinating for this participant."}
                              >
                                {/* {suggestedTimeButton} */}
                              </td>
                            )
                          })}
                        </div>
                      </tr>
                    )
                  }

                  return null
                })}
              </div>
              </div>
            </table>
            {timeMatrix.some(row => row.includes(1)) &&
            <div className="GroupTimepicker-pollButtonContainer">
              <div
                className="GroupTimepicker-pollButton"
                onClick={clearTimeSelections}
              >
                Clear selections
              </div>
              <div className="GroupTimepicker-pollButton">
                Confirm
              </div>
            </div>
            }
          </div>
        </div>
      </div>
    )
  }

  // display the timepicker from start to finish
  return (
    <div className="Timepicker">
      <div className="Timepicker-row">
        <div className="GroupTimepicker-participantSelect">
          <div className="GroupTimepicker-title">
            {"Who are you coordinating this meeting for?"}
          </div>
          <div className="GroupTimepicker-participantList">
            {participants.map((participant) => {
              return (
                <div
                  className={
                    selectedParticipants.includes(participant) ?
                      "GroupTimepicker-participantSelected"
                      : 
                      "GroupTimepicker-participant"
                  }
                  onClick={() => selectParticipant(participant)}
                >
                  <div className="GroupTimepicker-participantName">
                    {participant}
                  </div>
                </div>
              )
            })}
          </div>
          <div className="GroupTimepicker-continueContainer">
            {selectedParticipants.length !== 0 &&
              <div
                className="GroupTimepicker-continueButton"
                onClick={() => setCoordinating(true)}
              >
                {`Coordinate for ${selectedParticipants.length} ${selectedParticipants.length === 1 ? "person" : "people"}`}
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  )
}