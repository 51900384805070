import invariant from 'invariant';
import { identity } from 'lodash';
import { createAction, handleAction as reduxHandleAction } from 'redux-actions';
import reduceReducers from 'reduce-reducers';
import { PromiseRejectionSentinelError } from './promise-rejection-sentinel-error';
/**
 * Redux actions utilities
 */

export const ASYNC_BEGIN = 'REDUX_ACTION_ASYNC_BEGIN';

/**
 * createAsyncAction is a wrapper on top of createAction that sets `meta.sequence`
 * for actions depending on the payload passed in. Should be used in conjunction with
 * wrapPromiseToThunk and the implementation of `handleAction`/`handleActions` in this file.
 */
export function createAsyncAction(name, payloadCreator, metaCreator) {
  return createAction(name, payloadCreator, (payload, args) => {
    const metaCreatorValue = typeof metaCreator === 'function' ? metaCreator(payload, args) : undefined;

    return payload instanceof Error ? {
      args,
      ...metaCreatorValue,
    } : {
      sequence: payload === ASYNC_BEGIN ? 'begin' : 'complete',
      ...metaCreatorValue,
    };
  });
}

/**
 * wrapPromiseToThunk is a helper to properly lifecycle the begin/complete/error
 * actions that an async action thunk may emit.
 *
 * @param  action         async action from createAsyncAction
 * @param  promiseCreator function that accepts `(dispatch, getState, ...args)`
 * and any other arguments passed in from the action invocation. i.e., if the invocation
 * is `action('example')`, `promiseCreator` will receive `(dispatch, getState, 'example')`.
 *
 * @return                returns a thunk that can be dispatched
 *
 * Example usage:
 * const asyncAction = wrapPromiseToThunk(createAsyncAction('LOAD_DATA'), (dispatch, getState, resourceId) => {
 *   return fetch(`http://api.dev/${ resourceId }`).then(response => response.body.payload);
 * });
 *
 * When `dispatch(asyncAction('resource-id'))` is called, the thunk will immediately dispatch the action
 * with `ASYNC_BEGIN` (causing `action.meta.sequence` to be `begin`). Then it runs the `promiseCreator`
 * to completion.
 *
 * If the promise returned resolves, the thunk will dispatch the action with the resolved
 * promise value as the payload. (causing `action.meta.sequence` to be `complete`).
 */
export function wrapPromiseToThunk(action, promiseCreator, reportError, throwOnError = true) {
  return (...args) => {
    return function(dispatch, getState) {
      dispatch(action(ASYNC_BEGIN, args));
      const promise = promiseCreator({ dispatch, getState }, ...args);

      invariant(
        promise.then && promise.catch,
        `promiseCreator must return a promise. Check the implementation of the thunk on ${ action.toString() }`,
      );

      // promises have the ability to return multiple values, but in practice
      // we always just return one object as the payload
      return promise.then(rv => {
        dispatch(action(rv, args));
        return Promise.resolve(rv);
      }).catch(error => {
        try {
          dispatch(action(error, args));
        } catch (e) {
          console.error('Error dispatching action error: ', e);  // eslint-disable-line no-console
        }

        if (reportError && !(error instanceof PromiseRejectionSentinelError)) {
          dispatch(reportError(error));
        }
        if (throwOnError) {
          throw error;
        }
      });
    };
  };
}

/**
 * handleAction is a drop-in replacement for `handleAction` from `redux-actions`. It additionally
 * adds support for a `begin` reducer, such that reducers can be implemented with the syntax:
 * handleAction(ACTION, {
 *   begin() { ... },   // corresponds to `begin` sequence actions
 *   next() { ... },    // corresponds to `complete` sequence actions (`next` nomenclature
 *                      // used to match the the redux-actions name for completion)
 *   throw() { ... },   // corresponds to actions with `action.error`
 * });
 */
export function handleAction(type, reducers, defaultState) {
  if (typeof reducers === 'function') {
    return reduxHandleAction(type, reducers, defaultState);
  }

  const combinedNext = (state, action) => {
    const { sequence } = action.meta;
    const { begin = identity, next = identity } = reducers;
    switch (sequence) {
      case 'begin':
        return begin(state, action);
      case 'complete':
        return next(state, action);
      default:
        return state;
    }
  };

  return reduxHandleAction(type, {
    ...reducers,
    next: combinedNext,
  }, defaultState);
}

/**
 * handleActions is a drop-in replacement for `handleActions` from `redux-actions`.
 * It is the same implementation, but unfortunately we have this copy/pasted here
 * so that `handleActions` will use our version of `handleAction` that supports the `begin`
 * reducer in reducer maps.
 */
import ownKeys from 'redux-actions/lib/utils/ownKeys';
export function handleActions(handlers, defaultState) {
  const reducers = ownKeys(handlers).map(type => handleAction(type, handlers[type], defaultState));
  const reducer = reduceReducers(...reducers);

  return (state = defaultState, action) => reducer(state, action);
}
