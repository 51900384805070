import React, { Component } from "react";
import { connect } from "react-redux";
import { Loader } from 'vogue';
import { createAsyncAction, wrapPromiseToThunk } from "../../../utils/redux-actions";
import { fetchApi } from "../../../utils/request";
import PropTypes from "prop-types";
import SuggestedTimeMessageView from "./suggested-time-message-view.react";

class SuggestTimeConfirmation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      suggestions: [],
      message: "",
      displayLoader: false,
      displayConfirmationMessage: true,
    };

    this.selectSuggestedTimeForJobHandler = this.selectSuggestedTimeForJobHandler.bind(this);
  }

  selectSuggestedTimeForJobHandler(uuid, e) {
    e.target.disabled = true;  // to avoid double click
    this.setState({
      displayLoader: true,
      displayConfirmationMessage: false
    });

    this.props.onSelectTimeForJob(uuid, this.props.isStrikeout)
      .then((response) => {
        const body = response.body;
        if (body) {
          this.setState({
            message: body.message,
            suggestions: body.alternative_suggestions,
            displayLoader: false,
          });
        }
      })
      .catch((error) => {
        const body = error.body;
        if (body) {
          this.setState({
            message: body.message,
            displayLoader: false,
          });
        }
      });
  }

  render() {
    if (this.state.displayLoader) {
      return <Loader />;
    } else if (this.state.displayConfirmationMessage) {
      let displayText = <p>Please confirm your selection for the meeting:</p>

      if (this.props.selectedTime) {
        displayText = <p>This meeting is already scheduled for {this.props.selectedTime}.<br />
        Please confirm that you'd like to change it to:</p>
      }

      return (
        <div>
          {displayText}
          <p style={{marginTop: "20px"}}>{this.props.timeSpan}</p>
          <button
            className="AvailabilityCalendar-button"
            style={{borderRadius: "5px", width: "50%"}}
            onClick={(e) => this.selectSuggestedTimeForJobHandler(this.props.uuid, e)}
          >CONFIRM</button>
        </div>
      )
    } else {
      return <SuggestedTimeMessageView
        message={this.state.message}
        suggestions={this.state.suggestions}
        clicked={this.selectSuggestedTimeForJobHandler} />
    }
  }
}

SuggestTimeConfirmation.propTypes = {
  uuid: PropTypes.string,
  timeSpan: PropTypes.string,
  selectedTime: PropTypes.string,
  isStrikeout: PropTypes.bool,
}

export const onSelectTimePromise = wrapPromiseToThunk(
  createAsyncAction('SUGGESTTIME/CONFIRM'),
  (_, args) => {
    return fetchApi("/suggested_times/confirm", {
      method: "post",
      json: {
        suggested_time_uuid: args.uuid,
        is_strikeout: args.isStrikeout,
      }
    })
  }
);

const mapDispatchToProps = dispatch => ({
  onSelectTimeForJob(uuid, isStrikeout) {
    return dispatch(onSelectTimePromise({
      "uuid": uuid,
      "isStrikeout": isStrikeout,
    }))
  }
});

export default connect(null, mapDispatchToProps)(SuggestTimeConfirmation);