/** @format */
import Raven from "raven-js";
import config from "../config";
import invariant from "invariant";
import { REPORT_ERROR } from "./modules/error";
import { PromiseRejectionSentinelError } from "../utils/promise-rejection-sentinel-error";

const IS_PRODUCTION = config.env === "production";

export function setup(store) {
  invariant(store, "Must pass in redux store to set up error reporting");

  function handleGlobalError(error) {
    // if an error bubbles up to an unhandled rejection or
    // a global window-ing error, we should show it to the user.
    if (error instanceof PromiseRejectionSentinelError) {
      return;
    }
    store.dispatch(REPORT_ERROR(error));
  }

  if (IS_PRODUCTION) {
    Raven.config(config.sentry.dsn).install();
  }

  const previousGlobalOnError = window.onerror;
  window.onerror = function(message, filename, lineno, colno, error) {
    // https://danlimerick.wordpress.com/2014/01/18/how-to-catch-javascript-errors-with-window-onerror-even-on-chrome-and-firefox/
    handleGlobalError(error);
  };

  // catch uncaught errors in promises
  // https://stackoverflow.com/questions/28001722/how-to-catch-uncaught-exception-in-promise
  window.addEventListener("unhandledrejection", function(err) {
    if (err.reason) {
      handleGlobalError(err.reason);
    }
  });

  return function teardown() {
    window.onerror = previousGlobalOnError;
  };
}
