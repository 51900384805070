/** @format */
import React, { Component } from "react";
import { connect } from "react-redux";
import { Route, withRouter } from "react-router-dom";
import { getActiveStep, SET_ONBOARDING_STEP } from "../modules/onboard";
import Registration from "../containers/onboarding/registration.react";
import CalendarSelect from "../containers/onboarding/calendar-select.react";
import OnboardingView from "../components/onboarding/view.react";
import OAuthProvider from "../components/onboarding/oauth-provider.react";
import { disableZendesk } from "../../utils/zendesk";

const ProgressStep = withRouter(props => 
  <props.baseComponent
    {...props}
    nextStep={() => props.history.push(props.nextStepPath)}
  />
)

const dispatchSetOnboardingStep = (store, step) => () =>
  store.dispatch(SET_ONBOARDING_STEP(step));

class OnboardingContainer extends Component {
  componentDidMount() {
    // Disable Zendesk chat during onboarding.
    disableZendesk();
    this.props.onEnter();
  }

  render() {
    return <OnboardingView {...this.props} />;
  }
}

const ConnectedOnboardingContainer = connect(state => ({
  activeStep: getActiveStep(state),
  stepCount: 2
}))(OnboardingContainer);

export default (store) => ([
  <Route
    path="/start"
    key="/start"
    exact
    component={(props) => 
      <ConnectedOnboardingContainer
        {...props}
        onEnter={dispatchSetOnboardingStep(store, 1)}
        children={
          <ProgressStep
            baseComponent={OAuthProvider}
            nextStepPath="/start/callback"
          />
        }
      />
    }
  />,
  <Route
    path="/start/callback"
    key="/start/callback"
    component={(props) =>
      <ConnectedOnboardingContainer
        {...props}
          onEnter={dispatchSetOnboardingStep(store, 2)}
          children={
            <ProgressStep
              baseComponent={Registration}
              nextStepPath="/start/calendars"
            />
          }
      />
    }
  />,
  <Route
    path="/start/calendars"
    key="/start/calendars"
    component={(props) => 
      <ConnectedOnboardingContainer
        {...props}
        onEnter={dispatchSetOnboardingStep(store, 2)}
        children={
          <ProgressStep
            baseComponent={CalendarSelect}
            nextStepPath="/"
          />
        }
      />
    }
  />,
]);
