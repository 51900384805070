import React, { Component } from "react";
import { connect } from "react-redux";
import { Loader } from 'vogue';
import { createAsyncAction, wrapPromiseToThunk } from "../../../utils/redux-actions";
import { fetchApi } from "../../../utils/request";
import PropTypes from "prop-types";
import SuggestedTimeMessageView from "./suggested-time-message-view.react";
import { onSelectTimePromise } from "./suggest-time-confirmation.react";


class SuggestedTimesList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cancelled: false,
      suggestions: [],
      selectedTime: null,
      displayLoader: true,
    };

    this.selectSuggestedTimeForJobHandler = this.selectSuggestedTimeForJobHandler.bind(this);
  }

  selectSuggestedTimeForJobHandler(uuid) {
    this.setState({displayLoader: true});
    this.props.onSelectTimeForJob(uuid, true)
      .then((response) => {
        const body = response.body;
        if (body) {
          this.setState({
            message: body.message,
            suggestions: body.alternative_suggestions,
            displayLoader: false,
          });
        } else {
          this.setState({
            message: "Unknown response",
            displayLoader: false,
          });
        }
      })
      .catch((error) => {
        const body = error.body;
        if (body) {
          this.setState({
            message: body.message,
            displayLoader: false,
          });
        }
      });
  }

  componentDidMount() {
    if (this.state.suggestions.length < 1) {
      this.props.onGetSuggestedTimes(this.props.match.params.jobUUID)
        .then((response) => {
          const body = response.body;
          this.setState({
            message: body.message,
            suggestions: body.suggested_times,
            displayLoader: false,
            cancelled: body.cancelled
          })
        })
        .catch((error) => {
          const body = error.body;
          this.setState({
            message: body.message,
            displayLoader: false
          })
        });
    }
  }

  render() {
    let element = null;
    const {displayLoader, cancelled, suggestions, message} = this.state;

    if (displayLoader) {
      element = <Loader />
    } else if (cancelled) {
      element = <p>This event has been cancelled. Please email me to reschedule it.</p>
    } else if (suggestions.length > 0) {
      element = <SuggestedTimeMessageView
        message={message}
        suggestions={suggestions}
        clicked={this.selectSuggestedTimeForJobHandler} />
    } else {
      element = <p>{message.split('<br />').map((item, key) => {
        return <span key={key}>{item}<br/></span>
      })}</p>
    }

    return (
      <div className="suggest-times-centered">
        {element}
      </div>
    );
  }
}

SuggestedTimesList.propTypes = {
  jobUUID: PropTypes.string,
}


const onGetSuggestedTimesPromise = wrapPromiseToThunk(
  createAsyncAction("SUGGESTTIME/FETCHACTIVESUGGESTEDTIMES"),
  (_, jobUUID) => {
    return fetchApi(
      "/suggested_times/active_suggested_times_for_job/" + jobUUID,
      {
        method: "get"
      }
    )
  }
);


const mapDispatchToProps = dispatch => ({
  onGetSuggestedTimes(jobUUID) {
    return dispatch(onGetSuggestedTimesPromise(jobUUID))
  },
  onSelectTimeForJob(uuid, isStrikeout) {
    return dispatch(onSelectTimePromise({
      "uuid": uuid,
      "isStrikeout": isStrikeout,
    }))
  }
});

export default connect(null, mapDispatchToProps)(SuggestedTimesList);
