/** @format */
import PropTypes from "prop-types";

import React from "react";

import { ResourceSyncedCalendarShape } from "../../../../utils/shapes";
import AvailableResources from "./available-resources.react";
import SelectedResources from "./selected-resources.react";
import SelectAllButton from "./select-all-button.react";

const Choose = ({
  availableResources,
  forceValidation,
  onChange,
  selectedResources
}) => {
  const selectedResourceIds = selectedResources.map(sr => sr.provider_id);
  const onUnselectResource = providerId =>
    onChange(selectedResourceIds.filter(id => id !== providerId));

  let selectAllButton;
  if (availableResources.length > 0) {
    selectAllButton = (
      <SelectAllButton
        availableResources={availableResources}
        selectedResourceIds={selectedResourceIds}
        onChange={onChange}
      />
    );
  }

  return (
    <div className="Choose">
      <div className="Choose-explanation">
        Choose your teamâs conference rooms from the list of resources weâve
        synced from your G Suite account. Clara will only choose from the
        selected list when a room is explicitly requested.
      </div>
      <div className="Choose-container">
        <div className="Choose-pane">
          <div className="Choose-heading">
            Available Resources {selectAllButton}
          </div>
          <AvailableResources
            availableResources={availableResources}
            selectedResourceIds={selectedResourceIds}
            onChange={onChange}
          />
        </div>
        <div className="Choose-pane">
          <div className="Choose-heading">Selected</div>
          <SelectedResources
            selectedResources={selectedResources}
            forceValidation={forceValidation}
            onUnselectResource={onUnselectResource}
          />
        </div>
      </div>
    </div>
  );
};

Choose.propTypes = {
  selectedResources: PropTypes.arrayOf(ResourceSyncedCalendarShape).isRequired,
  availableResources: PropTypes.arrayOf(ResourceSyncedCalendarShape).isRequired,
  forceValidation: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired
};

export default Choose;
