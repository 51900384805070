/** @format */
import PropTypes from "prop-types";
import React from "react";

import IntegrationPageBase from "./page-base.react";
import IntegrationAccountStatus from "./generic-integration/integration-account-status.react";

const MicrosoftTeams = ({ activeMicrosoftTeamsBool }) => {

  const props = {
    title: "Microsoft Teams",
    subtitle:
      "Clara can join your meetings in Teams.",
    image: "/images/microsoft-teams-logo.png",
    className: "Integration"
  };

  if (!activeMicrosoftTeamsBool) {
    return (
      <IntegrationPageBase {...props}>
        <div>
          Microsoft Teams is not setup for your team, contact{" "}
          <a href="mailto:support@claralabs.com">customer support</a> to start
          using Microsoft Teams with Clara.
        </div>
      </IntegrationPageBase>
    );
  }

  return (
    <IntegrationPageBase {...props}>
      <div className="Integration-content">
        <div>
          Microsoft Teams is connected.
        </div>
        <IntegrationAccountStatus
          isValid={true}
          className="Integration-accountStatus"
        />
      </div>
    </IntegrationPageBase>
  );
};

MicrosoftTeams.propTypes = {
  activeMicrosoftTeamsBool: PropTypes.bool.isRequired,
};

export default MicrosoftTeams;
