/** @format */
import PropTypes from "prop-types";

import React, { Component } from "react";
import { connect } from "react-redux";
import config from "../../../config";
import {
  ContactSyncedCalendar,
  GoogleAccount
} from "../../../collections/schema";
import { generateShape } from "../../../collections/shapes";
import AccountCalendarList from "../../components/dashboard/account/account-calendar-list.react";
import {
  getIsError,
  getContactSyncedCalendars,
  fetchAccountCalendars,
  setCalendars
} from "../../modules/connected-calendars";

export class GoogleAccountCalendarList extends Component {
  UNSAFE_componentWillMount() {
    this.props.onLoad();
  }

  render() {
    const {
      googleAccount,
      contactSyncedCalendars,
      isError,
      onSetCalendars
    } = this.props;

    let emailAddressOrId = googleAccount.email_address
      ? googleAccount.email_address.id
      : googleAccount.id;

    return (
      <AccountCalendarList
        name={`Google (${emailAddressOrId})`}
        email={emailAddressOrId}
        isLoading={!isError && contactSyncedCalendars === null}
        isValid={googleAccount.is_valid}
        isError={isError}
        reconnectUrl={`${
          config.api.host
        }/google_accounts/actions/update_or_create`}
        contactSyncedCalendars={contactSyncedCalendars}
        onSetCalendars={onSetCalendars}
      />
    );
  }
}

GoogleAccountCalendarList.propTypes = {
  googleAccount: generateShape(GoogleAccount, {
    email_address: {}
  }).isRequired,
  contactSyncedCalendars: PropTypes.arrayOf(
    generateShape(ContactSyncedCalendar, {
      synced_calendar: {}
    })
  ),
  isError: PropTypes.bool.isRequired,
  onLoad: PropTypes.func.isRequired,
  onSetCalendars: PropTypes.func.isRequired
};

const mapStateToProps = (state, { googleAccount }) => ({
  isError: getIsError(state, null, googleAccount.id),
  contactSyncedCalendars: getContactSyncedCalendars(
    state,
    null,
    googleAccount.id
  )
});

const mapDispatchToProps = (dispatch, { googleAccount }) => ({
  onLoad() {
    dispatch(fetchAccountCalendars(null, googleAccount.id));
  },

  onSetCalendars(contactSyncedCalendarIds) {
    dispatch(setCalendars(contactSyncedCalendarIds, null, googleAccount.id));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GoogleAccountCalendarList);
