/** @format */
import React from "react";
import { Route, Redirect, Switch } from "react-router-dom";

import App from "./containers/app";
import CandidateFeedback from "./components/candidate-feedback.react";
import CustomerFeedback from "./components/customer-feedback.react";
import LoginView from "./components/dashboard/login.react";
import NotFound from "./components/not-found.react.js";
import VerifyEmail from "./components/verify-email.react";
import OnboardingRoutes from "./containers/onboarding.react";
import ThankYouForFeedback from "./components/thank-you-for-feedback.react";
import SuggestTime from "./containers/suggest-time-email-confirmation/suggest-time-view.react";
import SuggestedTimesList from "./containers/suggest-time-email-confirmation/suggested-times-list-view.react";
import RequestedMeetings from "./containers/live-v2/requested-meetings.react";
import UpcomingMeetings from "./containers/live-v2/upcoming-meetings.react";
import HistoryMeetings from "./containers/live-v2/history-meetings.react";
import SpecialFeatures from "./containers/live-v2/special-features.react";
import CalendarPlanning from "./containers/live-v2/calendar-planning.react";
import MeetingDetails from "./containers/live-v2/meeting-details.react";
import ClarasEmails from "./containers/live-v2/claras-emails.react";
import LibraryWrapper from "./containers/live-v2/library-wrapper.react";
import MeetingsWrapper from "./containers/live-v2/meetings-wrapper.react";
import SettingsWrapper from "./containers/live-v2/settings-wrapper.react";
import Profile from "./components/live-v2/profile.react";
import Team from "./containers/live-v2/team.react";
import Integrations from "./containers/live-v2/index.react";
import Billing from "./components/live-v2/billing.react";
import CustomerCalendarView from "./components/customer-calendar-view.react";
import Referral from "./components/dashboard/referral.react";
import GoogleMeet from "./containers/integrations/google-meet.react";
import Greenhouse from "./containers/integrations/greenhouse.react";
import GSuite from "./containers/integrations/gsuite.react";
import Lever from "./containers/integrations/lever.react";
import Icims from "./containers/integrations/icims.react";
import Coderpad from "./containers/integrations/coderpad.react";
import Hackerrank from "./containers/integrations/hackerrank.react";
import Zoom from "./containers/integrations/zoom.react";
import MicrosoftTeams from "./containers/integrations/microsoft-teams.react";
import Slack from "./containers/integrations/slack.react";
import LinkCard from "./components/link-card/link-card.react";
import GroupTimeSelect from "./components/group-time-select.react";

const LIBRARY_PATHS = [
  "/library/my-meetings",
  "/library/team-meetings",
  "/library/liked-meetings",
  "/library/collections",
]

const MEETINGS_PATHS = [
  "/meetings/requested",
  "/meetings/upcoming",
  "/meetings/history",
]

const SETTINGS_PATHS = [
  "/settings/referral",
  "/settings/calendar-planning",
  "/settings/meeting-details",
  "/settings/claras-emails",
  "/settings/profile",
  "/settings/team",
  "/settings/integrations",
  "/settings/billing",
  "/settings/special-features",
  "/settings/google-meet",
  "/settings/greenhouse",
  "/settings/gsuite",
  "/settings/lever",
  "/settings/icims",
  "/settings/coderpad",
  "/settings/hackerrank",
  "/settings/slack",
  "/settings/zoom",
  "/settings/microsoft-teams"
]

const featureFlaggedLibraryRoutes = []

const Redirects = [
  <Redirect from="/account/team" to="/team" key="/account/team-/team" />,
  <Redirect from="/account" to="/connected-calendars" key="/account-/connected-calendars" />,
  <Redirect from="/refer" to="/settings/referral" key="/refer-/referral" />,
  <Redirect from="/register" to="/start" key="/register-/start" />,
]

export default ({store}) => (
  <App>
    <Switch>
      {/* Redirects for old routes that were/are used externally. */}
      {Redirects}

      {/* Routes for the onboarding flow. */}
      {OnboardingRoutes(store)}

      <Route path="/link-card" component={LinkCard} />
      <Route path="/verify_email" component={VerifyEmail} />
      <Route path="/feedback" component={CustomerFeedback} />
      <Route path="/login" component={LoginView} />
      <Route path="/anonymous-feedback/:uuid" component={CandidateFeedback} />
      <Route path="/anonymous-feedback" component={ThankYouForFeedback} />
      <Route path="/suggest-time/:jobUUID/:position" component={SuggestTime} />
      <Route path="/suggest-time/:suggestedTimeUUID" component={CustomerCalendarView} />
      <Route path="/suggest-times/:jobUUID" component={SuggestedTimesList} />
      <Route path="/time-select/:jobUUID" component={CustomerCalendarView} />
      <Route path="/group-time-select/:jobUUID" component={GroupTimeSelect} />

      <Route path={LIBRARY_PATHS}>
        <LibraryWrapper>
          {featureFlaggedLibraryRoutes}
        </LibraryWrapper>
      </Route>

      <Route path={MEETINGS_PATHS}>
        <MeetingsWrapper>
          <Route path="/meetings/requested" component={RequestedMeetings} />
          <Route path="/meetings/upcoming" component={UpcomingMeetings} />
          <Route path="/meetings/history" component={HistoryMeetings} />
        </MeetingsWrapper>
      </Route>

      <Route path={SETTINGS_PATHS}>
        <SettingsWrapper>
          <Route path="/settings/referral" component={Referral} />
          <Route path="/settings/calendar-planning" component={CalendarPlanning} />
          <Route path="/settings/meeting-details" component={MeetingDetails} />
          <Route path="/settings/claras-emails" component={ClarasEmails} />
          <Route path="/settings/profile" component={Profile} />
          <Route path="/settings/team" component={Team} />
          <Route path="/settings/integrations" component={Integrations} />
          <Route path="/settings/billing" component={Billing} />
          <Route path="/settings/special-features" component={SpecialFeatures} />

          <Route
            path="/settings/google-meet"
            component={GoogleMeet}
          />
          <Route
            path="/settings/greenhouse"
            component={Greenhouse}
          />
          <Route path="/settings/gsuite" component={GSuite} />
          <Route path="/settings/lever" component={Lever} />
          <Route path="/settings/icims" component={Icims} />
          <Route path="/settings/coderpad" component={Coderpad} />
          <Route
            path="/settings/hackerrank"
            component={Hackerrank}
          />
          <Route
            path="/settings/slack"
            component={Slack}
          />
          <Route path="/settings/zoom" component={Zoom} />
          <Route path="/settings/microsoft-teams" component={MicrosoftTeams} />
        </SettingsWrapper>
      </Route>

      <SettingsWrapper>
        <Route path="/" exact component={Profile} />
      </SettingsWrapper>

      <Route path="*" component={NotFound} />
    </Switch>
  </App>
);
