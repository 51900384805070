/** @format */
import PropTypes from "prop-types";

import React from "react";

import {
  ConferenceRoomSyncedCalendarShape,
  ResourceSyncedCalendarShape
} from "../../../utils/shapes";
import ConferenceRoomsBlock from "../conference-rooms/preference-block.react";
import IntegrationPageBase from "./page-base.react";

const GSuiteConferenceRooms = props => {
  const { hasConferenceRooms, ...rest } = props;

  let content;
  if (!hasConferenceRooms) {
    content = (
      <div>
        G Suite conference rooms require Clara to be hosted on your domain.
        Contact&nbsp;
        <a href="mailto:support@claralabs.com">customer support</a>&nbsp;for
        more information.
      </div>
    );
  } else {
    content = <ConferenceRoomsBlock {...rest} />;
  }

  return (
    <IntegrationPageBase
      title="G Suite"
      subtitle="Do your best work with Googleâs suite of intelligent apps."
      image="/images/gsuite-logo.png"
    >
      {content}
    </IntegrationPageBase>
  );
};

GSuiteConferenceRooms.propTypes = {
  hasConferenceRooms: PropTypes.bool.isRequired,
  isConfigLoading: PropTypes.bool.isRequired,
  conferenceRooms: PropTypes.arrayOf(ConferenceRoomSyncedCalendarShape),
  connectedEmailAddress: PropTypes.string,
  availableResources: PropTypes.arrayOf(ResourceSyncedCalendarShape),
  onAuthError: PropTypes.func.isRequired,
  onAddScopeAndSyncResources: PropTypes.func.isRequired,
  onUpdateResources: PropTypes.func.isRequired
};

export default GSuiteConferenceRooms;
