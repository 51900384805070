/** @format */
import PropTypes from "prop-types";

import React, { Component } from "react";
import { Button, Icon, Loader } from "vogue";

import GoogleLogin from "../../../shared/google-login.react";

const RESOURCES_READ_SCOPE =
  "https://www.googleapis.com/auth/admin.directory.resource.calendar.readonly";

export default class ConfigurationBlock extends Component {
  constructor(props) {
    super(props);

    this._onLogin = this._onLogin.bind(this);
  }

  render() {
    const {
      isLoading,
      connectedEmailAddress,
      onAuthError,
      onConfigure
    } = this.props;

    if (isLoading) {
      return (
        <div className="ConferenceRoomConfigurationBlock">
          <h4 className="ConferenceRoomConfigurationBlock-loadingText">
            Syncing resources...
          </h4>
          <Loader />
        </div>
      );
    }

    if (connectedEmailAddress) {
      return (
        <div className="ConferenceRoomConfigurationBlock">
          <h4 className="ConferenceRoomConfigurationBlock-title">
            <Icon
              name="green-check"
              className="ConferenceRoomConfigurationBlock-connectedIcon"
            />
            Resources Synced
          </h4>
          <div className="ConferenceRoomConfigurationBlock-explainerText">
            Connected with {connectedEmailAddress}
          </div>
          <Button
            className="ConferenceRoomConfigurationBlock-button"
            onClick={onConfigure}
          >
            Configure Conference Rooms
          </Button>
        </div>
      );
    }

    return (
      <div className="ConferenceRoomConfigurationBlock">
        <Icon
          name="inperson-office"
          className="ConferenceRoomConfigurationBlock-icon"
        />
        <h4>Setup Conference Rooms</h4>
        <p className="ConferenceRoomConfigurationBlock-explainerText">
          To setup conference rooms for your billing group begin by connecting a
          G Suite admin account to sync your organization's resources.
        </p>
        <GoogleLogin
          onLogin={this._onLogin}
          onAuthError={onAuthError}
          scope={RESOURCES_READ_SCOPE}
          forceApproval={true}
          className="ConferenceRoomConfigurationBlock-button"
        >
          Connect with Google
        </GoogleLogin>
      </div>
    );
  }

  _onLogin({ code }) {
    this.props.onAddScopeAndSyncResources(code);
  }
}

ConfigurationBlock.propTypes = {
  connectedEmailAddress: PropTypes.string,
  isLoading: PropTypes.bool.isRequired,
  onAddScopeAndSyncResources: PropTypes.func.isRequired,
  onAuthError: PropTypes.func.isRequired,
  onConfigure: PropTypes.func.isRequired
};
