/** @format */
import {
  handleActions,
  createAsyncAction,
  wrapPromiseToThunk
} from "../../utils/redux-actions";
import { fetchApi, generateFieldExpansion } from "../../utils/request";
import fetchGroup from "../utils/fetch-group";
import { REPORT_ERROR } from "./error";
import { consumePayload, expand } from "../../collections/module";
import { Preference, Customer } from "../../collections/schema";
import { getCurrentCustomer } from "./session";

const FETCH_PREFERENCE = createAsyncAction("PREFERENCES/FETCH_PREFERENCE");
const UPDATE_ATTRIBUTE = createAsyncAction(
  "PREFERENCES/UPDATE_ATTRIBUTE",
  x => x,
  (payload, args) => ({ key: args[0] }) // use meta to pass through key
);

const PREFERENCE_EXPANSION = {
  meeting_permissions: {
    contact: {}
  },
  locations: {}
};

export const fetchPreference = wrapPromiseToThunk(
  FETCH_PREFERENCE,
  ({ dispatch }, customerId) => {
    const fields = generateFieldExpansion(PREFERENCE_EXPANSION);
    return fetchApi(
      `/customers/${customerId}/preference?fields=${fields}`
    ).then(({ body }) => {
      dispatch(consumePayload(body, Preference));
      return body.id;
    });
  },
  REPORT_ERROR
);

export const updatePreferenceAttribute = wrapPromiseToThunk(
  UPDATE_ATTRIBUTE,
  ({ dispatch, getState }, key, value) => {
    const state = getState();
    const customer = getCurrentCustomer(state);
    const preference = state.collections.preferences[customer.preference];

    return fetchApi(`/preferences/${preference.id}`, {
      method: "put",
      json: {
        [key]: value
      }
    }).then(({ body }) => {
      dispatch(
        consumePayload(
          {
            ...preference,
            [key]: value
          },
          Preference
        )
      );
      return body;
    });
  },
  REPORT_ERROR
);

export const reducer = handleActions(
  {
    [FETCH_PREFERENCE]: {
      begin: state => ({ ...state, fetchGroup: state.fetchGroup.startFetch() }),
      next: state => ({
        ...state,
        fetchGroup: state.fetchGroup.completeFetch()
      }),
      throw: state => ({
        ...state,
        fetchGroup: state.fetchGroup.completeFetch()
      })
    },
    [UPDATE_ATTRIBUTE]: {
      begin: (state, action) => {
        return {
          ...state,
          inflightAttributes: [...state.inflightAttributes, action.meta.key]
        };
      },
      next: (state, action) => {
        const filtered = state.inflightAttributes.filter(attribute => {
          return attribute !== action.meta.key;
        });
        return {
          ...state,
          inflightAttributes: filtered
        };
      },
      throw: (state, action) => {
        const filtered = state.inflightAttributes.filter(attribute => {
          return attribute !== action.meta.key;
        });
        return {
          ...state,
          inflightAttributes: filtered
        };
      }
    }
  },
  {
    fetchGroup: fetchGroup(true),
    inflightAttributes: []
  }
);

export const getPreference = (state, props) => {
  return expand(
    Customer,
    props.customer.id,
    { preference: PREFERENCE_EXPANSION },
    state
  ).preference;
};

export const getIsLoadingPreference = state =>
  state.preferences.fetchGroup.isFetching;

export const getInflightAttributes = state =>
  state.preferences.inflightAttributes;
