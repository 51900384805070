/** @format */
import React from "react";
import SideNavigationLoading from "./side-navigation-loading.react";
import SideNavigationMinimizedContainer from "../../containers/live-v2/side-navigation-minimized.react";
import SideNavigationContainer from "../../containers/live-v2/side-navigation.react";

export const SIDE_NAVIGATION_ITEMS_LIVE = [
    {text: "Library", link: "/library/my-meetings", key: "library"},
    {text: "Meetings", link: "/meetings/requested", key: "meetings"},
    {text: "Settings", link: "/settings/profile", key: "settings"},
];

export const SIDE_NAVIGATION_ITEMS = [
    {text: "Meetings", link: "/meetings/requested", key: "meetings"},
    {text: "Settings", link: "/settings/profile", key: "settings"},
];

export const renderSideNavigation = (
  isLoading,
  isGuest,
  minimized,
  customer,
  handleToggle,
  selected
) => {
    if (isGuest) {
      return null;
    }

    if (isLoading) {
      return <SideNavigationLoading minimized={minimized}/>
    }

    if (minimized) {
      return (
        <SideNavigationMinimizedContainer
          customerHasLive={false}
          selected={selected}
          handleToggle={handleToggle}
        />
      )
    } else {
      return (
        <SideNavigationContainer
          customerHasLive={false}
          selected={selected}
          handleToggle={handleToggle}
        />
      )
    }
}

export const calculateTrialProgress = (trialDaysRemaining) => {
  const trialLength = 13;
  return `${(trialLength - trialDaysRemaining) / trialLength * 100}%`;
}
