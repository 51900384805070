/** @format */
import { connect } from "react-redux";

import Lever from "../../components/dashboard/integrations/lever.react";
import { getIsFeatureEnabled } from "../../modules/feature-flags";
import {
  configureLever,
  fetchLever,
  getIsConfiguringLever,
  getIsLoadingLever,
  getLeverDetails
} from "../../modules/integrations";
import { FEATURE_FLAGS } from "../../utils/feature-flags";
import createInitiallyLoadingContainer from "../create-initially-loading-container.react";

const mapStateToProps = state => ({
  leverDetails: getLeverDetails(state),
  isLoading: getIsLoadingLever(state),
  isConfiguringLever: getIsConfiguringLever(state),
  isLeverAvailable: getIsFeatureEnabled(state, FEATURE_FLAGS.LEVER)
});

const mapDispatchToProps = dispatch => ({
  onLoad() {
    return dispatch(fetchLever());
  },

  onConfigureLever(leverApiKey) {
    return dispatch(configureLever(leverApiKey));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(createInitiallyLoadingContainer(Lever));
