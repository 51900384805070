/** @format */
import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import { Icon } from "vogue";

const IntegrationDetail = ({ href, isEnabled, isValid, logo, name }) => {
  let linkContents;
  if (isEnabled) {
    linkContents = isValid ? (
      <span>Manage</span>
    ) : (
      [
        <Icon name="error" key="link-icon" />,
        <span key="link-text">Manage</span>
      ]
    );
  } else {
    linkContents = <span>Connect</span>;
  }

  // HACK(gavin): Very brittle way of making react-router links look like our other buttons; not
  // sure if we can style these correctly while Vogue is a separate package.
  const linkClasses = classNames("Button Button--secondary TextButton", {
    "IntegrationDetail-link--invalid": isEnabled && !isValid
  });

  const logoImage = isEnabled ? logo : `${logo}-gray`;

  return (
    <div className="IntegrationDetail">
      <img
        className="IntegrationDetail-image"
        src={`/images/${logoImage}.png`}
      />
      <div className="IntegrationDetail-name">{name}</div>
      <div className="IntegrationDetail-action">
        <Link className={linkClasses} to={href}>
          {linkContents}
        </Link>
      </div>
    </div>
  );
};

IntegrationDetail.propTypes = {
  href: PropTypes.string.isRequired,
  isEnabled: PropTypes.bool.isRequired,
  isValid: PropTypes.bool,
  logo: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired
};

export default IntegrationDetail;
