/** @format */
import PropTypes from "prop-types";

import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { fetchCurrentUser } from "../../modules/session";

export default function authRequired(opts) {
  const { onboarded, onboarding } = opts || {};
  const getUser = state => {
    const {
      session: { currentUserId }
    } = state;
    if (currentUserId) {
      return state.collections.users[currentUserId];
    }
  };

  const getCustomer = state => {
    const user = getUser(state);
    if (!user) {
      return;
    }
    return state.collections.customers[user.customer];
  };

  const validateUser = state => {
    const customer = getCustomer(state);
    if (!customer) {
      return false;
    }

    if (onboarded !== undefined && onboarded !== customer.onboarded) {
      return false;
    }

    if (onboarding !== undefined && onboarding !== customer.onboarding) {
      return false;
    }
    return true;
  };

  return function(WrappedComponent) {
    class AuthRequired extends Component {
      UNSAFE_componentWillMount() {
        this.props.onMount();
      }

      render() {
        const props = { ...this.props };
        delete props.onMount; // avoid proxying onMount to wrapped component
        return <WrappedComponent {...props} />;
      }
    }

    AuthRequired.propTypes = {
      onMount: PropTypes.func.isRequired,
      user: PropTypes.object,
      history: PropTypes.shape({
        push: PropTypes.func
      })
    };

    const mapStateToProps = state => {
      let user = null;
      if (validateUser(state)) {
        user = getUser(state);
      }
      return { user };
    };

    const mapDispatchToProps = (dispatch, ownProps) => ({
      onMount() {
        return dispatch((thunkDispatch, getState) => {
          const afterFetched = () => {
            const state = getState();
            const customer = getCustomer(state);
            if (!customer) {
              ownProps.history.push("/login");
            } else if (!validateUser(state)) {
              let redirectUrl = "/";
              if (!customer.onboarded && !customer.onboarding) {
                redirectUrl = "/start/callback";
              } else if (!customer.onboarded && customer.onboarding) {
                redirectUrl = "/start/calendars";
              }
              ownProps.history.push(redirectUrl);
            }
          };

          const state = getState();
          const {
            session: { currentUserId }
          } = state;
          if (!(currentUserId && state.collections.users[currentUserId])) {
            return thunkDispatch(fetchCurrentUser(false)).then(afterFetched);
          } else {
            afterFetched();
          }
        });
      }
    });

    return withRouter(
      connect(
        mapStateToProps,
        mapDispatchToProps
      )(AuthRequired)
    );
  };
}
