/** @format */
import { isEqual } from "lodash";
import PropTypes from "prop-types";
import React from "react";

import { ContactShape } from "../../../utils/shapes";
import { PreferenceContentBlock } from "../../layout/block.react";
import { LongTextPreference } from "./basic.react";
import { bcc, cc } from "./mailto-templates";
import { SelectPreference } from "../../shared/attribute-select.react";

const isBasicForwarding = ({ always, first, confirm }, customerEmail) =>
  (isEqual(always, [customerEmail]) || always.length === 0) &&
  (isEqual(first, [customerEmail]) || first.length === 0) &&
  (isEqual(confirm, [customerEmail]) || confirm.length === 0);

const prettifyForwarding = ({ always, first, confirm }) => {
  if (always.length) {
    return "Always";
  } else if (confirm.length && !first.length) {
    return "On final email";
  } else if (!confirm.length && first.length) {
    return "On first email";
  } else if (confirm.length && first.length) {
    return "On both first and final emails";
  } else {
    return "Never";
  }
};

const forwardingGetter = forwardingBlob => {
  const { always, first, confirm } = JSON.parse(forwardingBlob);
  if (always.length) {
    return "always";
  } else if (confirm.length && !first.length) {
    return "final";
  } else if (!confirm.length && first.length) {
    return "first";
  } else if (confirm.length && first.length) {
    return "both";
  } else {
    return "never";
  }
};

const forwardingSetter = customerEmail => value => {
  switch (value) {
    case "always": {
      return JSON.stringify({
        always: [customerEmail],
        confirm: [],
        first: []
      });
    }
    case "final": {
      return JSON.stringify({
        always: [],
        confirm: [customerEmail],
        first: []
      });
    }
    case "first": {
      return JSON.stringify({
        always: [],
        confirm: [],
        first: [customerEmail]
      });
    }
    case "both": {
      return JSON.stringify({
        always: [],
        confirm: [customerEmail],
        first: [customerEmail]
      });
    }
    default: {
      return JSON.stringify({ always: [], confirm: [], first: [] });
    }
  }
};

const Forwarding = props => {
  const { attribute, customerContact, mailToFn, preference, ...rest } = props;

  const forwarding = JSON.parse(preference[attribute]);
  const preferenceValue = prettifyForwarding(forwarding);

  if (isBasicForwarding(forwarding, customerContact.default_email)) {
    return (
      <PreferenceContentBlock {...rest}>
        <SelectPreference
          selectedOption={preference[attribute]}
          attribute={attribute}
          options={[
            { label: "Never", value: "never" },
            { label: "Always", value: "always" },
            { label: "On final email", value: "final" },
            { label: "On first email", value: "first" },
            { label: "On both first and final emails", value: "both" }
          ]}
          getter={forwardingGetter}
          setter={forwardingSetter(customerContact.default_email)}
        />
      </PreferenceContentBlock>
    );
  } else {
    return (
      <PreferenceContentBlock
        {...rest}
        onEdit={mailToFn(customerContact.first_name)}
      >
        <LongTextPreference>{preferenceValue}</LongTextPreference>
      </PreferenceContentBlock>
    );
  }
};

Forwarding.propTypes = {
  attribute: PropTypes.oneOf(["whenToCC", "whenToBCC"]).isRequired,
  mailToFn: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  supportLink: PropTypes.string.isRequired,
  customerContact: ContactShape.isRequired,
  preference: PropTypes.shape({
    whenToCC: PropTypes.string,
    whenToBCC: PropTypes.string
  }).isRequired
};

export const CC = props => (
  <Forwarding
    {...props}
    attribute="whenToCC"
    mailToFn={cc}
    title="CC settings"
    subtitle="When should Clara CC you on an email?"
    supportLink="https://support.claralabs.com/article/112-cc-bcc-email-preferences"
    bordered={props.bordered}
  />
);

CC.propTypes = {
  customerContact: ContactShape.isRequired,
  preference: PropTypes.shape({
    whenToCC: PropTypes.string.isRequired
  }).isRequired
};

export const BCC = props => (
  <Forwarding
    {...props}
    attribute="whenToBCC"
    mailToFn={bcc}
    title="BCC settings"
    subtitle="When should Clara BCC you on an email?"
    supportLink="https://support.claralabs.com/article/112-cc-bcc-email-preferences"
    bordered={props.bordered}
  />
);

BCC.propTypes = {
  customerContact: ContactShape.isRequired,
  preference: PropTypes.shape({
    whenToBCC: PropTypes.string.isRequired
  }).isRequired
};
