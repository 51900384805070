/** @format */
// URL to the static marker image for marking locations in dashboard maps.
export const MAP_MARKER_ICON =
  "https://s3-us-west-2.amazonaws.com/clara-static-assets/location-circle.png";

// San Francisco coordinates as a map center default.
export const DEFAULT_MAP_COORDS = {
  lat: 37.7486956,
  lng: -122.4150619
};

// Default zoom setting for maps.
export const DEFAULT_MAP_ZOOM = 17;
