/** @format */
import React from "react";

const Testimonials = () => (
  <div className="container PricingTestimonials">
    <div className="row">
      <div className="col-xs-12 PricingTestimonials-header">
        <h3>Trusted by Great People at Great Companies</h3>
      </div>
    </div>
    <div className="row PricingTestimonials-logos">
      <div className="col-xs-4 col-sm-4 col-md-2 center-xs PricingTestimonials-logo">
        <img alt="Pinterest" src="/images/logos/pinterest.png" />
      </div>
      <div className="col-xs-4 col-sm-4 col-md-2 center-xs PricingTestimonials-logo">
        <img alt="AirBnb" src="/images/logos/airbnb.png" />
      </div>
      <div className="col-xs-4 col-sm-4 col-md-2 center-xs PricingTestimonials-logo">
        <img alt="Stripe" src="/images/logos/stripe-color.png" />
      </div>
      <div className="col-xs-4 col-sm-4 col-md-2 center-xs PricingTestimonials-logo">
        <img alt="Twilio" src="/images/logos/twilio.png" />
      </div>
      <div className="col-xs-4 col-sm-4 col-md-2 center-xs PricingTestimonials-logo">
        <img alt="Angellist" src="/images/logos/angellist.png" />
      </div>
      <div className="col-xs-4 col-sm-4 col-md-2 center-xs PricingTestimonials-logo">
        <img alt="Sequoia" src="/images/logos/sequoia.png" />
      </div>
    </div>
    <div className="PricingTestimonials-testimonialContainer">
      <img
        alt="Danielle Morrill"
        className="PricingTestimonials-photo"
        src="/images/daniellebw.png"
      />
      <div className="PricingTestimonials-testimonial">
        <h2>Danielle Morrill</h2>
        <h3 className="PricingTestimonials-attribution">CEO, Mattermark</h3>
        <p className="PricingTestimonials-quote">
          "Spending $2K/month on Clara Labs now â doing at least 2 FTE worth of
          work. It is AMAZING. My team and I couldn&#39;t live without it."
        </p>
      </div>
    </div>
  </div>
);

export default Testimonials;
