/** @format */
import { find } from "lodash";
import PropTypes from "prop-types";
import React, { Component } from "react";

import { VirtualCategory, VirtualKind } from "../../../../utils/enums";
import { VIRTUAL_KIND_TO_UI_DETAIL } from "../../../../utils/virtual";
import { VirtualDetailShape } from "../../../utils/shapes";
import { PreferenceContentBlock } from "../../layout/block.react";
import { SelectAttribute } from "../../shared/attribute-select.react";

const _VIRTUAL_KIND_TO_UI_DETAIL = {
  ...VIRTUAL_KIND_TO_UI_DETAIL,
  [VirtualKind.phone]: { label: "Phone" },
  [VirtualKind.conference_line]: { label: "Conference line" }
};

export default class VirtualDetailDefault extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedDetailId: this._getSelectedDetailId(props.virtualDetails)
    };

    this._onSelect = this._onSelect.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!nextProps.isLoading) {
      this.setState({
        selectedDetailId: this._getSelectedDetailId(nextProps.virtualDetails)
      });
    }
  }

  _showGoogleMicrosoftOption() {
    let assistant = this.props.activeAssistant
    if (!assistant) return false

    let orgDomains = this.props.customer.organization.domains
    if (!orgDomains) return false

    let assistantEmail = assistant.default_email
    let assistantEmailDomain = assistantEmail.split("@")[1]

    return orgDomains.includes(assistantEmailDomain)
  }

  render() {
    const { isLoading, supportLink, virtualDetails } = this.props;
    const { selectedDetailId } = this.state;

    let showGoogleMicrosoft = this._showGoogleMicrosoftOption()

    let options = virtualDetails.map(({ id, kind, value }) => {
      if (
        (kind === "googlemeet" && !showGoogleMicrosoft) ||
        (kind === "microsoft_teams" && !showGoogleMicrosoft)
      ) {
        // don't show these options if Clara isn't on the customer's org's domains
        return null
      }

      const valueLabel = value.length > 0 ? ` (${value})` : "";
      return {
        label: `${_VIRTUAL_KIND_TO_UI_DETAIL[kind].label}${valueLabel}`,
        value: String(id)
      };
    });

    options = options.filter((option) => {
      return option
    })

    return (
      <PreferenceContentBlock
        title="Call default"
        subtitle="What contact details should Clara use by default for calls?"
        supportLink={supportLink}
        bordered={this.props.bordered}
      >
        {options.length ?
          <SelectAttribute
            selectedOption={selectedDetailId}
            options={options}
            onSelect={this._onSelect}
            isLoading={isLoading}
          />
          :
          <div className="SelectAttribute">
            No contact details found. Please add contact details below.
          </div>
        }
      </PreferenceContentBlock>
    );
  }

  _getSelectedDetailId(virtualDetails) {
    // no default contact details, usually from a newly registered user
    if (virtualDetails.length === 0) return null

    const selectedVirtualDetail = find(
      virtualDetails,
      vd =>
        vd.categories.indexOf(VirtualCategory.direct_call_default) !== -1 &&
        vd.categories.indexOf(VirtualCategory.group_call_default) !== -1
    );
    return String(selectedVirtualDetail.id);
  }

  _onSelect(value) {
    this.setState({ selectedDetailId: value });
    return this.props.onSetDefault(value);
  }
}

VirtualDetailDefault.propTypes = {
  virtualDetails: PropTypes.arrayOf(VirtualDetailShape).isRequired,
  isLoading: PropTypes.bool.isRequired,
  onSetDefault: PropTypes.func.isRequired,
  supportLink: PropTypes.string.isRequired
};
