/** @format */
import PropTypes from "prop-types";

import React, { Component } from "react";
import { connect } from "react-redux";
import config from "../../../config";
import {
  ContactSyncedCalendar,
  MicrosoftAccount
} from "../../../collections/schema";
import { generateShape } from "../../../collections/shapes";
import AccountCalendarList from "../../components/dashboard/account/account-calendar-list.react";
import {
  getIsError,
  getContactSyncedCalendars,
  fetchAccountCalendars,
  setCalendars
} from "../../modules/connected-calendars";

export class MicrosoftAccountCalendarList extends Component {
  UNSAFE_componentWillMount() {
    this.props.onLoad();
  }

  render() {
    const {
      microsoftAccount,
      contactSyncedCalendars,
      isError,
      onSetCalendars
    } = this.props;
    return (
      <AccountCalendarList
        name={`Microsoft (${microsoftAccount.email_address ? microsoftAccount.email_address.id : ""})`}
        email={microsoftAccount.email_address ? microsoftAccount.email_address.id : null}
        isLoading={!isError && contactSyncedCalendars === null}
        isValid={microsoftAccount.is_valid}
        isError={isError}
        reconnectUrl={`${
          config.api.host
        }/microsoft_accounts/actions/update_or_create`}
        contactSyncedCalendars={contactSyncedCalendars}
        onSetCalendars={onSetCalendars}
      />
    );
  }
}

MicrosoftAccountCalendarList.propTypes = {
  microsoftAccount: generateShape(MicrosoftAccount, {
    email_address: {}
  }).isRequired,
  contactSyncedCalendars: PropTypes.arrayOf(
    generateShape(ContactSyncedCalendar, {
      synced_calendar: {}
    })
  ),
  isError: PropTypes.bool.isRequired,
  onLoad: PropTypes.func.isRequired,
  onSetCalendars: PropTypes.func.isRequired
};

const mapStateToProps = (state, { microsoftAccount }) => ({
  isError: getIsError(state, microsoftAccount.id),
  contactSyncedCalendars: getContactSyncedCalendars(state, microsoftAccount.id)
});

const mapDispatchToProps = (dispatch, { microsoftAccount }) => ({
  onLoad() {
    dispatch(fetchAccountCalendars(microsoftAccount.id));
  },

  onSetCalendars(contactSyncedCalendarIds) {
    dispatch(setCalendars(contactSyncedCalendarIds, microsoftAccount.id));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MicrosoftAccountCalendarList);
