/** @format */
import { connect } from "react-redux";

import CalendarSelect from "../../components/onboarding/calendar-select.react";
import authRequired from "../../components/shared/auth-required.react";
import {
  fetchCalendars,
  setCalendars,
  getAccounts,
  getIsError,
  getIsLoading
} from "../../modules/connected-calendars";
import { fetchCompleteOnboard, getIsCompleting } from "../../modules/onboard";
import { getCurrentCustomer } from "../../modules/session";

const mapStateToProps = state => {
  const customer = getCurrentCustomer(state);
  const accounts = getAccounts(state, customer);
  const googleAccount =
    accounts && accounts.googleAccounts.length > 0
      ? accounts.googleAccounts[0]
      : null;
  const microsoftAccount =
    accounts && accounts.microsoftAccounts.length > 0
      ? accounts.microsoftAccounts[0]
      : null;
  const isError = getIsError(state);

  return {
    isCompleting: getIsLoading(state) || getIsCompleting(state),
    isLoading: !(accounts || isError),
    isError,
    googleAccount,
    microsoftAccount
  };
};

const mapDispatchToProps = (dispatch, { nextStep }) => ({
  onUser() {
    dispatch(fetchCalendars());
  },

  onSetCalendars(
    contactSyncedCalendarIds,
    microsoftAccountId,
    googleAccountId
  ) {
    dispatch(
      setCalendars(
        contactSyncedCalendarIds,
        microsoftAccountId,
        googleAccountId
      )
    ).then(() => {
      dispatch(fetchCompleteOnboard()).then(nextStep);
    });
  }
});

export default authRequired({ onboarded: false, onboarding: true })(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(CalendarSelect)
);
