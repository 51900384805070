import React from 'react';
import { generateHashColor, extractInitials } from '../live-v2/utils/meeting-utilities.react'


const MeetingItem = (props) => {
  const formatMeetingTime = (date) => {
    let d = new Date(date);

    let hrs = d.getHours();
    let mins = d.getMinutes();
    let ampm = hrs >= 12 ? 'PM' : 'AM';

    hrs = hrs % 12;
    hrs = hrs ? hrs : 12;
    mins = mins < 10 ? '0' + mins : mins;

    return hrs + ':' + mins + ' ' + ampm;
  }

  const renderIcon = () => {
    let subIcon
    let iconColor
    let iconSrc = props.location ? "/images/meeting-location.svg" : "/images/meeting-call.svg"

    let now = new Date()
    let endTime = new Date(props.endTime)

    if (props.isCancelled) {
      iconColor = "#FFFFFF"
      subIcon = (
        <div className="MeetingItem-subIcon">
          <img src="/images/meeting-cancel-icon.svg" />
        </div>
      )
    } else if (now >= endTime) {
      iconColor = "#E5FCEE"
      subIcon = (
        <div className="MeetingItem-subIcon">
          <img src="/images/meeting-done-icon.svg" />
        </div>
      )
    } else {
      iconColor = "#FEF7E6"
    }

    return (
      <div className="MeetingItem-iconContainer" style={{backgroundColor: iconColor}}>
        <img className="MeetingItem-icon" src={iconSrc} />
        {subIcon}
      </div>
    )
  }

  const renderTimeRow = () => {
    let status
    if (props.isCancelled) {
      status = (
        <div className="MeetingItem-cancelledIndicator">Cancelled</div>
      )
    } else if (props.isCompleted) {
      status = (
        <div className="MeetingItem-completedIndicator">Completed</div>
      )
    }

    return (
      <div className="MeetingItem-timeContainer">
        {formatMeetingTime(props.startTime)}
        {status}
      </div>
    )
  }

  const renderTitleRow = () => {
    return (
      <div className="MeetingItem-title">
        {props.title ? props.title : "No meeting title"}
      </div>
    )
  }

  const renderParticipantRow = () => {
    if (props.participantCount !== 0) {
      return (
        <div className="MeetingItem-participantRow">
          {props.participants.map((participant, index) => {
            let participantGroup = (
              <div className="MeetingItem-avatarGroup">
                <div
                  className="MeetingItem-avatar"
                  style={{backgroundColor: generateHashColor(participant), color: generateHashColor(participant, true)}}
                >
                  {extractInitials(participant)}
                </div>
                {participant}
              </div>
            )

            if (index === 0) {
              return participantGroup
            } else {
              return (
                <div className="MeetingItem-participantGroup">
                  <div className="MeetingItem-dot"/>
                  {participantGroup}
                </div>
              )
            }
          })}
        </div>
      )
    } else {
      return (
        <div className="MeetingItem-participantRow">
          No participants found.
        </div>
      )
    }
  }

  return (
    <div className={props.isCancelled ? "MeetingItem-cancelled" : "MeetingItem"}>
      <div className="MeetingItem-iconColumn">
        {renderIcon()}
      </div>
      <div className="MeetingItem-detailsColumn">
        {renderTimeRow()}
        <div className="MeetingItem-dividerContainer">
          <div className="MeetingItem-divider" />
        </div>
        {renderParticipantRow()}
        {renderTitleRow()}
      </div>
    </div>
  )
}

export default MeetingItem
