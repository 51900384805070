/** @format */
import React, { useState } from "react";
import CustomerStatusIndicator from "./customer-status-indicator.react";

export default ({ customer, contact, company, googleAccount, isEditing }) => {
  const [contactNameValue, setContactNameValue] = useState(contact.name)
  const [companyNameValue, setCompanyNameValue] = useState(company ? company.name : null)

  const handleOnChange = (type) => {
    let elementId
    if (type === "contact") {
      elementId = "nameInput"
    } else if (type === "company") {
      elementId = "companyInput"
    }
  
    let element = document.getElementById(elementId)
    if (!element) return

    if (type === "contact") {
      setContactNameValue(element.value)
    } else if (type === "company") {
      setCompanyNameValue(element.value)
    }
  }

  let photo;
  if (googleAccount && googleAccount.photo_url) {
    photo = (
      <img
        className="CustomerProfile-photo"
        src={`${googleAccount.photo_url}?sz=128`}
      />
    );
  }

  let companyName
  let contactName
  if (company && company.name) {
    if (isEditing) {
      contactName = (
        <input
          className="CustomerProfile-nameInput"
          id="nameInput"
          defaultValue={contactNameValue}
          onChange={() => handleOnChange("contact")}
        />
      )
      companyName = (
        <input
          className="CustomerProfile-companyInput"
          id="companyInput"
          defaultValue={companyNameValue}
          onChange={() => handleOnChange("company")}
        />
      )
    } else {
      contactName = <div className="CustomerProfile-name">{contactNameValue}</div>
      companyName = <div className="CustomerProfile-company">{companyNameValue}</div>
    }
  }

  return (
    <div className="CustomerProfile">
      {photo}
      <div className="CustomerProfile-detail">
        {contactName}
        {companyName}
        <div className="CustomerProfile-status">
          <CustomerStatusIndicator customer={customer} />
        </div>
      </div>
    </div>
  );
};
