import { schema } from 'normalizr';
import { mapValues, pick, keys } from 'lodash';
import generateRelationships from './relationships';

// Process strategy for entities that have loading and deleted meta properties.
const metaProcessStrategy = entity => ({
  ...entity,
  meta: {
    loading: false,
    deleted: false,
    ...entity.meta,
  },
});

export const Assistant = new schema.Entity('assistants');
export const BillingGroup = new schema.Entity('billing_groups');
export const BillingGroupInvitation = new schema.Entity('billing_group_invitations');
export const Calendar = new schema.Entity('calendars');
export const Company = new schema.Entity('companies');
export const Contact = new schema.Entity('contacts');
export const ContactSyncedCalendar = new schema.Entity('contact_synced_calendars');
export const Contractor = new schema.Entity('contractors');
export const Customer = new schema.Entity('customers');
export const CustomerAuditLog = new schema.Entity('customer_audit_logs');
export const EmailAddress = new schema.Entity('email_addresses');
export const EmailAddressVerification = new schema.Entity('email_address_verifications');
export const EmailMessage = new schema.Entity('email_messages');
export const EmailMessageJob = new schema.Entity('email_message_jobs');
export const EmailMessageWrite = new schema.Entity('email_message_writes');
export const Event = new schema.Entity('events');
export const EventParticipant = new schema.Entity('event_participants');
export const EventWrite = new schema.Entity('event_writes');
export const FeedbackRating = new schema.Entity('feedback_ratings');
export const Job = new schema.Entity('jobs');
export const JobSession = new schema.Entity('job_sessions');
export const JobSessionReview = new schema.Entity('job_session_reviews');
export const JobSessionReviewMistake = new schema.Entity('job_session_review_mistakes');
export const JobSessionReviewMistakeAppealAuditLog = new schema.Entity('job_session_review_mistake_appeal_audit_logs');
export const JobStateMachineAuditLog = new schema.Entity('job_state_machine_audit_logs');
export const GoogleAccount = new schema.Entity('google_accounts');
export const Keyword = new schema.Entity('keywords');
export const Location = new schema.Entity('locations', {}, {
  processStrategy: metaProcessStrategy,
});
export const MicrosoftAccount = new schema.Entity('microsoft_accounts');
export const MeetingPermission = new schema.Entity('meeting_permissions');
export const MeetingResourceGroup = new schema.Entity('meeting_resource_groups');
export const MeetingType = new schema.Entity('meeting_types')
export const Organization = new schema.Entity('organizations');
export const Participant = new schema.Entity('participants');
export const Preference = new schema.Entity('preferences');
export const SuggestedTime = new schema.Entity('suggested_times');
export const SyncedCalendar = new schema.Entity('synced_calendars');
export const Thread = new schema.Entity('threads');
export const TimeConstraintRecurring = new schema.Entity('time_constraint_recurrings');
export const User = new schema.Entity('users');
export const VirtualDetail = new schema.Entity('virtual_details', {}, {
  processStrategy: metaProcessStrategy,
});

export const keyToSchema = [
  Assistant,
  BillingGroup,
  BillingGroupInvitation,
  Calendar,
  Company,
  Contact,
  ContactSyncedCalendar,
  Contractor,
  Customer,
  CustomerAuditLog,
  EmailAddress,
  EmailAddressVerification,
  EmailMessage,
  EmailMessageJob,
  EmailMessageWrite,
  Event,
  EventParticipant,
  EventWrite,
  FeedbackRating,
  Job,
  JobSession,
  JobSessionReview,
  JobSessionReviewMistake,
  JobSessionReviewMistakeAppealAuditLog,
  JobStateMachineAuditLog,
  GoogleAccount,
  Keyword,
  Location,
  MeetingPermission,
  MeetingResourceGroup,
  MeetingType,
  MicrosoftAccount,
  Organization,
  Participant,
  Preference,
  SuggestedTime,
  SyncedCalendar,
  Thread,
  TimeConstraintRecurring,
  User,
  VirtualDetail,
].reduce((map, scheme) => {
  map[scheme.key] = scheme;
  return map;
}, {});

// generate and apply relationship definitions
export const relationships = generateRelationships();
keys(relationships).forEach(key => {
  keyToSchema[key].define(
    mapValues(relationships[key], (specification) => {
      return specification.isArray ?
      new schema.Array(specification.scheme) :
      specification.scheme;
    })
  );
});

// in the case of multiple relationships between the
// same schema, we return null
export const getRelationshipName = (from, to) => {
  const matchingRelationships = pick(from.schema, (v) => {
    return v === to || v.schema === to;
  });

  const relationshipKeys = keys(matchingRelationships);
  if (relationshipKeys.length === 1) {
    return relationshipKeys[0];
  } else {
    return null;
  }
};

export const schemaWithRelationships = (rootSchema, relationshipTree) => {
  const clone = new schema.Entity(rootSchema.key);
  Object.entries(relationshipTree).forEach(([key, subtree]) => {
    const relatedSchema = rootSchema.schema[key];
    if (!relatedSchema) {
      throw new Error(`"${ key }" not found in "${ rootSchema.key }" schema definition`);
    }

    if (relatedSchema instanceof schema.Array) {
      clone.define({ [key]: [schemaWithRelationships(relatedSchema.schema, subtree)] });
    } else {
      clone.define({ [key]: schemaWithRelationships(relatedSchema, subtree) });
    }
  });

  return clone;
};

export default keyToSchema;
