/** @format */
import PropTypes from "prop-types";

import React, { Component } from "react";
import { IconWithTooltip, TextArea, Toggle } from "vogue";

import GooglePlacesSearchBox from "../../shared/google-places-search-box.react";
import SelectMenu from "../../shared/select-menu.react";
import { LOCATION_NAME_MAPPING } from "../../../../utils/location";
import { LocationShape } from "../../../utils/shapes";
// import SimpleMap from "../../shared/maps/simple-map.react";
import ValidatingTextField from "../../shared/validating-text-field.react";
import FormInputContainer from "../../shared/form-input-container.react";

const getNameValidationError = name => {
  if (!name || name.trim().length === 0) return "Name cannot be left blank";
};

const getFormattedAddressValidationError = formattedAddress => {
  if (!formattedAddress) return "Address cannot be left blank";
};

const getCategoryValidationError = category => {
  if (!category) return "Category cannot be left blank";
};

export const getValidationError = ({ formatted_address, name, category }) =>
  getFormattedAddressValidationError(formatted_address) ||
  getNameValidationError(name) ||
  getCategoryValidationError(category);

const categories = [{ label: "", value: "", disabled: true }].concat(
  Object.entries(LOCATION_NAME_MAPPING).map(([key, name]) => {
    return {
      label: name,
      value: key
    };
  })
);

export default class LocationDetailForm extends Component {
  constructor(props) {
    super(props);

    this._onAdditionalInfoSet = this._onAdditionalInfoSet.bind(this);
    this._onCategorySet = this._onCategorySet.bind(this);
    this._onDefaultSet = this._onDefaultSet.bind(this);
    this._onLocationSet = this._onLocationSet.bind(this);
    this._onNameSet = this._onNameSet.bind(this);
  }

  render() {
    const { forceValidation, location } = this.props;

    let formattedAddressProps = {
      modifiers: [],
      helperText: null
    };

    const validationError = getFormattedAddressValidationError(
      location.formatted_address
    );
    if (forceValidation && validationError) {
      formattedAddressProps = {
        modifiers: ["error"],
        helperText: validationError
      };
    }

    let defaultToggle;
    if (location.category) {
      defaultToggle = (
        <div className="LocationDetailForm-default">
          <span>
            Set as default for {LOCATION_NAME_MAPPING[location.category]}
          </span>
          <Toggle checked={location.is_default} onChange={this._onDefaultSet} />
        </div>
      );
    }

    const additionalLocationDetailsHeading = (
      <span>
        Additional location details
        <IconWithTooltip
          name="messageid"
          tooltip="This will be added to the event description"
          className="LocationDetailForm-toolTip"
        />
      </span>
    );

    return (
      <div className="LocationDetailForm">
        <div className="LocationDetailForm-form">
          <FormInputContainer heading="Address">
            <GooglePlacesSearchBox
              label=""
              initialValue={location.formatted_address}
              onChange={this._onLocationSet}
              {...formattedAddressProps}
            />
          </FormInputContainer>
          <FormInputContainer heading="Name">
            <ValidatingTextField
              value={location.name}
              validate={getNameValidationError}
              forceValidation={forceValidation}
              onChange={this._onNameSet}
            />
          </FormInputContainer>
          <FormInputContainer heading="Use this place for">
            <SelectMenu
              onChange={this._onCategorySet}
              value={location.category || ""}
              options={categories}
            />
            {defaultToggle}
          </FormInputContainer>
          <FormInputContainer heading={additionalLocationDetailsHeading}>
            <TextArea
              placeholder="e.g. Enter code #12345 for the door"
              value={location.additional_info}
              onChange={this._onAdditionalInfoSet}
              rows={2}
              autoFocus={false}
            />
          </FormInputContainer>
        </div>

        {/* FIXME: Map causing breakage. */}
        {/* <div className="LocationDetailForm-map">
          <SimpleMap location={location} />
        </div> */}
      </div>
    );
  }

  _onAdditionalInfoSet({ target: { value } }) {
    this.props.onChange({
      additional_info: value
    });
  }

  _onCategorySet({ target: { value } }) {
    this.props.onChange({
      category: value,
      is_default: false
    });
  }

  _onDefaultSet() {
    this.props.onChange({ is_default: !this.props.location.is_default });
  }

  _onLocationSet(input) {
    this.props.onChange({
      formatted_address: input.formattedAddress,
      lat: input.lat,
      lng: input.lng,
      google_place_id: input.googlePlaceId,
      name: input.name
    });
  }

  _onNameSet(event) {
    this.props.onChange({ name: event.target.value });
  }
}

LocationDetailForm.defaultProps = {
  forceValidation: false
};

LocationDetailForm.propTypes = {
  onChange: PropTypes.func.isRequired,
  forceValidation: PropTypes.bool,
  location: LocationShape.isRequired
};
