/** @format */
import { omit } from "lodash";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { TextField } from "vogue";

/**
 * A `ValidatingTextField` is a `TextField` that validates the user's input and displays the
 * appropriate error states. By default the input is validated on blur but by setting the
 * `forceValidation` prop to `true` the input will be validated before render.
 */
export default class ValidatingTextField extends Component {
  constructor(props) {
    super(props);

    this._onBlur = this._onBlur.bind(this);
    this._onChange = this._onChange.bind(this);

    if (props.forceValidation) {
      this.state = this._validate(props.value);
    } else {
      this.state = {
        valid: true,
        errorMessage: ""
      };
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.forceValidation) {
      const result = this._validate(nextProps.value);
      this.setState(result);
    }
  }

  render() {
    const { helperText, modifiers, ...props } = this.props;
    const { errorMessage, valid } = this.state;

    return (
      <TextField
        {...omit(props, "validate", "forceValidation")}
        onBlur={this._onBlur}
        onChange={this._onChange}
        helperText={valid ? helperText || "" : errorMessage}
        modifiers={valid ? modifiers : (modifiers || []).concat("error")}
      />
    );
  }

  _onBlur(event) {
    const result = this._validate(event.target.value);
    this.setState(result);

    if (this.props.onBlur) this.props.onBlur(event);
  }

  _onChange(event) {
    if (!this.state.valid) {
      const result = this._validate(event.target.value);
      this.setState(result);
    }

    if (this.props.onChange) this.props.onChange(event);
  }

  _validate(value) {
    const result = this.props.validate(value);
    return {
      valid: !result,
      errorMessage: result || ""
    };
  }
}

ValidatingTextField.defaultProps = { forceValidation: false };

ValidatingTextField.propTypes = {
  ...TextField.propTypes,
  forceValidation: PropTypes.bool,
  validate: PropTypes.func.isRequired
};
