import moment from 'moment-timezone';
import 'moment-duration-format';
import JobUtils from './job';

export function getFollowUpRelativeTime(dt, stripPrefix) {
  if (dt && moment.utc(dt) > moment()) {
    const time = moment.utc(dt);
    return time.fromNow(stripPrefix);
  } else if (moment.utc(dt) <= moment()) {
    return 'Needs Follow-Up';
  } else {
    return '';
  }
}

export function getHumanizedMinutes(totalMinutes) {
  // Not using moment.duration.humanize because it is not precise enough:
  // it renders 90 minutes as 2 hours.
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;
  const result = [];
  if (hours) {
    result.push(`${ hours } hr`);
  }
  if (minutes) {
    result.push(`${ minutes } min`);
  }
  return result.join(' ');
}

export function getFollowUpTimeIndicatorLong(dt, timezone) {
  if (dt && moment.utc(dt) > moment()) {
    let time = moment.utc(dt);
    if (timezone) {
      time = time.tz(timezone);
    }
    return time.format('ddd, MMM D, YYYY');
  } else {
    return 'no follow-up';
  }
}

export function momentPropValidator(props, propName, componentName) {
  if (props[propName] && !moment.isMoment(props[propName])) {
    return new Error(`Invalid prop \`${ propName }\` supplied to \`${ componentName }\`, expected a \`moment\`.`);
  }
}

export function eventStart(event) {
  return event.start_time || event.start_date;
}

export function eventEnd(event) {
  return event.end_time || event.end_date;
}

export function renderRangeFromTimeSpan(zonedMoment, timeSpan) {
  const start = zonedMoment(timeSpan.start_time);
  const end = zonedMoment(timeSpan.end_time);
  const startTime = start.format('h:mm');
  const startAMPM = start.format('A') === end.format('A')
    ? '' : ` ${ start.format('A') }`;
  const endTime = end.format('h:mm A');

  return `${ startTime }${ startAMPM } \u2014 ${ endTime }`;
}

export function renderEventTimeRange(zonedMoment, event, rangeThreshold = null) {
  // TODO: how do we render multi-day all day events?
  const isAllDay = event.start_time === null;
  const start = zonedMoment(eventStart(event));
  const end = zonedMoment(eventEnd(event));
  let timeRange;
  if (isAllDay) {
    timeRange = 'All Day';
  } else {
    const duration = end.diff(start, 'minutes');
    let durationPart = '';
    if (rangeThreshold && duration > rangeThreshold) {
      durationPart = 'Range';
    } else {
      durationPart = `${ duration } minutes`;
    }
    // unicode 2014 is an emdash
    timeRange = `${ renderRangeFromTimeSpan(zonedMoment, event) } (${ durationPart })`;
  }

  return timeRange;
}

export function renderEventStartVerbose(zonedMoment, event) {
  const start = zonedMoment(eventStart(event));
  return start.format('dddd, Do MMMM, YYYY (z)');
}

export function calendarEventDisplay(job, event) {
  const startDate = renderEventStartVerbose(JobUtils.zonedMoment(job), event);
  const timeRange = renderEventTimeRange(JobUtils.zonedMoment(job), event, job.duration);
  return [startDate, timeRange];
}

export function PSTMoment() {
  return moment.apply(null, arguments).tz('America/Los_Angeles');
}

export function durationToTextFormat(minutes) {
  // note: if moment or moment-duration-format ever get updated,
  // might be able to clean up this function and remove special cases.
  const hourText = minutes < 120 ? 'hour' : 'hours';
  if (minutes % 60 === 0) {
    return moment.duration(minutes, 'minutes').format(`h [${hourText}]`);
  }
  return moment.duration(minutes, 'minutes').format(`h [${hourText}] m [minutes]`);
}

export function createZonedMoment(timezone) {
  // A zoned moment is a wrapped version of moment that coerces the timezone
  // of all constructed moments to the target timezone. This coercion does not
  // change the UTC time of an argument.
  return function() {
    return moment(...arguments).tz(timezone);
  };
}

export function getRelativeTime(time) {
  const diff = moment(time).diff(moment.utc(), "seconds");
  return Math.abs(diff) < 60 ? "just now" : moment(time).fromNow();
}
