/** @format */
import React from "react";

import { getRelativeTime } from "../../../../../utils/time";
import { SlackAccountShape } from "../../../../utils/shapes";

const SlackAccountDetail = ({ slackAccount }) => (
  <div>
    <span className="u-textWeight500">Connected to channel: {JSON.stringify(slackAccount.meta.channel)}</span>

    <div>
      <span className="IntegrationAccountDetail-timeSince">
        (added {getRelativeTime(slackAccount.created)})
      </span>
    </div>
  </div>
);

SlackAccountDetail.propTypes = {
  slackAccount: SlackAccountShape.isRequired
};

export default SlackAccountDetail;
