import invariant from 'invariant';
import { APIResponseError } from './request';

function RetriesExceededError(retryAttempts, originalError) {
  this.name = 'RetriesExceededError';
  this.message = `Failed to load after ${ retryAttempts } attempts`;
  this.originalError = originalError;
  this.stack = (new Error()).stack;
}

RetriesExceededError.prototype = Object.create(Error.prototype);
RetriesExceededError.prototype.constructor = RetriesExceededError;

const retryable = (promiseCreator, { maxAttempts = 3, delay = 1000, onStatusCodes = [] } = {}) => {
  return new Promise((resolve, reject) => {
    let attempts = 0;
    const tryRequest = () => {
      const p = promiseCreator();
      invariant(typeof p.then === 'function', 'promiseCreator must return a promise');
      attempts += 1;

      const retry = (originalError) => {
        if (attempts >= maxAttempts) {
          reject(new RetriesExceededError(maxAttempts, originalError));
        } else {
          setTimeout(tryRequest, delay);
        }
      };

      const shouldRetry = (res) => {
        const statusCode = res && res.status;
        invariant(
          statusCode,
          'expected promise to resolve with a fetch response object'
        );

        return onStatusCodes.includes(statusCode);
      };

      p.then(({ body, res }) => {
        if (shouldRetry(res)) {
          const error = new Error(
            `Request to ${ res.url } responded with ${ res.status } (retries on: ${ onStatusCodes.join(', ') })`
          );
          error.res = res;
          retry(error);
        } else {
          resolve({ body, res });
        }
      }).catch((error) => {
        if (error instanceof APIResponseError && shouldRetry(error.res)) {
          retry(error);
        } else {
          reject(error);
        }
      });
    };

    tryRequest();
  });
};

export default retryable;
