/** @format */
import { connect } from "react-redux";

import Greenhouse from "../../components/dashboard/integrations/greenhouse.react";
import {
  fetchSummary,
  getGreenhouseDetails,
  getIsLoadingSummary
} from "../../modules/integrations";
import createInitiallyLoadingContainer from "../create-initially-loading-container.react";

const mapStateToProps = state => ({
  greenhouseDetails: getGreenhouseDetails(state),
  isLoading: getIsLoadingSummary(state)
});

const mapDispatchToProps = dispatch => ({
  onLoad() {
    return dispatch(fetchSummary());
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(createInitiallyLoadingContainer(Greenhouse));
