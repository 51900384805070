/** @format */
import React from "react";

import { getRelativeTime } from "../../../../../utils/time";
import { ZoomAccountShape } from "../../../../utils/shapes";

const ZoomAccountDetail = ({ zoomAccount }) => (
  <div>
    <span className="u-textWeight500">Connected account</span>
    <div>
      <span>{zoomAccount.zoom_user_email}</span>
      <span className="ZoomAccountDetail-timeSince">
        (added {getRelativeTime(zoomAccount.created)})
      </span>
    </div>
    {!zoomAccount.is_valid && (
      <div className="ZoomAccountDetail-invalidText">
        Clara is unable to add Zoom links to meeting invitations until this
        account is reconnected.
      </div>
    )}
  </div>
);

ZoomAccountDetail.propTypes = {
  zoomAccount: ZoomAccountShape.isRequired
};

export default ZoomAccountDetail;
