/** @format */
import { connect } from "react-redux";

import Hackerrank from "../../components/dashboard/integrations/hackerrank.react";
import {
  configureHackerrank,
  fetchHackerrank,
  getIsConfiguringHackerrank,
  getIsLoadingHackerrank,
  getHackerrankDetails
} from "../../modules/integrations";
import createInitiallyLoadingContainer from "../create-initially-loading-container.react";

const mapStateToProps = state => ({
  hackerrankDetails: getHackerrankDetails(state),
  isLoading: getIsLoadingHackerrank(state),
  isConfiguringHackerrank: getIsConfiguringHackerrank(state)
});

const mapDispatchToProps = dispatch => ({
  onLoad() {
    return dispatch(fetchHackerrank());
  },

  onConfigureHackerrank(hackerrankApiKey) {
    return dispatch(configureHackerrank(hackerrankApiKey));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(createInitiallyLoadingContainer(Hackerrank));
