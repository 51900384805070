/** @format */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  SIDE_NAVIGATION_ITEMS_LIVE,
  SIDE_NAVIGATION_ITEMS,
  calculateTrialProgress
} from "./side-navigation-config.react"

class SideNavigation extends Component {
  constructor(props) {
    super(props);
    this.state = { 
      showMobileAd: false
    }

    this.handleHideAd = this.handleHideAd.bind(this)
  }

  goToGooglePlay() {
    window.open("https://play.google.com/store/apps/details?id=com.claramobile", "_blank")
  }

  goToAppStore() {
    window.open("https://apps.apple.com/us/app/clara-mobile/id1594981204", "_blank")
  }

  handleHideAd() {
    this.props.hideMobileAd(this.props.customer.contact_id)
    .then(() => {
      this.setState({showMobileAd: false})
    })
  }

  renderTabs() {
    let items = this.props.customerHasLive ? SIDE_NAVIGATION_ITEMS_LIVE : SIDE_NAVIGATION_ITEMS
    return (
      <div className="SideNavigation-tabs">
        {items.map((x) => {
          let tabClass
          let tabTextClass
          let iconSrc

          if (x.text === this.props.selected) {
            tabClass = "SideNavigation-selectedTab"
            tabTextClass = "SideNavigation-selectedTabText"
            iconSrc = `../../../images/side-nav-${x.text.toLowerCase()}-selected.svg`
          } else {
            tabClass = "SideNavigation-tab"
            tabTextClass = "SideNavigation-tabText"
            iconSrc = `../../../images/side-nav-${x.text.toLowerCase()}.svg`
          }

          return (
            <Link
              className={tabClass}
              key={x.key}
              to={x.link}
            >
              <div className="SideNavigation-tabIcon">
                <img src={iconSrc} />
              </div>
              <div className={tabTextClass}>
                {x.text}
              </div>
            </Link>
          )
        })}
      </div>
    )
  }

  renderAppSpacer() {
    if (!this.state.showMobileAd) return

    return (
      <div className="SideNavigation-adSpacerContainer">
        <div className="SideNavigation-adSpacer" />
      </div>
    )
  }

  renderAppAd() {
    if (!this.state.showMobileAd) return

    return (
      <div id="claraMobileAd" className="SideNavigation-adContainer">
        <img style={{width: "80%"}} src="/images/mobile-ad.svg" />
        <div className="SideNavigation-hideAdButton" onClick={this.handleHideAd}/>
        <div className="SideNavigation-adButtonRow">
          <div className="SideNavigation-googleAdButton" onClick={this.goToGooglePlay} />
          <div className="SideNavigation-appleAdButton" onClick={this.goToAppStore} />
        </div>
      </div>
    )
  }

  renderTrial() {
    if (!this.props.isPaying) {
      let trialDaysRemaining = this.props.trialDaysRemaining > 0 ? this.props.trialDaysRemaining : 0 // no negatives
      let dayString = this.props.trialDaysRemaining === 1 ? "day" : "days"

      const daysRemainingTitle =
        trialDaysRemaining > 0
          ? `Your trial has ${trialDaysRemaining} ${dayString} left`
          : "Your trial has expired";

      const daysRemainingText = 
        trialDaysRemaining > 0
          ? `Free trial ends in ${trialDaysRemaining} ${dayString}`
          : "Free trial has expired";

      return (
        <div className="SideNavigationMinimized-trial">
          <div className="SideNavigationMinimized-trialBox">
            <div className="SideNavigationMinimized-trialUpgradeRow">
              <div className="trialText" title={daysRemainingTitle}>{daysRemainingText}</div>
              <div
                className="upgradeText"
                onClick={() => {window.location=`https://claralabs.com/pricing?c=true`}}
              >
                Upgrade
              </div>
            </div>
            <div className="SideNavigationMinimized-trialProgressRow">
              <div className="SideNavigationMinimized-trialProgressBar">
                <div
                  className="SideNavigationMinimized-trialProgress"
                  style={{width: calculateTrialProgress(trialDaysRemaining)}}
                />
              </div>
            </div>
          </div>
        </div>
      )
    }
  }

  render() {
    return (
      <div className="SideNavigation">
        <div className="SideNavigation-logoContainer">
          <div className="SideNavigation-logoRow">
            <img src="/images/clara-logo-v2.svg" />
            <div className="SideNavigation-arrow" onClick={() => this.props.handleToggle()}>
              <img src="/images/side-nav-minimize.svg" />
            </div>
          </div>
        </div>
        <div className="SideNavigation-searchBarRow" />
        {this.renderAppSpacer()}
        {this.renderTabs()}
        {this.renderAppAd()}
        {this.renderTrial()}
        <div className="SideNavigation-help">
          <a className="SideNavigation-tab" href={"https://support.claralabs.com/"}>
            <div className="SideNavigation-tabIcon">
              <img src="/images/help-resources.svg" />
            </div>
            <div className="SideNavigation-tabText">Help</div>
          </a>
        </div>
      </div>
    )

  }
}

export default SideNavigation;
