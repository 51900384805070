/** @format */
import { combineReducers } from "redux";
import {
  handleActions,
  createAsyncAction,
  wrapPromiseToThunk
} from "../../utils/redux-actions";
import { handleFetchGroupActions } from "../utils/fetch-group";
import { fetchApi } from "../../utils/request";
import { requestErrorReducer } from "../utils/reducers";
import { REPORT_ERROR, reportError } from "./error";
import { consumePayload, expand } from "../../collections/module";
import {
  BillingGroup,
  BillingGroupInvitation,
  Customer
} from "../../collections/schema";
import { getCurrentBillingGroup } from "./session";

export const MOUNT_POINT = "team";

const CREATE_INVITATION = createAsyncAction("TEAM/CREATE_INVITATION");
const FETCH_INVITATIONS = createAsyncAction("TEAM/FETCH_INVITATIONS");
const GET_TEAM_CUSTOMERS = createAsyncAction("TEAM/GET_TEAM_CUSTOMERS");
const SET_SEND_EMAIL_RECEIPTS = createAsyncAction(
  "TEAM/SET_SEND_EMAIL_RECEIPTS"
);
const RESEND_INVITATION = createAsyncAction(
  "TEAM/RESEND_INVITATION",
  x => x,
  (payload, args) => ({ invitationId: args[0] }) // use meta to pass through invitationId
);
const SET_CUSTOMER_ROLES = createAsyncAction(
  "TEAM/SET_CUSTOMER_ROLES",
  x => x,
  (payload, args) => ({ customerId: args[0] }) // use meta to pass through customerId
);
const SET_BILLING_POINT_OF_CONTACT = createAsyncAction(
  "TEAM/SET_BILLING_POINT_OF_CONTACT"
);
const FETCH_DATA_EXPORT = createAsyncAction("TEAM/FETCH_DATA_EXPORT");

export const fetchInvitations = wrapPromiseToThunk(
  FETCH_INVITATIONS,
  ({ dispatch, getState }, page) => {
    const billingGroupId = getCurrentBillingGroup(getState()).id;
    return fetchApi(`/billing_groups/${billingGroupId}/invitations`, {
      page
    }).then(({ body, paging }) => {
      body.forEach(invitation =>
        dispatch(consumePayload(invitation, BillingGroupInvitation))
      );

      const invitationIds = body.map(invitation => invitation.id);
      return { invitationIds, paging };
    });
  },
  REPORT_ERROR
);

export const createInvitation = wrapPromiseToThunk(
  CREATE_INVITATION,
  ({ dispatch }, email) =>
    fetchApi("/endo/referral/invite", {
      method: "post",
      json: {
        invitee: email
      }
    }).then(() => {
      return dispatch(fetchInvitations(1));
    }),
  reportError
);

export const resendInvitation = wrapPromiseToThunk(
  RESEND_INVITATION,
  (_, invitationId) =>
    fetchApi(
      `/billing_group_invitations/${invitationId}/actions/resend_invite`,
      { method: "post" }
    ),
  REPORT_ERROR
);

export const fetchTeamCustomers = wrapPromiseToThunk(
  GET_TEAM_CUSTOMERS,
  ({ dispatch }, organizationId, page, status) =>
    fetchApi(
      `/organizations/${organizationId}/customers?mandate=status:${status}` +
        "&fields=contact{default_email}",
      { page }
    ).then(({ body, paging }) => {
      body.forEach(customer => dispatch(consumePayload(customer, Customer)));

      const customerIds = body.map(customer => customer.id);
      return { customerIds, paging };
    }),
  REPORT_ERROR
);

export const setSendEmailReceipts = wrapPromiseToThunk(
  SET_SEND_EMAIL_RECEIPTS,
  ({ dispatch, getState }, billingGroupId, value) => {
    return fetchApi(`/billing_groups/${billingGroupId}`, {
      method: "put",
      json: {
        send_email_receipts: value
      }
    }).then(() => {
      const bg = getState().collections.billing_groups[billingGroupId];
      dispatch(
        consumePayload(
          {
            ...bg,
            send_email_receipts: value
          },
          BillingGroup
        )
      );
    });
  },
  REPORT_ERROR
);

export const setCustomerRoles = wrapPromiseToThunk(
  SET_CUSTOMER_ROLES,
  ({ dispatch, getState }, customerId, roles) =>
    fetchApi(`/customers/${customerId}`, {
      method: "put",
      json: {
        roles: roles
      }
    }).then(() => {
      const customer = getState().collections.customers[customerId];
      dispatch(
        consumePayload(
          {
            ...customer,
            roles: roles
          },
          Customer
        )
      );
    }),
  REPORT_ERROR
);

export const setBillingPointOfContact = wrapPromiseToThunk(
  SET_BILLING_POINT_OF_CONTACT,
  ({ dispatch }, billingGroupId, email) =>
    fetchApi(
      `/billing_groups/${billingGroupId}/actions/set_billing_point_of_contact`,
      {
        method: "put",
        json: { email }
      }
    ).then(({ body }) => dispatch(consumePayload({ ...body }, BillingGroup))),
  REPORT_ERROR
);

export const fetchDataExport = wrapPromiseToThunk(
  FETCH_DATA_EXPORT,
  () =>
    fetchApi("/assistants/data_export")
      .then(({ body }) => body)
      .catch(error => {
        if (
          // Assistant does not support data export
          error.res.status === 403 ||
          // Assistant supports data export but no download is available
          error.res.status === 404
        ) {
          return {};
        }
        throw error;
      }),
  reportError
);

export const reducer = combineReducers({
  fetchGroups: combineReducers({
    createInvitation: handleFetchGroupActions(CREATE_INVITATION),
    fetchInvitations: handleFetchGroupActions(FETCH_INVITATIONS),
    fetchTeamCustomers: handleFetchGroupActions(GET_TEAM_CUSTOMERS, true),
    setSendEmailReceipts: handleFetchGroupActions(SET_SEND_EMAIL_RECEIPTS),
    setBillingPointOfContact: handleFetchGroupActions(
      SET_BILLING_POINT_OF_CONTACT
    ),
    fetchDataExport: handleFetchGroupActions(FETCH_DATA_EXPORT, true)
  }),
  invitations: handleActions(
    {
      [FETCH_INVITATIONS]: {
        begin: state => ({
          ...state,
          ids: []
        }),
        next: (state, action) => ({
          ids: action.payload.invitationIds,
          paging: action.payload.paging || null
        })
      }
    },
    { ids: [], paging: null }
  ),
  teamCustomers: handleActions(
    {
      [GET_TEAM_CUSTOMERS]: {
        begin: state => ({
          ...state,
          ids: []
        }),
        next: (state, action) => ({
          ids: action.payload.customerIds,
          paging: action.payload.paging
        })
      }
    },
    { ids: [], paging: null }
  ),
  customerRoles: handleActions(
    {
      [SET_CUSTOMER_ROLES]: {
        begin: (state, action) => [...state, action.meta.customerId],
        next: (state, action) =>
          state.filter(id => id !== action.meta.customerId),
        throw: (state, action) =>
          state.filter(id => id !== action.meta.customerId)
      }
    },
    []
  ),
  dataExport: handleActions(
    {
      [FETCH_DATA_EXPORT]: {
        next: (
          state,
          { payload: { version, download_link, generation_time } }
        ) => ({
          version,
          downloadLink: download_link,
          generationTime: generation_time
        })
      }
    },
    {}
  ),
  inflightResendingInvitations: handleActions(
    {
      [RESEND_INVITATION]: {
        begin: (state, { meta: { invitationId } }) => [...state, invitationId],
        next: (state, { meta: { invitationId } }) =>
          state.filter(id => id !== invitationId),
        throw: (state, { meta: { invitationId } }) =>
          state.filter(id => id !== invitationId)
      }
    },
    []
  ),
  errors: combineReducers({
    createInvitation: requestErrorReducer(CREATE_INVITATION)
  })
});

export const getIsCreatingInvitation = state =>
  state[MOUNT_POINT].fetchGroups.createInvitation.isFetching;

export const getIsFetchingInvitations = state =>
  state[MOUNT_POINT].fetchGroups.fetchInvitations.isFetching;

export const getIsFetchingTeamCustomers = state =>
  state[MOUNT_POINT].fetchGroups.fetchTeamCustomers.isFetching;

export const getIsSettingSendEmailReceipts = state =>
  state[MOUNT_POINT].fetchGroups.setSendEmailReceipts.isFetching;

export const getIsSettingBillingPointOfContact = state =>
  state[MOUNT_POINT].fetchGroups.setBillingPointOfContact.isFetching;

export const getIsLoadingDataExport = state =>
  state[MOUNT_POINT].fetchGroups.fetchDataExport.isFetching;

export const getCustomerRolesIsInflight = (state, customerId) =>
  state[MOUNT_POINT].customerRoles.includes(customerId);

export const getInvitations = state =>
  expand(BillingGroupInvitation, state[MOUNT_POINT].invitations.ids, {}, state);

export const getInvitationsPaging = state =>
  state[MOUNT_POINT].invitations.paging;

export const getTeamCustomers = state =>
  expand(
    Customer,
    state[MOUNT_POINT].teamCustomers.ids,
    { contact: {} },
    state
  );

export const getTeamCustomersPaging = state =>
  state[MOUNT_POINT].teamCustomers.paging;

export const getDataExport = state => state[MOUNT_POINT].dataExport;

export const getResendingInvitations = state =>
  state[MOUNT_POINT].inflightResendingInvitations;

export const getCreateInvitationError = state =>
  state[MOUNT_POINT].errors.createInvitation;
