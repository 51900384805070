/** @format */
import { combineReducers } from "redux";
import {
  createAsyncAction,
  wrapPromiseToThunk
} from "../../utils/redux-actions";
import { fetchApi } from "../../utils/request";
import { handleFetchGroupActions } from "../utils/fetch-group";
import { REPORT_ERROR } from "./error";

export const MOUNT_POINT = "customerFeedback";

const SUBMIT = createAsyncAction("CUSTOMER_FEEDBACK/SUBMIT");

export const submitCustomerFeedback = wrapPromiseToThunk(
  SUBMIT,
  (_, code, rating, description) =>
    fetchApi("/feedback_ratings/actions/rate", {
      method: "post",
      json: {
        code,
        rating,
        feedback: description
      }
    }).then(({ body }) => body),
  REPORT_ERROR
);

export const reducer = combineReducers({
  submitFetchGroup: handleFetchGroupActions(SUBMIT, true)
});

export const getIsSubmitting = state =>
  state[MOUNT_POINT].submitFetchGroup.isFetching;
