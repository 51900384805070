import _ from 'lodash';
import PropTypes from 'prop-types';

export const isEqual = (location1, location2) => {
  // string comp
  if (typeof location1 === typeof location2 && typeof location1 === 'string') {
    return location1 === location2;
  }

  // object comp
  return (
    (!location1 && !location2) ||
    (
      (!!location1 && !!location2) &&
      location1.name === location2.name &&
      location1.lat === location2.lat &&
      location1.lng === location2.lng &&
      location1.formatted_address === location2.formatted_address &&
      location1.category === location2.category
    )
  );
};

export const getBaseLocation = (locations) => {
  return _.find(locations, location => location.category === 'office');
};

export const isUnknown = (location) => {
  if (!location) {
    return true;
  }

  if (_.isEmpty(location)) {
    return true;
  }

  // category may be set, but other keys should be empty
  return !location.name && !location.lat && !location.lng && !location.formatted_address;
};

export const toAddress = (location, { renderName = true } = {}) => {
  if (!location) {
    return null;
  }

  if (typeof location === 'string') {
    return location;
  }

  const { name, formatted_address } = location;
  return renderName ? `${ name }, ${ formatted_address }` : formatted_address;
};

export const DEFAULT_LOCATION_VALUES = Object.freeze({
  name: '',
  category: null,
  lat: null,
  lng: null,
  formatted_address: '',
  google_place_id: '',
});

// Mapping of location categories to user-friendly strings.
export const LOCATION_NAME_MAPPING = {
  coffee: 'Coffee',
  breakfast: 'Breakfast',
  lunch: 'Lunch',
  dinner: 'Dinner',
  drinks: 'Drinks',
  office: 'Office',
  other: 'Other',
};

export const LOCATION_CATEGORIES = Object.keys(LOCATION_NAME_MAPPING);

export const LocationShapePropType = PropTypes.shape({
  name: PropTypes.string.isRequired,
  category: PropTypes.string.isRequired,
  lat: PropTypes.number.isRequired,
  lng: PropTypes.number.isRequired,
  formatted_address: PropTypes.string.isRequired,
  google_place_id: PropTypes.string,
  is_default: PropTypes.bool,
});
