/** @format */
import PropTypes from "prop-types";
import React, { useState } from "react";
import { CustomerShape } from "../../utils/shapes";
import MeetingsBanner from "./meetings-banner.react";

export const MeetingsMenu = ({ customer, children }) => {
  const PATHNAME_TO_TEXT = { 
    "/": "Requested",
    "/meetings/requested": "Requested",
    "/meetings/upcoming": "Upcoming",
    "/meetings/history": "History",
  }

  const [selectedSubTab, setSelectedSubTab] = useState(PATHNAME_TO_TEXT[window.location.pathname])
  
  const renderMeetingsBanner = () => {
    return <MeetingsBanner selected={selectedSubTab} onClick={setSelectedSubTab} />
  }

  return (
    <div>
      {renderMeetingsBanner()}
      {React.Children.map(children, child => (
        React.isValidElement(child) ? React.cloneElement(child, { ...child.props, customer }) : child
      ))}
    </div>
  );
}

MeetingsMenu.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.node
  ]).isRequired,
  customer: CustomerShape
};

export default MeetingsMenu;
