import { createAsyncAction, wrapPromiseToThunk } from "../../utils/redux-actions";
import { fetchApi } from "../../utils/request";
import { consumePayload } from "../../collections/module";
import { Keyword } from "../../collections/schema";

const UPDATE_KEYWORD = createAsyncAction("KEYWORDS/UPDATE_KEYWORD");

export const updateKeyword = wrapPromiseToThunk(
  UPDATE_KEYWORD,
  ({ dispatch }, id, keywordText) => {
    return fetchApi(`/keywords/${id}/actions/update`, {
      method: "put",
      json: { keyword: keywordText }
    })
      .then(({ body }) => {
        dispatch(consumePayload(body, Keyword));
        return body
      })
      .catch(error => {
        throw error;
      })
  }

);
