/** @format */
import React from "react";
import { Loader } from "vogue";

const FlexLoader = () => (
  <div className="FlexLoader">
    <Loader />
  </div>
);

export default FlexLoader;
