/** @format */
import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import { Icon } from "vogue";

const Step = ({ step, current, label }) => (
  <div
    aria-label={label}
    className={classNames("ProgressBar-step", {
      "ProgressBar-step--completed": step < current,
      "ProgressBar-step--current": step === current
    })}
  >
    {step < current ? <Icon name="green-checked" color="green" /> : null}
  </div>
);

const Connector = ({ step, current }) => (
  <div
    className={classNames("ProgressBar-connector", {
      "ProgressBar-connector--completed": step <= current
    })}
  />
);

const ProgressBar = ({ current }) => (
  <div className="ProgressBar">
    <Step step={-1} current={current} label="Sync Resources" />
    <Connector step={0} current={current} />
    <Step step={0} current={current} label="Choose Rooms" />
    <Connector step={1} current={current} />
    <Step step={1} current={current} label="Add Capacity" />
  </div>
);

ProgressBar.propTypes = { current: PropTypes.number.isRequired };

export default ProgressBar;
