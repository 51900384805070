export const complexity_tiers = {
  BOT: -1,
  SIMPLE: 1,
  NORMAL: 2,
  COMPLEX: 3,
  SANDBOX: 4,
};

class Contractor {

  static isEscalations(contractor) {
    return contractor.roles.filter(
      r => !(['TEST', 'ASSESSMENT']).includes(r)
    ).length > 1;
  }

  static isInternalSupport(contractor) {
    return contractor.roles.includes('SUPER_ESCALATE') || contractor.roles.includes('ENGINEERING');
  }

  static canViewOtherContractorDetails(contractor, other) {
    /** Determines whether or not a contractor should
    be able to view the information about the contractor
    attached to the audit log item.
    */

    return (
      other && (
        this.isInternalSupport(contractor) ||
        contractor.id === other.id
      )
    );
  }

  static isSandboxed(contractor) {
    return contractor.complexity_tier === complexity_tiers.SANDBOX;
  }
}

Contractor.complexity_tiers = complexity_tiers;

export default Contractor;
