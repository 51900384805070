/** @format */
import classnames from "classnames";
import React, { Component } from "react";
import { Button } from "vogue";

/* A standard link-colored text-only button, should be used for primary actions. */
export default class TextButton extends Component {
  render() {
    const { children, className, ...props } = this.props;

    return (
      <Button
        className={classnames("TextButton", className)}
        modifiers={["secondary"]}
        {...props}
      >
        {children}
      </Button>
    );
  }
}

TextButton.propTypes = Button.propTypes;
