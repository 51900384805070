class ParticipantUtils {
  static contextualName(participant, allParticipants) {
    const otherParticipants = allParticipants.filter(p => p.id !== participant.id);
    const contact = participant.contact;

    const sameFirstname = otherParticipants.find(p => p.contact.first_name === contact.first_name);
    if (!sameFirstname) {
      return contact.first_name;
    }

    const sameLastInitial = otherParticipants.find(p =>
      p.contact.first_name === contact.first_name &&
      p.contact.last_name &&
      p.contact.last_name[0] === contact.last_name[0]
    );

    if (!sameLastInitial) {
      return `${ contact.first_name } ${ contact.last_name[0] }.`;
    }

    return contact.name;
  }

  static participantsToSelections(participants) {
    const fieldsToCopy = [
      'contact_id',
      'coordinator_id',
      'name',
      'company',
      'phone',
      'skype',
      'timezone',
      'response',
    ]
    return participants.map(participant => {
      let selections = {}

      for (const fieldToCopy of fieldsToCopy) {
        selections[fieldToCopy] = participant[fieldToCopy]
      }

      return selections;
    })
  }

  static getResponse(participant, confirmedEvents) {
    // If a response was manually chosen in the current job session
    if (participant.response) {
      return participant.response;

    // Iterate through confirmed events, searching for a matching event participant
    } else if (confirmedEvents && confirmedEvents.length > 0) {
      for (const confirmedEvent of confirmedEvents) {
        if (confirmedEvent.event_participants) {
          const eventParticipant = confirmedEvent.event_participants.find((p) => {
            return participant.active_email_address_id === p.email_address_id;
          });

          if (eventParticipant) {
            return eventParticipant.response;
          }
        }
      }
    }

    return null;
  }
}

export default ParticipantUtils;
