/** @format */
import { connect } from "react-redux";

import CalendarPlanning from "../../components/live-v2/calendar-planning.react";
import {
  fetchAvailability,
  getIsLoading as getIsLoadingAvailability,
  getAvailability
} from "../../modules/availability";
import {
    fetchPreference,
    getIsLoadingPreference,
    getPreference
  } from "../../modules/preferences";
import {
  getAccounts,
  getIsLoading as getIsLoadingCalendar
} from "../../modules/connected-calendars";
import createCustomLoadingContainer from "../create-custom-loading-container.react";

const mapStateToProps = (state, { customer }) => {
  if (
    getIsLoadingAvailability(state) ||
    getIsLoadingPreference(state) || 
    getIsLoadingCalendar(state)
  ) {
    return { isLoading: true };
  }

  const customerContact = state.collections.contacts[customer.contact_id];

  let emailAddresses;
  // If there's no active verification request occurring generate the list of email addresses.
  if (!state.verifyEmail.fetchGroup.isFetching) {
    const verifiedEmailAddresses = customerContact.email_addresses.map(ea => {
      return {
        id: state.collections.email_addresses[ea].id,
        is_default: state.collections.email_addresses[ea].is_default,
        is_verified: true
      };
    });
    const unverifiedEmailAddresses = customer.email_address_verifications
      .filter(eav => state.collections.email_address_verifications[eav].active)
      .map(eav => {
        return {
          id: state.collections.email_address_verifications[eav].email,
          is_default: false,
          is_verified: false
        };
      });

    emailAddresses = verifiedEmailAddresses.concat(unverifiedEmailAddresses);
  }

  const accounts = getAccounts(state, customer) || {};

  return {
    emailAddresses,
    addingEmailAddress: state.verifyEmail.fetchGroup.isFetching,
    ...accounts,
    isLoading: false,
    tcrs: getAvailability(state),
    preference: getPreference(state, { customer })
  };
};

const mapDispatchToProps = (dispatch, { customer }) => ({
  onLoad() {
    dispatch(fetchAvailability(customer.contact_id));
    dispatch(fetchPreference(customer.id));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(createCustomLoadingContainer(CalendarPlanning));
