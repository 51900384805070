/** @format */
import PropTypes from "prop-types";

import React, { Component } from "react";
import { PreferenceContentBlock } from "../../layout/block.react";
import { SelectPreference } from "../../shared/attribute-select.react";
import { multiline } from "../../../../utils/string";

const generateTitleOptions = (virtual, contact, company) => {
  const example = virtual ? "Call" : "Coffee";
  const firstName = contact && contact.first_name;
  const fullName = contact && contact.name;
  const companyName = company && company.name ? ` (${company.name})` : "";
  return [
    {
      label: "First Names, Company",
      value: "{{standard_title_legacy}}",
      caption: `e.g. Ellie (Acme) <> ${firstName}${companyName}`
    },
    {
      label: "Full Names, Company",
      value: multiline(`
        {{^has_only_customers}}{{{multiNamesFullWithCompanies}}} <>
        {{/has_only_customers}}{{{multiCustomersNamesFull}}} ({{CustomerCompany}})
      `),
      caption: `e.g. Ellie Arroway (Acme) <> ${fullName}${companyName}`
    },
    {
      label: "First Names",
      value: multiline(`
        {{^has_only_customers}}{{{multiNames}}} <>
        {{/has_only_customers}}{{{multiCustomersNames}}}
      `),
      caption: `e.g. Ellie <> ${firstName}`
    },
    {
      label: "Full Names",
      value: multiline(`
        {{^has_only_customers}}{{{multiNamesFull}}} <>
        {{/has_only_customers}}{{{multiCustomersNamesFull}}}
      `),
      caption: `e.g. Ellie Arroway <> ${fullName}`
    },
    { label: "", value: "", disabled: true }, // line break
    {
      label: "Meeting Type, First Names, Company",
      value: "{{standard_title}}",
      caption: `e.g. ${example}: Ellie (Acme) <> ${firstName}${companyName}`
    },
    {
      label: "Meeting Type, Full Names, Company",
      value: multiline(`
        {{meetingType}}: {{^has_only_customers}}{{{multiNamesFullWithCompanies}}}
        <> {{/has_only_customers}}{{{multiCustomersNamesFull}}} ({{CustomerCompany}})
      `),
      caption: `e.g. ${example}: Ellie Arroway (Acme) <> ${fullName}${companyName}`
    },
    {
      label: "Meeting Type, First Names",
      value: multiline(`
        {{meetingType}}: {{^has_only_customers}}{{{multiNames}}} <>
        {{/has_only_customers}}{{{multiCustomersNames}}}
      `),
      caption: `e.g. ${example}: Ellie <> ${firstName}`
    },
    {
      label: "Meeting Type, Full Names",
      value: multiline(`
        {{meetingType}}: {{^has_only_customers}}{{{multiNamesFull}}} <>
        {{/has_only_customers}}{{{multiCustomersNamesFull}}}
      `),
      caption: `e.g. ${example}: Ellie Arroway <> ${fullName}`
    }
  ];
};

export const EventTitleSelect = ({
  attribute,
  title,
  customerContact,
  customerCompany,
  setter
}) => {
  let options;
  const titleOptions = generateTitleOptions(
    attribute === "virtual_title",
    customerContact,
    customerCompany
  );
  if (!titleOptions.find(o => o.value === title)) {
    options = [
      ...titleOptions,
      {
        label: "Unsupportable Preference",
        value: title,
        disabled: true,
        caption: "Unsupportable Preference"
      }
    ];
  } else {
    options = titleOptions;
  }

  const _setter = (value) =>  {
    setter(attribute, value)
  }

  return (
    <SelectPreference
      className="EventTitles-select"
      selectedOption={title}
      attribute={attribute}
      options={options}
      setter={_setter}
    />
  );
};

class EventTitlePreference extends Component {
  render() {
    return (
      <PreferenceContentBlock
        title="Event titles"
        subtitle="What information do you prefer to be included in your event titles?"
        bordered={this.props.bordered}
      >
        <div className="EventTitles">
          <div className="EventTitles-row">
            <div className="EventTitles-label">In-person</div>
            <div className="EventTitles-value">
              <EventTitleSelect
                attribute="in_person_title"
                title={this.props.preference.in_person_title}
                customerContact={this.props.customerContact}
                customerCompany={this.props.customerCompany}
                setter={this.props.setter}
              />
            </div>
          </div>
          <div className="EventTitles-row">
            <div className="EventTitles-label">Virtual</div>
            <div className="EventTitles-value">
              <EventTitleSelect
                attribute="virtual_title"
                title={this.props.preference.virtual_title}
                customerContact={this.props.customerContact}
                customerCompany={this.props.customerCompany}
                setter={this.props.setter}
              />
            </div>
          </div>
        </div>
      </PreferenceContentBlock>
    );
  }
}
EventTitlePreference.propTypes = {
  preference: PropTypes.object.isRequired,
  customerContact: PropTypes.object.isRequired,
  customerCompany: PropTypes.object
};

export default EventTitlePreference;
