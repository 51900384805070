/** @format */
import PropTypes from "prop-types";

import React from "react";

import { BUFFER_DURATIONS } from "../../../utils/times";
import { PreferenceContentBlock } from "../../layout/block.react";
import { SelectPreference } from "../../shared/attribute-select.react";

const BufferTimes = ({ bufferKey, value, title, subtitle, bordered }) => {
  const bufferGetter = bufferString => {
    const parsedBufferTimes = JSON.parse(bufferString);
    if (parsedBufferTimes && parsedBufferTimes[bufferKey].duration) {
      return parsedBufferTimes[bufferKey].duration;
    } else {
      return 0;
    }
  };

  const bufferSetter = bufferSelection =>
    JSON.stringify({
      ...JSON.parse(value),
      [bufferKey]: {
        duration: bufferSelection,
        pre: bufferSelection !== "0",
        post: bufferSelection !== "0"
      }
    });

  return (
    <PreferenceContentBlock
      title={title}
      subtitle={subtitle}
      supportLink="https://support.claralabs.com/article/109-buffer-times"
      bordered={bordered}
    >
      <SelectPreference
        selectedOption={value}
        attribute="bufferTimes"
        options={BUFFER_DURATIONS}
        getter={bufferGetter}
        setter={bufferSetter}
      />
    </PreferenceContentBlock>
  );
};

BufferTimes.propTypes = {
  bufferKey: PropTypes.oneOf(["InPerson", "Virtual"]).isRequired,
  value: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired
};

export default BufferTimes;
