/** @format */
import Raven from "raven-js";
import { handleActions, createAction } from "redux-actions";
import warning from "warning";

import config from "../../config";
import { APIResponseError } from "../../utils/request";
import { getFeaturesEnabled } from "./feature-flags";

const IS_PRODUCTION = config.env === "production";

const getRequestId = error => {
  if (error instanceof APIResponseError && error.res && error.res.headers) {
    return error.res.headers.get("request-id");
  } else {
    return null;
  }
};

const getErrorMessage = error => {
  if (error instanceof APIResponseError && error.body && error.body.message) {
    return error.body.message;
  } else if (error && error.message) {
    return error.message;
  } else {
    return null;
  }
};

export const MOUNT_POINT = "error";

const SET_ERROR = createAction("ERROR/SET_ERROR");
export const DISMISS_ERROR = createAction("ERROR/DISMISS_ERROR");

// NOTE(gavin): This "action creator" would be better structured as emitting a
// `REPORT_ERROR` action that is acted on by a Raven middleware layer.
export const reportError = error => (_, getState) => {
  if (IS_PRODUCTION) {
    Raven.captureException(error, {
      extra: {
        "Feature Flags": getFeaturesEnabled(getState()),
        "Request-Id": getRequestId(error)
      }
    });
  }
};

export const reportAndSetError = error => {
  return (dispatch, getState) => {
    if (IS_PRODUCTION) {
      Raven.captureException(error, {
        extra: {
          "Feature Flags": getFeaturesEnabled(getState()),
          "Request-Id": getRequestId(error)
        }
      });
    }

    dispatch(SET_ERROR(error));
  };
};

export const setError = error => dispatch => dispatch(SET_ERROR(error));

export const REPORT_ERROR = error => {
  warning(
    false,
    "Use of `REPORT_ERROR` is deprecated, use `reportAndSetError` instead."
  );

  return reportAndSetError(error);
};

export const reducer = handleActions(
  {
    [DISMISS_ERROR]: () => null,
    [SET_ERROR]: (state, action) => action.payload
  },
  null
);

export const getErrorDetails = state => ({
  error: state[MOUNT_POINT],
  message: getErrorMessage(state[MOUNT_POINT]),
  requestId: getRequestId(state[MOUNT_POINT])
});
