/** @format */
import PropTypes from "prop-types";

import React, { Component } from "react";
import { connect } from "react-redux";

import {
  setCustomerRoles,
  getCustomerRolesIsInflight
} from "../../../modules/team";
import { getCurrentCustomer } from "../../../modules/session";
import { CustomerRoles, isAdmin } from "../../../utils/permissions";
import { TeamCustomerShape } from "../../../utils/shapes";
import { SelectAttribute } from "../../shared/attribute-select.react";

const getDisplayedRoleName = roles => {
  if (roles.indexOf(CustomerRoles.ADMIN) !== -1) return "Admin";
  else return "Schedulee";
};

export class CustomerRole extends Component {
  constructor(props) {
    super(props);

    this._onSelect = this._onSelect.bind(this);
    this._setter = this._setter.bind(this);
  }

  render() {
    const {
      canEdit,
      customer: { roles },
      isLoading
    } = this.props;

    if (!canEdit) {
      return <span>{getDisplayedRoleName(roles)}</span>;
    }

    return (
      <SelectAttribute
        selectedOption={roles}
        options={[
          { label: "Schedulee", value: CustomerRoles.SCHEDULEE },
          { label: "Admin", value: CustomerRoles.ADMIN }
        ]}
        getter={this._getter}
        setter={this._setter}
        onSelect={this._onSelect}
        isLoading={isLoading}
      />
    );
  }

  _getter(value) {
    return value.includes(CustomerRoles.ADMIN)
      ? CustomerRoles.ADMIN
      : CustomerRoles.SCHEDULEE;
  }

  _onSelect(value) {
    return this.props.onSetCustomerRoles(this.props.customer.id, value);
  }

  _setter(value) {
    if (value === CustomerRoles.SCHEDULEE) {
      return this.props.customer.roles.filter(
        role => role !== CustomerRoles.ADMIN
      );
    } else {
      return [CustomerRoles.ADMIN].concat(this.props.customer.roles);
    }
  }
}

CustomerRole.propTypes = {
  customer: TeamCustomerShape.isRequired,
  canEdit: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  onSetCustomerRoles: PropTypes.func.isRequired
};

const mapStateToProps = (state, { customer }) => {
  const currentCustomer = getCurrentCustomer(state);

  return {
    isLoading: getCustomerRolesIsInflight(state, customer.id),
    canEdit:
      isAdmin(currentCustomer.roles) && currentCustomer.id !== customer.id
  };
};

const mapDispatchToProps = dispatch => ({
  onSetCustomerRoles(customerId, roles) {
    return dispatch(setCustomerRoles(customerId, roles));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomerRole);
