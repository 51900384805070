/** @format */
import { connect } from "react-redux";

import VirtualMeetingDetailCreator from "../../components/dashboard/virtual-details/creator.react";
import {
  getCurrentCustomerContact,
  getActiveZoomAccount,
  getIsCoderpadActive,
  getIsHackerrankActive,
  getIsMicrosoft,
  getIsGoogle,
  getActiveAssistantContact,
} from "../../modules/session";
import {
  createVirtualDetail,
  updateMeetingType,
  getAvailableVirtualKinds,
  getIsCreatingVirtualDetail
} from "../../modules/virtual-details";

const mapStateToProps = state => ({
  creatingVirtualDetail: getIsCreatingVirtualDetail(state),
  hasActiveZoomAccount: !!getActiveZoomAccount(state),
  isCoderpadActive: getIsCoderpadActive(state),
  isHackerrankActive: getIsHackerrankActive(state),
  isMicrosoft: getIsMicrosoft(state),
  isGoogle: getIsGoogle(state),
  availableVirtualKinds: getAvailableVirtualKinds(
    state,
    getCurrentCustomerContact(state).id
  ),
  activeAssistant: getActiveAssistantContact(state)
});

const mapDispatchToProps = dispatch => ({
  onCreateVirtualDetail(virtualDetail) {
    return dispatch(createVirtualDetail(virtualDetail));
  },
  onUpdateMeetingType(meetingType) {
    return dispatch(updateMeetingType(meetingType));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VirtualMeetingDetailCreator);
