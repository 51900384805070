import { fetchApi } from '../../utils/request';

const Api = {
    updateTranscriptAssignment: (assistantCallSlug, emailAddress, callName) => 
			fetchApi(`/assistant_call/${assistantCallSlug}/update?email=${emailAddress}&name=${callName}`)
			.then(({ body }) => body),
		deleteTranscriptAssignment: (assistantCallSlug, emailAddress) =>
			fetchApi(`/assistant_call/${assistantCallSlug}/delete?email=${emailAddress}`)
			.then(({ body }) => body)
};

export default Api;