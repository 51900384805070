/** @format */
import { createAction } from "redux-actions";
import { handleActions } from "../../utils/redux-actions";

export const MOUNT_POINT = "featureFlags";

export const SET_FEATURE_FLAG = createAction("FEATURE_FLAGS/SET_FEATURE_FLAG");

export const reducer = handleActions(
  {
    [SET_FEATURE_FLAG]: (state, action) => ({
      ...state,
      // If a feature flag is set to `false` (only possible via initial state) then we don't let any
      // later actions override this value.
      [action.payload]: state[action.payload] === false ? false : true // eslint-disable-line no-unneeded-ternary
    })
  },
  {}
);

export const getIsFeatureEnabled = (state, flag) => !!state[MOUNT_POINT][flag];

export const getFeaturesEnabled = state =>
  Object.keys(state[MOUNT_POINT]).filter(flag => state[MOUNT_POINT][flag]);
