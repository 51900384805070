/** @format */
import React from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";

import { getIsFeatureEnabled } from "../modules/feature-flags";

const FeatureFlagged = ({ isEnabled, children }) =>
  isEnabled ? <>{children}</> : null;

FeatureFlagged.propTypes = {
  featureFlag: PropTypes.string.isRequired,
  isEnabled: PropTypes.bool.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.node
  ]).isRequired
};

const mapStateToProps = (state, { featureFlag }) => ({
  isEnabled: getIsFeatureEnabled(state, featureFlag)
});

export default connect(mapStateToProps)(FeatureFlagged);
