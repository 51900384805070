/** @format */
import PropTypes from "prop-types";
import React, { Component } from "react";
import classnames from "classnames";
import { TextArea, Button } from "vogue";
import { connect } from "react-redux";

import config from "../../config";
import {
  getIsSubmitting,
  submitCustomerFeedback
} from "../modules/customer-feedback";
import Header from "./shared/header.react";
import querystringParse from "../../utils/querystring";

export class CustomerFeedback extends Component {
  constructor(props) {
    super(props);

    this.state = {
      rating: null,
      description: "",
      onLoadFinished: false
    };

    this._onSelectRating = this._onSelectRating.bind(this);
    this._onUpdateDescription = this._onUpdateDescription.bind(this);
    this._submit = this._submit.bind(this);
  }

  UNSAFE_componentWillMount() {
    const setStateCallback = () => {
      return this._submit().then(() => {
        this.setState({
          onLoadFinished: true
        });
      });
    };

    this.setState(
      {
        rating: parseInt(querystringParse().rating, 10)
      },
      setStateCallback
    );
  }

  _onSelectRating(rating) {
    this.setState({
      rating: rating
    });
  }

  _onUpdateDescription({ target: { value } }) {
    this.setState({
      description: value
    });
  }

  _submit() {
    return this.props.onSubmitCustomerFeedback(
      querystringParse().code,
      this.state.rating,
      this.state.description
    );
  }

  render() {
    const ratingBoxes = [1, 2, 3, 4, 5].map(rating => {
      const ratingClasses = classnames("CustomerFeedback-rating", {
        "CustomerFeedback-rating--selected": rating === this.state.rating
      });
      return (
        <a
          key={rating.toString()}
          className={ratingClasses}
          onClick={this._onSelectRating.bind(this, rating)}
        >
          {rating}
        </a>
      );
    });

    return (
      <div className="CustomerFeedback">
        <Header alwaysVisible={true} />
        <div className="CustomerFeedback-container">
          <div className="CustomerFeedback-heroContainer">
            <h1>Thanks for submitting feedback!</h1>
            <p>
              We truly care about making your experience as effortless and
              powerful as possible. Your feedback is important and helps us
              improve Clara.
            </p>
          </div>

          <div className="CustomerFeedback-ratingContainer">
            <h4>Rating</h4>
            <div className="CustomerFeedback-ratings">{ratingBoxes}</div>
          </div>

          <div className="CustomerFeedback-feedbackContainer">
            <h4>Would you like to tell us more?</h4>
            <TextArea
              className="CustomerFeedback-feedback"
              onChange={this._onUpdateDescription}
              value={this.state.description}
            />
          </div>

          <Button
            className="CustomerFeedback-submitFeedback"
            onClick={this._submit}
            loading={this.props.isLoading}
            loadingAnimation={this.state.onLoadFinished}
          >
            Submit
          </Button>

          <Button
            className="Button Button--secondary CustomerFeedback-backLink"
            href={config.services.endo.host}
          >
            Back to Your Dashboard
          </Button>
        </div>
      </div>
    );
  }
}
CustomerFeedback.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  onSubmitCustomerFeedback: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  isLoading: getIsSubmitting(state)
});

const mapDispatchToProps = dispatch => ({
  onSubmitCustomerFeedback(code, rating, description) {
    return dispatch(submitCustomerFeedback(code, rating, description));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomerFeedback);
