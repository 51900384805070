/** @format */
import { isEqual } from "lodash";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { Modal } from "vogue";

import { ResourceSyncedCalendarShape } from "../../../../utils/shapes";
import Capacity from "./capacity.react";
import Choose from "./choose.react";
import ProgressBar from "./progress-bar.react";

const onChangeFns = [
  ({ availableResources }, values) => ({
    availableResources: availableResources.map(resource => ({
      ...resource,
      is_conference_room: values.indexOf(resource.provider_id) !== -1,
      capacity:
        values.indexOf(resource.provider_id) !== -1 ? resource.capacity : null
    }))
  }),
  ({ availableResources }, { provider_id, capacity }) => ({
    availableResources: availableResources.map(resource => ({
      ...resource,
      capacity:
        resource.provider_id === provider_id ? capacity : resource.capacity
    }))
  })
];

const stepValidationFns = [
  ({ availableResources }) => {
    const selectedResources = availableResources.filter(
      resource => resource.is_conference_room
    );
    return selectedResources.length > 0;
  },
  ({ availableResources }) => {
    const selectedResources = availableResources.filter(
      resource => resource.is_conference_room
    );
    return !selectedResources.find(resource => !resource.capacity);
  }
];

export default class ConfigurationModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentStep: 0,
      forceValidation: false,
      availableResources: props.availableResources
    };

    this._nextStep = this._nextStep.bind(this);
    this._prevStep = this._prevStep.bind(this);
    this._onChange = this._onChange.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.isOpen !== this.props.isOpen) {
      this.setState({
        currentStep: 0,
        forceValidation: false,
        availableResources: nextProps.availableResources
      });
    }

    if (!isEqual(nextProps.availableResources, this.state.availableResources)) {
      this.setState({ availableResources: nextProps.availableResources });
    }
  }

  render() {
    const { isOpen, onClose } = this.props;
    const { availableResources, currentStep, forceValidation } = this.state;

    const selectedResources = (availableResources || []).filter(
      resource => resource.is_conference_room
    );

    const modalActions = [
      {
        key: "back",
        label: ["Cancel", "Back"][currentStep],
        handler: this._prevStep
      },
      {
        key: "continue",
        label: ["Continue", "Save"][currentStep],
        handler: this._nextStep,
        modifier: ["primaryRightAligned", "confirmRightAligned"][currentStep]
      }
    ];

    const StepKlass = [Choose, Capacity][currentStep];

    return (
      <Modal
        actions={modalActions}
        open={isOpen}
        onRequestClose={onClose}
        modifier="noGutter"
        ref="conferenceRoomConfigurationModal"
      >
        <div className="ConferenceRoomConfigurationModal">
          <ProgressBar current={currentStep} />
          <StepKlass
            selectedResources={selectedResources}
            availableResources={availableResources}
            forceValidation={forceValidation}
            onChange={this._onChange}
          />
        </div>
      </Modal>
    );
  }

  _onChange(arg) {
    const result = onChangeFns[this.state.currentStep](this.state, arg);
    this.setState({ ...result });
  }

  _nextStep() {
    const { currentStep } = this.state;
    const validationFn = stepValidationFns[currentStep];
    if (validationFn(this.state, this.props)) {
      if (currentStep === 1) {
        this.props
          .onUpdateResources(this.state.availableResources)
          .then(this.props.onClose);
      } else {
        this.setState({
          currentStep: currentStep + 1,
          forceValidation: false
        });
      }
    } else {
      this.setState({ forceValidation: true });
      this.refs.conferenceRoomConfigurationModal.shake();
    }
  }

  _prevStep() {
    const { currentStep } = this.state;
    if (currentStep === 0) {
      this.props.onClose();
    } else {
      this.setState({
        currentStep: currentStep - 1,
        forceValidation: false
      });
    }
  }
}

ConfigurationModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  availableResources: PropTypes.arrayOf(ResourceSyncedCalendarShape),
  onUpdateResources: PropTypes.func.isRequired
};
