/** @format */
import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import { Icon } from "vogue";

import { ResourceSyncedCalendarShape } from "../../../../utils/shapes";

const SelectedResources = ({
  forceValidation,
  onUnselectResource,
  selectedResources
}) => {
  let content;
  if (selectedResources.length > 0) {
    content = selectedResources.map(resource => (
      <div className="SelectedResources-item" key={resource.id}>
        <div className="SelectedResources-name">{resource.name}</div>
        <Icon
          name="close"
          className="SelectedResources-remove"
          onClick={() => onUnselectResource(resource.provider_id)}
        />
      </div>
    ));
  } else {
    const titleClasses = classNames("SelectedResources-titleText", {
      "SelectedResources-titleText--error": forceValidation
    });

    content = (
      <div className="SelectedResources-item SelectedResources-item--empty">
        <div className={titleClasses}>No resources selected</div>
        <div className="SelectedResources-helperText">
          Choose your conference rooms from the list to make them available to
          Clara when scheduling
        </div>
      </div>
    );
  }

  const classes = classNames("SelectedResources", {
    "SelectedResources--empty": selectedResources.length === 0,
    "SelectedResources--error":
      forceValidation && selectedResources.length === 0
  });

  return <div className={classes}>{content}</div>;
};

SelectedResources.propTypes = {
  selectedResources: PropTypes.arrayOf(ResourceSyncedCalendarShape).isRequired,
  forceValidation: PropTypes.bool.isRequired,
  onUnselectResource: PropTypes.func.isRequired
};

export default SelectedResources;
