/** @format */
import { connect } from "react-redux";

import SpecialFeatures from "../../components/live-v2/special-features.react";
import {
  fetchConferenceRooms,
  fetchConnectedEmailAddress,
  fetchMeetingResourceGroup,
  getAllConferenceRooms,
  getAvailableResources,
  getConnectedEmailAddress,
  getIsLoading as getIsLoadingConferenceRooms,
  getIsSettingMeetingResourceGroup,
  getMeetingResourceGroup,
  setMeetingResourceGroup
} from "../../modules/conference-rooms";
import {
  fetchPreference,
  getIsLoadingPreference,
  getPreference
} from "../../modules/preferences";
import {
  getActiveAssistantContact,
  getIsLoading as getIsLoadingSession
} from "../../modules/session";
import { isCustomDomainClara } from "../../utils/assistant";
import { isAdmin } from "../../utils/permissions";
import createCustomLoadingContainer from "../create-custom-loading-container.react";

const mapStateToProps = (state, { customer }) => {
  if (
    getIsLoadingSession(state) ||
    getIsLoadingPreference(state) ||
    getIsLoadingConferenceRooms(state)
  ) {
    return { isLoading: true };
  }

  return {
    isLoading: false,
    preference: getPreference(state, { customer }),
    customerIsAdmin: isAdmin(customer.roles),
    hasConferenceRooms: isCustomDomainClara(
      getActiveAssistantContact(state).default_email
    ),
    isSettingMeetingResourceGroup: getIsSettingMeetingResourceGroup(state),
    conferenceRooms: getAllConferenceRooms(state),
    connectedEmailAddress: getConnectedEmailAddress(state),
    availableResources: getAvailableResources(state),
    meetingResourceGroup: getMeetingResourceGroup(state)
  };
};

const mapDispatchToProps = (dispatch, { customer }) => ({
  onLoad() {
    dispatch(fetchPreference(customer.id)).then(preferenceId => {
      dispatch(fetchMeetingResourceGroup(preferenceId));
    });
    dispatch(fetchConferenceRooms(customer.contact_id));
    dispatch(fetchConnectedEmailAddress());
  },

  onSetMeetingResourceGroup(meetingResourceIds) {
    return dispatch(setMeetingResourceGroup(meetingResourceIds));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(createCustomLoadingContainer(SpecialFeatures));
