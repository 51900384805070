class EmailAddressUtils {

  static isValid(email) {
    if (!email) {
      return false;
    }

    /* eslint-disable max-len */
    // http://stackoverflow.com/questions/46155/validate-email-address-in-javascript
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    /* eslint-enable max-len */
    return re.test(email);
  }
}

export default EmailAddressUtils;
