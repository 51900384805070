/** @format */
import PropTypes from "prop-types";
import React, { Component } from "react";

export default ChildComponent => {
  class CustomLoading extends Component {
    constructor(props) {
      super(props);

      this.state = {
        hasInitiallyFetched: false,
        isLoading: true
      };
    }

    componentDidMount() {
      this.props.onLoad();
      this.setState({ hasInitiallyFetched: true });
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
      this.setState({
        isLoading: nextProps.isLoading || !this.state.hasInitiallyFetched
      });
    }

    render() {
      const { isLoading, onLoad, ...props } = this.props;
      return this.state.isLoading ? (
        <div style={{height: '100%', width: '100%', backgroundColor: 'red'}}></div>
      ) : (
        <ChildComponent {...props} />
      );
    }
  }

  CustomLoading.propTypes = {
    onLoad: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired
  };

  return CustomLoading;
};
