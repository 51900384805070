/** @format */
import PropTypes from "prop-types";

import React, { Component } from "react";
import { Loader } from "vogue";

import { getRelativeTime } from "../../../../utils/time";
import { BillingGroupInvitationShape } from "../../../utils/shapes";
import TextButton from "../../shared/text-button.react";

export default class InvitationRow extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loaderAnimating: false,
      error: false
    };

    this._onFinishAnimating = this._onFinishAnimating.bind(this);
    this._onResendInvitation = this._onResendInvitation.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      loaderAnimating:
        !nextProps.isResendingInvitation && this.props.isResendingInvitation
    });
  }

  render() {
    const { customerIsAdmin, isResendingInvitation, invitation } = this.props;
    const { loaderAnimating, error } = this.state;

    let resendInviteElem;
    if (isResendingInvitation || loaderAnimating) {
      resendInviteElem = (
        <Loader
          animating={loaderAnimating}
          onFinishAnimating={this._onFinishAnimating}
          error={error}
        />
      );
    } else if (customerIsAdmin) {
      resendInviteElem = (
        <TextButton onClick={this._onResendInvitation}>
          resend invite
        </TextButton>
      );
    }

    return (
      <tr className="TeamTable-row">
        <td className="TeamTable-email">{invitation.email_address_id}</td>
        <td className="TeamTable-date">
          <span>{getRelativeTime(invitation.created)}</span>
          {resendInviteElem}
        </td>
      </tr>
    );
  }

  _onResendInvitation() {
    this.props.onResendInvitation().catch(() => {
      this.setState({
        loaderAnimating: true,
        error: true
      });
    });
  }

  _onFinishAnimating() {
    this.setState({
      loaderAnimating: false,
      error: false
    });
  }
}

InvitationRow.propTypes = {
  customerIsAdmin: PropTypes.bool.isRequired,
  invitation: BillingGroupInvitationShape.isRequired,
  onResendInvitation: PropTypes.func.isRequired,
  isResendingInvitation: PropTypes.bool.isRequired
};
