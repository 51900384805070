/** @format */
import classNames from "classnames";
import PropTypes from "prop-types";
import React, { Component } from "react";

import TextButton from "./text-button.react";

export default class ExpandableTextDisplay extends Component {
  constructor(props) {
    super(props);

    this.state = { isExpanded: false };

    this._onToggleExpanded = this._onToggleExpanded.bind(this);
  }

  render() {
    const { content } = this.props;
    const { isExpanded } = this.state;

    const containerClasses = classNames("ExpandableTextDisplay", {
      "ExpandableTextDisplay--collapsed": !isExpanded
    });

    const contentClasses = classNames("ExpandableTextDisplay-content", {
      "ExpandableTextDisplay-content--collapsed": !isExpanded
    });

    return (
      <div className={containerClasses}>
        <div className={contentClasses}>{content}</div>
        <TextButton
          onClick={this._onToggleExpanded}
          className="ExpandableTextDisplay-disclosure"
        >
          {isExpanded ? "Show Less" : "Show All"}
        </TextButton>
      </div>
    );
  }

  _onToggleExpanded() {
    this.setState({ isExpanded: !this.state.isExpanded });
  }
}

ExpandableTextDisplay.propTypes = { content: PropTypes.string.isRequired };
