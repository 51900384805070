/** @format */
import PropTypes from "prop-types";
import React, { Component } from "react";
import { CustomerShape } from "../../utils/shapes";
import FlexLoader from "../shared/flex-loader.react";
import SettingsMenu from "./settings-menu.react";
import TopBanner from "./top-banner.react";
import { renderSideNavigation } from "./side-navigation-config.react";

export default class SettingsWrapper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sideNavigationMinimized: localStorage.getItem("side-nav-minimized") === "true",
    };
    this.toggleSideNavigation = this.toggleSideNavigation.bind(this);
  }

  toggleSideNavigation() {
    localStorage.setItem("side-nav-minimized", !this.state.sideNavigationMinimized)
    this.setState({
      sideNavigationMinimized: !this.state.sideNavigationMinimized
    })
  }

  render() {
    const {
      children,
      customer,
      customerIsAdmin,
      isLoading,
      onLogout
    } = this.props;

    let sideNavigation = renderSideNavigation(
      this.props.isLoading,
      false,
      this.state.sideNavigationMinimized,
      this.props.customer,
      this.toggleSideNavigation,
      "Settings"
    )

    if (isLoading) {
      return (
        <div className="DashboardWrapper">
          <FlexLoader />
        </div>
      );
    }

    return (
      <div className="Library">
        <div className="Library-container">
          {sideNavigation}
          <div className="Library-rightHandSide">
            <TopBanner title="Settings" />
            <div className="LibraryContent">
              <div className="LibraryContent-textColumnNoScroll">
                <div>
                  <SettingsMenu customer={customer} customerIsAdmin={customerIsAdmin}>
                    {React.Children.map(children, child => (
                      React.isValidElement(child) && (child.props.component !== undefined)
                        ? React.cloneElement(
                          child,
                          {
                            component: undefined,
                            render: (routerProps) => React.cloneElement(child.props.component, { ...routerProps, ...child.props, ref: null, customer, onLogout})
                          }
                        )
                        : child
                    ))}
                  </SettingsMenu>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

SettingsWrapper.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.node
  ]),
  customer: CustomerShape,
  customerIsAdmin: PropTypes.bool,
  customerProfile: PropTypes.shape({
    fullName: PropTypes.string.isRequired,
    company: PropTypes.string
  }),
  onLogout: PropTypes.func.isRequired,
  seatType: PropTypes.string,
  isPaying: PropTypes.bool,
  trialDaysRemaining: PropTypes.number
};
