/** @format */
import React from "react";
import { Button, Icon } from "vogue";
import config from "../../../../config";
import { Contact } from "../../../../collections/schema";
import { generateShape } from "../../../../collections/shapes";
import { ContentBlock } from "../../layout/block.react";
import withSchema from "../../shared/with-schema.react";
import GoogleAccountCalendarList from "../../../containers/account/google-account-calendar-list.react";
import MicrosoftAccountCalendarList from "../../../containers/account/microsoft-account-calendar-list.react";

export const ConnectedCalendars = ({
  data: { google_accounts, microsoft_accounts }, bordered
}) => {
  const googleCalendarLists = google_accounts.map(g => (
    <GoogleAccountCalendarList key={g.id} googleAccount={g} />
  ));
  const microsoftCalendarLists = microsoft_accounts.map(m => (
    <MicrosoftAccountCalendarList key={m.id} microsoftAccount={m} />
  ));

  return (
    <ContentBlock
      title="Your calendars"
      subtitle="Clara will use these calendars to determine your availability"
      icon="calendar"
      anchor="calendars"
      bordered={bordered}
    >
      <div className="ConnectedCalendars-content">
        <ul className="ConnectedCalendars-calendars">
          {googleCalendarLists}
          {microsoftCalendarLists}
        </ul>
        <div className="ConnectedCalendars-add">
          <Icon name="close" className="ConnectedCalendars-icon" />
          <Button
            className="ConnectedCalendars-connectButton"
            modifiers={["secondary"]}
            href={`${config.api.host}/google_accounts/actions/update_or_create`}
          >
            Connect New Google Account
          </Button>
        </div>
        <div className="ConnectedCalendars-add">
          <Icon name="close" className="ConnectedCalendars-icon" />
          <Button
            className="ConnectedCalendars-connectButton"
            modifiers={["secondary"]}
            href={`${
              config.api.host
            }/microsoft_accounts/actions/update_or_create`}
          >
            Connect New Microsoft Account
          </Button>
        </div>
      </div>
    </ContentBlock>
  );
};

const expansions = {
  google_accounts: {
    email_address: {}
  },
  microsoft_accounts: {
    email_address: {}
  }
};

ConnectedCalendars.propTypes = { data: generateShape(Contact, expansions) };

export default withSchema(Contact, expansions, props => props.contactId)(
  ConnectedCalendars
);
