/** @format */
import React from "react";
import PropTypes from "prop-types";
import querystring from "querystring";
import { withRouter } from "react-router-dom";
import { Button } from "vogue";

import config from "../../../config";
import Header from "../shared/header.react";

export const Login = ({ location }) => {
  const browserQuery = new URLSearchParams(location.search);

  const force_approval = browserQuery.get("force_approval");
  const next = browserQuery.get("next");

  let loginQuery = { force_approval: force_approval || "false" };
  if (next) {
    loginQuery.next = decodeURIComponent(next);
  }
  const query = querystring.stringify(loginQuery);
  const googleLoginUrl = `${config.api.host}/auth/login/google?${query}`;
  const microsoftLoginUrl = `${config.api.host}/auth/login/microsoft?${query}`;

  return (
    <div className="Login">
      <div className="container">
        <div className="row center-xs">
          <div className="col-xs-12 col-sm-8 col-md-6 col-lg-4">
            <Header />
            <h2 className="Login-cta">Sign in to Clara</h2>
            <div className="Login-helpText">
              The page you are trying to access requires you to sign into your
              Clara account.
            </div>
            <div className="Login-loginButton">
              <a href={googleLoginUrl}>
                <img height="46" width="191" src="/images/google-sign-in.png" />
              </a>
            </div>
            <div className="Login-loginButton">
              <a href={microsoftLoginUrl}>
                <img width="191" src="/images/microsoft-sign-in.png" />
              </a>
            </div>
            <div className="Login-helpText">OR</div>
            <Button href="https://docs.google.com/forms/d/e/1FAIpQLSdGpdFyvfpL43qhJGQ3HQs5yQwOHRW04qEeCbf05YHBHQy4tQ/viewform" modifiers={["secondary"]}>
              Join Beta
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
Login.propTypes = {
  location: PropTypes.object.isRequired
};

export default withRouter(Login);
