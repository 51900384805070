/** @format */
import PropTypes from "prop-types";

import React, { Component } from "react";
import { Toggle, Loader } from "vogue";
import { connect } from "react-redux";
import { getCurrentCustomer } from "../../../modules/session";
import { updatePreferenceAttribute } from "../../../modules/preferences";

const checkedToBoolean = checked => {
  return checked === "yes" || checked === true;
};

export class BooleanPreference extends Component {
  constructor(props) {
    super(props);

    this.timeout = null;

    this.state = {
      checked: checkedToBoolean(props.checked),
      loaderAnimating: false,
      error: false
    };

    this._onChange = this._onChange.bind(this);
    this._onFinishAnimating = this._onFinishAnimating.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!nextProps.isLoading) {
      if (this.props.isLoading) {
        this.setState({ loaderAnimating: true });
      }
      const castedChecked = checkedToBoolean(nextProps.checked);
      if (castedChecked !== this.state.checked) {
        this.setState({ checked: castedChecked });
      }
    }
  }

  _onChange(e) {
    const checked = e.target.checked;
    this.setState({
      checked: checked
    });

    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      let uncastedChecked;
      if (["yes", "no"].indexOf(this.props.checked) !== -1) {
        // uncast to yes/no
        uncastedChecked = checked ? "yes" : "no";
      } else {
        uncastedChecked = checked;
      }
      this.props.onToggle(uncastedChecked).catch(() => {
        this.setState({ error: true });
      });
    }, 400);
  }

  _onFinishAnimating() {
    this.setState({
      loaderAnimating: false,
      error: false
    });
  }

  render() {
    let loader;
    if (this.props.isLoading || this.state.loaderAnimating) {
      loader = (
        <Loader
          animating={this.state.loaderAnimating}
          onFinishAnimating={this._onFinishAnimating}
          error={this.state.error}
        />
      );
    }

    return (
      <div className="BooleanPreference">
        <Toggle
          onChange={this._onChange}
          checked={this.state.checked}
          disabled={this.props.isLoading}
        />
        {loader}
      </div>
    );
  }
}
BooleanPreference.propTypes = {
  attribute: PropTypes.string.isRequired,
  checked: PropTypes.oneOfType([PropTypes.bool, PropTypes.oneOf(["yes", "no"])])
    .isRequired,
  isLoading: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired
};

const mapStateToProps = (state, ownProps) => {
  const customer = getCurrentCustomer(state);
  return {
    isLoading:
      !customer ||
      state.preferences.inflightAttributes.indexOf(ownProps.attribute) !== -1
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onToggle(checked) {
    return dispatch(updatePreferenceAttribute(ownProps.attribute, checked));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BooleanPreference);
