/** @format */
import { endsWith } from "lodash";

const CLARA_LABS_DOMAIN_NAME = "@claralabs.com";
const INTERNAL_CLARA_EMAIL = "clara@claralabs.com";

export const isCustomDomainClara = assistantEmailAddress =>
  !endsWith(assistantEmailAddress, CLARA_LABS_DOMAIN_NAME) ||
  // Our internal Clara is only available to customers on the Clara Labs billing group.
  assistantEmailAddress === INTERNAL_CLARA_EMAIL;
