/** @format */
import PropTypes from "prop-types";

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import {
  ConferenceRoomSyncedCalendarShape,
  MeetingResourceGroupShape
} from "../../utils/shapes";
import { PreferenceContentBlock } from "../layout/block.react";
import { SelectPreference } from "../shared/attribute-select.react";
import Section from "../layout/section.react";
import MeetingResourceGroup from "../dashboard/conference-rooms/meeting-resource-group.react";
import InnerCircle from "../dashboard/preferences/inner-circle.react";
import Keywords from "../dashboard/preferences/keywords.react";
import FlexLoader from "../shared/flex-loader.react";
import { fetchApi } from "../../../utils/request";

const SpecialFeatures = (props) => {
  const [advancedExpansion, setAdvancedExpansion] = useState(null)
  const advancedDescription =
    "Setup your conference room preference, including when Clara should " +
    "automatically book a room. You can always make specific requests to Clara via email.";

  useEffect(() => {
    fetchApi(`/customers/get_advanced_expansions/${props.customer.id}`)
    .then((response) => {
      if (response.res.ok) {
        setAdvancedExpansion(response.body)
      } 
    })
  }, [])

  const NoConferenceRooms = () => (
    <div className="Settings-sectionContainerPadded">
      <div className="Settings-sectionContainerDetails">
        <div className="Settings-sectionContainerTitle">Preferred rooms & booking</div>
        <div className="Settings-sectionContainerSubtitle">{advancedDescription}</div>
      </div>
      <Section separator={true}>
        <div className="row">
          <div className="col-xs-12">
            <h4 className="ConferenceRooms-text">
              This feature is only available to teams hosting Clara on their domain.
            </h4>
            <div className="ConferenceRooms-smallText">
              To learn more about how to enable conference rooms for your team
              please contact{" "}
              <a href="mailto:support@claralabs.com">customer support</a>.
            </div>
          </div>
        </div>
      </Section>
    </div>
  );
  
  const ScheduleeCallToAction = () => (
    <div className="Settings-sectionContainerPadded">
      <div className="Settings-sectionContainerDetails">
        <div className="Settings-sectionContainerTitle">Preferred rooms & booking</div>
        <div className="Settings-sectionContainerSubtitle">{advancedDescription}</div>
      </div>
      <Section separator={true}>
        <div className="row">
          <div className="col-xs-12">
            <h4 className="ConferenceRooms-text">
              Conference rooms are not configured for your team.
            </h4>
            <div className="ConferenceRooms-smallText">
              Your <Link to="/team">team admin(s)</Link> can configure conference
              rooms and then Clara will be able to book rooms for your meetings.
            </div>
          </div>
        </div>
      </Section>
    </div>
  );
  
  const AdminCallToAction = () => (
    <div className="Settings-sectionContainerPadded">
      <div className="Settings-sectionContainerDetails">
        <div className="Settings-sectionContainerTitle">Preferred rooms & booking</div>
        <div className="Settings-sectionContainerSubtitle">{advancedDescription}</div>
      </div>
      <Section separator={true}>
        <div className="row">
          <div className="col-xs-12">
            <h4 className="ConferenceRooms-text">
              Conference rooms are not configured for your team.
            </h4>
            <div className="ConferenceRooms-smallText">
              You can{" "}
              <Link to="/settings/">
                configure conference rooms
              </Link>{" "}
              and then Clara will be able to book rooms for your meetings.
            </div>
          </div>
        </div>
      </Section>
    </div>
  );
  
  const AdvancedContent = () => {
    if (advancedExpansion) {
      const keywords = advancedExpansion.organization.keywords.concat(
        advancedExpansion.contact_keywords.keywords)

      return (
        <div className="Settings-sectionContainer">
          <div className="Settings-sectionContainerDetails">
            <div className="Settings-sectionContainerTitle">Advanced</div>
            <div className="Settings-sectionContainerSubtitle">
              Features that will enhance your experience with Clara.
            </div>
          </div>
          <Section>
            <div className="row">
              <div className="col-xs-12">
                <Keywords
                  customerContact={advancedExpansion.contact}
                  keywords={keywords}
                  bordered={true}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-xs-12">
                <InnerCircle
                  customerContact={advancedExpansion.contact}
                  meetingPermissions={advancedExpansion.preference.meeting_permissions}
                  bordered={true}
                />
              </div>
            </div>
          </Section>
        </div>
      );
    }

    return <FlexLoader />
  };
  
  const PreferredRoomsContent = () => {
    if (props.isLoading) {
      return <FlexLoader />
    }

    if (!props.hasConferenceRooms) {
      return <NoConferenceRooms />;
    }
  
    // Customers cannot use conference rooms unless we have the resource scope and at least one
    // conference room has been configured.
    const conferenceRoomsConfigured =
      props.connectedEmailAddress && props.conferenceRooms.length > 0;
    if (!conferenceRoomsConfigured) {
      return props.customerIsAdmin ? (
        <AdminCallToAction />
      ) : (
        <ScheduleeCallToAction />
      );
    }
  
    const description =
      "Setup your conference room preference, including when Clara should " +
      "automatically book a room. You can always make specific requests to Clara via email.";
  
    if (!props.isLoading) {
      return (
        <Section title="Preferred rooms & booking" description={description}>
          <div className="row">
            <div className="col-xs-12">
              <MeetingResourceGroup
                meetingResourceGroup={props.meetingResourceGroup}
                conferenceRooms={props.conferenceRooms}
                onSetMeetingResourceGroup={props.onSetMeetingResourceGroup}
                isSettingMeetingResourceGroup={props.isSettingMeetingResourceGroup}
              />
            </div>
            <div className="col-xs-12">
              <PreferenceContentBlock
                title="Auto-booking"
                subtitle="When should Clara book a room for a meeting? NOTE: The office location corresponds to your current default."
                supportLink="https://support.claralabs.com/article/90-conference-room-setup"
              >
                <SelectPreference
                  selectedOption={props.preference.when_to_book_conference_room}
                  attribute="when_to_book_conference_room"
                  options={[
                    { label: "Only when I request it", value: "customer_request" },
                    { label: "All virtual meetings", value: "virtual" },
                    { label: "All in-person office meetings", value: "office" },
                    {
                      label: "All in-person office and virtual meetings",
                      value: "office_virtual"
                    }
                  ]}
                />
              </PreferenceContentBlock>
            </div>
          </div>
        </Section>
      );
    }
  
    return null
  };

  return (
    <div className="Settings">
      {AdvancedContent()}
      {PreferredRoomsContent()}
    </div>
  )
}

SpecialFeatures.propTypes = {
  customerIsAdmin: PropTypes.bool.isRequired,
  hasConferenceRooms: PropTypes.bool.isRequired,
  isSettingMeetingResourceGroup: PropTypes.bool.isRequired,
  conferenceRooms: PropTypes.arrayOf(ConferenceRoomSyncedCalendarShape),
  connectedEmailAddress: PropTypes.string,
  onSetMeetingResourceGroup: PropTypes.func.isRequired,
  preference: PropTypes.shape({
    when_to_book_conference_room: PropTypes.string.isRequired
  }).isRequired,
  meetingResourceGroup: MeetingResourceGroupShape
};

export default SpecialFeatures;
