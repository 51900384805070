/** @format */
import { difference, intersection, omit, pluck } from "lodash";
import { combineReducers } from "redux";

import { VirtualKind } from "../../utils/enums";
import {
  createAsyncAction,
  wrapPromiseToThunk
} from "../../utils/redux-actions";
import { fetchApi } from "../../utils/request";
import { booleanReducer } from "../utils/reducers";
import { reportAndSetError } from "./error";
import {
  consumePayload,
  updateCollection,
  expand
} from "../../collections/module";
import { VirtualDetail, Contact } from "../../collections/schema";
import { handleFetchGroupActions } from "../utils/fetch-group";
import { getCurrentCustomer } from "./session";

export const MOUNT_POINT = "virtualDetails";

const SET_DEFAULT = createAsyncAction("VIRTUAL_DETAILS/SET_DEFAULT");
const CREATE_VIRTUAL_DETAIL = createAsyncAction(
  "VIRTUAL_DETAILS/CREATE_VIRTUAL_DETAIL"
);
const UPDATE_VIRTUAL_DETAIL = createAsyncAction(
  "VIRTUAL_DETAILS/UPDATE_VIRTUAL_DETAIL"
);
const UPDATE_MEETING_TYPE = createAsyncAction(
  "MEETING_TYPE/UPDATE_MEETING_TYPE"
)
const DELETE_VIRTUAL_DETAIL = createAsyncAction(
  "VIRTUAL_DETAILS/DELETE_VIRTUAL_DETAIL"
);
const FETCH_VIRTUAL_DETAIL = createAsyncAction(
  "VIRTUAL_DETAILS/FETCH_VIRTUAL_DETAIL"
);

const consumeVirtualDetailRecord = (record, meta) =>
  consumePayload(
    {
      ...record,
      meta: {
        ...record.meta,
        ...meta
      }
    },
    VirtualDetail
  );

export const fetchVirtualDetails = wrapPromiseToThunk(
  FETCH_VIRTUAL_DETAIL,
  ({ dispatch, getState }, contactId) => {
    return fetchApi(`/contacts/${contactId}/virtual_details`).then(
      ({ body }) => {
        if (body) {
          body.forEach(virtualDetail => {
            dispatch(consumePayload(virtualDetail, VirtualDetail));
          });
          const state = getState();
          dispatch(
            consumePayload(
              {
                ...state.collections.contacts[contactId],
                virtual_details: body.map(({ id }) => id)
              },
              Contact
            )
          );
        }
      }
    );
  },
  reportAndSetError
);

export const setDefault = wrapPromiseToThunk(
  SET_DEFAULT,
  ({ dispatch }, virtualDetailId) => {
    return fetchApi(`/virtual_details/${virtualDetailId}/actions/set_default`, {
      method: "put"
    }).then(({ body }) => {
      if (body && body.result) {
        // Consume any virtual detail models that were changed as a result of this request.
        body.result.forEach(virtualDetail =>
          dispatch(consumePayload(virtualDetail, VirtualDetail))
        );
      }
    });
  },
  reportAndSetError
);

export const createVirtualDetail = wrapPromiseToThunk(
  CREATE_VIRTUAL_DETAIL,
  ({ dispatch, getState }, virtualDetail) => {
    const customer = getCurrentCustomer(getState());
    return fetchApi(`/contacts/${customer.contact_id}/virtual_details`, {
      method: "post",
      // Remove the `meta` key from the virtual detail object which is used to store UI-relevant
      // state for the given virtual detail instance.
      json: omit(virtualDetail, ["meta", "preference", "contact"])
    }).then(({ body }) => {
      dispatch(consumeVirtualDetailRecord(body));
      dispatch(updateCollection(Contact, "virtual_details", body.id));
    });
  },
  reportAndSetError
);

export const updateMeetingType = wrapPromiseToThunk(
  UPDATE_MEETING_TYPE,
  (_, meetingType) => {
    return fetchApi(`/meeting_types/${meetingType.id}`, {
      method: "put",
      json: meetingType
    })
  },
  reportAndSetError
);

export const updateVirtualDetail = wrapPromiseToThunk(
  UPDATE_VIRTUAL_DETAIL,
  ({ dispatch, getState }, virtualDetail) => {
    const preUpdateVirtualDetail = getState().collections.virtual_details[
      virtualDetail.id
    ];

    dispatch(consumeVirtualDetailRecord(virtualDetail, { loading: true }));
    return fetchApi(`/virtual_details/${virtualDetail.id}`, {
      method: "put",
      // Remove the `meta` key from the virtual detail object which is used to store UI-relevant
      // state for the given virtual detail instance.
      json: omit(virtualDetail, ["meta", "preference", "contact"])
    })
      .then(() => {
        dispatch(consumeVirtualDetailRecord(virtualDetail, { loading: false }));
      })
      .catch(error => {
        dispatch(
          consumeVirtualDetailRecord(preUpdateVirtualDetail, { loading: false })
        );
        throw error;
      });
  },
  reportAndSetError
);

export const deleteVirtualDetail = wrapPromiseToThunk(
  DELETE_VIRTUAL_DETAIL,
  ({ dispatch, getState }, virtualDetail) => {
    const preDeleteVirtualDetail = getState().collections.virtual_details[
      virtualDetail.id
    ];

    dispatch(consumeVirtualDetailRecord(virtualDetail, { loading: true }));

    return fetchApi(`/virtual_details/${virtualDetail.id}`, {
      method: "delete"
    })
      .then(() => {
        dispatch(consumeVirtualDetailRecord(virtualDetail, { loading: false }));
        dispatch(
          updateCollection(Contact, "virtual_details", virtualDetail.id, true)
        );
      })
      .catch(error => {
        dispatch(
          consumeVirtualDetailRecord(preDeleteVirtualDetail, { loading: false })
        );
        throw error;
      });
  },
  reportAndSetError
);

export const reducer = combineReducers({
  isSettingDefault: booleanReducer(SET_DEFAULT),
  isFetchingVirtualDetails: booleanReducer(FETCH_VIRTUAL_DETAIL),
  isCreatingVirtualDetail: handleFetchGroupActions(CREATE_VIRTUAL_DETAIL)
});

export const getIsLoadingVirtualDetails = state =>
  state[MOUNT_POINT].isFetchingVirtualDetails;

export const getVirtualDetails = (state, props) => {
  return expand(Contact, props.contactId, { virtual_details: {} }, state)
    .virtual_details;
};

export const getIsCreatingVirtualDetail = state =>
  state[MOUNT_POINT].isCreatingVirtualDetail.isFetching;

export const getIsSettingDefault = state => state[MOUNT_POINT].isSettingDefault;

export const getVirtualDetail = (state, id) =>
  expand(VirtualDetail, id, {}, state);

// VirtualKinds of which only one active configuration per contact is allowed.
const AT_MOST_ONE_KINDS = [
  VirtualKind.zoom,
  VirtualKind.googlemeet,
  VirtualKind.coderpad,
  VirtualKind.hackerrank,
  VirtualKind.googlehangout,
  VirtualKind.skype
];

export const getAvailableVirtualKinds = (state, contactId) => {
  const existingVirtualDetails = getVirtualDetails(state, { contactId });
  const existingKinds = pluck(existingVirtualDetails, "kind");
  const kindsToExclude = intersection(AT_MOST_ONE_KINDS, existingKinds);
  return difference(Object.keys(VirtualKind), kindsToExclude);
};
