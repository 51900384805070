/** @format */
import PropTypes from "prop-types";

import React from "react";

import {
  BillingGroupInvitationShape,
  TeamCustomerShape
} from "../../../utils/shapes";
import CustomerRole from "./customer-role.react";
import InvitationRow from "./invitation-row.react";

const TeamTableRow = customer => (
  <tr className="TeamTable-row">
    <td className="TeamTable-name">{customer.contact.name}</td>
    <td className="TeamTable-email">{customer.contact.default_email}</td>
    <td className="TeamTable-role">
      <CustomerRole customer={customer} />
    </td>
  </tr>
);

TeamTableRow.propTypes = TeamCustomerShape.isRequired;

const EmptyRow = ({ status, colspan = 3 }) => (
  <tr className="TeamTable-row">
    <td className="TeamTable-empty" colSpan={colspan}>
      No {status} customers
    </td>
  </tr>
);

const TeamTable = props => {
  const {
    customers,
    invitations,
    status,
    onResendInvitation,
    resendingInvitations,
    customerIsAdmin
  } = props;
  let header;
  let content;
  if (status === "invited") {
    header = (
      <tr className="TeamTable-row">
        <td className="TeamTable-heading">Email</td>
        <td className="TeamTable-heading">Invite sent</td>
      </tr>
    );
    if (invitations.length) {
      content = invitations.map(invitation => (
        <InvitationRow
          invitation={invitation}
          key={invitation.id}
          onResendInvitation={() => onResendInvitation(invitation.id)}
          isResendingInvitation={
            resendingInvitations.indexOf(invitation.id) !== -1
          }
          customerIsAdmin={customerIsAdmin}
        />
      ));
    } else {
      content = <EmptyRow status={status} colspan={2} />;
    }
  } else {
    header = (
      <tr className="TeamTable-row">
        <td className="TeamTable-heading">Name</td>
        <td className="TeamTable-heading">Email</td>
        <td className="TeamTable-heading">Role</td>
      </tr>
    );
    if (customers.length) {
      content = customers.map(customer => (
        <TeamTableRow {...customer} key={customer.id} />
      ));
    } else {
      content = <EmptyRow status={status} />;
    }
  }

  return (
    <table className="TeamTable">
      <thead>{header}</thead>
      <tbody>{content}</tbody>
    </table>
  );
};

TeamTable.propTypes = {
  invitations: PropTypes.arrayOf(BillingGroupInvitationShape),
  customers: PropTypes.arrayOf(TeamCustomerShape),
  status: PropTypes.string.isRequired,
  onResendInvitation: PropTypes.func.isRequired,
  resendingInvitations: PropTypes.arrayOf(PropTypes.number).isRequired,
  customerIsAdmin: PropTypes.bool.isRequired
};

export default TeamTable;
