import React, { useRef } from "react";
import {
  useMeetingsInfiniteLoading,
  useIsInViewport,
  renderLoadMore,
  renderUpcomingMeetings,
  observeNextLoad
} from "./utils/meetings-infinite-loading.react";
import { fetchApi } from "../../../utils/request";


const UpcomingMeetings = (props) => {
  const { isLoading } = props;
  const observer = useRef(null)
  const isInViewport = useIsInViewport(observer);
  const { meetings, hasNext, loaded, loadNext } = useMeetingsInfiniteLoading({
    fetchMeetings: ( page ) => {    
      let time_start = new Date()
      let time_now = new Date()

      return fetchApi(`/endo/meetings/index`, {
        method: 'POST',
        json: {
          time_start: time_start,
          time_now: time_now,
          direction: "future",
          status: "scheduled",
          page: page,
          per_page: 10
        }
      })
    },
    isLoading: props.isLoading
  })

  observeNextLoad(isInViewport, hasNext, loadNext)

  return (
    <div className="Meetings-content">
      {renderUpcomingMeetings(meetings, loaded)}
      {renderLoadMore(observer, isLoading, hasNext)}
    </div>
  )
}

export default UpcomingMeetings