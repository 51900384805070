/** @format */
import {
  handleActions,
  createAsyncAction,
  wrapPromiseToThunk
} from "../../utils/redux-actions";
import { REPORT_ERROR } from "./error";
import { fetchApi } from "../../utils/request";
import fetchGroup from "../utils/fetch-group";

const SUBMIT_REFERRAL = createAsyncAction("REFERRAL/SUBMIT_REFERRAL");
const GET_SUGGESTIONS = createAsyncAction("REFERRAL/GET_SUGGESTIONS");

export const submitReferrals = wrapPromiseToThunk(
  SUBMIT_REFERRAL,
  (_, emailAddresses) => {
    return fetchApi("/endo/referral/refer", {
      method: "post",
      json: {
        referees: emailAddresses
      }
    });
  },
  REPORT_ERROR
);

export const getReferralSuggestions = wrapPromiseToThunk(
  GET_SUGGESTIONS,
  () => {
    return fetchApi("/endo/referral/suggestions").then(({ body }) => body);
  },
  REPORT_ERROR
);

export const reducer = handleActions(
  {
    [SUBMIT_REFERRAL]: {
      begin: state => ({ ...state, fetchGroup: state.fetchGroup.startFetch() }),
      next: state => ({
        ...state,
        fetchGroup: state.fetchGroup.completeFetch()
      }),
      throw: state => ({
        ...state,
        fetchGroup: state.fetchGroup.completeFetch()
      })
    },
    [GET_SUGGESTIONS]: {
      begin: state => ({ ...state, fetchGroup: state.fetchGroup.startFetch() }),
      next: (state, action) => ({
        ...state,
        fetchGroup: state.fetchGroup.completeFetch(),
        suggestions: action.payload.suggestions
      }),
      throw: state => ({
        ...state,
        fetchGroup: state.fetchGroup.completeFetch()
      })
    }
  },
  {
    fetchGroup: fetchGroup(true)
  }
);
