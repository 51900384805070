/** @format */
import classNames from "classnames";
import { isEqual } from "lodash";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { CheckboxGroup, CheckboxWithLabel, IconWithTooltip } from "vogue";

import {
  ConferenceRoomSyncedCalendarShape,
  MeetingResourceGroupShape
} from "../../../utils/shapes";
import { PreferenceContentBlock } from "../../layout/block.react";
import TextButton from "../../shared/text-button.react";
import FlexLoader from "../../shared/flex-loader.react";

const MeetingResourceGroupItem = ({ id, name, description, capacity }) => (
  <div className="MeetingResourceGroupItem">
    <CheckboxWithLabel value={id}>
      <div className="MeetingResourceGroupItem-contents">
        <div>
          <div>{name}</div>
          <div className="MeetingResourceGroupItem-description">
            {description}
          </div>
        </div>
        <div className="MeetingResourceGroupItem-capacity">
          <IconWithTooltip
            name="people-dark"
            className="MeetingResourceGroupItem-capacityIcon"
            tooltip="Room capacity"
          />
          {capacity} people
        </div>
      </div>
    </CheckboxWithLabel>
  </div>
);

export default class MeetingResourceGroup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selected: this._selectedFromProps(),
      errorMessage: null
    };

    this._onChange = this._onChange.bind(this);
    this._onClick = this._onClick.bind(this);
  }

  render() {
    let content;
    if (this.props.isSettingMeetingResourceGroup) {
      content = <FlexLoader />;
    } else {
      const checkboxes = this.props.conferenceRooms.map(conferenceRoom => (
        <MeetingResourceGroupItem key={conferenceRoom.id} {...conferenceRoom} />
      ));
      const dirty = !isEqual(
        this.state.selected.sort(),
        this._selectedFromProps().sort()
      );
      const buttonClassNames = classNames("MeetingResourceGroup-save", {
        "MeetingResourceGroup-save--visible": dirty
      });
      const errorMessage = this.state.errorMessage ? (
        <aside className="MeetingResourceGroup-errorMessage">
          {this.state.errorMessage}
        </aside>
      ) : null;

      content = (
        <div className="MeetingResourceGroup">
          {errorMessage}
          <div className="MeetingResourceGroup-contents">
            <CheckboxGroup
              values={this.state.selected}
              onChange={this._onChange}
            >
              {checkboxes}
            </CheckboxGroup>
            <TextButton onClick={this._onClick} className={buttonClassNames}>
              Save
            </TextButton>
          </div>
        </div>
      );
    }

    const subtitle =
      "Tell Clara which rooms you want for your meetings. Clara may step outside " +
      " your preferred list if no rooms are available.";
    return (
      <PreferenceContentBlock
        title="Your preferred rooms"
        subtitle={subtitle}
        supportLink="https://support.claralabs.com/hc/en-us/articles/115003860807"
      >
        {content}
      </PreferenceContentBlock>
    );
  }

  _onChange(values) {
    this.setState({
      selected: values,
      errorMessage: null
    });
  }

  _onClick() {
    if (!this.state.selected.length) {
      this.setState({
        errorMessage: "At least one conference room must be selected"
      });
    } else {
      this.props.onSetMeetingResourceGroup(this.state.selected);
    }
  }

  _selectedFromProps() {
    const selectedResources = this.props.meetingResourceGroup
      ? this.props.meetingResourceGroup.meeting_resources
      : this.props.conferenceRooms;
    return selectedResources.map(sr => sr.id);
  }
}

MeetingResourceGroup.propTypes = {
  meetingResourceGroup: MeetingResourceGroupShape,
  conferenceRooms: PropTypes.arrayOf(ConferenceRoomSyncedCalendarShape)
    .isRequired,
  onSetMeetingResourceGroup: PropTypes.func.isRequired,
  isSettingMeetingResourceGroup: PropTypes.bool.isRequired
};
