import React from 'react';

export class MiniCalendar extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      today: new Date(),
      year: 0,
      month: 0,
      firstDay: this.props.firstDay,
      lastDay: this.props.lastDay,
      clicked: new Date(2020, 2, 3),
      tempDatesList: []
    }

    this.renderCalendar = this.renderCalendar.bind(this);
  }

  componentDidMount() {
    let tempDatesList = []
    for (let i = 0; i < this.props.availableDates.length; i++) {
      let startTime = this.props.availableDates[i]["datetime"]["start"]
      tempDatesList.push(startTime.setHours(0, 0, 0, 0))
    }

    this.setState({
      tempDatesList: tempDatesList
    })

    this.state.year = this.props.year
    this.state.month = this.props.month
    this.state.firstDay = this.props.firstDay
    this.state.lastDay = this.props.lastDay
  }

  renderCalendar(startDay, numDays, today){
    let totalSlots = []
    for (let i = 0; i < startDay; i++) {
      totalSlots.push(
        <td key={`empty${i}`} className='calendar-day-empty'>
          {""}
        </td>
      )
    }

    for (let curr = 1; curr <= numDays; curr++) {
      let currDate = new Date(this.state.year, this.state.month, curr);

      let calendarSlot
      if (this.state.tempDatesList.includes(currDate.getTime())) {
        if (currDate.getTime() === this.state.clicked.getTime()) {
          calendarSlot = (
            <td
              key={curr}
              className={`calendar-day-selected-${curr === today ? 'today' : 'default'}`}
              onClick={() => this.selectDay(curr)}
            >
              {curr}
            </td>
          )
        } else {
          calendarSlot = (
            <td
              key={curr}
              className={`calendar-day-${curr === today ? 'today' : 'default'}`}
              onClick={() => this.selectDay(curr)}
            >
              {curr}
            </td>
          )
        }
      } else {
        calendarSlot = (
          <td
            key={curr}
            className={`calendar-day-blocked-${curr === today ? 'today' : 'default'}`}
          >
            {curr}
          </td>
        )
      }

      totalSlots.push(calendarSlot)
    }

    let rows = []
    let cells = []

    totalSlots.forEach((row, i) => {
      if (i % 7 !== 0) {
        cells.push(row)
      } else {
        rows.push(cells)
        cells = []
        cells.push(row)
      }
      
      if (i === totalSlots.length - 1) {
        rows.push(cells)
      }
    })

    let daysInMonth = rows.map((d) => {
      return <tr>{d}</tr>
    })

    return daysInMonth
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.firstDay !== this.props.firstDay ||
      prevProps.lastDay !== this.props.lastDay ||
      prevProps.month !== this.props.month ||
      prevProps.year !== this.props.year
    ) {
      this.setState({
        month: this.props.month,
        year: this.props.year,
        firstDay: this.props.firstDay,
        lastDay: this.props.lastDay
      })
    }
  }

  changeMonth(mod) {
    this.state.month = this.state.month + mod

    if (this.state.month > 11) {
      this.state.month %= 12
      this.state.year += 1
    } else if (this.state.month < 0){
      this.state.month += 12
      this.state.year -= 1
    }

    this.setState({
      firstDay: new Date(this.state.year, this.state.month, 1),
      lastDay: new Date(this.state.year, this.state.month + 1, 0),
    })
  }

  selectDay(date) {
    this.state.clicked = new Date(this.state.year, this.state.month, date);
    this.props.setSelectedDay(this.state.clicked);

    for (let i = 0; i < this.props.availableDates.length; i++) {
      let ad = this.props.availableDates[i]

      if (this.state.clicked <= ad.datetime.end && this.state.clicked >= ad.datetime.start) {
        this.props.setSelectedDateObject(ad.datetime)
      }
    }
  }

  render() {
    const DAYS = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
    return (
      <div className="MiniCalendar">
        <div className="MiniCalendar-titleRow">
          <div className="MiniCalendar-title">
            {this.state.firstDay.toLocaleString("en-US", {month: 'long'}) + " " + this.state.year}
          </div>
          <div className="MiniCalendar-box">
            <img
              className="MiniCalendar-arrow"
              src="/images/mini-cal-left.svg"
              onClick={() => this.props._changeMonth(-1)}
            />
            <img
              className="MiniCalendar-arrow"
              src="/images/mini-cal-right.svg"
              onClick={() => this.props._changeMonth(1)}
            /> 
          </div>
        </div>
        <table className="MiniCalendar-table">
          <thead>
            <tr>
              {DAYS.map((day) => {
                return (
                  <th key={day} className="MiniCalendar-week-day">
                    {day}
                  </th>
                )
              })}
            </tr>
          </thead>
          <tbody>
            {
              this.renderCalendar(this.state.firstDay.getDay(), 
                this.state.lastDay.getDate(), 
                (
                  (this.state.today.getMonth() === this.state.month) &&
                  (this.state.today.getFullYear() === this.state.year)
                ) ? this.state.today.getDate() : -1
              )
            }
          </tbody>
        </table>
      </div>
    )
  }
}

export default MiniCalendar
