/** @format */
import PropTypes from "prop-types";
import React, { Component } from "react";
import EmailAddressUtils from "../../../../utils/email-address";
import ButtonInput from "../button-input.react";

export class AddEmailAddressInput extends Component {
  render() {
    return (
      <ButtonInput
        isLoading={this.props.isLoading}
        onSubmit={this.props.onAddEmailAddress}
        validator={EmailAddressUtils.isValid}
        validationMessage="Invalid email address"
        placeholder="you@company.com"
        buttonText="Add Email Address"
        submitText="Done"
      />
    );
  }
}

AddEmailAddressInput.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  onAddEmailAddress: PropTypes.func.isRequired
};

export default AddEmailAddressInput;
