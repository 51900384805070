/** @format */
import PropTypes from "prop-types";

import React from "react";
import { connect } from "react-redux";

import {
  createInvitation,
  getCreateInvitationError,
  getIsCreatingInvitation
} from "../../../modules/team";
import EmailAddressUtils from "../../../../utils/email-address";
import ButtonInput from "../button-input.react";

export const AddTeamMemberInput = ({
  createInvitationError,
  isLoading,
  onCreateInvitation,
  onSendInviteFinish
}) => (
  <ButtonInput
    isLoading={isLoading}
    onSubmit={value => onCreateInvitation(value).then(onSendInviteFinish)}
    validator={EmailAddressUtils.isValid}
    validationMessage="Invalid email address"
    placeholder="coworker@company.com"
    buttonText="Add New Team Member"
    submitText="Send Invite"
    errorMessage={
      createInvitationError && !isLoading ? createInvitationError.message : null
    }
  />
);

AddTeamMemberInput.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  onCreateInvitation: PropTypes.func.isRequired,
  onSendInviteFinish: PropTypes.func.isRequired,
  createInvitationError: PropTypes.shape({
    message: PropTypes.string.isRequired
  })
};

const mapStateToProps = state => ({
  isLoading: getIsCreatingInvitation(state),
  createInvitationError: getCreateInvitationError(state)
});

const mapDispatchToProps = dispatch => ({
  onCreateInvitation(emailAddress) {
    return dispatch(createInvitation(emailAddress));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddTeamMemberInput);
