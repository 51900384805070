/** @format */
import PropTypes from "prop-types";

import React, { Component } from "react";
import moment from "moment-timezone";
import BigCalendar from "react-big-calendar";
import classNames from "classnames";
import _ from "lodash";
import { RRule } from "rrule";

import ComponentWithExtraProps from "../../../../utils/component-with-extra-props.react";
import { AVAILABLE_TYPES } from "../../../utils/time_constraints.js";
import { TcrShape } from "../../../utils/shapes";
import AvailabilityCalendarToolbar from "./availability-calendar-toolbar.react";
import AvailabilityEditor from "./availability-editor.react";
import { PreferenceContentBlock } from "../../layout/block.react";

const SM_CUTOFF = 1000;
const DEFAULT_VIEW = "week";
const ROOT_DATE = moment("2017-02-05T00:00:00"); // pick an arbitrary Sunday
export const WEEK_MAP = {
  S: ROOT_DATE,
  M: moment(ROOT_DATE).add(1, "days"),
  T: moment(ROOT_DATE).add(2, "days"),
  W: moment(ROOT_DATE).add(3, "days"),
  H: moment(ROOT_DATE).add(4, "days"),
  F: moment(ROOT_DATE).add(5, "days"),
  Sa: moment(ROOT_DATE).add(6, "days")
};
const WEEKDAY_LETTER_MAP = ["S", "M", "T", "W", "H", "F", "S"];
export const RRULE_WEEKDAY_MAP = ["M", "T", "W", "H", "F", "Sa", "S"];

BigCalendar.setLocalizer(BigCalendar.momentLocalizer(moment));

export default class AvailabilityCalendar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLarge: false,
      mediaQuery: null,
      calendarView: DEFAULT_VIEW,
      isModalOpen: false,
      selectedTcrId: null
    };

    this._mediaQueryChanged = this._mediaQueryChanged.bind(this);
    this._dayFormatter = this._dayFormatter.bind(this);
    this._onView = this._onView.bind(this);
    this._propGetter = this._propGetter.bind(this);
    this._parseToEvent = this._parseToEvent.bind(this);
    this._parseTcrToEvents = this._parseTcrToEvents.bind(this);
    this._closeModal = this._closeModal.bind(this);
    this._onCreate = this._onCreate.bind(this);
    this._onSelectEvent = this._onSelectEvent.bind(this);
  }

  UNSAFE_componentWillMount() {
    const mediaQuery = window.matchMedia(`(min-width: ${SM_CUTOFF}px)`);
    mediaQuery.addListener(this._mediaQueryChanged);
    this.setState({
      mediaQuery,
      isLarge: mediaQuery.matches
    });
  }

  componentWillUnmount() {
    this.state.mediaQuery.removeListener(this._mediaQueryChanged);
  }

  _mediaQueryChanged() {
    this.setState({ isLarge: this.state.mediaQuery.matches });
  }

  _propGetter(calendarEvent, start, end, isSelected) {
    return {
      className: classNames("AvailabilityCalendar-event", {
        "AvailabilityCalendar-event--unavailable": !calendarEvent.isAvailable,
        "AvailabilityCalendar-event--selected": isSelected,
        "AvailabilityCalendar-event--dayView":
          this.state.calendarView === "day",
        "AvailabilityCalendar-event--weekView":
          this.state.calendarView === "week"
      })
    };
  }

  _onView(view) {
    this.setState({ calendarView: view });
  }

  _dayFormatter(date, culture, localizer) {
    if (this.state.calendarView === "day") {
      return localizer.format(date, "dddd");
    }

    if (this.state.isLarge) {
      return localizer.format(date, "ddd");
    } else {
      return WEEKDAY_LETTER_MAP[localizer.format(date, "d")];
    }
  }

  _eventTimeFormatter({ start, end }, culture, localizer) {
    const truncateMinute = (time, l) => {
      if (l.format(time, "m") === "0") {
        return l.format(time, "h A");
      } else {
        return l.format(time, "h:mm A");
      }
    };
    return `${truncateMinute(start, localizer)} - ${truncateMinute(
      end,
      localizer
    )}`;
  }

  _parseToEvent(time, availableType) {
    // clone the day moment
    const startTime = moment(WEEK_MAP[time.day]);
    const endTime = moment(WEEK_MAP[time.day]);

    // mutate to the correct hour and minute
    const startHour = time.start.split(":")[0];
    const startMinute = time.start.split(":")[1];
    startTime.hour(startHour);
    startTime.minute(startMinute);

    const endHour = time.end.split(":")[0];
    const endMinute = time.end.split(":")[1];
    endTime.hour(endHour);
    endTime.minute(endMinute);
    return {
      start: startTime.toDate(),
      end: endTime.toDate(),
      allDay: false,
      title: time.note,
      isAvailable: availableType === AVAILABLE_TYPES.AVAILABLE,
      tcrId: time.tcrId
    };
  }

  _parseTcrToEvents(tcr) {
    const rrule = RRule.fromString(tcr.rrule);
    if (rrule.options.freq !== 2) {
      // rrule must be weekly
      return null;
    }

    return rrule.options.byweekday.map(day => {
      return this._parseToEvent(
        {
          start: tcr.start_time,
          end: tcr.end_time,
          // convert rrule day index to API weekday
          day: RRULE_WEEKDAY_MAP[day],
          note: tcr.formatted_condition,
          tcrId: tcr.id
        },
        tcr.available_type
      );
    });
  }

  _calendarEvents() {
    return _.chain(this.props.tcrs)
      .map(this._parseTcrToEvents)
      .flatten()
      .value();
  }

  _closeModal() {
    this.setState({
      isModalOpen: false
    });
  }

  _onCreate() {
    this.setState({
      selectedTcrId: null,
      isModalOpen: true
    });
  }

  _onSelectEvent(calendarEvent) {
    this.setState({
      selectedTcrId: calendarEvent.tcrId,
      isModalOpen: true
    });
  }

  render() {
    const AvailabilityCalendarToolbarShim = ComponentWithExtraProps(
      AvailabilityCalendarToolbar,
      { onCreate: this._onCreate }
    );

    const content = (
      <div>
        <BigCalendar
          events={this._calendarEvents()}
          views={["week", "day"]}
          defaultView={DEFAULT_VIEW}
          step={15}
          timeslots={4}
          defaultDate={ROOT_DATE.toDate()}
          toolbar={true}
          min={moment({ hour: 6 }).toDate()}
          max={moment({ hour: 20 }).toDate()}
          formats={{
            dayFormat: this._dayFormatter,
            timeGutterFormat: "h A",
            eventTimeRangeFormat: this._eventTimeFormatter
          }}
          eventPropGetter={this._propGetter}
          components={{
            toolbar: AvailabilityCalendarToolbarShim
          }}
          onView={this._onView}
          onSelectEvent={this._onSelectEvent}
        />
        <AvailabilityEditor
          isModalOpen={this.state.isModalOpen}
          onEditorClose={this._closeModal}
          id={this.state.selectedTcrId}
        />
      </div>
    );

    return (
      <PreferenceContentBlock
        // title="Preferred meeting times"
        // subtitle="Clara will generally prefer to schedule meetings within these times."
        // supportLink="https://support.claralabs.com/article/115-preferred-meeting-times"
        className="AvailabilityCalendar"
        subClassName="AvailabilityCalendar"
        children={content}
        noSeparator={true}
      />
    );
  }
}

AvailabilityCalendar.propTypes = {
  tcrs: PropTypes.arrayOf(TcrShape).isRequired
};

AvailabilityCalendar.displayName = "AvailabilityCalendar";
