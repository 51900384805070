/** @format */
import PropTypes from "prop-types";

import React, { Component } from "react";
import moment from "moment-timezone";
import classNames from "classnames";
import { WEEK_MAP } from "./availability-calendar.react.js";

export default class AvailabilityCalendarToolbar extends Component {
  constructor(props) {
    super(props);

    this._onNavigateBack = this._onNavigateBack.bind(this);
    this._onNavigateNext = this._onNavigateNext.bind(this);
  }

  _onNavigateBack() {
    const targetDate = moment(this.props.date).subtract(1, "days");
    if (WEEK_MAP.S <= targetDate && targetDate <= WEEK_MAP.Sa) {
      this.props.onNavigate("PREV");
    }
  }

  _onNavigateNext() {
    const targetDate = moment(this.props.date).add(1, "days");
    if (WEEK_MAP.S <= targetDate && targetDate <= WEEK_MAP.Sa) {
      this.props.onNavigate("NEXT");
    }
  }

  render() {
    const viewNamesGroup = this.props.views.map(name => (
      <button
        type="button"
        key={name}
        className={classNames(
          "AvailabilityCalendar-button AvailabilityCalendar-button--view",
          { "rbc-active": this.props.view === name }
        )}
        onClick={this.props.onViewChange.bind(null, name)}
      >
        {name}
      </button>
    ));

    const prevDate = moment(this.props.date).subtract(1, "days");
    const nextDate = moment(this.props.date).add(1, "days");
    const isPrevDisabled = prevDate < WEEK_MAP.S;
    const isNextDisabled = nextDate > WEEK_MAP.Sa;

    const navigationButtons =
      this.props.view === "day" ? (
        <span className="rbc-btn-group">
          <button
            type="button"
            className="AvailabilityCalendar-button AvailabilityCalendar-button--nav"
            onClick={this._onNavigateBack}
            disabled={isPrevDisabled}
          >
            prev
          </button>
          <button
            type="button"
            className="AvailabilityCalendar-button AvailabilityCalendar-button--nav"
            onClick={this._onNavigateNext}
            disabled={isNextDisabled}
          >
            next
          </button>
        </span>
      ) : null;

    const createButton = (
      <button
        type="button"
        className="AvailabilityCalendar-button"
        onClick={this.props.onCreate}
      >
        new
      </button>
    );

    return (
      <div className="AvailabilityCalendar-toolbar rbc-toolbar">
        <span className="rbc-btn-group">{viewNamesGroup}</span>
        {navigationButtons}
        {createButton}
      </div>
    );
  }
}

AvailabilityCalendarToolbar.propTypes = {
  onCreate: PropTypes.func.isRequired,
  // These props are passed in from React BigCalendar
  date: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  onNavigate: PropTypes.func.isRequired,
  onViewChange: PropTypes.func.isRequired,
  view: PropTypes.string.isRequired,
  views: PropTypes.array.isRequired
};
