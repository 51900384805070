import * as Models from './schema';


// Map of schema to other schema with configurations:
// <RELATIONSHIP_NAME>: {
//   scheme: <RELATED_SCHEME>
//   isArray: <BOOL>
//   TODO: (oliver) future possibilities
//   backRef: <STRING>
//   foreignKey: <STRING>
// }
const generateRelationships = () => ({
  [Models.Assistant.key]: {
    contact: {
      scheme: Models.Contact,
      foreignKey: 'contact_id',
      backref: 'assistant',
    },
    customers: {
      scheme: Models.Customer,
      backref: 'assistants',
      isArray: true,
    },
    email_message_jobs: {
      scheme: Models.EmailMessageJob,
      backref: 'assistant',
      isArray: true,
    },
    jobs: {
      scheme: Models.Job,
      backref: 'assistant',
      isArray: true,
    },
  },

  [Models.BillingGroup.key]: {
    invitations: {
      scheme: Models.BillingGroupInvitation,
      backref: 'billing_group',
      isArray: true,
    },
    billing_point_of_contact: {
      scheme: Models.Contact,
      foreignKey: 'billing_point_of_contact_id',
    },
    organization: {
      scheme: Models.Organization,
      backref: 'billing_group',
    },
  },

  [Models.BillingGroupInvitation.key]: {
    billing_group: {
      scheme: Models.BillingGroup,
      foreignKey: 'billing_group_id',
      backref: 'invitations',
    },
    email_address: {
      scheme: Models.EmailAddress,
      foreignKey: 'email_address_id',
      backref: 'invitations',
    },
  },

  [Models.Company.key]: {
    contacts: {
      scheme: Models.Contact,
      backref: 'company',
      isArray: true,
    },
  },

  [Models.Contact.key]: {
    assistant: {
      scheme: Models.Assistant,
      backref: 'contact',
    },
    company: {
      scheme: Models.Company,
      foreignKey: 'company_id',
      backref: 'contacts',
    },
    customer: {
      scheme: Models.Customer,
      backref: 'contact',
    },
    default_email: {
      scheme: Models.EmailAddress,
      computed: true,
    },
    email_addresses: {
      scheme: Models.EmailAddress,
      backref: 'contact',
      isArray: true,
    },
    google_accounts: {
      scheme: Models.GoogleAccount,
      backref: 'contact',
      isArray: true,
    },
    microsoft_accounts: {
      scheme: Models.MicrosoftAccount,
      backref: 'contact',
      isArray: true,
    },
    jobs: {
      scheme: Models.Job,
      backref: 'requester',
      isArray: true,
    },
    keywords: {
      scheme: Models.Keyword,
      backref: 'contact',
      isArray: true,
    },
    meeting_resources: {
      scheme: Models.SyncedCalendar,
      computed: true,
      isArray: true,
    },
    meeting_types: {
      scheme: Models.MeetingType,
      backref: 'contact',
      isArray: true,
    },
    time_constraint_recurrings: {
      scheme: Models.TimeConstraintRecurring,
      backref: 'contact',
      isArray: true,
    },
    virtual_details: {
      scheme: Models.VirtualDetail,
      backref: 'contact',
      isArray: true,
    },
  },

  [Models.ContactSyncedCalendar.key]: {
    synced_calendar: {
      scheme: Models.SyncedCalendar,
      foreignKey: 'synced_calendar_id',
      backref: 'contact_synced_calendars',
    },
    google_account: {
      scheme: Models.GoogleAccount,
      foreignKey: 'google_account_id',
      backref: 'contact_synced_calendars',
    },
    microsoft_account: {
      scheme: Models.MicrosoftAccount,
      foreignKey: 'microsoft_account_id',
      backref: 'contact_synced_calendars',
    },
  },

  [Models.Contractor.key]: {
    assigned_jobs: {
      scheme: Models.Job,
      backref: 'assigned_contractor',
      isArray: true,
    },
    user: {
      scheme: Models.User,
      foreignKey: 'user_id',
      backref: 'contractor',
    },
  },

  [Models.Customer.key]: {
    active_assistant: {
      scheme: Models.Assistant,
      computed: true,
    },
    assistants: {
      scheme: Models.Assistant,
      backref: 'customers',
      isArray: true,
    },
    contact: {
      scheme: Models.Contact,
      foreignKey: 'contact_id',
      backref: 'customer',
    },
    customer_audit_logs: {
      scheme: Models.CustomerAuditLog,
      isArray: true,
    },
    customer_user: {
      scheme: Models.User,
      foreignKey: 'customer_user_id',
      backref: 'customer',
    },
    email_address_verifications: {
      scheme: Models.EmailAddressVerification,
      backref: 'customer',
      isArray: true,
    },
    feedback_ratings: {
      scheme: Models.FeedbackRating,
      backref: 'customer',
      isArray: true,
    },
    google_accounts: {
      scheme: Models.GoogleAccount,
      computed: true,
      isArray: true,
    },
    jobs: {
      scheme: Models.Job,
      computed: true,
      isArray: true,
    },
    organization: {
      scheme: Models.Organization,
      foreignKey: 'organization_id',
      backref: 'customers',
    },
    preference: {
      scheme: Models.Preference,
      backref: 'customer',
    },
    referred_by: {
      scheme: Models.Customer,
      foreignKey: 'referred_by_id',
      backref: 'referrals',
    },
    referrals: {
      scheme: Models.Customer,
      backref: 'referred_by',
    },
  },

  [Models.EmailAddress.key]: {
    contact: {
      scheme: Models.Contact,
      foreignKey: 'contact_id',
      backref: 'email_addresses',
    },
    google_account: {
      scheme: Models.GoogleAccount,
      backref: 'email_address',
    },
    microsoft_account: {
      scheme: Models.MicrosoftAccount,
      backref: 'email_address',
    },
    invitations: {
      scheme: Models.BillingGroupInvitation,
      backref: 'email_address',
      isArray: true,
    },
  },

  [Models.EmailAddressVerification.key]: {
    customer: {
      scheme: Models.Customer,
      foreignKey: 'customer_id',
      backref: 'email_address_verifications',
    },
  },

  [Models.EmailMessage.key]: {
    header_bcc: {
      scheme: Models.EmailAddress,
      isArray: true,
    },
    header_cc: {
      scheme: Models.EmailAddress,
      isArray: true,
    },
    header_to: {
      scheme: Models.EmailAddress,
      isArray: true,
    },
    header_from: {
      scheme: Models.EmailAddress,
      isArray: true,
    },
    jobs: {
      scheme: Models.Job,
      computed: true,
      isArray: true,
    },
    email_message_jobs: {
      scheme: Models.EmailMessageJob,
      backref: 'email_message',
      isArray: true,
    },
    participants: {
      scheme: Models.Contact,
      coputed: true,
      isArray: true,
    },
    scheduler: {
      scheme: Models.Contractor,
      foreignKey: 'scheduler_id',
    },
    email_message_write: {
      scheme: Models.EmailMessageWrite,
      backref: 'email_message',
      isArray: false,
    },
  },

  [Models.EmailMessageJob.key]: {
    assistant: {
      scheme: Models.Assistant,
      foreignKey: 'assistant_id',
      backref: 'email_message_jobs',
    },
    job: {
      scheme: Models.Job,
      foreignKey: 'job_id',
      backref: 'job_email_messages',
    },
    email_message: {
      scheme: Models.EmailMessage,
      foreignKey: 'email_message_id',
      backref: 'email_message_jobs',
    },
  },

  [Models.EmailMessageWrite.key]: {
    job: {
      scheme: Models.Job,
      computed: true,
    },
    email_message: {
      scheme: Models.EmailMessage,
      backref: 'email_message_write',
      isArray: false,
    },
    sender: {
      scheme: Models.EmailAddress,
      foreignKey: 'sender_id',
    },
  },

  [Models.Event.key]: {
    event_participants: {
      scheme: Models.EventParticipant,
      backref: 'event',
      isArray: true,
    },
    synced_calendar: {
      scheme: Models.SyncedCalendar,
      foreignKey: 'synced_calendar_id',
      backref: 'events',
    },
  },

  [Models.EventWrite.key]: {
    job: {
      scheme: Models.Job,
      computed: true,
      backref: 'event_writes',
    },
  },

  [Models.EventParticipant.key]: {
    event: {
      scheme: Models.Event,
      foreignKey: 'event_id',
    },
    email_address: {
      scheme: Models.EmailAddress,
      foreignKey: 'email_address_id',
    },
  },

  [Models.FeedbackRating.key]: {
    customer: {
      scheme: Models.Customer,
      foreignKey: 'customer_id',
      backref: 'feedback_ratings',
    },
  },

  [Models.GoogleAccount.key]: {
    email_address: {
      scheme: Models.EmailAddress,
      foreignKey: 'email_address_id',
      backref: 'google_account',
    },
    contact: {
      scheme: Models.Contact,
      foreignKey: 'contact_id',
      backref: 'google_accounts',
    },
    contact_synced_calendars: {
      scheme: Models.ContactSyncedCalendar,
      backref: 'google_account',
      isArray: true,
    },
    customer: {
      scheme: Models.Customer,
      computed: true,
    },
  },

  [Models.Job.key]: {
    assigned_contractor: {
      scheme: Models.Contractor,
      foreignKey: 'assigned_contractor_id',
      backref: 'assigned_jobs',
    },
    assistant: {
      scheme: Models.Assistant,
      foreign_key: 'assistant_id',
      backref: 'jobs',
    },
    audit_logs: {
      scheme: Models.JobStateMachineAuditLog,
      backref: 'job',
      isArray: true,
    },
    available_meeting_resources: {
      scheme: Models.SyncedCalendar,
      computed: true,
      isArray: true,
    },
    customers: {
      scheme: Models.Customer,
      computed: true,
      isArray: true,
    },
    email_message_writes: {
      scheme: Models.EmailMessageWrite,
      computed: true,
      isArray: true,
    },
    email_messages: {
      scheme: Models.EmailMessage,
      computed: true,
      isArray: true,
    },
    event_writes: {
      scheme: Models.EventWrite,
      computed: true,
      backref: 'job',
      isArray: true,
    },
    events: {
      scheme: Models.Event,
      isArray: true,
    },
    job_email_messages: {
      scheme: Models.EmailMessageJob,
      backref: 'job',
      isArray: true,
    },
    job_sessions: {
      scheme: Models.JobSession,
      backref: 'job',
      isArray: true,
    },
    linked_audit_logs: {
      scheme: Models.JobStateMachineAuditLog,
      computed: true,
      isArray: true,
    },
    meeting_resource_groups: {
      scheme: Models.MeetingResourceGroup,
      backref: 'job',
      isArray: true,
    },
    participants_: {
      scheme: Models.Participant,
      isArray: true,
    },
    primary_customer: {
      scheme: Models.Customer,
      computed: true,
    },
    requester: {
      scheme: Models.Contact,
      foreignKey: 'requester_id',
      backref: 'jobs',
    },
    suggested_times: {
      scheme: Models.SuggestedTime,
      computed: true,
      isArray: true,
    },
  },

  [Models.JobSession.key]: {
    contractor: {
      scheme: Models.Contractor,
      foreignKey: 'contractor_id',
    },
    source_job_session: {
      scheme: Models.JobSession,
      foreignKey: 'source_job_session_id',
    },
    job: {
      scheme: Models.Job,
      foreignKey: 'job_id',
      backref: 'job_sessions',
    },
    job_session_reviews: {
      scheme: Models.JobSessionReview,
      backref: 'job_session',
      isArray: true,
    },
  },

  [Models.JobSessionReview.key]: {
    job_session: {
      scheme: Models.JobSession,
      foreignKey: 'job_session_id',
      backref: 'job_session_reviews',
    },
    job_session_review_mistakes: {
      scheme: Models.JobSessionReviewMistake,
      backref: 'job_session_review',
      isArray: true,
    },
    reviewer: {
      scheme: Models.Contractor,
      foreignKey: 'reviewer_id',
    },
  },

  [Models.JobSessionReviewMistake.key]: {
    job_session_review: {
      scheme: Models.JobSessionReview,
      foreignKey: 'job_session_review_id',
      backref: 'job_session_review_mistakes',
    },
    audit_logs: {
      scheme: Models.JobSessionReviewMistakeAppealAuditLog,
      backref: 'job_session_review_mistake',
      isArray: true,
    },
  },

  [Models.JobSessionReviewMistakeAppealAuditLog.key]: {
    job_session_review_mistake: {
      scheme: Models.JobSessionReviewMistake,
      foreignKey: 'job_session_review_mistake_id',
      backref: 'audit_logs',
    },
    contractor: {
      scheme: Models.Contractor,
      foreignKey: 'contractor_id',
    },
  },

  [Models.JobStateMachineAuditLog.key]: {
    contractor: {
      scheme: Models.Contractor,
      computed: true,
    },
    event: {
      scheme: Models.Event,
      computed: true,
    },
    event_participant: {
      scheme: Models.EventParticipant,
      computed: true,
    },
    job: {
      scheme: Models.Job,
      foreignKey: 'job_id',
      backref: 'audit_logs',
    },
  },

  [Models.Keyword.key]: {
    organization: {
      scheme: Models.Organization,
      foreignKey: 'organization_id',
      backref: 'keywords',
    },
    contact: {
      scheme: Models.Contact,
      foreignKey: 'contact_id',
      backref: 'keywords',
    },
  },

  [Models.Location.key]: {
    preference: {
      scheme: Models.Preference,
      foreignKey: 'preference_id',
      backref: 'locations',
    },
    organization: {
      scheme: Models.Organization,
      foreignKey: 'organization_id',
      backref: 'locations',
    },
  },

  [Models.MeetingPermission.key]: {
    preference: {
      scheme: Models.Preference,
      foreignKey: 'preference_id',
      backref: 'meeting_permissions',
    },
    contact: {
      scheme: Models.Contact,
      foreignKey: 'contact_id',
    },
  },

  [Models.MeetingResourceGroup.key]: {
    job: {
      scheme: Models.Job,
      foreignKey: 'job_id',
      backref: 'meeting_resource_groups',
    },
    meeting_resources: {
      scheme: Models.SyncedCalendar,
      computed: true,
      isArray: true,
    },
  },

  [Models.MeetingType.key]: {
    contact: {
      scheme: Models.Contact,
      foreign_key: 'contact_id',
      backref: 'meeting_types',
    },
  },

  [Models.MicrosoftAccount.key]: {
    email_address: {
      scheme: Models.EmailAddress,
      foreignKey: 'email_address_id',
      backref: 'microsoft_account',
    },
    contact: {
      scheme: Models.Contact,
      foreignKey: 'contact_id',
      backref: 'microsoft_accounts',
    },
    contact_synced_calendars: {
      scheme: Models.ContactSyncedCalendar,
      backref: 'microsoft_account',
      isArray: true,
    },
  },

  [Models.Organization.key]: {
    billing_group: {
      scheme: Models.BillingGroup,
      backref: 'organization',
      foreignKey: 'billing_group_id',
    },
    customers: {
      scheme: Models.Customer,
      backref: 'organization',
      isArray: true,
    },
    keywords: {
      scheme: Models.Keyword,
      backref: 'organization',
      isArray: true,
    },
    locations: {
      scheme: Models.Location,
      backref: 'organization',
      isArray: true,
    },
  },

  [Models.Participant.key]: {
    active_email_address: {
      scheme: Models.EmailAddress,
      foreignKey: 'active_email_address_id',
    },
    contact: {
      scheme: Models.Contact,
      foreignKey: 'contact_id',
    },
  },

  [Models.Preference.key]: {
    customer: {
      scheme: Models.Customer,
      foreignKey: 'customer_id',
      backref: 'preference',
    },
    locations: {
      scheme: Models.Location,
      backref: 'preference',
      isArray: true,
    },
    meeting_permissions: {
      scheme: Models.MeetingPermission,
      backref: 'preference',
      isArray: true,
    },
    meeting_resource_group: {
      scheme: Models.MeetingResourceGroup,
    },
  },

  [Models.SyncedCalendar.key]: {
    contact_synced_calendars: {
      scheme: Models.ContactSyncedCalendar,
      backref: 'synced_calendar',
      isArray: true,
    },
    events: {
      scheme: Models.Event,
      backref: 'synced_calendar',
      isArray: true,
    },
  },

  [Models.TimeConstraintRecurring.key]: {
    contact: {
      scheme: Models.Contact,
      foreignKey: 'contact_id',
      backref: 'time_constraint_recurrings',
    },
  },

  [Models.User.key]: {
    contractor: {
      scheme: Models.Contractor,
      backref: 'user',
    },
    customer: {
      scheme: Models.Customer,
      backref: 'customer_user',
    },
  },

  [Models.VirtualDetail.key]: {
    contact: {
      scheme: Models.Contact,
      foreignKey: 'contact_id',
      backref: 'virtual_details',
    },
  },
});

export default generateRelationships;
