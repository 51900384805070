import PropTypes from "prop-types";

import React, { useState, useEffect } from "react";

import { MeetingTypeShape, VirtualDetailShape } from "../../utils/shapes";
import { MEETING_DURATIONS } from "../../utils/times";
import { isCustomDomainClara } from "../../utils/assistant";
import { PreferenceContentBlock } from "../layout/block.react";
import Section from "../layout/section.react";
import { SelectPreference } from "../shared/attribute-select.react";
import VirtualDetailDefault from "../dashboard/virtual-details/default.react";
import VirtualMeetingDetail from "../dashboard/virtual-details/detail.react";
import VirtualMeetingDetailPlaceholder from "../dashboard/virtual-details/placeholder.react";

import { LocationShape } from "../../utils/shapes";
import LocationDetail from "../dashboard/locations/location-detail.react";
import LocationPlaceholder from "../dashboard/locations/placeholder.react";
import DefaultLocationCategory from "../dashboard/preferences/default-location-category.react";
import EventTitlePreference from "../dashboard/preferences/event-title.react";
import { LongTextPreference } from "../dashboard/preferences/basic.react";
import { sendInvitesTo as sendInvitesToMailto } from "../dashboard/preferences/mailto-templates";
import EventCalendar from "../../containers/event-calendar.react";
import { fetchApi } from "../../../utils/request";

const MeetingDetails = (props) => {
  const [invitationExpansion, setInvitationExpansion] = useState(null)

  const {
    preference,
    defaultVirtualMeetingType,
    onSetDefault,
    virtualDetails,
    isSettingDefault,
    activeAssistant,
    locations,
    customer
  } = props;

  useEffect(() => {
    fetchApi(`/customers/get_invitation_expansions/${props.customer.id}`)
    .then((response) => {
      if (response.res.ok) {
        setInvitationExpansion(response.body)
      }
    })
  }, [])

  const updateTitlePreference = (title_type, title_value) => {
    fetchApi(`/endo/preferences/update_title_preference`, {
      method: 'POST',
      json: {
        title_type: title_type,
        title_value: title_value
      }
    }).then((response) => {
      if (response.res.ok) {
        let updatedExpansion = invitationExpansion
        if (title_type === "in_person_title") {
          updatedExpansion.preference.in_person_title = title_value
        } else if (title_type === "virtual_title") {
          updatedExpansion.preference.virtual_title = title_value
        }
        setInvitationExpansion(updatedExpansion)
      }
    })
  }

  const locationComparator = (a, b) => {
    // First, sort at the category level.
    if (a.category < b.category) return -1;
    if (a.category > b.category) return 1;
  
    // Then, sort between default and non-default.
    if (a.is_default && !b.is_default) return -1;
    if (!a.is_default && b.is_default) return 1;
  
    // Finally, sort by name.
    if (a.name < b.name) return -1;
    if (a.name > b.name) return 1;
  
    return 0;
  };

  const renderVirtualCalls = () => {
    const description =
      "Clara uses your defaults when coordinating virtual meetings. If you prefer " +
      "to use different details, you can always make specific requests to Clara via email.";

    const secondaryDescription =
      "Add call and virtual meeting details you use for meetings " +
      "regularly. You can refer to these by name when you are communicating with Clara.";

    return (
      <div className="Settings-sectionContainer">
        <div className="Settings-sectionContainerDetails">
          <div className="Settings-sectionContainerTitle">Calls & virtual</div>
          <div className="Settings-sectionContainerSubtitle">{description}</div>
        </div>
        <Section>
          <div className="row">
            <div className="col-xs-12 col-sm-6">
              <VirtualDetailDefault
                customer={customer}
                activeAssistant={activeAssistant}
                virtualDetails={virtualDetails}
                isLoading={isSettingDefault}
                onSetDefault={onSetDefault}
                supportLink="https://support.claralabs.com/article/110-virtual-meetings-calls-remote"
                bordered={true}
              />
            </div>
            <div className="col-xs-12 col-sm-6">
              <PreferenceContentBlock
                title="Meeting duration"
                subtitle="How long should virtual meetings be?"
                supportLink="https://support.claralabs.com/article/119-meeting-duration"
                bordered={true}
              >
                <SelectPreference
                  selectedOption={preference.virtualDuration}
                  attribute="virtualDuration"
                  options={MEETING_DURATIONS}
                />
              </PreferenceContentBlock>
            </div>
          </div>
        </Section>
        <Section separator={true} description={secondaryDescription}>
          <div className="row">
            {virtualDetails.filter(vd => vd).map(virtualDetail => (
              <div className="col-xs-12 col-sm-6 col-lg-4" key={virtualDetail.id}>
                <VirtualMeetingDetail
                  meetingType={defaultVirtualMeetingType}
                  virtualDetail={virtualDetail}
                  bordered={true}
                />
              </div>
            ))}
            <div className="col-xs-12 col-sm-6 col-lg-4">
              <VirtualMeetingDetailPlaceholder
                meetingType={defaultVirtualMeetingType}
                virtualDetails={virtualDetails}
                customer={customer}
              />
            </div>
          </div>
        </Section>
      </div>
    )
  }

  const renderInPerson = () => {
    const description =
      "Clara uses these preferences when coordinating in-person meetings. The " +
      "default options available are based on the locations you have added in the section below. " +
      "If you prefer to step outside of your regular preferences, you can always make specific " +
      "requests to Clara via email.";

    const secondaryDescription =
      "Add details about in-person meetings and locations you use for " +
      "meetings regularly. You can refer to these locations by name when you are communicating with " +
      "Clara.";

    const locationDetails = locations.sort(locationComparator).map(location => (
      <div className="col-xs-12 col-sm-6 col-md-4" key={location.id}>
        <LocationDetail
          location={location}
          defaultLocationCategory={preference.default_location_category}
          bordered={true}
        />
      </div>
    ));

    return (
      <div className="Settings-sectionContainer">
        <div className="Settings-sectionContainerDetails">
          <div className="Settings-sectionContainerTitle">In-person</div>
          <div className="Settings-sectionContainerSubtitle">{description}</div>
        </div>
        <Section>
          <div className="row">
            <div className="col-xs-12 col-sm-6">
              <PreferenceContentBlock
                title="Meeting duration"
                subtitle="How long should in-person meetings be?"
                supportLink="https://support.claralabs.com/article/119-meeting-duration"
                bordered={true}
              >
                <SelectPreference
                  selectedOption={preference.inPersonDuration}
                  attribute="inPersonDuration"
                  options={MEETING_DURATIONS}
                />
              </PreferenceContentBlock>
            </div>
            <div className="col-xs-12 col-sm-6">
              <PreferenceContentBlock
                title="Default location category"
                subtitle="Where should Clara schedule in-person meetings by default?"
                supportLink="https://support.claralabs.com/article/123-default-location-category"
                bordered={true}
              >
                <DefaultLocationCategory
                  selectedOption={preference.default_location_category}
                  locations={locations}
                />
              </PreferenceContentBlock>
            </div>
          </div>
        </Section>
        <Section description={secondaryDescription} separator={true}>
          <div className="row">
            {locationDetails}
            <div className="col-xs-12 col-sm-6 col-md-4">
              <LocationPlaceholder existingLocations={locations} />
            </div>
          </div>
        </Section>
      </div>
    );
  }

  const renderMeetingInvitations = () => {
    if (!invitationExpansion) {
      return
    }

    const description =
      "Customize the way Clara constructs your meeting invitations after all the " +
      "details have been confirmed. You can always make specific requests to Clara via email.";

    let eventCalendar;
    if (isCustomDomainClara(invitationExpansion.active_assistant.contact.default_email.id)) {
      eventCalendar = (
        <div className="col-xs-12 col-sm-6">
          <EventCalendar
            customer={customer}
            bordered={true}
            className="Integrations-blockContainer"
            subClassName="Integrations-block"
          />
        </div>
      );
    }

    return (
      <div className="Settings-sectionContainer">
        <div className="Settings-sectionContainerDetails">
          <div className="Settings-sectionContainerTitle">Meeting invitations</div>
          <div className="Settings-sectionContainerSubtitle">{description}</div>
        </div>
        <Section>
          <div className="row">
            <div className="col-xs-12">
              <EventTitlePreference
                preference={invitationExpansion.preference}
                customerContact={invitationExpansion.contact}
                customerCompany={invitationExpansion.company}
                setter={updateTitlePreference}
                bordered={true}
              />
            </div>
            <div className="col-xs-12 col-sm-6">
              <PreferenceContentBlock
                title="Invitation email"
                subtitle="Which of your emails should Clara send all event invites to?"
                onEdit={sendInvitesToMailto(invitationExpansion.contact.first_name)}
                supportLink="https://support.claralabs.com/article/120-invitation-email"
                bordered={true}
              >
                <LongTextPreference>
                  {invitationExpansion.preference.sendInvitesTo}
                </LongTextPreference>
              </PreferenceContentBlock>
            </div>
            {eventCalendar}
          </div>
        </Section>
      </div>
    );
  }

  return (
    <div className="Settings">
      {renderVirtualCalls()}
      {renderInPerson()}
      {renderMeetingInvitations()}
    </div>
  );
}

MeetingDetails.propTypes = {
  preference: PropTypes.shape({
    virtualDuration: PropTypes.number.isRequired
  }).isRequired,
  defaultVirtualMeetingType: MeetingTypeShape,
  onSetDefault: PropTypes.func.isRequired,
  virtualDetails: PropTypes.arrayOf(VirtualDetailShape).isRequired,
  isSettingDefault: PropTypes.bool.isRequired,
  locations: PropTypes.arrayOf(LocationShape).isRequired,
};

export default MeetingDetails;
