import config from '../config';
import GoogleMapsLoader from 'google-maps';
import Promise from 'bluebird';

GoogleMapsLoader.KEY = config.google.browserKey;
GoogleMapsLoader.LIBRARIES = ['places', 'geometry'];

const GoogleMapsAPI = {

  /* NOTE - once the google API has been loaded, `google` is a globally available variable.
     const googleRef = google;  // eslint-disable-line no-undef -- is used to isolate lint errors */

  load() {
    if (typeof google === 'undefined') { // eslint-disable-line no-undef
      return new Promise((resolve) => {
        GoogleMapsLoader.load((googleRef) => {
          resolve(googleRef);
        });
      });
    }
    return Promise.resolve(google); // eslint-disable-line no-undef
  },

};

export default GoogleMapsAPI;
