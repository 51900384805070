/** @format */
import PropTypes from "prop-types";

import React from "react";
import createReactClass from "create-react-class";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import callAnalytics from "../../utils/segment";
import { setError } from "../modules/error";

function ApplicationError(message) {
  this.name = "ApplicationError";
  this.message = message;
  this.stack = new Error().stack;
}

ApplicationError.prototype = Object.create(Error.prototype);
ApplicationError.prototype.constructor = ApplicationError;

export const App = createReactClass({
  propTypes: {
    children: PropTypes.node.isRequired,
    location: PropTypes.object.isRequired,
    setError: PropTypes.func.isRequired
  },

  componentDidMount: function() {
    callAnalytics("page", "pagehit", window.location.pathname);
    
    const query = new URLSearchParams(this.props.location.search);
    const error = query.get("error");

    if (error) {
      this.props.setError(new ApplicationError(error));
    }
  },

  UNSAFE_componentWillReceiveProps: function(nextProps) {
    if (this.props.location !== nextProps.location) {
      callAnalytics("page", "pagehit", window.location.pathname);
      window.Beacon("event", {
        type: "page-viewed",
        url: document.location.href,
        title: document.title,
      })
      window.Beacon("suggest")
    }
  },

  render() {
    return <section className="EndoApplication">{this.props.children}</section>;
  }
});

export default withRouter(
  connect(
    null,
    { setError }
  )(App)
);
