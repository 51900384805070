/** @format */
const generateMailtoLink = (subject, content, customerName) => {
  const body = `Hi Clara Support,

${content.trim()}

Thanks!

Best regards,
${customerName}`;

  return `mailto:${encodeURIComponent(
    "support@claralabs.com"
  )}?Subject=${encodeURIComponent(subject)}&Body=${encodeURIComponent(body)}`;
};

export const keywords = customerName => {
  const subject = "Changing my codewords preference";
  const content = `
Could you please remember these codewords with their meanings: <insert codewords + meanings>?
  `;

  return generateMailtoLink(subject, content, customerName);
};

export const innerCircle = customerName => {
  const subject = "Changing my inner circle preference";
  const content = `
Could you please add <insert email> to my inner circle so they can <initiate/schedule/initial & schedule> meetings?
  `;

  return generateMailtoLink(subject, content, customerName);
};

export const bcc = customerName => {
  const subject = "Changing my bcc preference";
  const content = `
You should Bcc <insert "me"/someone in your inner circle> on <the first email, all emails, confirmation emails>.
  `;

  return generateMailtoLink(subject, content, customerName);
};

export const cc = customerName => {
  const subject = "Changing my cc preference";
  const content = `
You should Cc <insert "me"/someone in your inner circle> on <the first email, all emails, confirmation emails>.
  `;

  return generateMailtoLink(subject, content, customerName);
};

export const sendInvitesTo = customerName => {
  const subject = "Changing my invitation email preference";
  const content = `
You should send event invites to this email address: <insert email address>.
  `;

  return generateMailtoLink(subject, content, customerName);
};
