/** @format */
import PropTypes from "prop-types";

import React, { Component } from "react";
import { IconWithTooltip } from "vogue";

import { SyncedCalendarShape } from "../../../utils/shapes";

export default class ConferenceRoomList extends Component {
  render() {
    const { conferenceRooms } = this.props;

    let content;
    if (conferenceRooms.length === 0) {
      content = <div>No conference rooms configured</div>;
    } else {
      content = conferenceRooms.map(
        ({ provider_id, name, description, capacity }) => (
          <div className="ConferenceRoomListItem" key={provider_id}>
            <div className="ConferenceRoomListItem-left">
              <div>{name}</div>
              <div className="ConferenceRoomListItem-description">
                {description}
              </div>
            </div>
            <div className="ConferenceRoomListItem-capacity">
              <IconWithTooltip
                name="people-dark"
                className="ConferenceRoomListItem-capacityIcon"
                tooltip="Room capacity"
              />
              {capacity} people
            </div>
          </div>
        )
      );
    }

    return <div className="ConferenceRoomList">{content}</div>;
  }
}

ConferenceRoomList.propTypes = {
  conferenceRooms: PropTypes.arrayOf(SyncedCalendarShape).isRequired
};
