/** @format */
import { isEqual } from "lodash";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Modal } from "vogue";

import {
  createAvailability,
  updateAvailability,
  deleteAvailability,
  getIsEditorLoading
} from "../../../modules/availability";
import { TcrShape } from "../../../utils/shapes";
import { AVAILABLE_TYPES } from "../../../utils/time_constraints";
import AvailabilityForm, {
  getValidationError
} from "./availability-form.react";

export class AvailabilityEditor extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tcr: props.tcr,
      forceValidation: false
    };

    this._handleAdd = this._handleAdd.bind(this);
    this._handleRemove = this._handleRemove.bind(this);
    this._handleSave = this._handleSave.bind(this);
    this._onChange = this._onChange.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!isEqual(nextProps.tcr, this.state.tcr)) {
      this.setState({
        tcr: nextProps.tcr,
        forceValidation: false
      });
    }
  }

  render() {
    const inCreateMode = !this.state.tcr.id;
    const modalActions = inCreateMode
      ? this._creatingModalActions()
      : this._updatingModalActions();

    return (
      <Modal
        actions={modalActions}
        open={this.props.isModalOpen}
        loading={this.props.isLoading}
        onRequestClose={this.props.onEditorClose}
        classNames={{
          modal: ["AvailabilityEditor-modal"]
        }}
        ref="editorModal"
      >
        <AvailabilityForm
          tcr={this.state.tcr}
          onChange={this._onChange}
          forceValidation={this.state.forceValidation}
        />
      </Modal>
    );
  }

  _onChange(newTcr) {
    this.setState({
      ...this.state,
      tcr: {
        ...this.state.tcr,
        ...newTcr
      }
    });
  }

  _handleAdd() {
    if (getValidationError(this.state.tcr)) {
      this.setState({ forceValidation: true });
      if (this.refs.editorModal) this.refs.editorModal.shake();
    } else {
      this.props
        .onCreateAvailability(this.state.tcr)
        .then(this.props.onEditorClose);
    }
  }

  _handleRemove() {
    return this.props
      .onDeleteAvailability(this.state.tcr)
      .then(this.props.onEditorClose);
  }

  _handleSave() {
    if (getValidationError(this.state.tcr)) {
      this.setState({ forceValidation: true });
      if (this.refs.editorModal) this.refs.editorModal.shake();
    } else {
      this.props
        .onUpdateAvailability(this.state.tcr)
        .then(this.props.onEditorClose);
    }
  }

  _updatingModalActions() {
    return [
      {
        key: "save",
        label: "Save",
        modifier: "primary",
        handler: this._handleSave
      },
      {
        key: "cancel",
        label: "Cancel",
        handler: this.props.onEditorClose
      },
      {
        key: "remove",
        label: "Remove",
        modifier: "reject",
        handler: this._handleRemove
      }
    ];
  }

  _creatingModalActions() {
    return [
      {
        key: "add",
        label: "Add",
        modifier: "primary",
        handler: this._handleAdd
      },
      {
        key: "cancel",
        label: "Cancel",
        handler: this.props.onEditorClose
      }
    ];
  }
}

AvailabilityEditor.defaultProps = {
  tcr: {
    available_type: AVAILABLE_TYPES.AVAILABLE,
    condition: "ANY",
    start_time: "09:00:00",
    end_time: "17:00:00",
    rrule: ""
  }
};

AvailabilityEditor.propTypes = {
  id: PropTypes.number,
  tcr: TcrShape,
  isModalOpen: PropTypes.bool.isRequired,
  onEditorClose: PropTypes.func.isRequired,
  onCreateAvailability: PropTypes.func.isRequired,
  onUpdateAvailability: PropTypes.func.isRequired,
  onDeleteAvailability: PropTypes.func.isRequired
};

const mapDispatchToProps = dispatch => ({
  onCreateAvailability(tcr) {
    return dispatch(createAvailability(tcr));
  },

  onUpdateAvailability(tcr) {
    return dispatch(updateAvailability(tcr));
  },

  onDeleteAvailability(tcr) {
    return dispatch(deleteAvailability(tcr));
  }
});

const mapStateToProps = (state, ownProps) => ({
  isLoading: getIsEditorLoading(state),
  tcr: state.collections.time_constraint_recurrings[ownProps.id]
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AvailabilityEditor);
