/** @format */
import React from "react";
import { connect } from "react-redux";

import Slack from "../../components/dashboard/integrations/slack.react";
import FlexLoader from "../../components/shared/flex-loader.react";

import {
  getActiveSlack,
  getIsLoading as getIsLoadingSession
} from "../../modules/session";

const SlackContainer = ({ isLoading, ...props }) =>
  isLoading ? <FlexLoader /> : <Slack {...props} />;

const mapStateToProps = state => ({
  getActiveSlack: getActiveSlack(state),
  isLoading: getIsLoadingSession(state),
});

const mapDispatchToProps = () => ({

});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SlackContainer);
