import React from "react";

const ErrorMessage = (props) => {
  return (
    <div className="LibraryContent-errorMessage">
      {props.message}
    </div>
  )
}

export default ErrorMessage