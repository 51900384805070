import { combineReducers } from 'redux';
import { createAction } from 'redux-actions';
import { handleActions, createAsyncAction, wrapPromiseToThunk } from '../../utils/redux-actions';
import Api from '../utils/api';
import { handleFetchGroupActions } from '../utils/fetch-group';

export const MOUNT_POINT = 'transcriptAssignment';

// ACTIONS
const SET_ASSIGMENT_FROM_DETAILS = createAsyncAction('TRANSCRIPT/SET_ASSIGMENT_FROM_DETAILS');
const UPDATE_TRANSCRIPT_ASSIGNMENT = createAsyncAction('TRANSCRIPT/UPDATE_TRANSCRIPT_ASSIGNMENT');
const DELETE_TRANSCRIPT_ASSIGNMENT = createAsyncAction('TRANSCRIPT/DELETE_TRANSCRIPT_ASSIGNMENT');
const SET_TRANSCRIPT_ASSIGNMENT_STATE = createAction('TRANSCRIPT/SET_TRANSCRIPT_ASSIGNMENT_STATE');
const DELETE_TRANSCRIPT_ASSIGNMENT_STATE = createAction('TRANSCRIPT/DELETE_TRANSCRIPT_ASSIGNMENT_STATE');

// THUNK setter
// this can be cleaner, details endpoint can be made into 
// its own module and set transcript assignments there.
export const onSetAssignmentsFromDetails = wrapPromiseToThunk(
  SET_ASSIGMENT_FROM_DETAILS, 
  ({ dispatch }, assignments) => {
    dispatch(SET_TRANSCRIPT_ASSIGNMENT_STATE(assignments));
  }
);

// THUNK async updater
export const onUpdateAssignment = wrapPromiseToThunk(
    UPDATE_TRANSCRIPT_ASSIGNMENT, 
    ({ dispatch }, slug, emailAddress, callName) => {
        return Api.updateTranscriptAssignment(slug, emailAddress, callName)
        .then(() => {
            let payload = {};
            payload[emailAddress] = callName;
            dispatch(SET_TRANSCRIPT_ASSIGNMENT_STATE(payload));
        });
    }
);

// THUNK async updater
export const onDeleteAssignment = wrapPromiseToThunk(
  DELETE_TRANSCRIPT_ASSIGNMENT, 
  ({ dispatch }, slug, emailAddress) => {
      return Api.deleteTranscriptAssignment(slug, emailAddress)
      .then(() => { //(response) => {
          let payload = {};
          // var deleteKey = Object.keys(response["deleted"]).find(k => response["deleted"][k] === callName);
          // payload[deleteKey] = null
          payload[emailAddress] = null
          dispatch(DELETE_TRANSCRIPT_ASSIGNMENT_STATE(payload));
      });
  }
);

// REDUCER
export const transcriptAssignment = handleActions(
  {
    [SET_TRANSCRIPT_ASSIGNMENT_STATE]: (state, action) => {
        var stateValues = Object.values(state)
        var payloadValue = Object.values(action.payload)[0]

        // collision in assignments detected
        if (stateValues.includes(payloadValue)) {
          var collisionKey = Object.keys(state).find(k => state[k] === payloadValue);
          delete state[collisionKey]
        }

        return {
          ...state,
          ...action.payload
        }
    },

    [DELETE_TRANSCRIPT_ASSIGNMENT_STATE]: (state, action) => {
      return {
        ...state,
        ...action.payload
      }
  },
  }, { } // default transcriptAssignment, do not delete
);

// REDUCER
export const fetchGroups = combineReducers({
  onUpdateAssignment: handleFetchGroupActions(UPDATE_TRANSCRIPT_ASSIGNMENT, false),
  onDeleteAssignment: handleFetchGroupActions(DELETE_TRANSCRIPT_ASSIGNMENT, false)
});

// COMBINED REDUCER
export const reducer = combineReducers({
  transcriptAssignment,
  fetchGroups,
});

// send to mapStatetoProps
export const getTranscriptAssignments = state =>
    state[MOUNT_POINT].transcriptAssignment
