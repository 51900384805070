/** @format */
import PropTypes from "prop-types";

import React, { Component } from "react";
import { SegmentedControl } from "vogue";

import {
  BillingGroupInvitationShape,
  PagingShape
} from "../../utils/shapes";
import { Block, Content, PreferenceContentBlock } from "../layout/block.react";
import Pagination from "../shared/pagination.react";
import FlexLoader from "../shared/flex-loader.react";
import Section from "../layout/section.react";
import TeamTable from "../dashboard/account/team-table.react";
import AddTeamMemberInput from "../dashboard/account/add-team-member-input.react"
import { SelectAttribute } from "../shared/attribute-select.react";
import { VirtualKind } from "../../../utils/enums";

const MEMBER_SEGMENTS = [
  {
    title: "Active",
    value: "active"
  },
  {
    title: "Inactive",
    value: "inactive"
  },
  {
    title: "Incomplete",
    value: "incomplete"
  },
  {
    title: "Invited",
    value: "invited"
  }
];

export default class TeamBlock extends Component {

  constructor(props) {
    super(props);

    this.state = {
      selectedSegment: "active",
      selected: "Team",
      selectedDetailId: this.props.organization.default_virtual_detail_type,
    };

    this._onSelectedSegment = this._onSelectedSegment.bind(this);
    this._onPageSelected = this._onPageSelected.bind(this);
    this._onShowInvited = this._onSelectedSegment.bind(this, "invited");
    this._onSelect = this._onSelect.bind(this);
  }

  UNSAFE_componentWillMount() {
    this._getPage(1);
  }

  _onSelect = (value) => {
    this.setState({ selectedDetailId: value })
    return this.props.onSetDefault(value);
  }

  _showGoogleMicrosoftOption() {
    let assistant = this.props.activeAssistant
    if (!assistant) return false

    let orgDomains = this.props.customer.organization.domains
    if (!orgDomains) return false

    let assistantEmail = assistant.default_email
    let assistantEmailDomain = assistantEmail.split("@")[1]

    return orgDomains.includes(assistantEmailDomain)
  }

  renderDefaultVirtualDetail = () => {
    if (!this.props.customerIsAdmin) return null

    let options
    let showGoogleMicrosoft = this._showGoogleMicrosoftOption()
    if (this.props.isGoogle && showGoogleMicrosoft) {
      options = [
        {label: "No default", value: "no_default"},
        {label: "Google Meet", value: VirtualKind.googlemeet,}
      ]
    } else if (this.props.isMicrosoft && showGoogleMicrosoft) {
      options = [
        {label: "No default", value: "no_default"},
        {label: "Microsoft Teams", value: VirtualKind.microsoft_teams,}
      ]
    } else {
      options = [
        {label: "No default", value: "no_default"},
      ]
    }

    options.push((this.props.getActiveZoomAccount) ? {label: "Zoom", value: VirtualKind.zoom} : null)

    options = options.filter((option) => {
      return option
    })

    let description = 
      "This setting allows you to choose the default channel for virtual meetings for new team members. " +
      "Please note that this setting will only affect new users, and each user can still change their individual " +
      "default call option on the Meetings Details tab."
    
    return (
      <div className="Settings-sectionContainer">
        <div className="Settings-sectionContainerDetails">
          <div className="Settings-sectionContainerTitle">Default call channel</div>
          <div className="Settings-sectionContainerSubtitle">{description}</div>
        </div>
        <Section>
          <div className="row">
            <div className="col-xs-12">
              <PreferenceContentBlock
                title="Default call channel for new users"
                subtitle="Which call channel should be the default for new team members?"
                supportLink="https://support.claralabs.com/article/110-virtual-meetings-calls-remote"
                bordered={true}
              >
                <SelectAttribute
                  meetingTypeId={1}
                  attribute="team_default_virtual_detail"
                  selectedOption={this.state.selectedDetailId}
                  options={options}
                  onSelect={this._onSelect}
                  // isLoading={this.props.isLoading}
                />
              </PreferenceContentBlock>
            </div>
          </div>
        </Section>
      </div>
    )
  }

  renderTeamBlock = () => {
    let contents;
    if (this.props.isLoading) {
      contents = <FlexLoader />;
    } else {
      contents = (
        <TeamTable
          invitations={this.props.invitations}
          customers={this.props.teamCustomers}
          status={this.state.selectedSegment}
          onResendInvitation={this.props.onResendInvitation}
          resendingInvitations={this.props.resendingInvitations}
          customerIsAdmin={this.props.customerIsAdmin}
        />
      );
    }

    // Render the pagination controls if we have sufficient data.
    let pagination;
    if (this.state.selectedSegment === "invited" && this.props.invitationPaging) {
      pagination = (
        <Pagination
          {...this.props.invitationPaging}
          onPageSelected={this._onPageSelected}
        />
      );
    } else if (this.props.paging) {
      pagination = (
        <Pagination {...this.props.paging} onPageSelected={this._onPageSelected} />
      );
    }

    let addTeamMember;
    if (this.props.customerIsAdmin) {
      addTeamMember = (
        <AddTeamMemberInput onSendInviteFinish={this._onShowInvited} />
      );
    } else {
      addTeamMember = (
        <div className="TeamBlock-addTeamMemberCopy">
          To add members to your team please contact one of your Clara team
          admins.
        </div>
      );
    }

    let description = "Search and add to your Clara team. Additional seats are added at discounted rates.";

    return (
      <div className="Settings-sectionContainer">
        <div className="Settings-sectionContainerDetails">
          <div className="Settings-sectionContainerTitle">Team members</div>
          <div className="Settings-sectionContainerSubtitle">{description}</div>
        </div>
        <Section>
          <div className="row">
            <div className="col-xs-12">
              <Block bordered={true}>
                <Content className="TeamBlock">
                  <div className="TeamBlock-segmentedControl">
                    <SegmentedControl
                      segments={MEMBER_SEGMENTS}
                      selectedSegment={this.state.selectedSegment}
                      onSelectedSegment={this._onSelectedSegment}
                    />
                  </div>
                  {contents}
                  {pagination}
                  <div className="TeamBlock-addTeamMember">{addTeamMember}</div>
                </Content>
              </Block>
            </div>
          </div>
        </Section>
      </div>
    )
  }

  render() {
    return (
      <div className="Settings">
        {this.renderDefaultVirtualDetail()}
        {this.renderTeamBlock()}
      </div>
    );
  }

  _getPage(page) {
    if (this.state.selectedSegment === "invited") {
      this.props.onGetInvitations(page);
    } else {
      this.props.onGetTeamCustomers(
        this.props.organizationId,
        page,
        this.state.selectedSegment
      );
    }
  }

  _onSelectedSegment(value) {
    this.setState({ selectedSegment: value }, () => this._getPage(1));
  }

  _onPageSelected(selectedPage) {
    // Only update the page when the value is actually new; this callback is invoked when the
    // pagination component is rendered for the first time even though the page has not changed.
    if (
      (this.state.selectedSegment === "invited" &&
        selectedPage !== this.props.invitationPaging.current) ||
      (this.state.selectedSegment !== "invited" && this.props.paging.current)
    ) {
      this._getPage(selectedPage);
    }
  }
}

TeamBlock.propTypes = {
  customerIsAdmin: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  organizationId: PropTypes.number.isRequired,
  paging: PagingShape,
  invitationPaging: PagingShape,
  invitations: PropTypes.arrayOf(BillingGroupInvitationShape),
  teamCustomers: PropTypes.arrayOf(PropTypes.object),
  onGetTeamCustomers: PropTypes.func.isRequired,
  onResendInvitation: PropTypes.func.isRequired,
  resendingInvitations: PropTypes.arrayOf(PropTypes.number).isRequired
};
