/** @format */
import React from "react";
import moment from "moment-timezone";

import { computeStatus } from "../../utils/customer";
import { isAdmin } from "../../utils/permissions";
import { CustomerShape } from "../../utils/shapes";
import PillBox from "../shared/pill-box.react";

const CustomerStatusIndicator = ({ customer }) => {
  let description = null;
  if (customer.offboarding_start) {
    const offboardingEnd = moment(customer.offboarding_start).add(7, "days");
    const selectPlan = (
      <div
        className="CustomerStatusIndicator-billingButton"
        onClick={() => {window.location=`https://claralabs.com/pricing?c=true`}}
      >
        link your card and upgrade your plan.
      </div>
    )

    if (offboardingEnd.diff(moment.utc(), "days") > 0) {
      description = (
        <span className="CustomerStatusIndicator-description">
          Clara will continue to schedule your outstanding meetings until{" "}
          {offboardingEnd.format("MMM D, YYYY")}, but you cannot make new
          requests to Clara. To continue making requests, please {selectPlan}
        </span>
      );
    } else {
      description = (
        <span className="CustomerStatusIndicator-description">
          Clara is no longer scheduling your meetings. To reactivate your
          account, please {selectPlan}
        </span>
      );
    }
  }

  const status = computeStatus(customer);
  const badgeColor = {
    inactive: "yellow",
    incomplete: "blue",
    active: "green"
  }[status];

  return (
    <div className="CustomerStatusIndicator">
      <div className={description && "CustomerStatusIndicator-pillContainer"}>
        <PillBox text={status} modifier={badgeColor} />
        {isAdmin(customer.roles) && <PillBox text="admin" modifier="red" />}
      </div>
      {description}
    </div>
  );
};

CustomerStatusIndicator.propTypes = { customer: CustomerShape.isRequired };

export default CustomerStatusIndicator;
