/** @format */
import PropTypes from "prop-types";
import React, { Component } from "react";
import { Modal, TextField } from "vogue";
import FormInputContainer from "../../shared/form-input-container.react";

export default class KeywordsEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      keyword: ""
    };
    this._handleSave = this._handleSave.bind(this);
    this._onChange = this._onChange.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.keyword && nextProps.keyword.keyword) {
      this.setState({
        keyword: nextProps.keyword.keyword
      });
    }
  }

  render() {
    const { isEditing, onEditorClose } = this.props;

    const modalActions = [
      {
        key: "save",
        label: "Save",
        modifier: "primary",
        handler: this._handleSave
      },
      {
        key: "cancel",
        label: "Cancel",
        handler: onEditorClose
      }
    ];

    return (
      <Modal
        actions={modalActions}
        open={isEditing}
        onRequestClose={this.props.onEditorClose}
        ref="keywordEditorModal"
        classNames={{
          modal: ["KeywordEditor-modal"]
        }}
      >
        <FormInputContainer heading="Enter a phrase you would like to use for this keyword">
          <TextField
            value={this.state.keyword}
            onChange={this._onChange}
            placeholder="Keyword text"
          />
        </FormInputContainer>
      </Modal>
    );
  }

  _handleSave() {
    this.props.onUpdate(this.props.keyword.id, this.state.keyword);
  }

  _onChange(e) {
    this.setState({
      keyword: e.target.value
    });
  }
}

KeywordsEditor.propTypes = {
  isEditing: PropTypes.bool.isRequired,
  onEditorClose: PropTypes.func.isRequired,
  keyword: PropTypes.object,
  onUpdate: PropTypes.func.isRequired
};
