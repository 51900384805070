/** @format */
import PropTypes from "prop-types";

import React, { Component } from "react";
import ReactPaginate from "react-paginate";

class Pagination extends Component {
  render() {
    const { current, first, last, onPageSelected } = this.props;
    if (last <= first) {
      // there is only one page. Do not display pagination
      return null;
    }

    return (
      <ReactPaginate
        pageCount={last}
        pageRangeDisplayed={3}
        marginPagesDisplayed={1}
        onPageChange={({ selected }) => onPageSelected(selected + 1)}
        forcePage={current - 1}
        containerClassName="Pagination-container"
        pageClassName="Pagination-page"
        pageLinkClassName="Pagination-pageLink"
        activeClassName="Pagination-page--active"
        previousClassName="Pagination-previous"
        nextClassName="Pagination-next"
      />
    );
  }
}
Pagination.propTypes = {
  current: PropTypes.number.isRequired,
  last: PropTypes.number.isRequired,
  first: PropTypes.number.isRequired,
  onPageSelected: PropTypes.func.isRequired
};

export default Pagination;
