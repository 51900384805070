/** @format */
import { pluck } from "lodash";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Modal } from "vogue";

import {
  createLocation,
  getIsCreatingLocation
} from "../../../modules/locations";
import { LocationShape } from "../../../utils/shapes";
import LocationDetailForm, { getValidationError } from "./form.react";

export class LocationDetailCreator extends Component {
  constructor(props) {
    super(props);

    this.state = {
      location: { meta: {} },
      forceValidation: false
    };

    this._handleAdd = this._handleAdd.bind(this);
    this._onChange = this._onChange.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.isCreating !== this.props.isCreating) {
      this.setState({
        location: { meta: {} },
        forceValidation: false
      });
    }
  }

  render() {
    const modalActions = [
      {
        key: "add",
        label: "Add",
        modifier: "primary",
        handler: this._handleAdd
      },
      {
        key: "cancel",
        label: "Cancel",
        handler: this.props.onEditorClose
      }
    ];

    // FIXME: GooglePlacesSearchBox interferes with React Modal's overlay closing
    // so we have purposely not included the connecting code:
    // onRequestClose={ this.props.onEditorClose }
    return (
      <Modal
        actions={modalActions}
        open={this.props.isCreating}
        loading={this.props.creatingLocation}
        modifier="noGutter"
        ref="editorModal"
      >
        <LocationDetailForm
          location={this.state.location}
          onChange={this._onChange}
          forceValidation={this.state.forceValidation}
        />
      </Modal>
    );
  }

  _handleAdd() {
    if (getValidationError(this.state.location)) {
      this.setState({ forceValidation: true });
      if (this.refs.editorModal) this.refs.editorModal.shake();
    } else {
      return this.props
        .onCreateLocation(this.state.location)
        .then(this.props.onEditorClose);
    }
  }

  _onChange(location) {
    let isDefault = !!location.is_default;

    // // If the category was changed then check if we need to auto set it to default.
    if (location.category !== this.state.location.category) {
      const existingLocationCategories = pluck(
        this.props.existingLocations,
        "category"
      );
      isDefault = existingLocationCategories.indexOf(location.category) === -1;
    }

    this.setState({
      location: {
        ...this.state.location,
        ...location,
        is_default: isDefault
      }
    });
  }
}

LocationDetailCreator.propTypes = {
  existingLocations: PropTypes.arrayOf(LocationShape).isRequired,
  onCreateLocation: PropTypes.func.isRequired,
  creatingLocation: PropTypes.bool.isRequired,
  isCreating: PropTypes.bool.isRequired,
  onEditorClose: PropTypes.func.isRequired
};

const mapDispatchToProps = dispatch => ({
  onCreateLocation(location) {
    return dispatch(createLocation(location));
  }
});

const mapStateToProps = state => ({
  creatingLocation: getIsCreatingLocation(state)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LocationDetailCreator);
