/** @format */
import { isEqual } from "lodash";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Modal } from "vogue";

import { DefaultLocationCategory } from "../../../../utils/enums";
import { deleteLocation, updateLocation } from "../../../modules/locations";
import { LocationShape } from "../../../utils/shapes";
import LocationDetailForm, { getValidationError } from "./form.react";

export class LocationDetailEditor extends Component {
  constructor(props) {
    super(props);

    this.state = {
      location: props.location,
      forceValidation: false
    };

    this._handleRemove = this._handleRemove.bind(this);
    this._handleSave = this._handleSave.bind(this);
    this._onChange = this._onChange.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!isEqual(nextProps.location, this.state.location)) {
      this.setState({
        location: nextProps.location,
        forceValidation: false
      });
    }
  }

  render() {
    const modalActions = [
      {
        key: "save",
        label: "Save",
        modifier: "primary",
        handler: this._handleSave
      },
      {
        key: "cancel",
        label: "Cancel",
        handler: this.props.onEditorClose
      }
    ];

    // Don't allow deletion of a location that is the default for the category set as the user's
    // default location category preference as it will be rejected by the server.
    if (
      !this.props.location.is_default ||
      this.props.location.category !== this.props.defaultLocationCategory
    ) {
      modalActions.push({
        key: "remove",
        label: "Remove",
        modifier: "reject",
        handler: this._handleRemove
      });
    }

    // FIXME: GooglePlacesSearchBox interferes with React Modal's overlay closing
    // so we have purposely not included the connecting code:
    // onRequestClose={ this.props.onEditorClose }
    return (
      <Modal
        actions={modalActions}
        open={this.props.isEditing}
        loading={this.props.location ? this.props.location.meta.loading : false}
        modifier="noGutter"
        ref="editorModal"
      >
        <LocationDetailForm
          location={this.state.location}
          onChange={this._onChange}
          forceValidation={this.state.forceValidation}
        />
      </Modal>
    );
  }

  _handleRemove() {
    return this.props
      .onDeleteLocation(this.state.location)
      .then(this.props.onEditorClose);
  }

  _handleSave() {
    if (getValidationError(this.state.location)) {
      this.setState({ forceValidation: true });
      if (this.refs.editorModal) this.refs.editorModal.shake();
    } else {
      return this.props
        .onUpdateLocation(this.state.location)
        .then(this.props.onEditorClose);
    }
  }

  _onChange(location) {
    this.setState({
      location: {
        ...this.state.location,
        ...location
      }
    });
  }
}

LocationDetailEditor.propTypes = {
  id: PropTypes.number.isRequired,
  location: LocationShape,
  isEditing: PropTypes.bool.isRequired,
  onEditorClose: PropTypes.func.isRequired,
  onDeleteLocation: PropTypes.func.isRequired,
  onUpdateLocation: PropTypes.func.isRequired,
  defaultLocationCategory: PropTypes.oneOf(
    Object.values(DefaultLocationCategory)
  ).isRequired
};

const mapDispatchToProps = dispatch => ({
  onDeleteLocation(location) {
    return dispatch(deleteLocation(location));
  },
  onUpdateLocation(location) {
    return dispatch(updateLocation(location));
  }
});

const mapStateToProps = (state, ownProps) => ({
  location: state.collections.locations[ownProps.id]
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LocationDetailEditor);
