import _ from 'lodash';
import { combineReducers } from "redux";
import { createAction } from 'redux-actions';
import { createAsyncAction, handleActions, wrapPromiseToThunk } from '../../utils/redux-actions';
import { fetchApi } from '../../utils/request';
import { consumePayload } from '../../collections/module';
import { EventWrite } from '../../collections/schema';

export const MOUNT_POINT = "eventWrite";

const POLL_EVENT_WRITE = createAsyncAction('EVENT_WRITE/POLL_EVENT_WRITE');
const SET_POLL_EVENT_WRITE_TIMEOUT = createAction('EVENT_WRITE/SET_POLL_EVENT_WRITE_TIMEOUT');
const UNSET_POLL_EVENT_WRITE_TIMEOUT = createAction('EVENT_WRITE/CANCEL_POLL_EVENT_WRITE_TIMEOUT');


export const pollUnsyncedEventWrite = wrapPromiseToThunk(
  POLL_EVENT_WRITE,
  ({ dispatch, getState }, eventWrite) => {
    const promise = new Promise(resolve => {
      const poll = () => {
        const timeoutId = setTimeout(() => {
          fetchApi(`/event_writes/${ eventWrite.id }`).then(({ body }) => {
            dispatch(consumePayload(body, EventWrite));

            if (body.synced) {
              dispatch(UNSET_POLL_EVENT_WRITE_TIMEOUT({[eventWrite.id]: null}));
              resolve(body);
            } else {
              const timeoutsById = getState()[MOUNT_POINT].polling;
              if (timeoutsById[eventWrite.id]) {
                poll(body);
              }
            }
          });
        }, 2000);

        dispatch(SET_POLL_EVENT_WRITE_TIMEOUT({id: eventWrite.id, timeoutId}));
      };

      poll();
    });

    return promise;
  }
);

export const clearEventWritePolling = eventWrite => {
  return (dispatch, getState) => {
    const timeoutsById = getState()[MOUNT_POINT].polling;
    if (timeoutsById[eventWrite.id]) {
      clearTimeout(timeoutsById[eventWrite.id]);
      dispatch(UNSET_POLL_EVENT_WRITE_TIMEOUT(eventWrite.id));
    }
  };
};

export const polling = handleActions({
  [SET_POLL_EVENT_WRITE_TIMEOUT]: (state, action) => ({
    ...state,
    [action.payload.id]: action.payload.timeoutId
  }),
  [UNSET_POLL_EVENT_WRITE_TIMEOUT]: (state, action) => _.omit(state, action.payload),
}, {});

export const reducer = combineReducers({
  polling
});
