/** @format */
import moment from "moment-timezone";
import React, { Component } from "react";
import { connect } from "react-redux";

import config from "../../../config";
import MeetingsWrapper from "../../components/live-v2/meetings-wrapper.react";
import authRequired from "../../components/shared/auth-required.react";
import {
  getCurrentBillingGroup,
  getCurrentCustomer,
  getCurrentCustomerProfile,
  getIsLoading,
  logout
} from "../../modules/session";
import { isAdmin } from "../../utils/permissions";

class MeetingsWrapperContainer extends Component {
  render() {
    return <MeetingsWrapper {...this.props} />;
  }
}

const expansions = {
  contact: {},
  organization: {}
};

const mapStateToProps = state => {
  const customer = getCurrentCustomer(state, expansions);
  if (getIsLoading(state) || !customer) {
    return { isLoading: true };
  }

  const billingGroup = getCurrentBillingGroup(state);

  try {
    window.Beacon("identify", {
      name: `${customer.contact.first_name + " " + customer.contact.last_name}`,
      email: `${customer.contact.default_email}`,
      company: `${customer.organization.name}`,
    });
  } catch (e) {
    console.log("Unable to identify beacon user", e)
  }

  return {
    isLoading: false,
    customer,
    customerIsAdmin: isAdmin(customer.roles),
    customerProfile: getCurrentCustomerProfile(state),
    seatType: billingGroup.seat_type,
    isPaying: billingGroup.treat_as_paying,
    trialDaysRemaining: moment(billingGroup.trial_end_date).diff(
      moment.utc(),
      "days"
    ),
    isNewDesign: window.location.href.includes("call-recordings")
  };
};

const mapDispatchToProps = dispatch => ({
  onLogout() {
    return dispatch(logout()).then(() => {
      window.location = config.services.site.host;
    });
  }
});

export default authRequired({ onboarded: true })(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(MeetingsWrapperContainer)
);
