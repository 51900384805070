/** @format */
import { combineReducers } from "redux";
import { createAction } from "redux-actions";
import {
  handleActions,
  createAsyncAction,
  wrapPromiseToThunk
} from "../../utils/redux-actions";
import { fetchApi } from "../../utils/request";
import { handleFetchGroupActions } from "../utils/fetch-group";
import { REPORT_ERROR } from "./error";
import { consumePayload, expand } from "../../collections/module";
import { MeetingResourceGroup, SyncedCalendar } from "../../collections/schema";
import { ADD_SCOPE_SYNC_RESOURCES } from "./conference-rooms-configuration";

export const MOUNT_POINT = "conferenceRooms";

const FETCH_CONFERENCE_ROOMS = createAsyncAction(
  "CONFERENCE_ROOMS/FETCH_CONFERENCE_ROOMS"
);
const FETCH_MEETING_RESOURCE_GROUP = createAsyncAction(
  "CONFERENCE_ROOMS/FETCH_MEETING_RESOURCE_GROUP"
);
const FETCH_CONNECTED_EMAIL_ADDRESS = createAsyncAction(
  "CONFERENCE_ROOMS/FETCH_CONNECTED_EMAIL_ADDRESS"
);
const SET_MEETING_RESOURCE_GROUP = createAsyncAction(
  "CONFERENCE_ROOMS/SET_MEETING_RESOURCE_GROUP"
);
const SET_MEETING_RESOURCE_GROUP_ID = createAction(
  "CONFERENCE_ROOMS/SET_MEETING_RESOURCE_GROUP_ID"
);
export const SET_CONNECTED_EMAIL_ADDRESS = createAction(
  "CONFERENCE_ROOMS/SET_CONNECTED_EMAIL_ADDRESS"
);

export const fetchConferenceRooms = wrapPromiseToThunk(
  FETCH_CONFERENCE_ROOMS,
  ({ dispatch }, contactId) => {
    return fetchApi(`/contacts/${contactId}/meeting_resources`).then(
      ({ body }) =>
        body.map(syncedCalendar => {
          dispatch(consumePayload(syncedCalendar, SyncedCalendar));
          return syncedCalendar.id;
        })
    );
  },
  REPORT_ERROR
);

export const fetchMeetingResourceGroup = wrapPromiseToThunk(
  FETCH_MEETING_RESOURCE_GROUP,
  ({ dispatch }, preferenceId) => {
    return fetchApi(
      `/meeting_resource_groups/${preferenceId}/refresh_meeting_resource_group`
    )
      .then(({ body }) => {
        dispatch(consumePayload(body, MeetingResourceGroup));
        dispatch(SET_MEETING_RESOURCE_GROUP_ID(body.id));
        return body;
      })
      .catch(error => {
        // This endpoint returning a 404 indicates that the current customer doesn't have an
        // existing customer meeting resource group which is an expected condition.
        if (error.res.status !== 404) {
          throw error;
        }
      });
  },
  REPORT_ERROR
);

export const setMeetingResourceGroup = wrapPromiseToThunk(
  SET_MEETING_RESOURCE_GROUP,
  ({ dispatch }, meetingResourceIds) => {
    return fetchApi("/meeting_resource_groups/actions/update_or_create", {
      method: "post",
      json: { meeting_resource_ids: meetingResourceIds }
    }).then(({ body }) => {
      dispatch(consumePayload(body, MeetingResourceGroup));
      dispatch(SET_MEETING_RESOURCE_GROUP_ID(body.id));
      return body;
    });
  },
  REPORT_ERROR
);

export const fetchConnectedEmailAddress = wrapPromiseToThunk(
  FETCH_CONNECTED_EMAIL_ADDRESS,
  ({ dispatch }) =>
    fetchApi("/endo/meeting_resources/status").then(({ body }) => {
      dispatch(SET_CONNECTED_EMAIL_ADDRESS(body.connected_email_address));
    }),
  REPORT_ERROR
);

const fetchGroups = combineReducers({
  fetchConnectedEmailAddress: handleFetchGroupActions(
    FETCH_CONNECTED_EMAIL_ADDRESS
  ),
  fetchConferenceRooms: handleFetchGroupActions(FETCH_CONFERENCE_ROOMS),
  fetchMeetingResourceGroup: handleFetchGroupActions(
    FETCH_MEETING_RESOURCE_GROUP
  ),
  setMeetingResourceGroup: handleFetchGroupActions(SET_MEETING_RESOURCE_GROUP)
});

const connectedEmailAddress = handleActions(
  {
    // TODO(gavin): `redux-actions` omits `payload` if `null` or `undefined` is passed to the action
    // creator. This can be removed once we upgrade to v1.2.1 or higher which resolves this issue.
    [SET_CONNECTED_EMAIL_ADDRESS]: (_, action) => action.payload || null
  },
  null
);

const availableResourceIds = handleActions(
  {
    [FETCH_CONFERENCE_ROOMS]: {
      begin: () => [],
      next: (_, action) => action.payload,
      throw: () => []
    },
    [ADD_SCOPE_SYNC_RESOURCES]: {
      begin: () => [],
      next: (_, action) => action.payload.map(sc => sc.id),
      throw: () => []
    }
  },
  []
);

const meetingResourceGroupId = handleActions(
  {
    [SET_MEETING_RESOURCE_GROUP_ID]: (_, action) => action.payload
  },
  null
);

export const reducer = combineReducers({
  fetchGroups,
  connectedEmailAddress,
  availableResourceIds,
  meetingResourceGroupId
});

export const getConnectedEmailAddress = state =>
  state[MOUNT_POINT].connectedEmailAddress;

const comparator = (a, b) => {
  if (a.name < b.name) return -1;
  else if (a.name > b.name) return 1;
  else return 0;
};

export const getAvailableResources = state =>
  expand(
    SyncedCalendar,
    state[MOUNT_POINT].availableResourceIds,
    {},
    state
  ).sort(comparator);

export const getAllConferenceRooms = state =>
  getAvailableResources(state).filter(resource => resource.is_conference_room);

export const getMeetingResourceGroup = state => {
  if (!state[MOUNT_POINT].meetingResourceGroupId) return null;

  const meetingResourceGroup = expand(
    MeetingResourceGroup,
    state[MOUNT_POINT].meetingResourceGroupId,
    { meeting_resources: {} },
    state
  );

  return {
    ...meetingResourceGroup,
    meeting_resources: meetingResourceGroup.meeting_resources.sort(comparator)
  };
};

export const getIsLoading = state =>
  state[MOUNT_POINT].fetchGroups.fetchConnectedEmailAddress.isFetching ||
  state[MOUNT_POINT].fetchGroups.fetchConferenceRooms.isFetching ||
  state[MOUNT_POINT].fetchGroups.fetchMeetingResourceGroup.isFetching;

export const getIsSettingMeetingResourceGroup = state =>
  state[MOUNT_POINT].fetchGroups.setMeetingResourceGroup.isFetching;
