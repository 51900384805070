/** @format */

/* palettes for library-items */
const palette = [
  "E6E7F9",
  "E5F1FC",
  "FEF7E6",
  "FFECF8",
  "E5FCEE",
  "FFF2F0",
];

const darkPallete = [
  "585C9F",
  "6197C3",
  "EBBE42",
  "EF97D0",
  "58BC82",
  "FF4B30",
]

const hashCode = (input) => {
  let hash = 0;

  if (!input || input.length === 0) {
    return hash;
  }

  for (let i = 0; i < input.length; i++) {
    let char = input.charCodeAt(i);
    hash = ((hash << 5) - hash) + char;
    hash = hash & hash;
  }

  return hash;
}

export const generateHashColor = (name, dark) => {
  let hash = Math.abs(hashCode(name));
  let index = hash % palette.length;

  if (dark) {
    return "#" + darkPallete[index]
  }

  return "#" + palette[index];
}


export const generateTextColor = (name) => {
  let c = generateHashColor(name).substring(1)

  let rgb = parseInt(c, 16);   // convert rrggbb to decimal
  let r = (rgb >> 16) & 0xff;  // extract red
  let g = (rgb >>  8) & 0xff;  // extract green
  let b = (rgb >>  0) & 0xff;  // extract blue

  let luma = 0.2126 * r + 0.7152 * g + 0.0722 * b; // per ITU-R BT.709

  // colors 0 - 125 should have light text
  // colors 125 - 255 should have dark text
  return luma < 125 ? "#FFFFFF" : "#757575";
}

/* other utilities */

// returns the initials of a name
export const extractInitials = (speaker) => {
  if (!speaker) {
      return ""
  }

  var names = speaker.split(" ");
  if (names.length >= 2) {
    return names[0][0].toUpperCase() + names[names.length - 1][0].toUpperCase()
  } else if (names.length === 1) {
    return names[0][0].toUpperCase()
  }
}

// returns object's size
export const size = (object) => {
  let objSize = 0;
  let key;
  for (key in object) {
      if (object.hasOwnProperty(key)) objSize++;
  }
  return objSize;
}

// returns the key associated with a value in a an object
export const getKey = (object, value) => {
  return Object.keys(object).find(k => object[k] === value);
}

// returns the participant from a set of participant associated with an email address
export const getParticipant = (key, participants) => {
  for (var i = 0; i < participants.length; i++) {
    var p = participants[i];
    if (p.email === key) {
      return p
    }
  }
  return null
}
