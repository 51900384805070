/** @format */
import PropTypes from "prop-types";

import React, { Component } from "react";
import { Button, TextField, CheckboxGroup, CheckboxWithLabel } from "vogue";
import EmailAddressUtils from "../../../utils/email-address";
import FlexLoader from "../shared/flex-loader.react";

export default class ReferralWidget extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: "",
      emailAddresses: [],
      error: false,
      helperText: ""
    };

    this._onChange = this._onChange.bind(this);
    this._onCheck = this._onCheck.bind(this);
    this._onAdd = this._onAdd.bind(this);
    this._onSubmit = this._onSubmit.bind(this);
  }

  _onChange(e) {
    this.setState({
      value: e.target.value
    });
  }

  _onAdd() {
    const { value, emailAddresses } = this.state;
    if (EmailAddressUtils.isValid(value)) {
      this.setState({
        value: "",
        emailAddresses: [value, ...emailAddresses],
        error: false,
        helperText: ""
      });
    } else {
      this.setState({
        error: true,
        helperText: "Invalid email address"
      });
    }
  }

  _onSubmit() {
    this.props.onSubmitReferrals(this.state.emailAddresses).then(() => {
      this.setState({
        value: "",
        emailAddresses: [],
        error: false,
        helperText: "Invitations sent!"
      });
    });
  }

  _onCheck(newValues) {
    this.setState({
      emailAddresses: newValues
    });
  }

  render() {
    if (this.props.referralFetching) {
      return <FlexLoader />;
    }

    const { value, error, helperText, emailAddresses } = this.state;

    const selections = emailAddresses.map(ea => (
      <CheckboxWithLabel value={ea} key={ea}>
        <span className="ReferralWidget-email">{ea}</span>
      </CheckboxWithLabel>
    ));

    const suggestions = this.props.suggestions
      .filter(suggestion => emailAddresses.indexOf(suggestion.email) === -1)
      .map(({ email, meeting_count, first_name }) => (
        <CheckboxWithLabel value={email} key={email}>
          <span className="ReferralWidget-email">
            {email}
            <a
              href="#"
              className="ReferralWidget-tooltip tooltip tooltip--top tooltip--large"
              aria-label={`You've scheduled ${meeting_count} meetings with ${first_name}!`}
            >
              <label className="ReferralWidget-tooltipTag">Suggestion</label>
            </a>
          </span>
        </CheckboxWithLabel>
      ));

    let selectionsBox;
    if (selections.length || suggestions.length) {
      selectionsBox = (
        <div className="ReferralWidget-selections">
          <CheckboxGroup
            values={this.state.emailAddresses}
            onChange={this._onCheck}
          >
            {selections}
            {suggestions}
          </CheckboxGroup>
        </div>
      );
    }

    return (
      <div className="ReferralWidget">
        <div className="ReferralWidget-addGroup">
          <TextField
            className="ReferralWidget-input"
            modifiers={["hidesLabel"]}
            value={value}
            onChange={this._onChange}
            onEnterKeyPressed={this._onAdd}
            placeholder="friend@company.com"
            spellCheck={false}
            error={error}
            helperText={helperText}
          />
          <Button
            className="ReferralWidget-add Button Button--secondary"
            onClick={this._onAdd}
          >
            Add
          </Button>
        </div>
        {selectionsBox}
        <Button
          className="ReferralWidget-submit"
          onClick={this._onSubmit}
          disabled={!emailAddresses.length}
        >
          Refer
        </Button>
      </div>
    );
  }
}

ReferralWidget.propTypes = {
  onSubmitReferrals: PropTypes.func.isRequired,
  referralFetching: PropTypes.bool.isRequired,
  suggestions: PropTypes.arrayOf(PropTypes.object)
};
