/** @format */
export const MEETING_DURATIONS = [
  { label: "5 min", value: "5" },
  { label: "10 min", value: "10" },
  { label: "15 min", value: "15" },
  { label: "20 min", value: "20" },
  { label: "25 min", value: "25" },
  { label: "30 min", value: "30" },
  { label: "35 min", value: "35" },
  { label: "40 min", value: "40" },
  { label: "45 min", value: "45" },
  { label: "50 min", value: "50" },
  { label: "55 min", value: "55" },
  { label: "1 hr", value: "60" },
  { label: "1 hr 15 min", value: "75" },
  { label: "1 hr 30 min", value: "90" },
  { label: "1 hr 45 min", value: "105" },
  { label: "2 hr", value: "120" }
];

export const BUFFER_DURATIONS = [
  { label: "0 min", value: "0" },
  { label: "5 min", value: "5" },
  { label: "10 min", value: "10" },
  { label: "15 min", value: "15" },
  { label: "20 min", value: "20" },
  { label: "25 min", value: "25" },
  { label: "30 min", value: "30" },
  { label: "45 min", value: "45" },
  { label: "60 min", value: "60" }
];

export const valueToDurationLabel = number => {
  // MEETING_DURATIONS doesn't have an entry for 0.
  if (number === 0) {
    return "0 min";
  } else {
    const duration = MEETING_DURATIONS.find(
      ({ value }) => value === String(number)
    );
    return duration.label;
  }
};

export const toHHMMSS = string => {
  let sec_num = parseInt(string, 10);
  let hours  = Math.floor(sec_num / 3600);
  let minutes = Math.floor((sec_num - (hours * 3600)) / 60);
  let seconds = sec_num - (hours * 3600) - (minutes * 60);

  let Ohours = hours + '';
  let Ominutes = minutes + '';
  let Oseconds = seconds + '';
  
  if (minutes < 10) {
      Ominutes = "0" + minutes;
  }
  if (seconds < 10) {
      Oseconds = "0" + seconds;
  }
  var time = Ohours + ':' + Ominutes + ':' + Oseconds;
  if (hours === 0){
    time = Ominutes + ':' +Oseconds;
  }
  return time;
};