/** @format */
import PropTypes from "prop-types";

import React from "react";
import { CheckboxGroup, CheckboxWithLabel } from "vogue";

import { ResourceSyncedCalendarShape } from "../../../../utils/shapes";

const AvailableResourcesList = ({
  availableResources,
  onChange,
  selectedResourceIds
}) => (
  <div className="AvailableResourcesList">
    <CheckboxGroup values={selectedResourceIds} onChange={onChange}>
      {availableResources.map(({ description, name, provider_id }) => (
        <div className="AvailableResourcesList-item" key={provider_id}>
          <CheckboxWithLabel value={provider_id}>
            <div className="AvailableResourcesList-name">{name}</div>
            <div className="AvailableResourcesList-description">
              {description}
            </div>
          </CheckboxWithLabel>
        </div>
      ))}
    </CheckboxGroup>
  </div>
);

AvailableResourcesList.propTypes = {
  availableResources: PropTypes.arrayOf(ResourceSyncedCalendarShape).isRequired,
  onChange: PropTypes.func.isRequired,
  selectedResourceIds: PropTypes.arrayOf(PropTypes.string).isRequired
};

export default AvailableResourcesList;
