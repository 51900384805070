/** @format */
import PropTypes from "prop-types";

import React, { Component } from "react";
import { Utils } from "vogue";
const RenderingUtils = Utils.Rendering;
import classNames from "classnames";

class SelectMenu extends Component {
  UNSAFE_componentWillMount() {
    this.backupName = RenderingUtils.uniqueId();
  }

  render() {
    const { name = this.backupName, options, value, onChange } = this.props;

    const optionsList = options.map(opt => (
      <option
        key={opt.value}
        value={opt.value}
        disabled={opt.disabled ? "disabled" : ""}
      >
        {opt.label}
      </option>
    ));

    const selectClassNames = classNames("SelectMenu", this.props.className, {
      "SelectMenu--noUnderline": this.props.noUnderline,
      "SelectMenu--large": this.props.isLarge
    });

    const selectInputClassNames = classNames("SelectMenu-input", {
      "SelectMenu-input--error": this.props.error
    });

    const selectCaretClassNames = classNames("SelectMenu-caret", {
      "SelectMenu-caret--error": this.props.error
    });

    return (
      <div className={selectClassNames}>
        <select
          className={selectInputClassNames}
          name={name}
          value={value}
          onChange={onChange}
          disabled={this.props.disabled}
        >
          {optionsList}
        </select>
        <div className={selectCaretClassNames} />
      </div>
    );
  }
}
SelectMenu.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      disabled: PropTypes.bool
    })
  ),
  className: PropTypes.string,
  disabled: PropTypes.bool,
  noUnderline: PropTypes.bool,
  isLarge: PropTypes.bool,
  error: PropTypes.bool
};

export default SelectMenu;
