/** @format */
import PropTypes from "prop-types";
import React, { useState } from "react";
import { CustomerShape } from "../../utils/shapes";
import SettingsBanner from "./settings-banner.react";
import SettingsBannerAdmin from "./settings-banner-admin.react";

export const SettingsMenu = ({ customer, customerIsAdmin, children }) => {
  const PATHNAME_TO_TEXT = {
    "/settings/calendar-planning": "Calendar Planning",
    "/settings/meeting-details": "Meeting Details",
    "/settings/claras-emails": "Clara's Emails",
    "/settings/profile": "Profile",
    "/settings/team": "Team",
    "/settings/integrations": "Integrations",
    "/settings/billing": "Billing",
    "/settings/referral": "Billing",
    "/settings/special-features": "Special Features",
    "/settings/google-meet": "Integrations",
    "/settings/greenhouse": "Integrations",
    "/settings/gsuite": "Integrations",
    "/settings/lever": "Integrations",
    "/settings/icims": "Integrations",
    "/settings/coderpad": "Integrations",
    "/settings/hackerrank": "Integrations",
    "/settings/slack": "Integrations",
    "/settings/zoom": "Integrations",
    "/settings/microsoft-teams": "Integrations"
  }

  const [selectedSubTab, setSelectedSubTab] = useState(PATHNAME_TO_TEXT[window.location.pathname])
  
  const renderSettingsBanner = () => {
    if (customerIsAdmin) {
      return <SettingsBannerAdmin selected={selectedSubTab} onClick={setSelectedSubTab} />
    }

    return <SettingsBanner selected={selectedSubTab} onClick={setSelectedSubTab} />
  }

  return (
    <div>
      {renderSettingsBanner()}
      {React.Children.map(children, child => (
        React.isValidElement(child) ? React.cloneElement(child, { ...child.props, customer }) : child
      ))}
    </div>
  );
}

SettingsMenu.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.node
  ]).isRequired,
  customer: CustomerShape
};

export default SettingsMenu;
