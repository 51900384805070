/** @format */
import { handleActions } from "../../utils/redux-actions";

/**
 * Simple class for coordinating multiple data fetches inside one module. Both public methods return
 * a new instance of the class that captures the new state.
 *
 * @param hasStartedFetching - Boolean indicating if at least one fetch has been started.
 * @param fetchesInProgress - Number of fetches that are in progress.
 * @param initiallyLoading - Whether the fetch group should initially display as loading
 */
export function FetchGroup(
  hasStartedFetching,
  fetchesInProgress,
  initiallyLoading
) {
  this._hasStartedFetching = hasStartedFetching;
  this._fetchesInProgress = fetchesInProgress;
  if (initiallyLoading) {
    this.isFetching = !hasStartedFetching || fetchesInProgress > 0;
  } else {
    this.isFetching = hasStartedFetching && fetchesInProgress > 0;
  }
}

FetchGroup.prototype = {
  /** True if there are outstanding fetches in this group, false if all fetches are complete. */
  isFetching: false,

  /** Begin a new fetch in this group. */
  startFetch: function() {
    return new FetchGroup(true, this._fetchesInProgress + 1);
  },

  /** Complete a fetch in this group. */
  completeFetch: function() {
    if (!this._hasStartedFetching) {
      throw new Error("Attempted to complete fetch but have been started.");
    }

    if (this._fetchesInProgress < 1) {
      throw new Error("Attempted to complete fetch but none were in progress.");
    }

    return new FetchGroup(true, this._fetchesInProgress - 1);
  }
};

export default function fetchGroup(initiallyLoading = false) {
  return new FetchGroup(false, 0, initiallyLoading);
}

export function handleFetchGroupActions(action, initiallyLoading = false) {
  return handleActions(
    {
      [action]: {
        begin: state => state.startFetch(),
        next: state => state.completeFetch(),
        throw: state => state.completeFetch()
      }
    },
    fetchGroup(initiallyLoading)
  );
}
