/** @format */
import PropTypes from "prop-types";

import React, { Component } from "react";
import classNames from "classnames";
import { Loader } from "vogue";
import SelectMenu from "./select-menu.react";
import { connect } from "react-redux";
import { getCurrentCustomer } from "../../modules/session";
import {
  getInflightAttributes as getPreferenceInflightAttributes,
  updatePreferenceAttribute
} from "../../modules/preferences";
import {
  getInflightAttributes as getMeetingTypeInflightAttributes,
  updateMeetingTypeAttribute
} from "../../modules/meeting-types";

// Value to mark a select option as having a null value.
export const UNSET = "__UNSET_SELECT_OPTION__";

export class SelectAttribute extends Component {
  constructor(props) {
    super(props);

    this.timeout = null;

    this.state = {
      selectedOption: props.selectedOption,
      loaderAnimating: false,
      error: false
    };

    this._onSelect = this._onSelect.bind(this);
    this._onFinishAnimating = this._onFinishAnimating.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!nextProps.isLoading) {
      if (this.props.isLoading) {
        this.setState({ loaderAnimating: true });
      }
      if (nextProps.selectedOption !== this.state.selectedOption) {
        this.setState({ selectedOption: nextProps.selectedOption });
      }
    }
  }

  _onSelect({ target }) {
    const value = target.value === UNSET ? null : target.value;
    const updated = this.props.setter(value);
    this.setState({
      selectedOption: updated
    });

    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.props.onSelect(updated).catch(() => {
        this.setState({ error: true });
      });
    }, 400);
  }

  _onFinishAnimating() {
    this.setState({
      loaderAnimating: false,
      error: false
    });
  }

  render() {
    let loader;
    if (this.props.isLoading || this.state.loaderAnimating) {
      loader = (
        <Loader
          animating={this.state.loaderAnimating}
          onFinishAnimating={this._onFinishAnimating}
          error={this.state.error}
        />
      );
    }

    const selectAttributeClasses = classNames(
      "SelectAttribute",
      this.props.className,
      {
        "SelectAttribute--loading":
          this.props.isLoading || this.state.loaderAnimating
      }
    );

    let caption;
    if (this.state.selectedOption) {
      const selected = this.props.options.filter(
        // eslint-disable-next-line eqeqeq
        o => o.value == this.props.getter(this.state.selectedOption)
      )[0];
      if (selected && selected.caption) {
        caption = (
          <div className="SelectAttribute-caption">{selected.caption}</div>
        );
      }
    }

    return (
      <div className={selectAttributeClasses}>
        <div className="SelectAttribute-container">
          <SelectMenu
            disabled={this.props.isLoading}
            className="SelectAttribute-select"
            options={this.props.options}
            onChange={this._onSelect}
            value={this.props.getter(this.state.selectedOption)}
            noUnderline={true}
            isLarge={this.props.isLarge}
          />
          {loader}
        </div>
        {caption}
      </div>
    );
  }
}
SelectAttribute.propTypes = {
  className: PropTypes.string,
  attribute: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      caption: PropTypes.string
    })
  ).isRequired,
  selectedOption: PropTypes.string.isRequired,
  getter: PropTypes.func,
  setter: PropTypes.func,
  isLarge: PropTypes.bool,
  isLoading: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired,
  meetingTypeId: PropTypes.number
};
SelectAttribute.defaultProps = {
  getter: x => x,
  setter: x => x
};

const mapPreferenceStateToProps = (state, ownProps) => {
  const customer = getCurrentCustomer(state);
  return {
    isLoading:
      !customer ||
      getPreferenceInflightAttributes(state).includes(ownProps.attribute)
  };
};

const mapPreferenceDispatchToProps = (dispatch, ownProps) => ({
  onSelect(option) {
    return dispatch(updatePreferenceAttribute(ownProps.attribute, option));
  }
});

const mapMeetingTypeStateToProps = (state, ownProps) => {
  const customer = getCurrentCustomer(state);
  return {
    isLoading:
      !customer ||
      getMeetingTypeInflightAttributes(state, ownProps.meetingTypeId).includes(
        ownProps.attribute
      )
  };
};

const mapMeetingTypeDispatchToProps = (dispatch, ownProps) => ({
  onSelect(option) {
    return dispatch(
      updateMeetingTypeAttribute(
        ownProps.meetingTypeId,
        ownProps.attribute,
        option
      )
    );
  }
});

export const SelectPreference = connect(
  mapPreferenceStateToProps,
  mapPreferenceDispatchToProps
)(SelectAttribute);

export const SelectMeetingType = connect(
  mapMeetingTypeStateToProps,
  mapMeetingTypeDispatchToProps
)(SelectAttribute);
