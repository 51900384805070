/** @format */
import PropTypes from "prop-types";

import React from "react";

import { ContentBlock } from "../layout/block.react";
import Section from "../layout/section.react";
import IntegrationDetail from "../dashboard/integrations/integration-detail.react";
import ErrorMessage from "./error-message.react";

const Integrations = (props) => {
  const renderIndex = () => {
    const description =
      "Clara works with the systems your team uses to track information and connect.";

    return (
      <div className="Settings">
        <div className="Settings-sectionContainer">
        <div className="Settings-sectionContainerDetails">
          <div className="Settings-sectionContainerTitle">Integrations</div>
          <div className="Settings-sectionContainerSubtitle">{description}</div>
        </div>
        <Section>
          <div className="row">
            <div className="col-xs-12 col-sm-6">
              <ContentBlock
                title="Video conferencing"
                subtitle="Have Clara add video conference information to calendar invitations. "
                className="Integrations-blockContainer"
                subClassName="Integrations-block"
                bordered={true}
              >
                <IntegrationDetail
                  name="Google Meet"
                  href="/settings/google-meet"
                  logo="google-meet-logo"
                  {...props.googleMeet}
                />
                <IntegrationDetail
                  name="Microsoft Teams"
                  href="/settings/microsoft-teams"
                  logo="microsoft-teams-logo"
                  {...props.microsoftTeams}
                />
                <IntegrationDetail
                  name="Zoom"
                  href="/settings/zoom"
                  logo="zoom-logo"
                  {...props.zoom}
                />
                <IntegrationDetail
                  name="CoderPad"
                  href="/settings/coderpad"
                  logo="coderpad-logo"
                  {...props.coderpad}
                />
                <IntegrationDetail
                  name="HackerRank"
                  href="/settings/hackerrank"
                  logo="hackerrank-logo"
                  {...props.hackerrank}
                />
              </ContentBlock>
            </div>
            <div className="col-xs-12 col-sm-6">
              <ContentBlock
                title="Applicant tracking systems"
                subtitle="Get scheduling support from Clara in your ATS workflow."
                className="Integrations-blockContainer"
                subClassName="Integrations-block"
                bordered={true}
              >
                <IntegrationDetail
                  name="Greenhouse"
                  href="/settings/greenhouse"
                  logo="greenhouse-logo"
                  {...props.greenhouse}
                />
                <IntegrationDetail
                  name="Lever"
                  href="/settings/lever"
                  logo="lever-logo"
                  {...props.lever}
                />
                <IntegrationDetail
                  name="iCIMS"
                  href="/settings/icims"
                  logo="icims-i-logo"
                  {...props.icims}
                />
              </ContentBlock>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 col-sm-6">
              <ContentBlock
                title="Conference rooms"
                subtitle="Clara can reserve a conference room when you need one."
                className="Integrations-blockContainer"
                subClassName="Integrations-block"
                bordered={true}
              >
                <IntegrationDetail
                  name="G Suite"
                  href="/settings/gsuite"
                  logo="gsuite-logo"
                  {...props.gsuite}
                />
              </ContentBlock>
            </div>
          </div>
          { props.liveFlag && props.abilitiesFlag &&
            <div className="row">
              <div className="col-xs-12 col-sm-6">
                <ContentBlock
                  title="Workspace management"
                  subtitle="Let Clara handle the busy-work for you."
                  className="Integrations-blockContainer"
                  subClassName="Integrations-block"
                  bordered={true}
                >
                  <IntegrationDetail
                    name="Slack"
                    href="/settings/slack"
                    logo="slack-logo"
                    {...props.slack}
                  />
                </ContentBlock>
              </div>
            </div>
          }
        </Section>
        </div>
      </div>
    )
  }

  if (props.customer.roles.includes("ADMIN")) {
    return (
      renderIndex()
    );
  } else {
    return (
      <div className="LibraryContent-errorMessage">
        <ErrorMessage message="Only organization admins can view or edit integrations." />
      </div>
    )
  }
};

const IntegrationStateShape = PropTypes.shape({
  isEnabled: PropTypes.bool.isRequired,
  isValid: PropTypes.bool,
});

Integrations.propTypes = {
  abilitiesFlag: PropTypes.bool,
  liveFlag: PropTypes.bool,
  googleMeet: IntegrationStateShape.isRequired,
  microsoftTeams: IntegrationStateShape.isRequired,
  greenhouse: IntegrationStateShape.isRequired,
  gsuite: IntegrationStateShape.isRequired,
  lever: IntegrationStateShape.isRequired,
  icims: IntegrationStateShape.isRequired,
  zoom: IntegrationStateShape.isRequired,
  slack: IntegrationStateShape.isRequired
};

export default Integrations;
