/** @format */
import PropTypes from "prop-types";

import React, { Component } from "react";
import { CheckboxGroup, CheckboxWithLabel } from "vogue";

import { ContactSyncedCalendarShape } from "../../utils/shapes";
import TextButton from "./text-button.react";

const nameComparator = (a, b) => {
  const aName = a.synced_calendar.name;
  const bName = b.synced_calendar.name;
  if (aName === bName) {
    return 0;
  }
  if (aName < bName) {
    return -1;
  }
  return 1;
};

// Sort calendars in descending order of importance: owner, writer, reader, name.

const accessComparator = (a, b) => {
  if (a.access === b.access) {
    return nameComparator(a, b);
  }

  if (
    a.access === "owner" ||
    (a.access === "writer" && b.access === "reader")
  ) {
    return -1;
  }
  return 1;
};

const informsAvailabilityComparator = (a, b) => {
  if (a.informs_availability === b.informs_availability) {
    return nameComparator(a, b);
  }
  if (a.informs_availability) {
    return -1;
  }
  return 1;
};

const CalendarCheckbox = ({ contactSyncedCalendar }) => (
  <div className="CalendarList-formGroup">
    <CheckboxWithLabel value={contactSyncedCalendar.id}>
      <span className="CalendarList-formLabel">
        {contactSyncedCalendar.synced_calendar.name}
        {contactSyncedCalendar.primary ? " (default)" : null}
      </span>
    </CheckboxWithLabel>
  </div>
);

CalendarCheckbox.propTypes = {
  contactSyncedCalendar: ContactSyncedCalendarShape.isRequired
};

export default class CalendarList extends Component {
  constructor(props) {
    super(props);
    this.state = { expanded: false };
  }

  render() {
    const { contactSyncedCalendars, checkedIds, onChange } = this.props;
    const { expanded } = this.state;

    let displayedCalendars;
    if (expanded) {
      displayedCalendars = contactSyncedCalendars.sort(accessComparator);
    } else {
      displayedCalendars = contactSyncedCalendars
        .filter(c => c.informs_availability || c.access === "owner")
        .sort(informsAvailabilityComparator);
    }
    const numHidden = contactSyncedCalendars.length - displayedCalendars.length;

    let expandLink;
    if (!expanded && numHidden > 0) {
      expandLink = (
        <TextButton
          className="CalendarList-expandLink"
          onClick={() => this.setState({ expanded: true })}
        >
          Show&nbsp;{numHidden}&nbsp;more calendars
        </TextButton>
      );
    }

    return (
      <CheckboxGroup
        values={checkedIds}
        onChange={onChange}
        className="CalendarList"
      >
        {displayedCalendars.map(c => (
          <CalendarCheckbox key={c.id} contactSyncedCalendar={c} />
        ))}
        {expandLink}
      </CheckboxGroup>
    );
  }
}

CalendarList.propTypes = {
  checkedIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  contactSyncedCalendars: PropTypes.arrayOf(ContactSyncedCalendarShape)
    .isRequired,
  onChange: PropTypes.func.isRequired
};
