/** @format */
import PropTypes from "prop-types";

import React from "react";
import { connect } from "react-redux";
import { Icon } from "vogue";

import { DISMISS_ERROR, getErrorDetails } from "../../modules/error";
import config from "../../../config";

export const GenericError = ({ error, requestId, message, dismissError }) => {
  if (!error) {
    return null;
  }

  let optionalRequestId;
  if (requestId) {
    optionalRequestId = <div>Request ID: {requestId}</div>;
  }

  /* eslint-disable max-len */
  return (
    <div className="GenericError">
      <div className="GenericError-message">
        {config.env === "production" ?
          <div>Something went wrong.</div>
          :
          <div>{message}</div>
        }
        <div>
          Please try again or email{" "}
          <a className="GenericError-link" href="mailto:support@claralabs.com">
            support@claralabs.com
          </a>
        </div>
        <aside>{optionalRequestId}</aside>
      </div>
      <Icon
        name="close"
        className="GenericError-close"
        onClick={dismissError}
      />
    </div>
  );
  /* eslint-enable max-len */
};

GenericError.propTypes = {
  error: PropTypes.object,
  message: PropTypes.string,
  requestId: PropTypes.string,
  dismissError: PropTypes.func.isRequired
};

GenericError.defaultProps = {
  message: "An error occurred"
};

const mapDispatchToProps = dispatch => ({
  dismissError: () => {
    dispatch(DISMISS_ERROR());
  }
});

export default connect(
  getErrorDetails,
  mapDispatchToProps
)(GenericError);
