/** @format */
import { combineReducers } from "redux";
import { createAction } from "redux-actions";
import {
  createAsyncAction,
  wrapPromiseToThunk,
  handleActions
} from "../../utils/redux-actions";
import retryable from "../../utils/retryable";
import { fetchApi } from "../../utils/request";
import { handleFetchGroupActions } from "../utils/fetch-group";
import {
  consumePayload,
  getIfFetched,
  getIsUnfetched
} from "../../collections/module";
import {
  Contact,
  GoogleAccount,
  MicrosoftAccount
} from "../../collections/schema";
import { REPORT_ERROR } from "./error";

export const MOUNT_POINT = "connectedCalendars";

const FETCH_CALENDARS = createAsyncAction(
  "CONNECTED_CALENDARS/FETCH_CALENDARS"
);
const FETCH_ACCOUNT_CALENDARS = createAsyncAction(
  "CONNECTED_CALENDARS/FETCH_ACCOUNT_CALENDARS"
);
const FETCH_EVENT_CALENDAR_OPTIONS = createAsyncAction(
  "CONNECTED_CALENDARS/FETCH_EVENT_CALENDAR_OPTIONS"
);
const SET_CALENDARS = createAsyncAction("CONNECTED_CALENDARS/SET_CALENDARS");
const SET_EVENT_CALENDAR = createAsyncAction(
  "CONNECTED_CALENDARS/SET_EVENT_CALENDAR"
);
const SET_EVENT_CALENDAR_OPTIONS = createAction(
  "CONNECETED_CALENDARS/SET_EVENT_CALENDAR_OPTIONS"
);
const UNSET_EVENT_CALENDAR = createAsyncAction(
  "CONNECTED_CALENDARS/UNSET_EVENT_CALENDAR"
);

const ACCOUNT_EXPANSIONS = {
  contact_synced_calendars: {
    synced_calendar: {}
  }
};

const EXPANSIONS = {
  google_accounts: ACCOUNT_EXPANSIONS,
  microsoft_accounts: ACCOUNT_EXPANSIONS
};

export const fetchCalendars = wrapPromiseToThunk(
  FETCH_CALENDARS,
  ({ dispatch, getState }, contactId = null) => {
    if (
      !contactId ||
      getIsUnfetched(Contact, contactId, EXPANSIONS, getState())
    ) {
      return retryable(
        () => {
          return fetchApi("/endo/calendars");
        },
        { maxAttempts: 30, onStatusCodes: [202, 503] }
      ).then(({ body }) => {
        dispatch(consumePayload(body, Contact));
      });
    } else {
      return new Promise(resolve => resolve());
    }
  },
  null,
  false
);

export const fetchAccountCalendars = wrapPromiseToThunk(
  FETCH_ACCOUNT_CALENDARS,
  (
    { dispatch, getState },
    microsoftAccountId = null,
    googleAccountId = null
  ) => {
    let id;
    let resource;
    let fetchUrl;
    if (googleAccountId) {
      id = googleAccountId;
      resource = GoogleAccount;
      fetchUrl = `/endo/calendars/google_accounts/${id}`;
    } else {
      id = microsoftAccountId;
      resource = MicrosoftAccount;
      fetchUrl = `/endo/calendars/microsoft_accounts/${id}`;
    }

    if (getIsUnfetched(resource, id, ACCOUNT_EXPANSIONS, getState())) {
      return retryable(
        () => {
          return fetchApi(fetchUrl);
        },
        { maxAttempts: 30, onStatusCodes: [202, 503] }
      ).then(({ body }) => {
        dispatch(consumePayload(body, resource));
      });
    } else {
      return new Promise(resolve => resolve());
    }
  },
  null,
  false
);

export const fetchEventCalendarOptions = wrapPromiseToThunk(
  FETCH_EVENT_CALENDAR_OPTIONS,
  ({ dispatch }) => {
    return retryable(
      () => {
        return fetchApi("/endo/calendars/event_calendar_options");
      },
      { maxAttempts: 30, onStatusCodes: [202, 503] }
    ).then(({ body }) => {
      dispatch(SET_EVENT_CALENDAR_OPTIONS(body.options));
    });
  },
  REPORT_ERROR
);

export const setCalendars = wrapPromiseToThunk(
  SET_CALENDARS,
  (
    { dispatch },
    contactSyncedCalendarIds,
    microsoftAccountId = null,
    googleAccountId = null
  ) => {
    let url;
    let resource;
    if (microsoftAccountId) {
      url = `/endo/calendars/microsoft_accounts/${microsoftAccountId}`;
      resource = MicrosoftAccount;
    } else {
      url = `/endo/calendars/google_accounts/${googleAccountId}`;
      resource = GoogleAccount;
    }

    return fetchApi(url, {
      method: "post",
      json: contactSyncedCalendarIds
    }).then(({ body }) => {
      dispatch(consumePayload(body, resource));
    });
  },
  REPORT_ERROR
);

export const getEventCalendarOptions = state =>
  state[MOUNT_POINT].eventCalendarOptions;

export const setEventCalendar = wrapPromiseToThunk(
  SET_EVENT_CALENDAR,
  ({ dispatch, getState }, syncedCalendarId) => {
    return fetchApi("/endo/calendars/event_calendar", {
      method: "post",
      json: { synced_calendar_id: syncedCalendarId }
    }).then(() => {
      const state = getState();
      const newOptions = getEventCalendarOptions(state).map(o => ({
        ...o,
        selected: o.id === syncedCalendarId
      }));
      dispatch(SET_EVENT_CALENDAR_OPTIONS(newOptions));
    });
  },
  REPORT_ERROR
);

export const unsetEventCalendar = wrapPromiseToThunk(
  UNSET_EVENT_CALENDAR,
  ({ dispatch, getState }) => {
    return fetchApi("/endo/calendars/event_calendar", {
      method: "delete"
    }).then(() => {
      const state = getState();
      const newOptions = getEventCalendarOptions(state).map(o => ({
        ...o,
        selected: false
      }));
      dispatch(SET_EVENT_CALENDAR_OPTIONS(newOptions));
    });
  },
  REPORT_ERROR
);

const fetchGroups = combineReducers({
  fetchCalendars: handleFetchGroupActions(FETCH_CALENDARS),
  fetchEventCalendarOptions: handleFetchGroupActions(
    FETCH_EVENT_CALENDAR_OPTIONS
  ),
  setCalendars: handleFetchGroupActions(SET_CALENDARS),
  setEventCalendar: handleFetchGroupActions(SET_EVENT_CALENDAR),
  unsetEventCalendar: handleFetchGroupActions(UNSET_EVENT_CALENDAR)
});

const handleErrorState = (
  state,
  microsoftAccountId,
  googleAccountId,
  value
) => {
  if (googleAccountId) {
    return {
      ...state,
      googleAccounts: {
        ...state.googleAccounts,
        [googleAccountId]: value
      }
    };
  } else {
    return {
      ...state,
      microsoftAccounts: {
        ...state.microsoftAccounts,
        [microsoftAccountId]: value
      }
    };
  }
};

export const reducer = combineReducers({
  fetchGroups,
  eventCalendarOptions: handleActions(
    {
      [SET_EVENT_CALENDAR_OPTIONS]: (state, { payload }) => payload
    },
    null
  ),
  error: handleActions(
    {
      [FETCH_CALENDARS]: {
        begin: state => ({ ...state, global: false }),
        throw: state => ({ ...state, global: true })
      },
      [FETCH_ACCOUNT_CALENDARS]: {
        begin: (state, { payload: { microsoftAccountId, googleAccountId } }) =>
          handleErrorState(state, microsoftAccountId, googleAccountId, false),
        throw: (
          state,
          {
            meta: {
              args: [microsoftAccountId, googleAccountId]
            }
          }
        ) => {
          return handleErrorState(
            state,
            microsoftAccountId,
            googleAccountId,
            true
          );
        }
      }
    },
    {
      googleAccounts: {},
      microsoftAccounts: {},
      global: false
    }
  )
});

export const getIsError = (
  state,
  microsoftAccountId = null,
  googleAccountId = null
) => {
  const errorState = state[MOUNT_POINT].error;
  if (googleAccountId) {
    return errorState.googleAccounts[googleAccountId] || false;
  } else if (microsoftAccountId) {
    return errorState.microsoftAccounts[microsoftAccountId] || false;
  } else {
    return errorState.global || false;
  }
};

export const getContactSyncedCalendars = (
  state,
  microsoftAccountId = null,
  googleAccountId = null
) => {
  let account;
  if (googleAccountId) {
    account = getIfFetched(
      GoogleAccount,
      googleAccountId,
      ACCOUNT_EXPANSIONS,
      state
    );
  } else {
    account = getIfFetched(
      MicrosoftAccount,
      microsoftAccountId,
      ACCOUNT_EXPANSIONS,
      state
    );
  }

  return account ? account.contact_synced_calendars : null;
};

export const getIsLoading = state =>
  state[MOUNT_POINT].fetchGroups.fetchCalendars.isFetching ||
  state[MOUNT_POINT].fetchGroups.setCalendars.isFetching;

export const getAccounts = (state, customer) => {
  if (!(customer && customer.contact_id)) {
    return null;
  }

  const contact = getIfFetched(
    Contact,
    customer.contact_id,
    {
      google_accounts: {
        email_address: {},
        contact_synced_calendars: {
          synced_calendar: {}
        }
      },
      microsoft_accounts: {
        email_address: {},
        contact_synced_calendars: {
          synced_calendar: {}
        }
      }
    },
    state
  );

  return contact
    ? {
        googleAccounts: contact.google_accounts,
        microsoftAccounts: contact.microsoft_accounts
      }
    : null;
};

export const getIsUpdatingEventCalendars = state =>
  state[MOUNT_POINT].fetchGroups.setEventCalendar.isFetching ||
  state[MOUNT_POINT].fetchGroups.unsetEventCalendar.isFetching;

export const getIsFetchingEventCalendarOptions = state =>
  state[MOUNT_POINT].fetchGroups.fetchEventCalendarOptions.isFetching;
