import PropTypes from 'prop-types';
import { schema as normalizrSchema } from 'normalizr';
import * as enums from '../utils/enums';

const keyToPropTypes = {
  assistants: {
    id: PropTypes.number.isRequired,
    contact_id: PropTypes.number.isRequired,
  },
  billing_groups: {
    id: PropTypes.number.isRequired,
    billing_point_of_contact_id: PropTypes.number.isRequired,
    description: PropTypes.string.isRequired,
    is_trial: PropTypes.bool.isRequired,
    seat_type: PropTypes.oneOf(Object.values(enums.SeatType)),
    send_email_receipts: PropTypes.bool.isRequired,
    stripe_customer_id: PropTypes.string,
    treat_as_paying: PropTypes.bool.isRequired,
    trial_end_date: PropTypes.string,
  },
  companies: {
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  },
  contacts: {
    id: PropTypes.number.isRequired,
    company_id: PropTypes.number,
    first_name: PropTypes.string.isRequired,
    last_name: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  },
  customers: {
    id: PropTypes.number.isRequired,
    contact_id: PropTypes.number.isRequired,
    customer_user_id: PropTypes.number.isRequired,
    organization_id: PropTypes.number.isRequired,
    onboarded: PropTypes.bool.isRequired,
    onboarding: PropTypes.bool.isRequired,
    offboarding_start: PropTypes.string,
    roles: PropTypes.arrayOf(PropTypes.oneOf(Object.values(enums.CustomerRoles))).isRequired,
    referral_code: PropTypes.string.isRequired,
  },
  email_addresses: {
    id: PropTypes.string.isRequired,
    contact_id: PropTypes.number.isRequired,
    is_default: PropTypes.bool.isRequired,
  },
  google_accounts: {
    id: PropTypes.number.isRequired,
    contact_id: PropTypes.number.isRequired,
    email_address_id: PropTypes.string.isRequired,
    is_valid: PropTypes.bool.isRequired,
    photo_url: PropTypes.string,
  },
  keywords: {
    id: PropTypes.number.isRequired,
    contact_id: PropTypes.number,
    organization_id: PropTypes.number,
    customer_intent: PropTypes.string.isRequired,
    keyword: PropTypes.string.isRequired,
  },
  meeting_permissions: {
    id: PropTypes.number.isRequired,
    preference_id: PropTypes.number.isRequired,
    contact_id: PropTypes.number,
    domain: PropTypes.string,
    meeting_permission_kind: PropTypes.oneOf(Object.values(enums.MeetingPermissionKind)).isRequired,
  },
  organizations: {
    id: PropTypes.number.isRequired,
    billing_group_id: PropTypes.number,
    lever_api_key_last_4: PropTypes.string,
  },
  preferences: {
    id: PropTypes.number.isRequired,
    bufferTimes: PropTypes.string.isRequired,
    customer_id: PropTypes.number.isRequired,
    default_location_category: PropTypes.oneOf(Object.values(enums.DefaultLocationCategory)).isRequired,
    eventDescription: PropTypes.string.isRequired,
    followupFrequency: PropTypes.number.isRequired,
    followupMax: PropTypes.number.isRequired,
    inPersonDuration: PropTypes.number.isRequired,
    in_person_buffer: PropTypes.number,
    in_person_title: PropTypes.string.isRequired,
    send_meeting_confirmation: PropTypes.bool.isRequired,
    virtualDuration: PropTypes.number.isRequired,
    virtual_buffer: PropTypes.number,
    virtual_title: PropTypes.string.isRequired,
    wantTravel: PropTypes.string.isRequired,
    wantWeekly: PropTypes.string.isRequired,
    whenToBCC: PropTypes.string.isRequired,
    whenToCC: PropTypes.string.isRequired,
    when_to_book_conference_room: PropTypes.oneOf(Object.values(enums.WhenToBookConferenceRoom)).isRequired,
  },
};

export const generateShape = (schema, expansions = {}) => {
  const propTypes = keyToPropTypes[schema.key];
  const relatedPropTypes = Object.entries(expansions).reduce((obj, [relationship, relatedExpansions]) => {
    const relatedSchema = schema.schema[relationship];
    const isArray = relatedSchema instanceof normalizrSchema.Array;

    const relatedShape = generateShape(
      isArray ? relatedSchema.schema : relatedSchema,
      relatedExpansions,
    );

    return {
      ...obj,
      [relationship]: isArray ? PropTypes.arrayOf(relatedShape) : relatedShape,
    };
  }, {});

  return PropTypes.shape({
    ...propTypes,
    ...relatedPropTypes,
  });
};
