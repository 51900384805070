/** @format */
import {
  handleActions,
  createAsyncAction,
  wrapPromiseToThunk
} from "../../utils/redux-actions";
import { fetchApi } from "../../utils/request";
import retryable from "../../utils/retryable";
import fetchGroup from "../utils/fetch-group";
import { REPORT_ERROR } from "./error";
import { consumePayload } from "../../collections/module";
import { GoogleAccount, SyncedCalendar } from "../../collections/schema";
import { getCurrentCustomer } from "./session";
import { SET_CONNECTED_EMAIL_ADDRESS } from "./conference-rooms";

export const ADD_SCOPE_SYNC_RESOURCES = createAsyncAction(
  "CONFERENCE_ROOM_CONFIGURATION/ADD_SCOPE_SYNC_RESOURCES"
);
const UPDATE_RESOURCES = createAsyncAction(
  "CONFERENCE_ROOM_CONFIGURATION/UPDATE_RESOURCES"
);

export const addScopeAndSyncResources = wrapPromiseToThunk(
  ADD_SCOPE_SYNC_RESOURCES,
  ({ dispatch, getState }, googleAuthorizationCode) => {
    const contactId = getCurrentCustomer(getState()).contact_id;
    const addGoogleAccount = fetchApi(
      "/endo/google_accounts/?fields=email_address",
      {
        method: "post",
        json: {
          code: googleAuthorizationCode,
          refresh_token_info: true
        }
      }
    );

    return addGoogleAccount
      .then(({ body }) => {
        dispatch(consumePayload(body, GoogleAccount));
        dispatch(SET_CONNECTED_EMAIL_ADDRESS(body.email_address.id));

        return retryable(
          () => {
            return fetchApi(
              `/endo/meeting_resources/${body.email_address.id}/sync_resources`
            );
          },
          { maxAttempts: 50, onStatusCodes: [202, 503] }
        );
      })
      .then(({ body }) => {
        const syncedCalendars = body.map(syncedCalendar => ({
          ...syncedCalendar,
          contact_id: contactId
        }));

        syncedCalendars.forEach(syncedCalendar =>
          dispatch(consumePayload(syncedCalendar, SyncedCalendar))
        );

        return syncedCalendars;
      });
  },
  REPORT_ERROR
);

export const updateResources = wrapPromiseToThunk(
  UPDATE_RESOURCES,
  ({ dispatch }, resources) => {
    return fetchApi("/endo/meeting_resources/", {
      method: "post",
      json: { synced_calendars: resources }
    }).then(() => {
      resources.forEach(resource =>
        dispatch(consumePayload(resource, SyncedCalendar))
      );
    });
  },
  REPORT_ERROR
);

export const reducer = handleActions(
  {
    [ADD_SCOPE_SYNC_RESOURCES]: {
      begin: state => ({
        ...state,
        fetchGroup: state.fetchGroup.startFetch()
      }),
      next: state => ({
        ...state,
        fetchGroup: state.fetchGroup.completeFetch()
      }),
      throw: state => ({
        ...state,
        fetchGroup: state.fetchGroup.completeFetch()
      })
    },
    [UPDATE_RESOURCES]: {
      begin: state => ({
        ...state,
        fetchGroup: state.fetchGroup.startFetch()
      }),
      next: state => ({
        ...state,
        fetchGroup: state.fetchGroup.completeFetch()
      }),
      throw: state => ({
        ...state,
        fetchGroup: state.fetchGroup.completeFetch()
      })
    }
  },
  {
    fetchGroup: fetchGroup()
  }
);
