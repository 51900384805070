/** @format */
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
/* eslint-disable no-use-before-define */
import moment from "moment-timezone";
import React, { useState } from "react";

import config from "../../../config";
import { Customer } from "../../../collections/schema";
import { generateShape } from "../../../collections/shapes";
import withSchema from "../shared/with-schema.react";
import {
  Block,
  Content,
  PreferenceContentBlock,
  TextPreferenceContentBlock
} from "../layout/block.react";
import Section from "../layout/section.react";
import AssistantProfile from "../dashboard/account/assistant-profile.react";
import CustomerProfile from "../dashboard/customer-profile.react";
import TextButton from "../shared/text-button.react";
import { LongTextPreference } from "../dashboard/preferences/basic.react";
import { fetchApi } from "../../../utils/request";

export const Profile = ({ data: customer, onLogout }) => {
  const [selectedTimezone, setSelectedTimezone] = useState(customer.contact.timezone.replace("_", " ").replace("/", " - "))
  const [editingDetails, setEditingDetails] = useState(false)

  const _getCurrentTimezone = () => {
    let tz = moment.tz.guess();
    setContactTimezone(tz).then(() => {
      setSelectedTimezone(tz.replace("_", " ").replace("/", " - "))
    })
  }

  const setContactTimezone = (timezone) => {
    return fetchApi(`/contacts/${customer.contact.id}/actions/update_timezone`, {
      method: "POST",
      json: { timezone: timezone },
    })
  }

  const updateContactName = (name) => {
    return fetchApi(`/contacts/${customer.contact.id}/actions/update_name`, {
      method: "POST",
      json: { name: name },
    })
  }

  const updateCompanyName = (name) => {
    return fetchApi(`/contacts/${customer.contact.id}/actions/update_company_name`, {
      method: "POST",
      json: { name: name },
    })
  }

  const handleToggleEditing = () => {
    let companyName;
    let contactName;
    if (editingDetails) {
      companyName = document.getElementById("companyInput").value
      contactName = document.getElementById("nameInput").value

      if (
        (!companyName || companyName === "") ||
        (!contactName || contactName === "")
      ) {
        return
      }

      updateCompanyName(companyName)
      updateContactName(contactName)
    }

    setEditingDetails(!editingDetails)
  }

  const renderProfile = (customerData) => {
    return (
      <Section>
        <div className="row">
          <div className="col-xs-12">
            <Block className="Block-shadowed">
              <Content>
                <CustomerProfile
                  customer={customerData}
                  contact={customerData.contact}
                  company={customerData.contact.company}
                  googleAccount={customerData.contact.google_accounts[0]}
                  isEditing={editingDetails}
                />
                <div className="CustomerProfile-actionButtonRow">
                  <div
                    className="CustomerProfile-editButton"
                    onClick={() => handleToggleEditing()}
                  >
                    {editingDetails ? "Save" : "Edit"}
                  </div>
                  <div
                    className="CustomerProfile-logoutButton"
                    onClick={() => onLogout()}
                  >
                    Logout
                  </div>
                </div>
              </Content>
            </Block>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 col-md-6">
            <AssistantProfile
              contact={customerData.active_assistant.contact}
              siteHost={config.services.site.host}
              className="Block-shadowed"
              subClassName="Block"
            />
          </div>
          <div className="col-xs-12 col-md-6">
            <TextPreferenceContentBlock
              title="Default timezone"
              subtitle="Which timezone are you usually in?"
              supportLink="https://support.claralabs.com/article/111-default-timezone"
              className="Block-shadowed"
              subClassName="TextPreferenceContentBlock-paddingTop"
            >
              {moment.tz(customerData.contact.timezone).format("z")}
            </TextPreferenceContentBlock>
          </div>
        </div>
      </Section>
    )
  }

  return (
    <div className="Settings">
      {renderProfile(customer)}
    </div>
  );
}

const expansions = {
  contact: {
    company: {},
    google_accounts: {}
  },
  active_assistant: {
    contact: {}
  }
};

Profile.propTypes = { data: generateShape(Customer, expansions) };

export default withSchema(Customer, expansions, props => props.customer.id)(
  Profile
);
