/** @format */
import { connect } from "react-redux";

import ClarasEmails from "../../components/live-v2/claras-emails.react";
import {
  fetchDefaultMeetingTypes,
  getIsLoadingDefaultMeetingTypes,
  getDefaultVirtualMeetingType
} from "../../modules/meeting-types";
import {
  fetchPreference,
  getIsLoadingPreference,
  getPreference
} from "../../modules/preferences";
import {
  getCurrentCustomerContact, 
  getIsLoading as getIsLoadingSession
} from "../../modules/session";
import {
  setDefault,
  fetchVirtualDetails,
  getVirtualDetails,
  getIsLoadingVirtualDetails,
  getIsSettingDefault
} from "../../modules/virtual-details";
import createInitiallyLoadingContainer from "../create-initially-loading-container.react";

const mapStateToProps = (state, { customer }) => {
  if (
    getIsLoadingPreference(state) || // yes
    getIsLoadingDefaultMeetingTypes(state) ||
    getIsLoadingVirtualDetails(state) ||
    getIsLoadingSession(state) // yes
  ) {
    return { isLoading: true };
  }

  const preference = getPreference(state, { customer })
  return {
    isLoading: false,
    preference: preference,
    locations: preference.locations,
    defaultVirtualMeetingType: getDefaultVirtualMeetingType(state),
    virtualDetails: getVirtualDetails(state, {
      contactId: customer.contact_id
    }),
    isSettingDefault: getIsSettingDefault(state),
    customer: customer,
    customerContact: getCurrentCustomerContact(state),
  };
};

const mapDispatchToProps = (dispatch, { customer }) => ({
  onLoad() {
    dispatch(fetchPreference(customer.id));
    dispatch(fetchDefaultMeetingTypes(customer.contact_id));
    dispatch(fetchVirtualDetails(customer.contact_id));
  },

  onSetDefault(virtualDetailId) {
    return dispatch(setDefault(virtualDetailId));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(createInitiallyLoadingContainer(ClarasEmails));
