/** @format */
import { connect } from "react-redux";

import Integrations from "../../components/live-v2/index.react";
import {
  fetchSummary,
  getIsLoadingSummary,
  getSummary
} from "../../modules/integrations";
import { getCurrentCustomer } from "../../modules/session";

import createInitiallyLoadingContainer from "../create-initially-loading-container.react";

const mapStateToProps = state => {
  const customer = getCurrentCustomer(state);
  const liveBool = false
  const abilitiesBool = customer.feature_flags.includes("abilities")

  return {
    abilitiesFlag: abilitiesBool,
    liveFlag: liveBool,
    isLoading: getIsLoadingSummary(state),
    ...getSummary(state)
  }
} 


const mapDispatchToProps = dispatch => ({
  onLoad() {
    dispatch(fetchSummary());
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(createInitiallyLoadingContainer(Integrations));
