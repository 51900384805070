/** @format */
import { fetchApi } from "../../utils/request";
import {
  createAsyncAction,
  handleActions,
  wrapPromiseToThunk
} from "../../utils/redux-actions";
import config from "../../config";
import fetchGroup from "../utils/fetch-group";
import { consumePayload } from "../../collections/module";
import { EmailAddressVerification } from "../../collections/schema";
import { REPORT_ERROR } from "./error";

const ADD_EMAIL_ADDRESS = createAsyncAction("VERIFY_EMAIL/ADD_EMAIL_ADDRESS");
const VERIFY_TOKEN = createAsyncAction("VERIFY_EMAIL/VERIFY_TOKEN");

export const addEmailAddress = wrapPromiseToThunk(
  ADD_EMAIL_ADDRESS,
  ({ dispatch }, email) => {
    return fetchApi("/customers/actions/email_addresses/add", {
      method: "post",
      json: { email }
    }).then(({ body }) => {
      body.email_address_verifications.forEach(v => {
        dispatch(consumePayload(v, EmailAddressVerification));
      });
      return body;
    });
  },
  REPORT_ERROR
);

export const verifyEmailToken = wrapPromiseToThunk(VERIFY_TOKEN, (_, token) => {
  return fetch(`${config.api.host}/customers/actions/email_addresses/verify`, {
    method: "post",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      token
    })
  })
    .then(({ status }) => ({ successful: status === 204 }))
    .catch(() => ({ successful: false }));
});

export const reducer = handleActions(
  {
    [ADD_EMAIL_ADDRESS]: {
      begin: state => ({
        ...state,
        fetchGroup: state.fetchGroup.startFetch()
      }),
      next: state => ({
        ...state,
        fetchGroup: state.fetchGroup.completeFetch()
      }),
      throw: state => ({
        ...state,
        fetchGroup: state.fetchGroup.completeFetch()
      })
    },
    [VERIFY_TOKEN]: {
      begin: state => ({
        ...state,
        successful: false,
        fetchGroup: state.fetchGroup.startFetch()
      }),
      next: (state, action) => ({
        ...state,
        successful: action.payload.successful,
        fetchGroup: state.fetchGroup.completeFetch()
      }),
      throw: state => ({
        ...state,
        successful: false,
        fetchGroup: state.fetchGroup.completeFetch()
      })
    }
  },
  {
    successful: null,
    fetchGroup: fetchGroup(false)
  }
);
