/** @format */
import React from "react";
import createReactClass from "create-react-class";
import Header from "./shared/header.react";

const NotFound = createReactClass({
  render() {
    return (
      <div>
        <Header />
        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-md-10 col-md-offset-2">
              <div className="NotFound">
                <h1 className="NotFound-hero">Oh, hey!</h1>
                <h1 className="NotFound-hero">How did you get here?</h1>
                <h3 className="NotFound-heading">
                  The page you were looking for could not be found or does not
                  exist.
                </h3>
                <p>
                  You can go back to{" "}
                  <a className="NotFound-link" href="/">
                    our home page
                  </a>, or send us a message at{" "}
                  <a
                    className="NotFound-link"
                    href="mailto:support@claralabs.com"
                  >
                    support@claralabs.com
                  </a>.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
});

export default NotFound;
