import React from 'react';
import PropTypes from "prop-types";

import SuggestedTimeChoice from './suggested-time-choice.react';

const SuggestedTimeMessageView = props => {
  let suggestions = [];

  if (props.suggestions && props.suggestions.length > 0) {
    props.suggestions.forEach(suggestion => {
      suggestions.push(
        <SuggestedTimeChoice
          key={suggestion.uuid}
          uuid={suggestion.uuid}
          timeSpan={suggestion.timeSpan}
          clicked={props.clicked}
        />);
    });
  }

  return (
    <div style={{
      margin: "10px",
      padding: "10px"
    }}>
      <p>{props.message.split('<br />').map((item, key) => {
        return <span key={key}>{item}<br/></span>
      })}</p>
      {suggestions}
    </div>
  );
}


SuggestedTimeMessageView.propTypes = {
  message: PropTypes.string.isRequired,
  suggestions: PropTypes.array,
  clicked: PropTypes.func.isRequired,
};

export default SuggestedTimeMessageView;