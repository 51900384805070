/** @format */
import PropTypes from "prop-types";

import React, { Component } from "react";
import { Icon, Loader, TextField } from "vogue";
import classnames from "classnames";

import TextButton from "../shared/text-button.react";

export class IcimsButtonInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      editing: false,
      value: "",
      customerIdValue: "",
      clientIdValue: "",
      clientSecretValue: "",
      error: false,
      helperText: "",
      editingCustomerId: false,
      editingClientId: false,
      editingClientSecret: false
    };
    this._setEditingTrue = this._setEditingTrue.bind(this);
    this._setEditingCustomerIdTrue = this._setEditingCustomerIdTrue.bind(this);
    this._setEditingClientIdTrue = this._setEditingClientIdTrue.bind(this);
    this._setEditingClientSecretTrue = this._setEditingClientSecretTrue.bind(this);

    this._onChange = this._onChange.bind(this);
    this._onChangeCustomerId = this._onChangeCustomerId.bind(this);
    this._onChangeClientId = this._onChangeClientId.bind(this);
    this._onChangeClientSecret = this._onChangeClientSecret.bind(this);

    this._onSubmit = this._onSubmit.bind(this);
    this._onCancel = this._onCancel.bind(this);
    this._connectIcims = this._connectIcims.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.refs.input && !prevState.editing && this.state.editing) {
      this.refs.input.focus();
    }
  }

  _onChange(e) {
    this.setState({
      value: e.target.value
    });
  }

  _onChangeCustomerId(e) {
    this.setState({
      customerIdValue: e.target.value
    });
  }

  _onChangeClientId(e) {
    this.setState({
      clientIdValue: e.target.value
    });
  }

  _onChangeClientSecret(e) {
    this.setState({
      clientSecretValue: e.target.value
    });
  }

  _setEditingCustomerIdTrue() {
    this.setState({ 
        editingCustomerId: true,
        editingClientId: false,
        editingClientSecret: false,
    });
  }

  _setEditingClientIdTrue() {
    this.setState({ 
        editingCustomerId: false,
        editingClientId: true,
        editingClientSecret: false,
    });
  }

  _setEditingClientSecretTrue() {
    this.setState({ 
        editingCustomerId: false,
        editingClientId: false,
        editingClientSecret: true,    
    });
  }

  _setEditingTrue() {
    this.setState({ editing: true });
  }

  _connectIcims() {
    this._setEditingTrue();
    this._setEditingCustomerIdTrue();
  }

  _onSubmit() {
    const { value, error, helperText, customerIdValue, clientIdValue, clientSecretValue } = this.state;

    if (this.props.validator(value)) {
      if (error || helperText) {
        this.setState({
          error: false,
          helperText: ""
        });
      }

      this.props.onSubmit(customerIdValue, clientIdValue, clientSecretValue).then(() => {
        this.setState({
          editing: false,
          value: "",
          customerIdValue: "",
          clientIdValue: "",
          clientSecretValue: "",
          editingCustomerId: false,
          editingClientId: false,
          editingClientSecret: false,
        });
      });
    } else {
      this.setState({
        error: true,
        helperText: this.props.validationMessage
      });
    }
  }

  _onCancel() {
    this.setState({
      editing: false,
      value: "",
      customerIdValue: "",
      clientIdValue: "",
      clientSecretValue: "",
      editingCustomerId: false,
      editingClientId: false,
      editingClientSecret: false,
      error: false,
      helperText: ""
    });
  }

  render() {
    const { editing, customerIdValue, clientIdValue, clientSecretValue, editingCustomerId, editingClientId, editingClientSecret } = this.state;
    const { isLoading } = this.props;

    const iconClasses = classnames("ButtonInput-icon", {
      "ButtonInput-icon--editing": editing
    });

    let onClickIcon = () => {};
    if (!isLoading) {
        onClickIcon = editing ? this._onCancel : this._connectIcims;
    }

    const error = !!this.props.errorMessage || this.state.error;
    const helperText = this.props.errorMessage || this.state.helperText;

    return (
      <div className="ButtonInput">
        <Icon
          name="close"
          className={iconClasses}
          disabled={isLoading}
          onClick={onClickIcon}
        />
        {editing ? (
          [
            editingCustomerId && (
                <TextField
                    ref="input"
                    key="input"
                    className="ButtonInput-input"
                    modifiers={["hidesLabel"]}
                    disabled={isLoading}
                    value={customerIdValue}
                    onChange={this._onChangeCustomerId}
                    onEnterKeyPressed={this._setEditingClientIdTrue}
                    placeholder={'Step 1 (of 3): Input Customer ID'}
                    error={error}
                    helperText={helperText}
                    spellCheck={false}
                />
            ),
            editingClientId && (
                <TextField
                    ref="input"
                    key="input"
                    className="ButtonInput-input"
                    modifiers={["hidesLabel"]}
                    disabled={isLoading}
                    value={clientIdValue}
                    onChange={this._onChangeClientId}
                    onEnterKeyPressed={this._setEditingClientSecretTrue}
                    placeholder={'Step 2 (of 3): Input Client ID'}
                    error={error}
                    helperText={helperText}
                    spellCheck={false}
                />
            ),
            editingClientSecret && (
                <TextField
                    ref="input"
                    key="input"
                    className="ButtonInput-input"
                    modifiers={["hidesLabel"]}
                    disabled={isLoading}
                    value={clientSecretValue}
                    onChange={this._onChangeClientSecret}
                    onEnterKeyPressed={this._onSubmit}
                    placeholder={'Step 3 (of 3): Input Client Secret'}
                    error={error}
                    helperText={helperText}
                    spellCheck={false}
                />
            ),
            isLoading ? (
              <span key="loader" className="ButtonInput-loader">
                <Loader />
              </span>
            ) : (
                [
                    editingCustomerId && (
                        <TextButton
                            className="ButtonInput-button"
                            onClick={this._setEditingClientIdTrue}
                            key="button1"
                        >
                            {'Submit Customer ID'}
                        </TextButton>
                    ),
                    editingClientId && (
                        <TextButton
                            className="ButtonInput-button"
                            onClick={this._setEditingClientSecretTrue}
                            key="button2"
                        >
                            {'Submit Client ID'}
                        </TextButton>
                    ),
                    editingClientSecret && (
                        <TextButton
                            className="ButtonInput-button"
                            onClick={this._onSubmit}
                            key="button3"
                        >
                            {'Submit Client Secret'}
                        </TextButton>
                    )
                ]
            )
          ]
        ) : (
          <TextButton
            className="ButtonInput-button"
            onClick={this._connectIcims}
          >
            {this.props.buttonText}
          </TextButton>
        )}
      </div>
    );
  }
}

IcimsButtonInput.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  validator: PropTypes.func,
  validationMessage: PropTypes.string,
  submitText: PropTypes.string,
  buttonText: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  errorMessage: PropTypes.string
};

IcimsButtonInput.defaultProps = {
  validator: () => true,
  validationMessage: "An error occured, please contact Clara support to resolve this issue at support@claralabs.com",
  submitText: "Done"
};

export default IcimsButtonInput;
