/** @format */
import classNames from "classnames";
import { capitalize } from "lodash";
import PropTypes from "prop-types";
import React from "react";

const PillBox = ({ text, modifier, small, className }) => (
  <span
    className={classNames(
      "PillBox",
      {
        [`PillBox--${modifier}`]: !!modifier,
        "PillBox--small": !!small
      },
      className
    )}
  >
    {capitalize(text)}
  </span>
);

PillBox.propTypes = {
  text: PropTypes.string.isRequired,
  modifier: PropTypes.oneOf(["green", "yellow", "blue", "red", "grey"]),
  small: PropTypes.bool,
  className: PropTypes.string
};

export default PillBox;
