/** @format */
import { combineReducers } from "redux";
import * as session from "./session";
import * as featureFlags from "./feature-flags";
import { reducer as account } from "./account";
import * as availability from "./availability";
import * as conferenceRooms from "./conference-rooms";
import { reducer as conferenceRoomsConfiguration } from "./conference-rooms-configuration";
import * as eventWrite from "./event-write";
import * as connectedCalendars from "./connected-calendars";
import { reducer as collections } from "../../collections/module";
import * as candidateFeedback from "./candidate-feedback";
import * as customerFeedback from "./customer-feedback";
import * as error from "./error";
import * as locations from "./locations";
import * as meetings from "./meetings";
import * as onboard from "./onboard";
import { reducer as preferences } from "./preferences";
import { reducer as verifyEmail } from "./verify-email";
import { reducer as referral } from "./referral";
import * as team from "./team";
import * as virtualDetails from "./virtual-details";
import * as integrations from "./integrations";
import * as organizationLocations from "./organization-locations";
import * as meetingTypes from "./meeting-types";
import * as transcript from "./transcript";

const rootReducer = combineReducers({
  [session.MOUNT_POINT]: session.reducer,
  collections,
  [conferenceRooms.MOUNT_POINT]: conferenceRooms.reducer,
  conferenceRoomsConfiguration,
  [connectedCalendars.MOUNT_POINT]: connectedCalendars.reducer,
  [candidateFeedback.MOUNT_POINT]: candidateFeedback.reducer,
  [customerFeedback.MOUNT_POINT]: customerFeedback.reducer,
  [error.MOUNT_POINT]: error.reducer,
  [eventWrite.MOUNT_POINT]: eventWrite.reducer,
  [featureFlags.MOUNT_POINT]: featureFlags.reducer,
  [locations.MOUNT_POINT]: locations.reducer,
  [meetings.MOUNT_POINT]: meetings.reducer,
  [onboard.MOUNT_POINT]: onboard.reducer,
  preferences,
  verifyEmail,
  referral,
  [availability.MOUNT_POINT]: availability.reducer,
  account,
  [team.MOUNT_POINT]: team.reducer,
  [virtualDetails.MOUNT_POINT]: virtualDetails.reducer,
  [integrations.MOUNT_POINT]: integrations.reducer,
  [organizationLocations.MOUNT_POINT]: organizationLocations.reducer,
  [meetingTypes.MOUNT_POINT]: meetingTypes.reducer,
  [transcript.MOUNT_POINT]: transcript.reducer
});

export default rootReducer;
