class EmailMessageJob {
  static isFromAssistant(email_message_job) {
    const headerFromContact = email_message_job.email_message.header_from[0].contact;
    const isFromAssistant = (
      email_message_job.assistant && headerFromContact && headerFromContact.assistant &&
      email_message_job.assistant.id === headerFromContact.assistant.id
    );

    return isFromAssistant;
  }
}

export default EmailMessageJob;
