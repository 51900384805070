/** @format */
import PropTypes from "prop-types";

import React, { Component } from "react";
import ReactDOM from "react-dom";
import config from "../../../config";
import { Button, Icon } from "vogue";
import { connect } from "react-redux";
import {
  submitReferrals,
  getReferralSuggestions
} from "../../modules/referral";
import ReferralWidget from "./referral-widget.react";
import { CustomerShape } from "../../utils/shapes";
import Section from "../layout/section.react";
export class Referral extends Component {
  constructor(props) {
    super(props);

    this._copy = this._copy.bind(this);
  }

  UNSAFE_componentWillMount() {
    this.props.onLoad();
  }

  _copy() {
    const copyDiv = ReactDOM.findDOMNode(this.refs.referralLinkInput);
    copyDiv.classList.remove("u-hidden");
    copyDiv.select();

    document.execCommand("copy");

    copyDiv.classList.add("u-hidden");
  }

  render() {
    let copyButton;
    if (document.execCommand) {
      copyButton = (
        <Button
          className="Button--secondary Referral-linkCopy"
          onClick={this._copy}
        >
          <Icon name="copy" />
        </Button>
      );
    }

    return (
      <div className="Settings">
        <div className="Settings-sectionContainer">
          <Section>
            <div className="Referral">
                <div className="col-xs-8">
                  <div className="Referral-content">
                    <h2 className="Referral-header">Give $100, get $100.</h2>
                    <Icon name="gift" className="Referral-icon" />
                    <p className="Referral-description">
                      Introduce Clara to other busy professionals and get rewarded. They&#39;ll get
                      $100 off their first month of Clara, and you&#39;ll get $100
                      after they subscribe!
                    </p>
                    <ReferralWidget
                      onSubmitReferrals={this.props.onSubmitReferrals}
                      referralFetching={this.props.referralFetching}
                      suggestions={this.props.suggestions}
                    />
                    <div className="Referral-or">OR</div>
                    <div className="Referral-linkGroup">
                      <input
                        className="Referral-link"
                        readOnly={true}
                        value={this.props.referLink}
                      />
                      {copyButton}
                      <input
                        ref="referralLinkInput"
                        className="u-hidden"
                        readOnly={true}
                        value={this.props.referLink}
                      />
                    </div>
                    <Button
                      className="Button Button--secondary Referral-faq"
                      href="https://support.claralabs.com/article/104-how-does-claras-referral-program-give-100-get-100-work"
                      target="_blank"
                    >
                      Read Terms
                    </Button>
                </div>
              </div>
            </div>
          </Section>
        </div>
      </div>
    );
  }
}

Referral.propTypes = {
  customer: CustomerShape.isRequired,
  referLink: PropTypes.string,
  onSubmitReferrals: PropTypes.func.isRequired,
  onLoad: PropTypes.func.isRequired,
  referralFetching: PropTypes.bool.isRequired,
  suggestions: PropTypes.arrayOf(PropTypes.object)
};

const mapStateToProps = (state, { customer }) => {
  const referLink = `${config.services.endo.host}/start?referral_code=${
    customer.referral_code
  }`;
  const {
    referral: { fetchGroup, suggestions }
  } = state;

  return {
    referralFetching: fetchGroup.isFetching,
    referLink,
    suggestions
  };
};

const mapDispatchToProps = dispatch => ({
  onSubmitReferrals(emailAddresses) {
    return dispatch(submitReferrals(emailAddresses));
  },

  onLoad() {
    return dispatch(getReferralSuggestions());
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Referral);
