/** @format */
import PropTypes from "prop-types";

import { CustomerRoles } from "./permissions";
import {
  HighLevelChannelType,
  MeetingStatus,
  VirtualCategory,
  VirtualKind,
  VirtualWhoContactsWho
} from "../../utils/enums";

/* Describes a well-formed Location object. */
export const LocationShape = PropTypes.shape({
  id: PropTypes.number.isRequired,
  meta: PropTypes.shape({
    loading: PropTypes.bool.isRequired
  }).isRequired,
  category: PropTypes.string.isRequired,
  formatted_address: PropTypes.string.isRequired,
  google_place_id: PropTypes.string,
  is_default: PropTypes.bool,
  lat: PropTypes.number.isRequired,
  lng: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired
});

/* Describes an API TCR object */
export const TcrShape = PropTypes.shape({
  available_type: PropTypes.string,
  condition: PropTypes.string,
  contact_id: PropTypes.number,
  created: PropTypes.string,
  dtstart: PropTypes.string,
  end_time: PropTypes.string,
  formatted_condition: PropTypes.string,
  id: PropTypes.number,
  kind: PropTypes.string,
  last_materialized_at: PropTypes.string,
  modified: PropTypes.string,
  phrase: PropTypes.string,
  reason: PropTypes.string,
  rrule: PropTypes.string,
  start_time: PropTypes.string,
  timezone: PropTypes.string,
  token: PropTypes.string,
  until: PropTypes.string
});

/* Describes a well-formed ContactSyncedCalendar object */
export const ContactSyncedCalendarShape = PropTypes.shape({
  id: PropTypes.number.isRequired,
  access: PropTypes.string.isRequired,
  primary: PropTypes.bool.isRequired,
  informs_availability: PropTypes.bool.isRequired
});

/* Describes a well-formed VirtualDetail object */
export const VirtualDetailShape = PropTypes.shape({
  kind: PropTypes.oneOf(Object.keys(VirtualKind)).isRequired,
  categories: PropTypes.arrayOf(PropTypes.oneOf(Object.keys(VirtualCategory)))
    .isRequired,
  value: PropTypes.string.isRequired,
  additional_info: PropTypes.string
});

/* Describes a well-formed Paging object */
export const PagingShape = PropTypes.shape({
  first: PropTypes.number.isRequired,
  last: PropTypes.number.isRequired,
  prev: PropTypes.number,
  next: PropTypes.number,
  current: PropTypes.number,
  perPage: PropTypes.number
});

/** Describes a well-formed TeamCustomer object */
export const TeamCustomerShape = PropTypes.shape({
  contact: PropTypes.shape({
    name: PropTypes.string.isRequired,
    default_email: PropTypes.string.isRequired
  }).isRequired,
  id: PropTypes.number.isRequired,
  roles: PropTypes.arrayOf(PropTypes.oneOf(Object.keys(CustomerRoles)))
    .isRequired
});

const syncedCalendarProperties = {
  id: PropTypes.number.isRequired,
  provider_id: PropTypes.string.isRequired,
  kind: PropTypes.oneOf([
    "suggested",
    "travel",
    "holiday",
    "resource",
    "unclassified"
  ]).isRequired,
  is_conference_room: PropTypes.bool.isRequired,
  name: PropTypes.string,
  description: PropTypes.string,
  capacity: PropTypes.number
};

/** Describes a well-formed SyncedCalendar object */
export const SyncedCalendarShape = PropTypes.shape({
  ...syncedCalendarProperties
});

/* Describes a well-formed resource SyncedCalendar object */
export const ResourceSyncedCalendarShape = PropTypes.shape({
  ...syncedCalendarProperties,
  kind: PropTypes.oneOf(["resource"]).isRequired
});

/* Describes a well-formed conference room SyncedCalendar object */
export const ConferenceRoomSyncedCalendarShape = PropTypes.shape({
  ...syncedCalendarProperties,
  kind: PropTypes.oneOf(["resource"]).isRequired,
  is_conference_room: PropTypes.oneOf([true]).isRequired,
  capacity: PropTypes.number.isRequired
});

/* Describes a well-formed Customer object */
export const CustomerShape = PropTypes.shape({
  onboarded: PropTypes.bool.isRequired,
  onboarding: PropTypes.bool.isRequired,
  offboarding_start: PropTypes.string,
  offboarding_end: PropTypes.string,
  offboarding_retirement: PropTypes.string,
  contact_id: PropTypes.number.isRequired,
  organization_id: PropTypes.number.isRequired,
  customer_user_id: PropTypes.number.isRequired,
  roles: PropTypes.arrayOf(PropTypes.oneOf(Object.keys(CustomerRoles)))
    .isRequired,
  id: PropTypes.number.isRequired,
  referral_code: PropTypes.string.isRequired,
  feature_flags: PropTypes.arrayOf(PropTypes.string).isRequired
});

/* Describes a well-formed MeetingResourceGroup object */
export const MeetingResourceGroupShape = PropTypes.shape({
  id: PropTypes.number.isRequired,
  meeting_resources: PropTypes.arrayOf(ConferenceRoomSyncedCalendarShape)
    .isRequired
});

/* Describes a well-formed Contact object */
export const ContactShape = PropTypes.shape({
  first_name: PropTypes.string.isRequired,
  last_name: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  default_email: PropTypes.string.isRequired
});

const CommonMeetingPermissionProps = {
  id: PropTypes.number.isRequired,
  meeting_permission_kind: PropTypes.oneOf(["schedule", "initiate"]).isRequired
};

/* Describes a well-formed MeetingPermission object */
export const MeetingPermissionShape = PropTypes.oneOfType([
  PropTypes.shape({
    ...CommonMeetingPermissionProps,
    contact: ContactShape.isRequired
  }),
  PropTypes.shape({
    ...CommonMeetingPermissionProps,
    domain: PropTypes.string.isRequired,
    domain_aliases: PropTypes.arrayOf(PropTypes.string).isRequired
  })
]);

/* Describes a well-formed Keyword object */
export const KeywordShape = PropTypes.shape({
  id: PropTypes.number.isRequired,
  keyword: PropTypes.string.isRequired,
  customer_intent: PropTypes.string.isRequired
});

export const MeetingShape = PropTypes.shape({
  created: PropTypes.string.isRequired,
  participants: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      first_name: PropTypes.string,
      email_address: PropTypes.string.isRequired
    })
  ).isRequired,
  status: PropTypes.oneOf(Object.values(MeetingStatus)).isRequired,
  subject: PropTypes.string.isRequired,
  waiting_on: PropTypes.string,
  customer_contact: ContactShape,
  last_assistant_email_time: PropTypes.string,
  waiting_since: PropTypes.string
});

/* Describes a well-formed ZoomAccount object */
export const ZoomAccountShape = PropTypes.shape({
  id: PropTypes.number.isRequired,
  created: PropTypes.string.isRequired,
  active: PropTypes.bool.isRequired,
  is_valid: PropTypes.bool.isRequired,
  organization_id: PropTypes.number.isRequired,
  zoom_user_email: PropTypes.string.isRequired
});

export const SlackAccountShape = PropTypes.shape({
  id: PropTypes.number.isRequired,
  created: PropTypes.string.isRequired,
  is_valid: PropTypes.bool.isRequired,
  organization_id: PropTypes.number.isRequired,
});

export const IntegrationAccountShape = PropTypes.shape({
  id: PropTypes.number.isRequired,
  created: PropTypes.string.isRequired,
  is_valid: PropTypes.bool.isRequired,
  organization_id: PropTypes.number.isRequired,
});

export const EventWriteShape = PropTypes.shape({
  id: PropTypes.number.isRequired,
  internal_id: PropTypes.string,
  kind: PropTypes.string,
  operation: PropTypes.string.isRequired,
  synced: PropTypes.string,
  written: PropTypes.string
});

export const ScheduleWindowShape = PropTypes.shape({
  uuid: PropTypes.string.isRequired,
  after: PropTypes.string.isRequired,
  before: PropTypes.string.isRequired
});

export const ScheduleSearchParamsShape = PropTypes.shape({
  scheduleTemplateId: PropTypes.number,
  windows: PropTypes.arrayOf(ScheduleWindowShape).isRequired
});

export const TimeSpanShape = PropTypes.shape({
  start_time: PropTypes.string.isRequired,
  end_time: PropTypes.string.isRequired
});

export const ScheduleConflictShape = PropTypes.shape({
  event: PropTypes.shape({
    title: PropTypes.string.isRequired,
    start: PropTypes.string.isRequired,
    end: PropTypes.string.isRequired
  })
});

export const ScheduleSearchResultShape = PropTypes.shape({
  uuid: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  timezone: PropTypes.string.isRequired
});

/* Describes a well-formed BillingGroupInvitation object */
export const BillingGroupInvitationShape = PropTypes.shape({
  id: PropTypes.number.isRequired,
  billing_group_id: PropTypes.number.isRequired,
  email_address_id: PropTypes.string.isRequired,
  created: PropTypes.string.isRequired
});

export const MeetingTypeShape = PropTypes.shape({
  id: PropTypes.number.isRequired,
  is_default: PropTypes.bool.isRequired,
  high_level_channel_type: PropTypes.oneOf(Object.values(HighLevelChannelType))
    .isRequired,
  who_contacts_who: PropTypes.oneOf(Object.values(VirtualWhoContactsWho))
});
