/** @format */
import PropTypes from "prop-types";

import React, { Component } from "react";
import SelectMenu from "../../shared/select-menu.react";
import { TcrShape } from "../../../utils/shapes";
import {
  rruleTimeToMoment,
  momentToSplitFormat,
  splitFormatToRruleTime
} from "../../../utils/rrule";

const hourOptions = [
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
  "11",
  "12"
].map(hour => ({ value: hour, label: hour }));
const minuteOptions = [
  "00",
  "05",
  "10",
  "15",
  "20",
  "25",
  "30",
  "35",
  "40",
  "45",
  "50",
  "55",
  "60"
].map(minute => ({ value: minute, label: minute }));
const periodOptions = [
  { value: "AM", label: "AM" },
  { value: "PM", label: "PM" }
];

const PartsEnum = Object.freeze({
  hour: "hour",
  minute: "minute",
  period: "period"
});

export default class AvailabilityEditorTime extends Component {
  constructor(props) {
    super(props);

    this._onTimeSet = this._onTimeSet.bind(this);
  }

  render() {
    const formattedRruleTime = {
      start: momentToSplitFormat(rruleTimeToMoment(this.props.tcr.start_time)),
      end: momentToSplitFormat(rruleTimeToMoment(this.props.tcr.end_time))
    };

    const startOrEnd = this.props.start ? "start" : "end";

    return (
      <div className="AvailabilityForm-time">
        <SelectMenu
          className="AvailabilityForm-selectTime"
          options={hourOptions}
          onChange={this._onTimeSet.bind(this, PartsEnum.hour, startOrEnd)}
          value={formattedRruleTime[startOrEnd].hour}
          error={this.props.error}
        />
        <SelectMenu
          className="AvailabilityForm-selectTime"
          options={minuteOptions}
          onChange={this._onTimeSet.bind(this, PartsEnum.minute, startOrEnd)}
          value={formattedRruleTime[startOrEnd].minute}
          error={this.props.error}
        />
        <SelectMenu
          className="AvailabilityForm-selectTime"
          options={periodOptions}
          onChange={this._onTimeSet.bind(this, PartsEnum.period, startOrEnd)}
          value={formattedRruleTime[startOrEnd].period}
          error={this.props.error}
        />
      </div>
    );
  }

  /**
   * Sets the new time on the tcr
   * @param  {string} part       PartsEnum value
   * @param  {string} startOrEnd StartOrEndEnum value
   * @param  {object} event      event containing the new time value
   */
  _onTimeSet(
    part,
    startOrEnd,
    {
      target: { value }
    }
  ) {
    const tcrKey = `${startOrEnd}_time`;
    const splat = momentToSplitFormat(
      rruleTimeToMoment(this.props.tcr[tcrKey])
    );
    const newTime = splitFormatToRruleTime({
      ...splat,
      [part]: value
    });
    this.props.onChangeTcr({
      ...this.props.tcr,
      [tcrKey]: newTime
    });
  }
}
AvailabilityEditorTime.propTypes = {
  tcr: TcrShape.isRequired,
  start: PropTypes.bool.isRequired,
  onChangeTcr: PropTypes.func.isRequired,
  error: PropTypes.bool
};
