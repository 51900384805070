/** @format */
import { combineReducers } from "redux";
import { createAction, handleActions } from "redux-actions";

import { PromiseRejectionSentinelError } from "../../utils/promise-rejection-sentinel-error";
import {
  createAsyncAction,
  wrapPromiseToThunk
} from "../../utils/redux-actions";
import { fetchApi } from "../../utils/request";
import { handleFetchGroupActions } from "../utils/fetch-group";
import { REPORT_ERROR } from "./error";
import { CLEAR_CURRENT_USER } from "./session";
import { consumePayload } from "../../collections/module";
import { TimeConstraintRecurring } from "../../collections/schema";
import { CREATE_TCR, DELETE_TCR } from "./availability";

export const MOUNT_POINT = "onboard";

export const SET_ONBOARDING_STEP = createAction("ONBOARD/SET_ONBOARDING_STEP");
const BEGIN_ONBOARD = createAsyncAction("ONBOARD/BEGIN");
const SET_AVAILABILITY = createAsyncAction("ONBOARD/SET_AVAILABILITY");
const COMPLETE_ONBOARD = createAsyncAction("ONBOARD/COMPLETE");
const FETCH_MEETING_DETAILS = createAsyncAction(
  "ONBOARD/FETCH_MEETING_DETAILS"
);

export const fetchMeetingDetails = wrapPromiseToThunk(
  FETCH_MEETING_DETAILS,
  () => {
    return fetchApi("/endo/onboard/meeting_details", {
      redirectOnUnauthorized: false
    })
      .then(({ body }) => ({
        companyName: body.company_name,
        phoneNumber: body.phone,
        preferredLocation: body.location
      }))
      .catch(error => {
        // Ignore an unauthorized response which can happen when this fetch is called if the user is
        // not yet a customer.
        if (error instanceof PromiseRejectionSentinelError) {
          return {};
        } else {
          throw error;
        }
      });
  },
  REPORT_ERROR
);

export const fetchBeginOnboard = wrapPromiseToThunk(
  BEGIN_ONBOARD,
  ({ dispatch }, timezone) => {
    return fetchApi("/endo/onboard/actions/begin", {
      method: "post",
      json: { timezone }
    }).then(() => dispatch(CLEAR_CURRENT_USER()));
  },
  REPORT_ERROR
);

const formatTcr = tcr => ({
  rrule: tcr.rrule,
  start_time: tcr.start_time,
  end_time: tcr.end_time
});

export const setAvailability = wrapPromiseToThunk(
  SET_AVAILABILITY,
  ({ dispatch }, tcrs) => {
    return fetchApi("/endo/onboard/actions/set_availability", {
      method: "post",
      json: { time_constraint_recurrings: tcrs.map(formatTcr) }
    }).then(({ body }) => {
      body.time_constraint_recurrings.forEach(tcr => {
        dispatch(consumePayload(tcr, TimeConstraintRecurring));
        dispatch(CREATE_TCR(tcr));
      });

      body.deleted_tcrs.forEach(tcr => dispatch(DELETE_TCR(tcr)));
    });
  },
  REPORT_ERROR
);

export const fetchCompleteOnboard = wrapPromiseToThunk(
  COMPLETE_ONBOARD,
  ({ dispatch }) => {
    return fetchApi("/endo/onboard/actions/complete", {
      method: "post"
    }).then(() => {
      dispatch(CLEAR_CURRENT_USER());
    });
  },
  REPORT_ERROR
);

const fetchGroups = combineReducers({
  fetchBeginOnboarding: handleFetchGroupActions(BEGIN_ONBOARD),
  setAvailability: handleFetchGroupActions(SET_AVAILABILITY),
  fetchCompleteOnboard: handleFetchGroupActions(COMPLETE_ONBOARD),
  fetchMeetingDetails: handleFetchGroupActions(FETCH_MEETING_DETAILS)
});

const activeStep = handleActions(
  {
    [SET_ONBOARDING_STEP]: (_, { payload }) => payload
  },
  0
);

const meetingDetails = handleActions(
  {
    [FETCH_MEETING_DETAILS]: {
      begin: () => ({}),
      next: (_, action) => action.payload,
      throw: () => ({})
    }
  },
  {}
);

export const reducer = combineReducers({
  activeStep,
  fetchGroups,
  meetingDetails
});

export const getIsSubmitting = state =>
  state[MOUNT_POINT].fetchGroups.fetchBeginOnboarding.isFetching;

export const getIsSettingAvailability = state =>
  state[MOUNT_POINT].fetchGroups.setAvailability.isFetching;

export const getIsCompleting = state =>
  state[MOUNT_POINT].fetchGroups.fetchCompleteOnboard.isFetching;

export const getIsLoadingMeetingDetails = state =>
  state[MOUNT_POINT].fetchGroups.fetchMeetingDetails.isFetching;

export const getActiveStep = state => state[MOUNT_POINT].activeStep;

export const getMeetingDetails = state => state[MOUNT_POINT].meetingDetails;
