import React from "react";
import PropTypes from "prop-types";

const SuggestedTimeChoice = props => {
  return (
    <div>
      <p>
        <button
          className="AvailabilityCalendar-button"
          style={{ borderRadius: "5px", width: "50%", marginTop: "10px" }}
          onClick={(e) => props.clicked(props.uuid, e)}
        >{props.timeSpan}</button>
      </p>
    </div>
  );
};

SuggestedTimeChoice.propTypes = {
  clicked: PropTypes.func.isRequired,
  timeSpan: PropTypes.string.isRequired,
};

export default SuggestedTimeChoice;