import React from 'react';
import createReactClass from 'create-react-class';
import config from "../../config";
import moment from 'moment';

import { Timepicker } from './timepicker.react';
import { Loader } from 'vogue';
import { connect } from "react-redux";
import { createAsyncAction, wrapPromiseToThunk } from "../../utils/redux-actions";
import { fetchApi } from "../../utils/request";

export const CustomerCalendarView = createReactClass({
  _toTimeZone(time, zone) {
    let format = 'YYYY/MM/DD HH:mm:ss ZZ';
    return new Date(moment(time, format).tz(zone).format(format));
  },

  getInitialState() {
    const urlParams = new URLSearchParams(window.location.search);

    return {
      duration: 0,
      availableTimes: [],
      originalAvailableTimes: [],
      internalParticipantNames: [],
      externalParticipantNames: [],
      title: null,
      loading: true,
      selectedDateTime: null,
      confirmed: false,
      updating: false,
      phoneRequired: false,
      daysToFetch: 30,
      isMobile: false,

      // .../suggested-times/...
      suggestedTimeUUID: null,
      timeSpan: null,
      message: null,
      selectedTime: null,
      isCancelled: null,
      isStrikeout: false,
      calculatedTimes: false,
      suggestBypass: false,
      skipDetails: urlParams.get('reschedule') === 'true' ? true : false,

      // <MiniCalendar />
      year: 0,
      month: 0,
      firstDay: null,
      lastDay: null,

      searchedMore: false,
      suggestMore: false,
      jobUUID: this.props.match.params.jobUUID,
      localTimezone: Intl.DateTimeFormat().resolvedOptions().timeZone,

      errorMessage: null,
    };
  },

  _changeMonth(mod) {
    this.state.month = this.state.month + mod

    if (this.state.month > 11) {
      this.state.month %= 12
      this.state.year += 1
    } else if (this.state.month < 0) {
      this.state.month += 12
      this.state.year -= 1
    }

    this.setState({
      firstDay: new Date(this.state.year, this.state.month, 1),
      lastDay: new Date(this.state.year, this.state.month + 1, 0),
    })
  },

  _onAccept(acceptedEvent, details) {
    if (acceptedEvent) {
      this.setState({
        selectedDateTime: acceptedEvent.start,
        updating: true,
      })
    } else {
      this.setState({
        selectedDateTime: null,
        updating: true,
      })
    }
    
    let jobUUID = this.state.jobUUID
    let acceptUrl = `${config.api.host}/jobs/${jobUUID}/confirm_meeting_scheduling`;

    if (acceptedEvent) {
      fetch(acceptUrl, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          start_time: acceptedEvent.start,
          details: details
        })
      })
      .then((response) => {
        if (response.ok) {
          this.setState({
            updating: false,
            confirmed: true,
          })
        }
      })
    }
  },

  _suggestMoreDays() {
    this.setState({
      daysToFetch: this.state.daysToFetch + 15,
      loading: true,
      suggestBypass: true,
      suggestMore: true,
    })
  },

  _changeTimezone(timezone) {
    this.setState({
      localTimezone: timezone,
      loading: true,
    })
  },

  UNSAFE_componentWillMount() {
    let pathname = window.location.pathname

    if (pathname.includes("/suggest-time/")) {
      // take timepicker directly to the confirmation screen

      // gets job details associated with this suggested time
      this.props.getJobDetails(this.props.match.params.suggestedTimeUUID)
      .then((response) => {
        let body = response.body

        this.setState({
          phoneRequired: body.virtual_detail["kind"] === "phone",
          internalParticipantNames: body.internal_participant_names ? body.internal_participant_names : [],
          externalParticipantNames: body.external_participant_names ? body.external_participant_names : [],
          title: body.event_title,
          duration: body.duration,
          jobUUID: body.job.uuid
        })

        let jobUUID = body.job.uuid

        this._handleFetchDays(this.state.daysToFetch, jobUUID, this.state.localTimezone)
      })

      if (!this.state.suggestedTimeUUID) {
        // let method = this.props.onSelectedJobAndPosition;  // Dynamic Email
        // let argArray = [this.props.match.params.jobUUID, this.props.match.params.position];

        let method
        let argArray
        if (this.props.match.params.suggestedTimeUUID) {  // Strikeout Email
          method = this.props.getSelectedSuggestedTime;
          argArray = [this.props.match.params.suggestedTimeUUID];
          this.setState({ isStrikeout: true });
        }
  
        method.apply(null, argArray)
          .then((response) => {
            const body = response.body;
            if (body) {   
              let suggested_time_start = this._toTimeZone(body.suggested_time_start, this.state.localTimezone);

              this.setState({
                suggestedTimeUUID: body.uuid,
                timeSpan: body.time_span,
                selectedTime: body.selected_time,
                isCancelled: body.is_cancelled,
                selectedDateTime: {
                  "datetime": {
                    "start_time": suggested_time_start,
                    // "end_time": body.suggested_time_end,
                  }
                },
                loading: false,
              })
            }
          })
      }
    } else if (pathname.includes("/time-select/")) {
      let jobUUID = this.state.jobUUID
      this.props.getJobDetails(jobUUID)
      .then((response) => {
        let body = response.body
        this.setState({
          phoneRequired: body.virtual_detail["kind"] === "phone",
          title: body.event_title,
          duration: body.duration,
          internalParticipantNames: body.internal_participant_names ? body.internal_participant_names : [],
          externalParticipantNames: body.external_participant_names ? body.external_participant_names : [],
        })

        this._handleFetchDays(this.state.daysToFetch, jobUUID, this.state.localTimezone)
      })
    }

    this.state.today = new Date()
    this.state.year = this.state.today.getFullYear()
    this.state.month = this.state.today.getMonth()
    this.state.firstDay = new Date(this.state.year, this.state.month, 1)
    this.state.lastDay = new Date(this.state.year, this.state.month + 1, 0)
  },

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.localTimezone !== this.state.localTimezone ||
      prevState.daysToFetch !== this.state.daysToFetch ||
      prevState.suggestMore !== this.state.suggestMore
    ) {
      this._handleFetchDays(this.state.daysToFetch, this.state.jobUUID, this.state.localTimezone)
    }
  },

  _handleFetchDays(days, jobUUID, localTimezone) {
    // will contain at least all availableTimes (potential selectedTime as well)
    let events = []

    // will only contain availableTimes (no selectedTime)
    let originalEvents = []

    let today = new Date()
    let startOffset = today
    let endOffset = new Date(today.getTime() + (days * 24 * 60 * 60 * 1000))

    let startOffsetYear = startOffset.getFullYear()
    let startOffsetMonth = startOffset.getMonth()
    let startOffsetDOM = startOffset.getDate()

    let endOffsetsetYear = endOffset.getFullYear()
    let endOffsetMonth = endOffset.getMonth()
    let endOffsetDOM = endOffset.getDate()

    let start_datetime = new Date(startOffsetYear, startOffsetMonth, startOffsetDOM, 0, 0)
    let end_datetime = new Date(endOffsetsetYear, endOffsetMonth, endOffsetDOM, 23, 59)
    
    let start_iso = start_datetime.toISOString()
    let end_iso = end_datetime.toISOString()
    let timesUrl = 
      `${config.api.host}/customers/times_for_job/${jobUUID}?tz=${localTimezone}&start_time=${start_iso}&end_time=${end_iso}`;

    fetch(timesUrl)
    .then((r) => {
      if (r.ok) {
        return r.json()
      }
    })
    .then((b) => {
      let suggested_times = b.suggested_times

      suggested_times.sort((x, y) => (x.start_time > y.start_time) ? 1 : -1)
      let first_suggested_time

      for (let i = 0; i < suggested_times.length; i++) {
        if (suggested_times[i].active) {
          first_suggested_time = new Date(suggested_times[0].start_time).setHours(0, 0, 0, 0)
          break
        }
      }

      if (!b) return [[], [], null, null, []]

      // check if selected_time already exists
      if (b.selected_time) {
        events.push(
          {
            title: b.event_title, 
            start: this._toTimeZone(b.selected_time, localTimezone),
            end: this._toTimeZone(b.selected_time, localTimezone),
          }
        )

        for (let j = 0; j < b.available_times.length; j++) {
          let at = b.available_times[j]

          let start_time = new Date(at.start_time).setHours(0, 0, 0, 0)

          if (
            !first_suggested_time ||
            (
              first_suggested_time &&
              start_time >= first_suggested_time
            )
          ) {
            events.push(
              {
                title: 'Available Time',
                start: this._toTimeZone(at.start_time, localTimezone), 
                end: this._toTimeZone(at.end_time, localTimezone), 
              }
            )

            originalEvents.push(
              {
                title: 'Available Time',
                start: this._toTimeZone(at.start_time, localTimezone), 
                end: this._toTimeZone(at.end_time, localTimezone), 
              }
            )
          }
        }

        this.setState({
          selectedDateTime: {"datetime": b.selected_time},
          confirmed: true,
        })
      } else {
        for (let i = 0; i < b.available_times.length; i++) {
          let at = b.available_times[i]

          let start_time = new Date(at.start_time).setHours(0, 0, 0, 0)
          // let end_time = new Date(at.end_time).setHours(0, 0, 0, 0)

          if (
            !first_suggested_time ||
            (
              first_suggested_time &&
              start_time >= first_suggested_time
            )
          ) {
            events.push(
              {
                title: 'Available Time',
                start: this._toTimeZone(at.start_time, localTimezone), 
                end: this._toTimeZone(at.end_time, localTimezone), 
              }
            )

            originalEvents.push(
              {
                title: 'Available Time',
                start: this._toTimeZone(at.start_time, localTimezone), 
                end: this._toTimeZone(at.end_time, localTimezone), 
              }
            )
          }
        }
      }
      return [events, originalEvents, b.event_title, b.duration, b.internal_participant_names, b.external_participant_names]
    })
    .then((fetchInfo) => {
      if (fetchInfo[0].length === 0 && !this.state.searchedMore) {
        // search another 30 days in no available times are found
        this.setState({
          searchedMore: true
        })

        this._handleFetchDays(days + 30, jobUUID, localTimezone)
      } else {
        this.setState({
          availableTimes: fetchInfo[0],
          originalAvailableTimes: fetchInfo[1],
          title: fetchInfo[2],
          duration: fetchInfo[3],
          internalParticipantNames: fetchInfo[4],
          externalParticipantNames: fetchInfo[5],
          loading: false,
          calculatedTimes: true,
        })
      }
    })
    .catch((e) => {
      this.setState({
        errorMessage: e.message,
        loading: false,
      })
    })
  },

  render() {
    if (this.state.loading) {
      return (
        <div className="centered-loader">
          <Loader />
        </div>
      )
    }

    return (
      <div className="Timepicker-background">
        <Timepicker
          availableTimes={this.state.availableTimes}
          originalAvailableTimes={this.state.originalAvailableTimes}
          title={this.state.title}
          duration={this.state.duration}
          internalParticipantNames={this.state.internalParticipantNames}
          externalParticipantNames={this.state.externalParticipantNames}
          selectedDateTime={this.state.selectedDateTime}
          localTimezone={this.state.localTimezone}
          phoneRequired={this.state.phoneRequired}
          updating={this.state.updating}
          eventConfirmed={this.state.confirmed}
          calculatedTimes={this.state.calculatedTimes}
          suggestBypass={this.state.suggestBypass}
          skipDetails={this.state.skipDetails}
          year={this.state.year}
          month={this.state.month}
          firstDay={this.state.firstDay}
          lastDay={this.state.lastDay}
          _changeMonth={this._changeMonth}
          _onAccept={this._onAccept}
          _suggestMoreDays={this._suggestMoreDays}
          _changeTimezone={this._changeTimezone}
        />
      </div>
    )
  },
})

const getJobDetailsPromise = wrapPromiseToThunk(
  createAsyncAction("JOB/GET-DETAILS"),
  (_, uuid) => {
    return fetchApi(
      `/jobs/${uuid}/get_details`,
      { method: "POST" }
    )
  }
)

const getSelectedSuggestedTimePromise = wrapPromiseToThunk(
  createAsyncAction('SUGGESTED-TIME/GET-SUGGESTED-TIME'),
  (_, suggestedTimeUUID) => {
    return fetchApi(
      "/suggested_times/suggested_time_status/" + suggestedTimeUUID, { method: "GET" }
    )
  }
)

const mapDispatchToProps = (dispatch) => ({
  getJobDetails(uuid) {
    return dispatch(getJobDetailsPromise(uuid))
  },
  getSelectedSuggestedTime(suggestedTimeUUID) {
    return dispatch(getSelectedSuggestedTimePromise(suggestedTimeUUID))
  }
})

export default connect(null, mapDispatchToProps)(CustomerCalendarView);
