import React from 'react';
import { generateHashColor, extractInitials } from '../live-v2/utils/meeting-utilities.react'
import { getRelativeTime } from '../../../utils/time';
import moment from "moment-timezone";

const MeetingRequest = (props) => {
  const formatTime = time => {
    const hourFormat = "h:mm a";
    const formatStr = `MMM Do, ${hourFormat}`;
    const timeWithTz = moment.tz(time, moment.tz.guess());
  
    return timeWithTz.calendar(null, {
      sameDay: `[Today], ${hourFormat}`,
      lastDay: `[Yesterday], ${hourFormat}`,
      nextDay: `[Tomorrow], ${hourFormat}`,
      lastWeek: formatStr,
      nextWeek: formatStr,
      sameElse: function(givenTime) {
        if (this.year() === givenTime.year()) {
          return formatStr;
        } else {
          return `L, ${hourFormat}`;
        }
      }
    });
  };

  const renderTitleRow = () => {
    let waitingOnMessage
    let waitingSinceIndicator
    let createdIndicator

    switch (props.waitingOn) {
      case "customer":
        waitingOnMessage = "Waiting on Customer"
        break
      case "participants":
        waitingOnMessage = "Waiting on Participants"
        break
      case "clara":
        waitingOnMessage = "Waiting on Clara"
        break
      default:
        waitingOnMessage = "Processing"
    }

    if (props.waitingSince) {
      waitingSinceIndicator = (
        <div className="MeetingRequest-extraIndicator">
          {getRelativeTime(props.waitingSince)}
        </div>
      )
    }

    if (props.created) {
      createdIndicator = (
        <div className="MeetingRequest-extraIndicator">
          {`Created: ${formatTime(props.created)}`}
        </div>
      )
    }

    return (
      <div className="MeetingRequest-titleRow">
        <div
          className="MeetingRequest-titleText"
          style={localStorage.getItem("side-nav-minimized") === "true" ? 
            {maxWidth: `calc(100vw - 676px)`}
            :
            {maxWidth: `calc(80vw - 556px)`}
          }
          title={props.title ? props.title : "No meeting subject."}
        >
          {props.title ? props.title : "No meeting subject."}
        </div>
        <div className="MeetingRequest-extras" id="extras">
          <div className="MeetingRequest-waitingIndicator">
            {waitingOnMessage}
          </div>
          {waitingSinceIndicator}
          {createdIndicator}
          {/* <div className="MeetingRequest-optionsContainer">
            <img src="/images/library-item-options.svg" />
          </div> */}
        </div>
      </div>
    )
  }

  const renderParticipantRow = () => {
    let participants
    
    if (props.participantCount !== 0) {
      participants = (
        <div className="MeetingItem-infoGroup">
          {props.participants.map((participant, index) => {
            let avatarGroup = (
              <div className="MeetingItem-avatarGroup">
                <div
                  className="MeetingItem-avatar"
                  style={{backgroundColor: generateHashColor(participant), color: generateHashColor(participant, true)}}
                >
                  {extractInitials(participant)}
                </div>
                {participant}
              </div>
            )

            if (index === 0) {
              return avatarGroup
            } else {
              return (
                <div className="MeetingItem-avatarDotGroup">
                  <div className="MeetingItem-dot"/>
                  {avatarGroup}
                </div>
              )
            }
              
            
          })}
        </div>
      )
    } else {
      participants = (
        "No participants."
      )
    }

    return (
      <div className="MeetingRequest-participantRow">
        {participants}
      </div>
    )
  }

  return (
    <div className="MeetingRequest">
      {renderTitleRow()}
      {renderParticipantRow()}
    </div>
  )
}

export default MeetingRequest
