/** @format */
import PropTypes from "prop-types";

import React, { Component } from "react";

import {
  Contact,
  Keyword as KeywordSchema
} from "../../../../collections/schema";
import { generateShape } from "../../../../collections/shapes";
import { PreferenceContentBlock } from "../../layout/block.react";
import PillBox from "../../shared/pill-box.react";
import TextButton from "../../shared/text-button.react";
import KeywordsEditor from "./keywords-editor.react";
import { getCurrentCustomer } from "../../../modules/session";
import { isAdmin } from "../../../utils/permissions";
import { connect } from "react-redux";
import { updateKeyword } from "../../../modules/keywords";

const Keyword = ({
  customer_intent,
  keyword,
  organization_id,
  showModal,
  canEdit
}) => {
  return (
    <div className="Keyword">
      <div className="Keyword-title">
        <div className="Keyword-keyword">"{keyword}"</div>
        <PillBox
          text={organization_id ? "Shared" : "Private"}
          modifier="grey"
          small={true}
          className="Keyword-pillBox"
        />
        <TextButton
          className="Keyword-edit"
          onClick={showModal}
          disabled={!canEdit}
        >
          Edit
        </TextButton>
      </div>
      <div className="Keyword-intent">{customer_intent}</div>
    </div>
  );
};

export class Keywords extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isEditing: false,
      keywords: this.props.keywords,
      keyword: null
    };

    this._onSave = this._onSave.bind(this);
  }

  render() {
    const { customerContact } = this.props;
    const keywords = this.state.keywords;

    const accessibleKeywords = keywords.filter(
      ({ contact_id, organization_id }) =>
        contact_id === customerContact.id || organization_id
    );

    const onEditKeyword = keyword => {
      this.setState({
        isEditing: true,
        keyword: keyword
      });
    };

    const items = accessibleKeywords.map(keyword => (
      <Keyword
        showModal={() => onEditKeyword(keyword)}
        canEdit={this.props.customerIsAdmin || !keyword.organization_id}
        key={keyword.id}
        {...keyword}
      />
    ));

    return (
      <PreferenceContentBlock
        title="Codewords"
        subtitle="What unique phrases would you like Clara to understand?"
        supportLink="https://support.claralabs.com/article/201-codewords"
        bordered={this.props.bordered}
      >
        <div className="Keywords">{items.length ? items : "Not set"}</div>
        <KeywordsEditor
          isEditing={this.state.isEditing}
          keyword={this.state.keyword}
          onEditorClose={() => {
            this.setState({ isEditing: false });
          }}
          onUpdate={this._onSave}
        />
      </PreferenceContentBlock>
    );
  }

  _onSave(id, keywordText) {
    this.props.onSave(id, keywordText).then(() => {
      let updatedKeywords = this.state.keywords

      updatedKeywords.forEach(function(k) {
        if (k.id === id) {
          k.keyword = keywordText
        }
      })

      this.setState({
        isEditing: false,
        keywords: updatedKeywords
      });
    });
  }
}

const mapStateToProps = state => {
  const customer = getCurrentCustomer(state);
  return {
    customerIsAdmin: isAdmin(customer.roles),
  };
};

const mapDispatchToProps = dispatch => ({
  onSave(id, keywordText) {
    return dispatch(updateKeyword(id, keywordText));
  }
});

Keywords.propTypes = {
  customerContact: generateShape(Contact).isRequired,
  keywords: PropTypes.arrayOf(generateShape(KeywordSchema)).isRequired
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Keywords);
