import moment from "moment-timezone";
import React, { Component } from "react";
import { connect } from "react-redux";

import SideNavigationMinimized from "../../components/live-v2/side-navigation-minimized.react";
import authRequired from "../../components/shared/auth-required.react";
import {
  getCurrentBillingGroup,
  getCurrentCustomer,
  getCurrentCustomerProfile,
  getIsLoading,
} from "../../modules/session";
import { isAdmin } from "../../utils/permissions";

class SideNavigationMinimizedContainer extends Component {
  render() {
    return <SideNavigationMinimized {...this.props} />;
  }
}

const mapStateToProps = state => {
  if (getIsLoading(state) || !getCurrentCustomer(state)) {
    return { isLoading: true };
  }

  const billingGroup = getCurrentBillingGroup(state);
  const customer = getCurrentCustomer(state);

  return {
    isLoading: false,
    customer,
    customerIsAdmin: isAdmin(customer.roles),
    customerProfile: getCurrentCustomerProfile(state),
    seatType: billingGroup.seat_type,
    isPaying: billingGroup.treat_as_paying,
    trialDaysRemaining: moment(billingGroup.trial_end_date).diff(
      moment.utc(),
      "days"
    ),
    isNewDesign: window.location.href.includes("call-recordings")
  };
}

export default authRequired({ onboarded: true })(
  connect(
    mapStateToProps,
    null
  )(SideNavigationMinimizedContainer)
)