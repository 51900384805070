/** @format */
import { combineReducers } from "redux";
import {
  createAsyncAction,
  wrapPromiseToThunk
} from "../../utils/redux-actions";
import { fetchApi } from "../../utils/request";
import { handleFetchGroupActions } from "../utils/fetch-group";
import { REPORT_ERROR } from "./error";

export const MOUNT_POINT = "candidateFeedback";

const FETCH = createAsyncAction("CANDIDATE_FEEDBACK/FETCH");
const SUBMIT = createAsyncAction("CANDIDATE_FEEDBACK/SUBMIT");

export const submitCandidateFeedback = wrapPromiseToThunk(
  SUBMIT,
  (_, uuid, type, text) =>
    fetchApi(`/endo/candidate_feedbacks/${uuid}`, {
      method: "put",
      json: {
        candidate_feedback: {
          type: type,
          text: text
        }
      }
    }).then(({ body }) => body),
  REPORT_ERROR
);

export const fetchJob = wrapPromiseToThunk(
  FETCH,
  (_, uuid) =>
    fetchApi(`/endo/candidate_feedbacks/${uuid}`)
      .then(({ body }) => body)
      .catch(error => {
        if (
          // Job not found
          error.res.status === 404
        ) {
          return null;
        }
        throw error;
      }),
  REPORT_ERROR
);

export const reducer = combineReducers({
  fetchFetchGroup: handleFetchGroupActions(FETCH, true),
  submitFetchGroup: handleFetchGroupActions(SUBMIT)
});

export const getIsSubmitting = state =>
  state[MOUNT_POINT].fetchFetchGroup.isFetching ||
  state[MOUNT_POINT].submitFetchGroup.isFetching;
