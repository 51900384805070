/** @format */
import {
  handleActions,
  createAsyncAction,
  wrapPromiseToThunk
} from "../../utils/redux-actions";
import { fetchApi } from "../../utils/request";
import fetchGroup from "../utils/fetch-group";
import { REPORT_ERROR } from "./error";
import { getCurrentBillingGroup } from "./session";

const LINK_CARD = createAsyncAction("ACCOUNT/LINK_CARD");

export const linkCard = wrapPromiseToThunk(
  LINK_CARD,
  ({ getState }, plan, token) => {
    const state = getState();
    const billingGroup = getCurrentBillingGroup(state);
    plan = (plan === "executive") ? "exec" : plan // backend calls this plan "exec"

    return fetchApi(`/billing_groups/${billingGroup.id}/actions/set_billing`, {
      method: "POST",
      json: {
        source: token.id,
        plan_type: plan
      }
    }).then(({ body }) => body);
  },
  REPORT_ERROR
);

export const reducer = handleActions(
  {
    [LINK_CARD]: {
      begin: state => ({
        ...state,
        linkCardFetchGroup: state.linkCardFetchGroup.startFetch()
      }),
      next: state => ({
        ...state,
        linkCardFetchGroup: state.linkCardFetchGroup.completeFetch()
      }),
      throw: state => ({
        ...state,
        linkCardFetchGroup: state.linkCardFetchGroup.completeFetch()
      })
    }
  },
  {
    linkCardFetchGroup: fetchGroup(false)
  }
);
