module.exports={
  "name": "endo",
  "version": "0.0.1",
  "description": "Clara customer frontend",
  "main": "index.js",
  "scripts": {
    "clara-build": "NODE_ENV=production gulp release",
    "test": "NODE_ENV=test jest && gulp test",
    "test_frontend": "NODE_ENV=test jest",
    "test_backend": "NODE_ENV=test gulp test",
    "lint": "gulp lint",
    "autoformat": "prettier --config .prettierrc --write '{app,frontend}/**/*.js'"
  },
  "precommit": [],
  "author": "",
  "license": "ISC",
  "jest": {
    "testEnvironment": "jsdom",
    "collectCoverageFrom": [
      "**/frontend/**/*.js"
    ],
    "coveragePathIgnorePatterns": [
      "<rootDir>/build/",
      "<rootDir>/node_modules/"
    ],
    "moduleNameMapper": {
      "^root(.*)$": "<rootDir>/frontend$1",
      "^endo(.*)$": "<rootDir>/frontend/endo$1",
      "^test(.*)$": "<rootDir>/frontend/test$1",
      "^utils(.*)$": "<rootDir>/frontend/utils$1"
    },
    "modulePathIgnorePatterns": [
      "<rootDir>/build/"
    ],
    "setupFiles": [
      "<rootDir>/frontend/test/shims.js",
      "<rootDir>/frontend/test/test-setup.js"
    ],
    "setupFilesAfterEnv": [
      "<rootDir>/frontend/test/setup-test-framework.js"
    ],
    "snapshotSerializers": [
      "<rootDir>/node_modules/enzyme-to-json/serializer"
    ],
    "testMatch": [
      "**/frontend/test/**/*.spec.js"
    ],
    "testPathIgnorePatterns": [
      "<rootDir>/build/",
      "<rootDir>/node_modules/"
    ],
    "transform": {
      "^.+\\.(js)$": "babel-jest"
    },
    "transformIgnorePatterns": [
      "^(?!.*([/\\\\]vogue[/\\\\])).*([/\\\\]node_modules[/\\\\])"
    ],
    "unmockedModulePathPatterns": [
      "node_modules/enzyme/",
      "node_modules/react/",
      "node_modules/vogue/",
      "node_modules/jest-enzyme/"
    ],
    "verbose": false,
    "watchPlugins": [
      "jest-watch-typeahead/filename",
      "jest-watch-typeahead/testname"
    ]
  },
  "dependenciesComments": {
    "react": "ned to upgrade to 18",
    "react-addons-css-transition-group": "need to replace with https://github.com/reactjs/react-transition-group",
    "react-addons-linked-state-mixin": " need to remove references to this package https://www.npmjs.com/package/react-addons-linked-state-mixin",
    "react-addons-pure-render-mixin": "need to remove references to this package https://www.npmjs.com/package/react-addons-pure-render-mixin",
    "react-beautiful-dnd": "can replace this with tailwind",
    "react-big-calendar": "replace with tailwind or do upgrades in this component",
    "vogue": "replace with tailwind or do upgrades in this component",
    "react-daterange-picker": "can replace this with tailwind", 
    "react-dom": "might need to upgrade this", 
    "react-paginate": "can replace this with tailwind", 
    "react-quill": "don't need this for endo, remove", 
    "react-redux": "might need to upgrade this", 
    "superagent": "low priority, upgrade or replace with fetch", 
    "superagent-bluebird-promise": "low priority, replace with native promises", 
    "bluebird": "low priority, replace with native promises",
    "moment": "low priority, replace with datefns or dayjs",
    "gulp": "it looks as if gulp works with node 20, but upgrading to gulp 4 for tailwind has some breaking changes. may be worth it to switch to vite. will need to remove gulp addons as well"
  },
  "dependencies": {
    "aws-sdk": "^2.814.0",
    "bloodhound-js": "^1.2.1",
    "bluebird": "^2.9.27", 
    "browser-cookies": "^1.1.0",
    "bunyan": "^1.7.0",
    "classnames": "^2.1.3",
    "compression": "^1.5.2",
    "convict": "^0.8.0",
    "cookie-parser": "^1.3.5",
    "create-react-class": "^15.7.0",
    "es6-promise": "^3.2.1",
    "es6-promise-debounce": "^1.0.1",
    "eslint-plugin-import": "^2.7.0",
    "eslint-plugin-jsx-a11y": "^5.1.1",
    "express": "^4.12.4",
    "express-handlebars": "^2.0.1",
    "google-maps": "^3.1.0",
    "gulp-imagemin": "^2.3.0",
    "helmet": "5.1.1",
    "invariant": "^2.2.0",
    "is-my-json-valid": "^2.12.2",
    "isomorphic-fetch": "^2.2.1",
    "lodash": "^3.10.1",
    "moment": "^2.19.1",
    "moment-duration-format": "1.3.0",
    "moment-range": "^3.0.0",
    "moment-timezone": "^0.5.35",
    "morgan": "^1.6.1",
    "normalizr": "^3.2.2",
    "parse-link-header": "^0.4.1",
    "prop-types": "^15.6.2",
    "quill": "^1.3.0",
    "raven": "^0.8.1",
    "raven-js": "^3.3.0",
    "react": "16.14.0",
    "react-addons-css-transition-group": "15.6.x",
    "react-addons-linked-state-mixin": "15.6.x",
    "react-addons-pure-render-mixin": "15.6.x",
    "react-beautiful-dnd": "13.1.0",
    "react-big-calendar": "git+https://github.com/clara-labs/react_big_calendar.git#6f275accd41dd1c6d744fc7b98122eb3ce0afc08",
    "react-confetti": "^6.0.1",
    "react-daterange-picker": "2.0.1", 
    "react-dom": "16.14.0", 
    "react-google-maps": "^9.4.5",
    "react-paginate": "^8.1.2", 
    "react-quill": "^1.1.0", 
    "react-redux": "^7.1.23", 
    "react-router-dom": "^5.3.1", 
    "redis": "^2.4.2",
    "reduce-reducers": "^0.1.2",
    "redux": "^3.5.2",
    "redux-actions": "^2.6.5",
    "redux-devtools-extension": "^2.13.0",
    "redux-logger": "^2.6.1",
    "redux-thunk": "^2.1.0",
    "reselect": "^2.5.1",
    "rrule": "^2.1.0",
    "seedrandom": "^3.0.5",
    "superagent": "^1.2.0", 
    "superagent-bluebird-promise": "^2.0.2", 
    "vogue": "git+https://github.com/clara-labs/vogue.git#9f9f5f2e106646483eb50ecae4c84b465f049155",
    "warning": "^2.1.0"
  },
  "devDependencies": {
    "@babel/core": "^7.0.0",
    "@babel/plugin-proposal-object-rest-spread": "^7.17.3",
    "@babel/plugin-transform-destructuring": "^7.17.7",
    "@babel/plugin-transform-runtime": "^7.0.0",
    "@babel/polyfill": "^7.0.0",
    "@babel/preset-env": "^7.16.11",
    "@babel/preset-react": "^7.0.0",
    "babel-core": "7.0.0-bridge.0",
    "babel-eslint": "^9.0.0",
    "babel-jest": "^27.5.1",
    "babel-plugin-syntax-trailing-function-commas": "^6.8.0",
    "babelify": "^10.0.0",
    "body-parser": "^1.14.0",
    "browserify": "^17.0.0",
    "chance": "^1.1.8",
    "envify": "^3.4.0",
    "enzyme": "^3.7.0",
    "enzyme-adapter-react-16": "^1.15.6",
    "enzyme-to-json": "^3.3.5",
    "eslint": "^4.4.1",
    "eslint-config-airbnb": "^15.1.0",
    "eslint-config-prettier": "^2.9.0",
    "eslint-plugin-jest": "^20.0.3",
    "eslint-plugin-prettier": "2.6.2",
    "eslint-plugin-react": "^7.2.1",
    "event-stream": "^3.3.2",
    "faker": "5.5.3",
    "fetch-mock": "^5.12.0",
    "gulp": "^3.8.11", 
    "gulp-babel": "8.0.0",
    "gulp-eslint": "^4.0.0",
    "gulp-ext-replace": "^0.2.0",
    "gulp-if": "^1.2.5",
    "gulp-mocha": "^2.1.3",
    "gulp-nodemon": "^2.0.3",
    "gulp-sourcemaps": "^1.5.2",
    "gulp-util": "^3.0.4",
    "gulp-wrap": "^0.11.0",
    "jest": "^27.5.1",
    "jest-enzyme": "^7.0.1",
    "jest-junit": "^13.0.0",
    "jest-watch-typeahead": "^0.6.5",
    "json-schema-faker": "^0.5.0-rcv.42",
    "mocha": "^8.4.0",
    "mockdate": "^3.0.5",
    "prettier": "^2.6.2",
    "proxyquire": "^1.7.2",
    "react-addons-test-utils": "15.6.x",
    "react-test-renderer": "^16.13.1",
    "redux-mock-store": "^1.2.2",
    "run-sequence": "2.0.0",
    "supertest": "1.1.0",
    "uglifyify": "^5.0.2",
    "vinyl-buffer": "^1.0.0",
    "vinyl-source-stream": "^1.1.0",
    "watchify": "4.0.0"
  },
  "resolutions": {
    "graceful-fs": "^4.2.4"
  },
  "optionalDependencies": {
    "gulp-iconfont": "^8.0.1",
    "gulp-iconfont-css": "^2.1.0",
    "gulp-juice": "^0.1.0",
    "juice": "1.2.0"
  }
}
