/** @format */
import PropTypes from "prop-types";

import React from "react";
import { IconWithTooltip, TextArea } from "vogue";

import { VirtualKind } from "../../../../utils/enums";
import {
  VIRTUAL_KINDS,
  VIRTUAL_KIND_USES_DYNAMIC_LINK
} from "../../../../utils/virtual";
import { VirtualDetailShape } from "../../../utils/shapes";
import SelectMenu from "../../shared/select-menu.react";
import ValidatingTextField from "../../shared/validating-text-field.react";
import FormInputContainer from "../../shared/form-input-container.react";

const FORM_LABELS = {
  [VirtualKind.phone]: {
    valueLabel: "Phone number",
    additionalInfoLabel: "e.g. Give me a text before you call"
  },
  [VirtualKind.skype]: {
    valueLabel: "Skype ID",
    additionalInfoLabel: "e.g. Give me a text before you call"
  },
  [VirtualKind.googlehangout]: {
    valueLabel: "Hangouts link",
    additionalInfoLabel: "e.g. Give me a text before you call"
  },
  [VirtualKind.googlemeet]: {
    // valueLabel is intentionally omitted as Google Meet virtual details don't have a value.
    additionalInfoLabel: "e.g. Give me a text before you call"
  },
  [VirtualKind.conference_line]: {
    valueLabel: "Conference number",
    additionalInfoLabel: "e.g. PIN NUMBER: 409730"
  },
  [VirtualKind.zoom]: {
    // valueLabel is intentionally omitted as Zoom virtual details don't have a value.
    additionalInfoLabel: "e.g. Give me a text before you call"
  },
  [VirtualKind.microsoft_teams]: {
    // valueLabel is intentionally omitted as Zoom virtual details don't have a value.
    additionalInfoLabel: "e.g. Give me a text before you call"
  },
  [VirtualKind.coderpad]: {
    // valueLabel is intentionally omitted as Coderpad virtual details don't have a value.
    additionalInfoLabel: "e.g. Give me a text before you call"
  },
  [VirtualKind.hackerrank]: {
    // valueLabel is intentionally omitted as Hackerrank virtual details don't have a value.
    additionalInfoLabel: "e.g. Give me a text before you call"
  }
};

const getValueValidationError = (value, kind, meetingType) => {
  if (kind === VirtualKind.phone && meetingType.who_contacts_who === "customer") {
    return false
  } else if (!value || value.trim().length === 0) {
    return "Value field cannot be left blank";
  }
};

export const getValidationError = ({ value, kind }, meetingType) =>
  VIRTUAL_KIND_USES_DYNAMIC_LINK.includes(kind)
    ? false
    : getValueValidationError(value, kind, meetingType);

const VirtualMeetingDetailForm = ({
  forceValidation,
  onChange,
  onChangeMeetingType,
  virtualMeetingDetail,
  meetingType,
  virtualKinds
}) => {
  let supportLink;
  if (virtualMeetingDetail.kind === VirtualKind.googlehangout) {
    supportLink = (
      <a
        className="VirtualMeetingDetailForm-supportLink"
        target="_blank"
        href="https://support.claralabs.com/hc/en-us/articles/115006885188"
      >
        Learn how to configure Google Hangouts
      </a>
    );
  }

  const additionalCallDetailsHeading = (
    <span>
      Additional call details
      <IconWithTooltip
        name="messageid"
        tooltip="This will be added to the event description"
        className="VirtualMeetingDetailForm-toolTip"
      />
    </span>
  );

  const whoCallsWhomOptions = [
    {label: "You call them", value: "customer"},
    {label: "They call you", value: "participant"}
  ]

  let whoCallsWhomContainer;
  if (virtualMeetingDetail.kind === VirtualKind.phone) {
    whoCallsWhomContainer = (
      <FormInputContainer
        heading={"Who calls whom"}
      >
        <SelectMenu
          options={whoCallsWhomOptions}
          onChange={event => {
            if (event.target.value === "customer") {
              onChange("value", { value: "" })
            }
            onChangeMeetingType("who_contacts_who", event.target)
          }}
          value={meetingType.who_contacts_who}
        />
      </FormInputContainer>
    );
  }

  let valueInputContainer;
  if (FORM_LABELS[virtualMeetingDetail.kind].valueLabel && 
    (virtualMeetingDetail.kind !== VirtualKind.phone || meetingType.who_contacts_who === "participant")
  ) {
    valueInputContainer = (
      <FormInputContainer
        heading={FORM_LABELS[virtualMeetingDetail.kind].valueLabel}
      >
        <ValidatingTextField
          value={virtualMeetingDetail.value}
          validate={getValueValidationError}
          forceValidation={forceValidation}
          onChange={event => onChange("value", event.target)}
        />
      </FormInputContainer>
    );
  }


  const virtualKindOptions = VIRTUAL_KINDS.filter(
    ({ value }) => virtualKinds.indexOf(value) !== -1
  );

  return (
    <div>
      <FormInputContainer heading="Channel type">
        <SelectMenu
          options={virtualKindOptions}
          onChange={event => onChange("kind", event.target)}
          value={virtualMeetingDetail.kind}
        />
        {supportLink}
      </FormInputContainer>

      {whoCallsWhomContainer}
      {valueInputContainer}

      <FormInputContainer heading={additionalCallDetailsHeading}>
        <TextArea
          placeholder={
            FORM_LABELS[virtualMeetingDetail.kind].additionalInfoLabel
          }
          value={virtualMeetingDetail.additional_info}
          onChange={event => onChange("additional_info", event.target)}
          rows={2}
          autoFocus={false}
        />
      </FormInputContainer>
    </div>
  );
};

VirtualMeetingDetailForm.defaultProps = {
  forceValidation: false
};

VirtualMeetingDetailForm.propTypes = {
  onChange: PropTypes.func.isRequired,
  virtualMeetingDetail: VirtualDetailShape.isRequired,
  forceValidation: PropTypes.bool,
  virtualKinds: PropTypes.arrayOf(PropTypes.oneOf(Object.keys(VirtualKind)))
    .isRequired
};

export default VirtualMeetingDetailForm;
