import React from 'react';
import createReactClass from 'create-react-class';

const ComponentWithExtraProps = (Component, extraProps) => {
  const component = createReactClass({
    render() {
      const props = Object.assign({}, extraProps, this.props);
      return <Component {...props} />;
    },
  });
  return component;
};

export default ComponentWithExtraProps;
