/** @format */
import PropTypes from "prop-types";

import React, { Component } from "react";
import { Block, Content, Head } from "../../layout/block.react";
import { LongTextPreference } from "../preferences/basic.react";
import LocationDetailEditor from "./editor.react";
import StaticMap from "../../shared/maps/static-map.react";
import ExpandableTextDisplay from "../../shared/expandable-text-display.react";
import { LocationShape } from "../../../utils/shapes";
import { LOCATION_NAME_MAPPING } from "../../../../utils/location";
import { DefaultLocationCategory } from "../../../../utils/enums";

// NOTE(gavin): These dimensions were chosen based on what looked acceptable at the most screen
// sizes. In the future we might want to generate different images based on the screen size.
const MAP_DIMENSIONS = {
  // Ensure that this value is kept in sync with the styling for the <img> element.
  WIDTH: 585,
  HEIGHT: 300
};

export default class LocationDetail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isEditing: false
    };

    this._closeEditor = this._closeEditor.bind(this);
    this._openEditor = this._openEditor.bind(this);
  }

  render() {
    const {
      location: {
        additional_info,
        category,
        formatted_address,
        is_default,
        name
      }
    } = this.props;

    let additionalInfo;
    if (!additional_info || additional_info.trim().length === 0) {
      additionalInfo = (
        <span className="PropertyDetail-value--empty">
          No additional details
        </span>
      );
    } else {
      additionalInfo = <ExpandableTextDisplay content={additional_info} />;
    }

    return (
      <Block bordered={this.props.bordered}>
        <StaticMap
          address={formatted_address}
          width={MAP_DIMENSIONS.WIDTH}
          height={MAP_DIMENSIONS.HEIGHT}
          className="LocationDetail-map"
        />
        <Head
          title={
            LOCATION_NAME_MAPPING[category] + (is_default ? " (default)" : "")
          }
          subtitle={name}
        />
        <Content onEdit={this._openEditor}>
          <div className="LocationDetail-content">
            <LongTextPreference>{formatted_address}</LongTextPreference>
            <div className="PropertyDetail LocationDetail-propertyDetail">
              <div className="PropertyDetail-name">
                Additional location details
              </div>
              <div className="PropertyDetail-value">{additionalInfo}</div>
            </div>
          </div>
        </Content>
        <LocationDetailEditor
          id={this.props.location.id}
          onEditorClose={this._closeEditor}
          isEditing={this.state.isEditing}
          defaultLocationCategory={this.props.defaultLocationCategory}
        />
      </Block>
    );
  }

  _openEditor() {
    this.setState({ isEditing: true });
  }

  _closeEditor() {
    this.setState({ isEditing: false });
  }
}

LocationDetail.propTypes = {
  location: LocationShape.isRequired,
  defaultLocationCategory: PropTypes.oneOf(
    Object.values(DefaultLocationCategory)
  ).isRequired
};
