import PropTypes from 'prop-types';
import React from 'react';
import createReactClass from 'create-react-class';
import ReactDOM from 'react-dom';
import { TextField } from 'vogue';
import GoogleMapsAPI from '../../../utils/google-maps';

const GooglePlacesSearchBox = createReactClass({
  propTypes: {
    onChange: PropTypes.func.isRequired,  // triggers for the selection of a location
    disabled: PropTypes.bool,
    label: PropTypes.string,
    initialValue: PropTypes.string, // set the search box to contain an initial value
    onTextChange: PropTypes.func, // triggers for each change to the content
                                  // of the textfield (appx per keystroke)
    modifiers: PropTypes.arrayOf(PropTypes.string),
    error: PropTypes.bool,
    helperText: PropTypes.string,
  },

  getDefaultProps() {
    return {
      disabled: false,
      label: 'Places Search',
    };
  },

  getInitialState() {
    return {
      __TEXTFIELD_BINDING_DO_NOT_USE__: '' || this.props.initialValue,
      addressIsInvalid: false,
    };
  },

  componentDidMount() {
    GoogleMapsAPI.load().then((google) => {

      // don't cargo cult this - useful for having TextField behavior on the input
      const inputEl = ReactDOM.findDOMNode(this.refs.input).querySelector('input.TextField');

      const autocomplete = new google.maps.places.Autocomplete(inputEl);
      autocomplete.addListener('place_changed', () => {
        const place = autocomplete.getPlace();

        // If the user pressed enter on the text input instead of selecting a place from the
        // dropdown there won't be any of the necessary place data.
        if (!place.formatted_address) {
          this.setState({
            // keep the value that google provides in the text box
            __TEXTFIELD_BINDING_DO_NOT_USE__: inputEl.value,
            addressIsInvalid: true,
          });
          return;
        }

        // keep the value that google provides in the text box
        this.setState({
          __TEXTFIELD_BINDING_DO_NOT_USE__: inputEl.value,
          addressIsInvalid: false,
        });

        this.props.onChange({
          name: place.name,
          formattedAddress: place.formatted_address,
          googlePlaceId: place.place_id,
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
        });
      });
    });
  },

  onTextChange({ target: { value } }) {
    this.setState({
      __TEXTFIELD_BINDING_DO_NOT_USE__: value,
    });
    if (this.props.onTextChange) {
      this.props.onTextChange(value);
    }
  },

  render() {
    const { disabled, label } = this.props;

    let modifiers = this.props.modifiers || [];
    let helperText = this.props.helperText || null;

    if (this.state.addressIsInvalid || this.props.error) {
      modifiers = ['error'];
      helperText = this.props.helperText || 'Please choose an address from the list';
    }

    return (
      <TextField
        placeholder=" " // gets passed to <TextField>'s internal <input>
        label={ label }
        ref="input"
        disabled={ disabled }
        value={ this.state.__TEXTFIELD_BINDING_DO_NOT_USE__ }
        onChange={ this.onTextChange }
        modifiers={ modifiers }
        helperText={ helperText }
      />
    );
  },
});

export default GooglePlacesSearchBox;