export const enableZendesk = () => {
  if (window.zE) {
    // HACK(gavin): There's a race condition with the Zendesk script loading and overriding our call
    // to show the widget; this fixes the issue for now.
    setTimeout(() => {
      zE(zE.show); // eslint-disable-line no-undef
    }, 1);
  }
};

export const disableZendesk = () => {
  if (window.zE) {
    // HACK(gavin): There's a race condition with the Zendesk script loading and overriding our call
    // to hide the widget; this fixes the issue for now.
    setTimeout(() => {
      zE(zE.hide); // eslint-disable-line no-undef
    }, 1);
  }
};
