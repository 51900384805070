/** @format */
import PropTypes from "prop-types";

import React, { Component } from "react";
import { Button } from "vogue";
import { connect } from "react-redux";
import config from "../../../config";
import { getCurrentCustomer } from "../../modules/session";
import { linkCard } from "../../modules/account";
import TextButton from "./text-button.react";

const loadStripe = onload => {
  // from http://stackoverflow.com/questions/41500135/react-js-stripe-checkout-is-not-working/41500470#41500470
  if (!window.StripeCheckout) {
    const script = document.createElement("script");
    script.onload = function() {
      onload();
    };
    script.src = "https://checkout.stripe.com/checkout.js";
    document.head.appendChild(script);
  } else {
    onload();
  }
};

export class StripeButton extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isCollecting: false
    };

    this._collect = this._collect.bind(this);
  }

  UNSAFE_componentWillMount() {
    loadStripe(() => {
      this._handler = window.StripeCheckout.configure({
        key: config.stripe.publicKey,
        image: "/images/logo-padded.png",
        email: this.props.email,
        token: token => {
          this.props
            .onLinkCard(this.props.plan, token)
            .then(this.props.onSuccess);
        },
        name: "Clara Labs",
        panelLabel: "Link Card",
        allowRememberMe: false,
        closed: () => {
          this.setState({ isCollecting: false });
        },
        zipCode: true
      });
    });
  }

  componentWillUnmount() {
    if (this._handler) {
      this._handler.close();
    }
  }

  _collect() {
    this.setState({
      isCollecting: true
    });

    this._handler.open();
  }

  render() {
    const ButtonKlass = this.props.isTextButton ? TextButton : Button;

    return (
      <ButtonKlass
        className={this.props.className}
        onClick={this._collect}
        loading={this.state.isCollecting || this.props.isLoading}
        loadingAnimation={!this.props.isInitialLoad}
      >
        {this.props.children}
      </ButtonKlass>
    );
  }
}
StripeButton.propTypes = {
  onSuccess: PropTypes.func,
  plan: PropTypes.string,
  email: PropTypes.string,
  isLoading: PropTypes.bool.isRequired,
  onLinkCard: PropTypes.func.isRequired,
  className: PropTypes.string,
  isInitialLoad: PropTypes.bool.isRequired,
  isTextButton: PropTypes.bool
};
StripeButton.defaultProps = {
  onSuccess: () => {}
};

const mapStateToProps = state => {
  const customer = getCurrentCustomer(state);
  if (!customer) {
    return {
      isLoading: true,
      isInitialLoad: true
    };
  }

  return {
    isLoading: state.account.linkCardFetchGroup.isFetching,
    email: state.collections.contacts[customer.contact].default_email,
    isInitialLoad: false
  };
};

const mapDispatchToProps = dispatch => ({
  onLinkCard(plan, token) {
    return dispatch(linkCard(plan, token));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StripeButton);
