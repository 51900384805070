/** @format */
import PropTypes from "prop-types";

import React, { Component } from "react";
import { connect } from "react-redux";
import { Modal } from "vogue";

import { PreferenceContentBlock } from "../../layout/block.react";
import ValidatingTextField from "../../shared/validating-text-field.react";
import {
  getIsSettingBillingPointOfContact,
  setBillingPointOfContact
} from "../../../modules/team";
import EmailAddressUtils from "../../../../utils/email-address";

const validateValue = value => {
  if (!value || value.trim().length === 0)
    return "Email address cannot be left blank";
  if (!EmailAddressUtils.isValid(value))
    return "Invalid email address provided.";
};

export class BillingPointOfContact extends Component {
  constructor(props) {
    super(props);

    this.state = {
      editing: false,
      emailAddress: props.emailAddress,
      forceValidation: false
    };

    this._openEditor = this._openEditor.bind(this);
    this._handleCancel = this._handleCancel.bind(this);
    this._handleSave = this._handleSave.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.emailAddress !== this.props.emailAddress) {
      this.setState({ emailAddress: nextProps.emailAddress });
    }
  }

  render() {
    const modalActions = [
      {
        key: "save",
        label: "Save",
        modifier: "primary",
        handler: this._handleSave
      },
      {
        key: "cancel",
        label: "Cancel",
        handler: this._handleCancel
      }
    ];

    return (
      <PreferenceContentBlock
        title="Billing email address"
        subtitle="Where should Clara send billing notifications?"
        onEdit={this._openEditor}
        bordered={this.props.bordered}
      >
        <div className="BillingPointOfContact-details">
          <div className="BillingPointOfContact-email">
            {this.props.emailAddress || "Not Set"}
          </div>
        </div>

        <Modal
          actions={modalActions}
          open={this.state.editing}
          loading={this.props.isLoading}
        >
          <div className="BillingPointOfContact-inputHeading">
            Billing Email Address
          </div>
          <ValidatingTextField
            value={this.state.emailAddress}
            validate={validateValue}
            forceValidation={this.state.forceValidation}
            onChange={event =>
              this.setState({ emailAddress: event.target.value })
            }
          />
        </Modal>
      </PreferenceContentBlock>
    );
  }

  _handleCancel() {
    this.setState({
      editing: false,
      forceValidation: false,
      emailAddress: this.props.emailAddress
    });
  }

  _handleSave() {
    if (validateValue(this.state.emailAddress)) {
      this.setState({ forceValidation: true });
    } else {
      this.props
        .onSetBillingPointOfContact(
          this.props.billingGroupId,
          this.state.emailAddress
        )
        .then(() => {
          this.setState({
            editing: false,
            forceValidation: false
          });
        });
    }
  }

  _openEditor() {
    this.setState({ editing: true });
  }
}

BillingPointOfContact.propTypes = {
  billingGroupId: PropTypes.number.isRequired,
  emailAddress: PropTypes.string,
  isLoading: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
  isLoading: getIsSettingBillingPointOfContact(state)
});

const mapDispatchToProps = dispatch => ({
  onSetBillingPointOfContact(billingGroupId, email) {
    return dispatch(setBillingPointOfContact(billingGroupId, email));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BillingPointOfContact);
