/** @format */
import React, { Component } from "react";
import Testimonials from "./testimonials.react";
import config from "../../../config";
import querystringParse from "../../../utils/querystring";
import authRequired from "../shared/auth-required.react";
import StripeButton from "../shared/stripe-button.react";

export class LinkCard extends Component {
  render() {
    const parsedPlan = querystringParse().plan;
    let chosenPlan;
    if (parsedPlan) {
      chosenPlan = (
        <span>
          You&#39;ve chosen the&nbsp;
          <span className="LinkCard-plan">{parsedPlan}</span>&nbsp;plan.&nbsp;
        </span>
      );
    }

    const referralPrompt = (
      <p>
        {chosenPlan}
        Save $100 now by simply telling your friends about Clara!&nbsp;
      </p>
    );

    const onSuccess = () => {
      // hard refresh to fetch new data
      window.location = `${config.services.endo.host}#success`;
    };

    return (
      <div className="LinkCard-container">
        <div className="LinkCard">
          <div className="container">
            <div className="row">
              <div className="col-xs-12">
                <h2 className="LinkCard-header">Link a credit card</h2>
              </div>
            </div>
            <div className="row LinkCard-imageWrapper">
              <div className="col-xs-12">
                <img
                  className="LinkCard-creditCard"
                  src="/images/creditcard-grad.svg"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-xs-12 col-sm-6 col-sm-offset-3">
                {referralPrompt}
              </div>
            </div>
            <div className="row LinkCard-buttons">
              <div className="col-xs-12 col-sm-4 col-sm-offset-4">
                <StripeButton onSuccess={onSuccess} plan={parsedPlan}>
                  Add Credit Card
                </StripeButton>
              </div>
            </div>
            <div className="row">
              <div className="col-xs-12">
                <p className="LinkCard-description">
                  Your payment information is secured via Stripe.
                </p>
              </div>
            </div>
          </div>
          <Testimonials />
        </div>
      </div>
    );
  }
}

export default authRequired({
  onboarded: true
})(LinkCard);
