/** @format */
import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";

import { ResourceSyncedCalendarShape } from "../../../../utils/shapes";
import SelectMenu from "../../../shared/select-menu.react";

const CAPACITY_OPTIONS = [{ label: "0", value: "", disabled: true }].concat(
  [
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15"
  ].map(opt => ({ value: opt, label: opt }))
);

const ListItem = ({ resource, onChange, forceValidation }) => (
  <div className="Capacity-listItem">
    <div className="Capacity-resourceDetails">
      <div className="Capacity-name">{resource.name}</div>
      <div className="Capacity-description">{resource.description}</div>
    </div>
    <div className="Capacity-select">
      <SelectMenu
        options={CAPACITY_OPTIONS}
        onChange={({ target: { value } }) => onChange(parseInt(value, 10))}
        value={String(resource.capacity || "")}
        error={forceValidation && !resource.capacity}
        noUnderline={true}
      />
    </div>
  </div>
);

const Capacity = ({ onChange, forceValidation, selectedResources }) => {
  const listClasses = classNames("Capacity-list", {
    "Capacity-list--error": forceValidation
  });
  let errorMessage;
  if (forceValidation) {
    errorMessage = (
      <div className="Capacity-error">
        Please select a non-zero capacity value for all rooms.
      </div>
    );
  }

  return (
    <div className="Capacity">
      <div className="Capacity-explanation">
        Set the maximum capacity of each room, so Clara can choose an
        appropriately sized room based on number of participants in a meeting.
      </div>
      {errorMessage}
      <div className={listClasses}>
        {selectedResources.map(resource => (
          <ListItem
            key={resource.provider_id}
            resource={resource}
            onChange={capacity =>
              onChange({ provider_id: resource.provider_id, capacity })
            }
            forceValidation={forceValidation}
          />
        ))}
      </div>
    </div>
  );
};

Capacity.propTypes = {
  selectedResources: PropTypes.arrayOf(ResourceSyncedCalendarShape).isRequired,
  forceValidation: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired
};

export default Capacity;
