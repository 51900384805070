/** @format */
import { get, isEqual, uniq } from "lodash";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { Modal } from "vogue";

import { VirtualKind } from "../../../../utils/enums";
import { VirtualDetailShape } from "../../../utils/shapes";
import VirtualMeetingDetailForm, { getValidationError } from "./form.react";

export default class VirtualMeetingDetailEditor extends Component {
  constructor(props) {
    super(props);

    this.state = {
      virtualDetail: props.virtualDetail,
      meetingType: props.meetingType,
      forceValidation: false
    };

    this._onChange = this._onChange.bind(this);
    this._handleRemove = this._handleRemove.bind(this);
    this._handleSave = this._handleSave.bind(this);
    this._onChangeMeetingType = this._onChangeMeetingType.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!isEqual(nextProps.virtualDetail, this.state.virtualDetail)) {
      this.setState({
        virtualDetail: nextProps.virtualDetail,
        forceValidation: false
      });
    }
  }

  render() {
    const { isEditing, onEditorClose } = this.props;

    const modalActions = [
      {
        key: "save",
        label: "Save",
        modifier: "primary",
        handler: this._handleSave
      },
      {
        key: "cancel",
        label: "Cancel",
        handler: onEditorClose
      }
    ];

    const categories = get(this.props, "virtualDetail.categories", []);
    if (
      !categories.includes("group_call_default") &&
      !categories.includes("direct_call_default")
    ) {
      modalActions.push({
        key: "remove",
        label: "Remove",
        modifier: "reject",
        handler: this._handleRemove
      });
    }

    const virtualDetailKind = get(this.props, "virtualDetail.kind");

    const virtualKinds = this.props.availableVirtualKinds.filter(kind => {
      if (kind === VirtualKind.googlehangout && kind !== virtualDetailKind) {
        // Don't allow the creation of any new Google Hangout virtual details.
        return false;
      } else if (kind === VirtualKind.zoom && kind !== virtualDetailKind) {
        // Don't allow creation of a Zoom virtual detail if there's no active Zoom account.
        return !!this.props.hasActiveZoomAccount;
      } else if (kind === VirtualKind.coderpad && kind !== virtualDetailKind) {
        return this.props.isCoderpadActive;
      } else if (
        kind === VirtualKind.hackerrank &&
        kind !== virtualDetailKind
      ) {
        return this.props.isHackerrankActive;
      } else if (kind === VirtualKind.microsoft_teams) {
        return this.props.isMicrosoft;
      }
      return true;
    });

    return (
      <Modal
        actions={modalActions}
        open={isEditing}
        loading={get(this.props, "virtualDetail.meta.loading", false)}
        onRequestClose={this.props.onEditorClose}
        ref="editorModal"
        classNames={{
          modal: ["VirtualMeetingDetailEditor-modal"]
        }}
      >
        <VirtualMeetingDetailForm
          onChange={this._onChange}
          forceValidation={this.state.forceValidation}
          virtualMeetingDetail={this.state.virtualDetail}
          virtualKinds={uniq(virtualKinds.concat(virtualDetailKind))}
          meetingType={this.state.meetingType}
          onChangeMeetingType={this._onChangeMeetingType}
        />
      </Modal>
    );
  }

  _handleRemove() {
    return this.props
      .onDeleteVirtualDetail(this.state.virtualDetail)
      .then(this.props.onEditorClose);
  }

  _handleSave() {
    if (getValidationError(this.state.virtualDetail, this.state.meetingType)) {
      this.setState({ forceValidation: true });
      if (this.refs.editorModal) this.refs.editorModal.shake();
    } else {
      return this.props
        .onUpdateVirtualDetail(this.state.virtualDetail)
        .then(this.props.onUpdateMeetingType(this.state.meetingType))
        .then(this.props.onEditorClose);
    }
  }

  _onChange(key, { value }) {
    this.setState({
      virtualDetail: {
        ...this.state.virtualDetail,
        [key]: value
      }
    });
  }

  _onChangeMeetingType(key, { value }) {
    this.setState({
      meetingType: {
        ...this.state.meetingType,
        [key]: value
      }
    })
  }
}

VirtualMeetingDetailEditor.propTypes = {
  virtualDetail: VirtualDetailShape,
  virtualDetailId: PropTypes.number,
  onUpdateVirtualDetail: PropTypes.func.isRequired,
  onDeleteVirtualDetail: PropTypes.func.isRequired,
  onEditorClose: PropTypes.func.isRequired,
  isEditing: PropTypes.bool.isRequired,
  hasActiveZoomAccount: PropTypes.bool.isRequired,
  isCoderpadActive: PropTypes.bool.isRequired,
  isHackerrankActive: PropTypes.bool.isRequired,
  isMicrosoft: PropTypes.bool.isRequired,
  availableVirtualKinds: PropTypes.arrayOf(
    PropTypes.oneOf(Object.keys(VirtualKind))
  ).isRequired
};
