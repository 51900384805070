let Configuration;
if (typeof window === 'undefined') {
  Configuration = {};
} else {
  if (global.PRELOAD) {
    Configuration = global.PRELOAD.CONFIG;
  } else {
    Configuration = window.PRELOAD.CONFIG;
  }
}

export default Configuration;
