/** @format */
import PropTypes from "prop-types";

import React, { Component } from "react";
import { Button } from "vogue";

import { GoogleAccount, MicrosoftAccount } from "../../../collections/schema";
import { generateShape } from "../../../collections/shapes";
import CalendarList from "../shared/calendar-list.react";
import OnboardingHeader from "./onboarding-header.react";
import OnboardingLoader from "./onboarding-loader.react";

export default class CalendarSelect extends Component {
  constructor(props) {
    super(props);

    this.state = this._getInitialState(props);

    this._handleSelectClick = this._handleSelectClick.bind(this);
    this._onChange = this._onChange.bind(this);
  }

  _getInitialState(props) {
    const account = props.googleAccount || props.microsoftAccount;
    return {
      checkedIds: account
        ? account.contact_synced_calendars
            .filter(c => c.primary || c.informs_availability)
            .map(c => c.id)
        : [],
      skipStep: false
    };
  }

  UNSAFE_componentWillMount() {
    const account = this.props.googleAccount || this.props.microsoftAccount;
    if (!account && this.props.user) {
      this.props.onUser();
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const previousAccount =
      this.props.googleAccount || this.props.microsoftAccount;
    const nextAccount = nextProps.googleAccount || nextProps.microsoftAccount;

    if (!nextAccount && nextProps.user && nextProps.user !== this.props.user) {
      this.props.onUser();
    }

    if (!previousAccount && nextAccount) {
      if (nextAccount.contact_synced_calendars.length === 1) {
        nextProps.onSetCalendars(
          nextAccount.contact_synced_calendars.map(c => c.id),
          nextProps.microsoftAccount ? nextProps.microsoftAccount.id : null,
          nextProps.googleAccount ? nextProps.googleAccount.id : null
        );
        this.setState({ skipStep: true });
      } else {
        this.setState(this._getInitialState(nextProps));
      }
    }
  }

  _handleSelectClick() {
    this.props.onSetCalendars(
      this.state.checkedIds,
      this.props.microsoftAccount ? this.props.microsoftAccount.id : null,
      this.props.googleAccount ? this.props.googleAccount.id : null
    );
  }

  _onChange(checkedIds) {
    this.setState({ checkedIds });
  }

  render() {
    const {
      googleAccount,
      microsoftAccount,
      isError,
      isLoading,
      isCompleting
    } = this.props;
    const { skipStep } = this.state;

    if (skipStep || isLoading) {
      return <OnboardingLoader loadingText="Loading your calendars..." />;
    }

    const account = googleAccount || microsoftAccount;
    let errorMessage;
    let form;

    if (isError) {
      errorMessage = (
        <div className="CalendarSelect-errorMessage">
          <span>
            We&#39;re unable to load your calendars. Please reload the page to
            try again, or if this error persists,{" "}
            <a href="mailto:support@claralabs.com">contact support</a>.
          </span>
        </div>
      );
    } else if (account) {
      form = (
        <form className="CalendarSelect-form">
          <CalendarList
            onChange={this._onChange}
            checkedIds={this.state.checkedIds}
            contactSyncedCalendars={account.contact_synced_calendars}
          />
          <Button
            onClick={this._handleSelectClick}
            className="CalendarSelect-button"
            disabled={this.state.checkedIds.length === 0}
            loading={isCompleting}
          >
            Continue
          </Button>
        </form>
      );
    }

    return (
      <div className="CalendarSelect">
        <OnboardingHeader
          title="Your calendars"
          subtext="Clara will use these calendars to determine your availability."
        />
        {form}
        {errorMessage}
      </div>
    );
  }
}

const accountExpansions = {
  email_address: {},
  contact_synced_calendars: {
    synced_calendar: {}
  }
};

CalendarSelect.propTypes = {
  user: PropTypes.object,
  isCompleting: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  googleAccount: generateShape(GoogleAccount, accountExpansions),
  microsoftAccount: generateShape(MicrosoftAccount, accountExpansions),
  isError: PropTypes.bool,
  onUser: PropTypes.func.isRequired,
  onSetCalendars: PropTypes.func.isRequired
};
