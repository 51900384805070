/** @format */

/* eslint-disable no-undef */
/* eslint-disable no-labels */
/* eslint-disable no-unused-vars */
/* eslint-disable no-use-before-define */
import React, { useState, useEffect, useLayoutEffect, useRef } from "react";
import MiniCalendar from "./calendar/mini-calendar.react";
import moment from "moment";

export const Timepicker = (props) => {
  // takes list of datetimeObjects and returns list of objects containing the original datetimeObject and dateStrings 
  // (e.g. {"datetime": dateObject, "datetimeString": "Thursday, April 21"})
  const listifyAvailableDates = (availableTimes) => {
    // sort availableTimes chronologically
    availableTimes.sort(function(a, b) {
      return new Date(a.start.toString()) - new Date(b.start.toString())
    })

    let availableDatesList = []
    // used for removing dupes
    let lastDateObject = null

    for (let i = 0; i < availableTimes.length; i++) {
      let datetime = availableTimes[i]
      
      let dow = datetime.start.getDay()
      let month = datetime.start.getMonth()
      let dom = datetime.start.getDate()

      let dateObject = {
        'dow': dow,
        'month': month,
        'dom': dom,
      }

      // compare for dupes
      if (
        lastDateObject !== null &&
        dateObject.dow === lastDateObject.dow && 
        dateObject.month === lastDateObject.month &&
        dateObject.dom === lastDateObject.dom
      ) {
        continue
      }

      lastDateObject = {
        'dow': dow,
        'month': month,
        'dom': dom
      }

      availableDatesList.push(
        {
          datetime: datetime,
          datetimeString: moment(datetime.start).format("dddd, MMMM Do")
        }
      )
    }
    return availableDatesList
  }

  // takes dateObject and returns list of start times
  const listifyAvailableTimes = (availableTimes, dateObject) => {
    if (!availableTimes || !dateObject) return []

    // grab all dateObjects that have the same DOW, MONTH, and DOM as the given dateObject
    let dowGiven = dateObject.start.getDay()
    let monthGiven = dateObject.start.getMonth()
    let domGiven = dateObject.start.getDate()

    let availableStartsList = []

    for (let i = 0; i < availableTimes.length; i++) {
      let atObject = availableTimes[i]

      let dow = atObject.start.getDay()
      let month = atObject.start.getMonth()
      let dom = atObject.start.getDate()

      // date match found
      if (dowGiven === dow && monthGiven === month && domGiven === dom) {
        let atStart = atObject.start
        let atEnd = atObject.end
        let startPointer = atStart

        // earliest available start time should be at least 4 hours from now
        let bufferMoment = moment().add(4, "h").toDate()

        // continuously find time windows that fit in the [start, end] range

        // if the start time is not 00, 15, 30, or 45 minutes, round up to the nearest 15 minutes
        let startMinutes = moment(startPointer).toDate().getMinutes()
        if (startMinutes % 15 !== 0) {
          startPointer = moment(startPointer).add(15 - startMinutes % 15, 'm').toDate()
        }

        while (true) {
          let endPointer = moment(startPointer).add(props.duration, 'm').toDate()
          if (endPointer <= atEnd) {

            if (bufferMoment <= startPointer) {
              availableStartsList.push({
                datetime: startPointer,
                datetimeString: moment(startPointer).format("h:mm a").toUpperCase(),
                datetimeString24: moment(startPointer).format("H:mm").toUpperCase(),
              })
            }

            // increment new start time by 30 minutes
            startPointer = moment(startPointer).add(15, 'm').toDate()
          } else {
            // the start pointer for this available time no longer works, move on to the next
            break
          }
        }
      }
    }

    return availableStartsList
  }

  // closes the timezone dropdown when the user clicks away
  const detectOutsideClick = (ref) => {
    useEffect(() => {
      const handleOutsideClick = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
          setTzDropdown(false)
        }
      }
      document.addEventListener("mousedown", handleOutsideClick);
      return () => {
        document.removeEventListener("mousedown", handleOutsideClick);
      }
    }, [ref])
  }

  const _toTimeZone = (time, zone) => {
    let format = 'YYYY/MM/DD HH:mm:ss ZZ';
    return new Date(moment(time, format).tz(zone).format(format));
  }

  const [hourFormat12, setHourFormat12] = useState(true)
  const [tzDropdown, setTzDropdown] = useState(false)
  const [tz, setTz] = useState(props.localTimezone)
  const tzList = Intl.supportedValuesOf('timeZone');
  
  const [selectedDay, setSelectedDay] = useState(null)
  const [selectedDateObject, setSelectedDateObject] = useState(null)
  const [selectedDateTime, setSelectedDateTime] = useState(props.suggestBypass ? null : props.selectedDateTime)
  const [dateSlots, setDateSlots] = useState(
    listifyAvailableDates(props.availableTimes)
  )
  const [timeSlots, setTimeSlots] = useState([])

  const [confirmed, setConfirmed] = useState(false)
  const [eventConfirmed, setEventConfirmed] = useState(props.suggestBypass ? null : props.eventConfirmed)
  const [thankYou, setThankYou] = useState(false)
  const [alert, setAlert] = useState(null)

  const timezoneRef = useRef(null)
  detectOutsideClick(timezoneRef)

  useEffect(() => {
    setTimeSlots(
      listifyAvailableTimes(props.originalAvailableTimes, selectedDateObject)
    )
  }, [selectedDay])

  const handleConfirm = () => {    
    let details
    let name
    let phone

    if (!props.skipDetails) {
      name = document.getElementById("nameDetail") ? document.getElementById("nameDetail").value : null
      phone = document.getElementById("phoneDetail") ? document.getElementById("phoneDetail").value : null
      // email = document.getElementById("emailDetail") ? document.getElementById("emailDetail").value : null

      if (!name || (props.phoneRequired && !phone)) {
        setAlert("Please fill out all required fields.")
        return
      }

      details = {
        "name": name,
        "phone": phone,
        // "email": email
      }
    }

    let start_time

    if (selectedDateTime.datetime && selectedDateTime.datetime.start_time) {
      start_time = selectedDateTime.datetime.start_time
    } else {
      start_time = selectedDateTime.datetime
    }

    props._onAccept({start: start_time}, details)

    setConfirmed(true)
    setThankYou(true)

    setTimeout(() => {
      setConfirmed(false)
      setEventConfirmed(true)
    }, 4000)
  }

  const handleSetTz = (timezone) => {
    setTz(timezone)
    props._changeTimezone(timezone)
  }

  const handleCancel = () => {
    setSelectedDay(null)
    setSelectedDateObject(null)
    setSelectedDateTime(null)
    setDateSlots(listifyAvailableDates(props.availableTimes))
    setTimeSlots([])
  }

  const handleReschedule = () => {
    setEventConfirmed(false)
    handleCancel()
  }

  const formatDurationString = (duration) => {
    // e.g. returns "1 hour 30 minutes"    
    if (duration < 60) {
      return `${duration} minutes`
    }

    let durationString = `${parseInt(duration / 60, 10)} ${duration < 120 ? " hour " : " hours "} `

    if (duration % 60 !== 0) {
      durationString += duration % 60 + (duration % 60 === 1 ? " minute" : " minutes")
    }

    return durationString
  }

  const _renderMeetingTitle = () => {
    // participant's name is known, return full title
    if (
      props.externalParticipantNames.length &&
      props.externalParticipantNames[0] !== null
    ) {
      return props.title
    }

    return `Meeting with ${props.internalParticipantNames[0]}`
  }

  const renderConfirmButtons = () => {
    if (confirmed) {
      return (
        <div className="Timepicker-withThankYou">
          <div className="Timepicker-thankYouText">
            Thank you for booking a time! You will receive a calendar invite shortly.
          </div>
          <div className="Timepicker-confirmed">
            Confirmed!
          </div>
        </div>
      )
    }

    if (eventConfirmed) {
      if (thankYou) {
        return (
          <div className="Timepicker-withThankYou">
            <div className="Timepicker-thankYouText">
              Thank you for booking a time! You will receive a calendar invite shortly.
            </div>
            <div
              className="Timepicker-reschedule"
              onClick={() => handleReschedule()}
            >
              Reschedule
            </div>
          </div>
        )
      }

      return (
        <div
          className="Timepicker-reschedule"
          onClick={() => handleReschedule()}
        >
          Reschedule
        </div>
      )
    }

    return (
      <div className="Timepicker-withThankYou">
        {alert &&
          <div className="Timepicker-alertText">
            {alert}
          </div>
        }
        <div className="Timepicker-confirmationButtons">
          {props.calculatedTimes ?
            <div
              className="Timepicker-cancelBooking"
              onClick={() => handleCancel()}
            >
              Back
            </div>
            :
            <div className="Timepicker-cancelBookingLoading">
              Back
            </div>
          }
          <div
            className="Timepicker-confirmBooking"
            onClick={() => handleConfirm()}
          >
            Confirm
          </div>
        </div>
      </div>
    )
  }

  const renderSuggestMoreButton = () => {
    return (
      <div className="Timepicker-confirmationButtons">
        <div
          className="Timepicker-reschedule"
          onClick={() => props._suggestMoreDays()}
        >
          Display more available times
        </div>
      </div>
    )
  }

  // if the event has already been confirmed, show the confirmation details
  if (eventConfirmed) {
    let convertedStartTime
    
    // selectedDateTime from selecting it via timepicker comes in a different form
    // than that from the open times endpoint
    if (selectedDateTime.datetime.start_time) {
      convertedStartTime = _toTimeZone(selectedDateTime.datetime.start_time, tz)
    } else {
      convertedStartTime = _toTimeZone(selectedDateTime.datetime, tz)
    }

    return (
      <div className="Timepicker">
        <div className="Timepicker-row">
          <div className="Timepicker-confirm">
            <div className="Timepicker-confirmationDetails">
              <div className="Timepicker-title">
                {_renderMeetingTitle()}
              </div>
              <div className="Timepicker-infoContainer">
                <div className="Timepicker-infoRow">
                  <img className="Timepicker-infoIcon" src="/images/timepicker-person.svg" />
                  <div className="Timepicker-infoText">
                    {props.internalParticipantNames[0]}
                  </div>
                </div>
                <div className="Timepicker-infoRow">
                  <img className="Timepicker-infoIcon" src="/images/timepicker-clock.svg" />
                  <div className="Timepicker-infoText">
                    {formatDurationString(props.duration)}
                  </div>
                </div>
                <div className={"Timepicker-infoRow"}>
                  <div className="Timepicker-timezoneInfo">
                    <img className="Timepicker-infoIcon" src="/images/timepicker-timezone.svg" />
                    <div className="Timepicker-infoText">
                      {tz}
                    </div>
                  </div>
                </div>
                <div className="Timepicker-infoRow">
                  <img className="Timepicker-infoIcon" src="/images/timepicker-event.svg" />
                  <div className="Timepicker-infoTextDateTime">
                    {hourFormat12 ?
                      moment(convertedStartTime).format("ddd MMM Do h:mm A")
                      :
                      moment(convertedStartTime).format("ddd MMM Do H:mm")
                    }
                  </div>
                </div>
              </div>
            </div>
            {renderConfirmButtons()}
          </div>
        </div>
      </div>
    )
  }

  // if a date and time are selected, show the participant details form
  if (selectedDateTime) {
    let convertedStartTime
    
    // selectedDateTime from selecting it via timepicker comes in a different form
    // than that from the open times endpoint
    if (selectedDateTime.datetime.start_time) {
      convertedStartTime = _toTimeZone(selectedDateTime.datetime.start_time, tz)
    } else {
      convertedStartTime = _toTimeZone(selectedDateTime.datetime, tz)
    }

    return (
      <div className="Timepicker">
        {props.skipDetails ? 
          <div className="Timepicker-row">
            <div className="Timepicker-confirm">
              <div className="Timepicker-confirmationDetails">
                <div className="Timepicker-title">
                  {_renderMeetingTitle()}
                </div>
                <div className="Timepicker-infoContainer">
                  <div className="Timepicker-infoRow">
                    <img className="Timepicker-infoIcon" src="/images/timepicker-person.svg" />
                    <div className="Timepicker-infoText">
                      {props.internalParticipantNames[0]}
                    </div>
                  </div>
                  <div className="Timepicker-infoRow">
                    <img className="Timepicker-infoIcon" src="/images/timepicker-clock.svg" />
                    <div className="Timepicker-infoText">
                      {formatDurationString(props.duration)}
                    </div>
                  </div>
                  <div className={"Timepicker-infoRow"}>
                    <div className="Timepicker-timezoneInfo">
                      <img className="Timepicker-infoIcon" src="/images/timepicker-timezone.svg" />
                      <div className="Timepicker-infoText">
                        {tz}
                      </div>
                    </div>
                  </div>
                  <div className="Timepicker-infoRow">
                    <img className="Timepicker-infoIcon" src="/images/timepicker-event.svg" />
                    <div className="Timepicker-infoTextDateTime">
                      {hourFormat12 ?
                        moment(convertedStartTime).format("ddd MMM Do h:mm A")
                        :
                        moment(convertedStartTime).format("ddd MMM Do H:mm")
                      }
                    </div>
                  </div>
                </div>
              </div>
              {renderConfirmButtons()}
            </div>
          </div>
          :
          <div className="Timepicker-row">
            <div className="Timepicker-left">
              <div className="Timepicker-confirmationDetails">
                <div className="Timepicker-title">
                  {_renderMeetingTitle()}
                </div>
                <div className="Timepicker-infoContainer">
                  <div className="Timepicker-infoRow">
                    <img className="Timepicker-infoIcon" src="/images/timepicker-person.svg" />
                    <div className="Timepicker-infoText">
                      {props.internalParticipantNames[0]}
                    </div>
                  </div>
                  <div className="Timepicker-infoRow">
                    <img className="Timepicker-infoIcon" src="/images/timepicker-clock.svg" />
                    <div className="Timepicker-infoText">
                      {formatDurationString(props.duration)}
                    </div>
                  </div>
                  <div className={"Timepicker-infoRow"}>
                    <div className="Timepicker-timezoneInfo">
                      <img className="Timepicker-infoIcon" src="/images/timepicker-timezone.svg" />
                      <div className="Timepicker-infoText">
                        {tz}
                      </div>
                    </div>
                  </div>
                  <div className="Timepicker-infoRow">
                    <img className="Timepicker-infoIcon" src="/images/timepicker-event.svg" />
                    <div className="Timepicker-infoTextDateTime">
                      {hourFormat12 ?
                        moment(convertedStartTime).format("ddd MMM Do h:mm A")
                        :
                        moment(convertedStartTime).format("ddd MMM Do H:mm")
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="Timepicker-centerEnd">
              <div className="Timepicker-centerContent">
                <div className="Timepicker-details">
                  <div className="Timepicker-detailGroup">
                    <div className="Timepicker-detailRow">
                      <div className="Timepicker-detailTitle">
                        Full name
                      </div>
                      <div className="Timepicker-detailSubtitle">
                        (required)
                      </div>
                    </div>
                    <input
                      id="nameDetail"
                      className="Timepicker-detailInput"
                      defaultValue={
                        props.externalParticipantNames.length ?
                          props.externalParticipantNames[0]
                          :
                          null
                      }
                    />
                  </div>
                  <div className="Timepicker-detailGroup">
                    <div className="Timepicker-detailRow">
                      <div className="Timepicker-detailTitle">
                        Phone number
                      </div>
                      <div className="Timepicker-detailSubtitle">
                        { props.phoneRequired ? "(required)" : "(optional)" }
                      </div>
                    </div>
                    <input className="Timepicker-detailInput" id="phoneDetail" />
                  </div>
                  {/* <div className="Timepicker-detailGroup">
                    <div className="Timepicker-detailRow">
                      <div className="Timepicker-detailTitle">
                        Email address
                      </div>
                      <div className="Timepicker-detailSubtitle">
                        (optional)
                      </div>
                    </div>
                    <input className="Timepicker-detailInput" id="emailDetail"/>
                  </div> */}
                </div>
                {renderConfirmButtons()}
              </div>
            </div>
          </div>
        }
      </div>
    )
  }

  // display the timepicker from start to finish
  return (
    <div className="Timepicker">
      <div className="Timepicker-row">
        <div className="Timepicker-left">
          <div className="Timepicker-confirmationDetails">
            <div className="Timepicker-title">
              {_renderMeetingTitle()}
            </div>
            <div className="Timepicker-infoContainer">
              <div className="Timepicker-infoRow">
                <img className="Timepicker-infoIcon" src="/images/timepicker-person.svg" />
                <div className="Timepicker-infoText">
                  {props.internalParticipantNames[0]}
                </div>
              </div>
              <div className="Timepicker-infoRow">
                <img className="Timepicker-infoIcon" src="/images/timepicker-clock.svg" />
                <div className="Timepicker-infoText">
                  {formatDurationString(props.duration)}
                </div>
              </div>
              <div
                className="Timepicker-infoRow"
                // className={tzDropdown ? "Timepicker-timezoneRowSelected" : "Timepicker-timezoneRow"}
                ref={timezoneRef}
                // onClick={() => setTzDropdown(!tzDropdown)}
              >
                <div className="Timepicker-timezoneInfo">
                  <img className="Timepicker-infoIcon" src="/images/timepicker-timezone.svg" />
                  <div className="Timepicker-infoText">
                    {tz}
                  </div>
                </div>
                {/* <img className="Timepicker-infoIcon" src="/images/timepicker-dropdown.svg" />
                {tzDropdown && 
                  <div className="Timepicker-timezoneDropdown">
                    {tzList.map((tzString) => {
                      return (
                        <div
                          className={`Timepicker-timezoneOption ${tz === tzString && "Timepicker-timezoneOptionSelected"}`}
                          onClick={() => {handleSetTz(tzString)}}
                        >
                          {tzString}
                        </div>
                      )
                    })}
                  </div>
                } */}
              </div>
            </div>
          </div>
          {renderSuggestMoreButton()}
        </div>
        <div className={selectedDay ? "Timepicker-center" : "Timepicker-centerEnd"}>
          <MiniCalendar
            availableDates={dateSlots}
            selectedDay={selectedDay}
            month={props.month}
            year={props.year}
            firstDay={props.firstDay}
            lastDay={props.lastDay}
            _changeMonth={props._changeMonth}
            setSelectedDay={setSelectedDay}
            setSelectedDateObject={setSelectedDateObject}
          />
        </div>
        {selectedDay &&
          <div className="Timepicker-right">
            <div className="Timepicker-dateHourRow">
              <div className="Timepicker-dayDate">
                <span className="Timepicker-dayDateDow">
                  {`${moment(selectedDay).format("ddd")}, `}
                </span>
                <span className="Timepicker-dayDateDom">
                  {moment(selectedDay).format("MMM Do")}
                </span>
              </div>
              <div className="Timepicker-hourFormat">
                <div
                  className={hourFormat12 ? "Timepicker-hourFormatButtonSelected" : "Timepicker-hourFormatButton"}
                  onClick={() => setHourFormat12(true)}
                >
                  12h
                </div>
                <div
                  className={hourFormat12 ? "Timepicker-hourFormatButton" : "Timepicker-hourFormatButtonSelected"}
                  onClick={() => setHourFormat12(false)}
                >
                  24h
                </div>
              </div>
            </div>
            {timeSlots.length !== 0 ?
              <div className="Timepicker-timeSelect">
                {timeSlots.map((ts) => {
                  return (
                    <div
                      className={(selectedDateTime && selectedDateTime.datetime.getTime() === ts.datetime.getTime()) ?
                        "Timepicker-timeOptionSelected" : "Timepicker-timeOption"
                      }
                      onClick={() => setSelectedDateTime(ts)}
                    >
                      {hourFormat12 ? ts.datetimeString : ts.datetimeString24}
                    </div>
                  )
                })}
              </div>
              :
              <div className="Timepicker-timeSelect">
                <div className="Timepicker-timeOptionNone">
                  No more times available
                </div>
              </div>
            }
          </div>
        }
      </div>
      {/* <div className="Timepicker-claraBranding">
        Powered by Clara
      </div> */}
    </div>
  )
}