import ContractorUtils from './contractor';

const escalationLevelToColor = (escalationLevel) => {
  // Colors are roughtly determined based on the following:
  // red - meeting
  // blue - people
  // green - channel
  // purple - snooze, preferences
  // grey - default
  // yellow - etiquettes core clara
  if (['PREFERENCE_BASIC', 'PREFERENCE_ADVANCED'].includes(escalationLevel)) {
    return 'purple';
  } else if (['GMAIL_GCAL'].includes(escalationLevel)) {
    return 'red';
  } else if (['ENGINEERING'].includes(escalationLevel)) {
    return 'blue';
  } else if (['SUPPORTABILITY'].includes(escalationLevel)) {
    return 'yellow';
  } else if (escalationLevel === 'SUPER_ESCALATE') {
    return 'green';
  } else if (escalationLevel === 'NORMAL') {
    return null;  // no color for normal jobs
  }

  return 'grey';
};

const EscalationUtils = {

  EscalationLevels: {
    PREFERENCE_BASIC: {
      icon: 'preferences',
      label: 'Preferences',
      modifiers: [
        escalationLevelToColor('PREFERENCE_BASIC'),
        'large',
      ],
    },
    PREFERENCE_ADVANCED: {
      icon: 'preferences',
      label: 'Preferences Advanced',
      modifiers: [
        escalationLevelToColor('PREFERENCE_ADVANCED'),
        'large',
      ],
    },
    GMAIL_GCAL: {
      icon: 'calendar',
      label: 'GMail/GCal',
      modifiers: [
        escalationLevelToColor('GMAIL_GCAL'),
        'large',
      ],
    },
    HELPER: {
      icon: 'cue',
      label: 'Other',
      modifiers: [
        escalationLevelToColor('HELPER'),
        'large',
      ],
    },
    SUPPORTABILITY: {
      icon: 'tentative',
      label: 'Supportability',
      modifiers: [
        escalationLevelToColor('SUPPORTABILITY'),
        'large',
      ],
    },
    ENGINEERING: {
      icon: 'bugs',
      label: 'Engineering',
      modifiers: [
        escalationLevelToColor('ENGINEERING'),
        'large',
      ],
    },
    SUPER_ESCALATE: {
      icon: 'people-dark',
      label: 'Admin',
      modifiers: [
        escalationLevelToColor('SUPER_ESCALATE'),
        'large',
      ],
    },
    BUG: {
      label: 'Bugs',
    },
    NORMAL: {
      label: 'Normal',
    },
    CUSTOMER_BLOCKED: {
      label: 'Customer Blocked',
    },
    TEST: {
      label: 'Testing',
    },
    ASSESSMENT: {
      label: 'Assessment',
    },
  },

  escalationLevelToColor: escalationLevelToColor,

  allowedEscalationLevels: (contractor) => {
    if (ContractorUtils.isInternalSupport(contractor)) {
      return EscalationUtils.adminEscalationOptions;
    } else if (ContractorUtils.isEscalations(contractor)) {
      return EscalationUtils.helperEscalationOptions;
    } else {
      return EscalationUtils.normalEscalationOptions;
    }
  },

  normalEscalationOptions: ['HELPER'],

  helperEscalationOptions: [
    'SUPER_ESCALATE', 'HELPER',
  ],

  adminEscalationOptions: [
    'SUPER_ESCALATE', 'HELPER',
  ],

};

export default EscalationUtils;
