/** @format */
import classNames from "classnames";
import PropTypes from "prop-types";
import React, { useState } from "react";

const Section = ({ children, description, separator, title, className }) => {
  const [descriptionOpened, setDescriptionOpened] = useState(title === "Billing" ? true : false)

  let descriptionContent;
  if (description && descriptionOpened) {
    descriptionContent = (
      <div className="Section-description">{description}</div>
    );
  }

  let titleContent;
  if (title) {
    titleContent = (
      <div className="Section-titleContainer">
        <div className="Section-title">{title}</div>
        {description &&
          <div
            className="Section-learnMore"
            onClick={() => setDescriptionOpened(!descriptionOpened)}
          >
            {descriptionOpened ? "Show less" : "Learn more"}
          </div>
        }
      </div>
    )
  }

  return (
    <div
      className={classNames("Section", className, {
        "Section--separator": !!separator
      })}
    >
      {titleContent}
      {descriptionContent}
      {children}
    </div>
  );
};

Section.propTypes = {
  description: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.node
  ]),
  separator: PropTypes.bool,
  title: PropTypes.string,
  className: PropTypes.string
};

export default Section;
