/** @format */
import PropTypes from "prop-types";
import React, { Component } from "react";
import { TextField, Button, RadioButton, RadioGroup } from "vogue";
import { connect } from "react-redux";

import {
  getIsSubmitting,
  fetchJob,
  submitCandidateFeedback
} from "../modules/candidate-feedback";
import Header from "./shared/header.react";
import FormInputContainer from "./shared/form-input-container.react";
import querystringParse from "../../utils/querystring";

let feedbackTypes = [
  {
    label: "Great",
    value: "positive",
    question: "What did you like most about the meeting?"
  },
  {
    label: "Not so great",
    value: "negative",
    question: "Why?"
  },
  {
    label: "I missed the meeting",
    value: "me_no_show",
    question: "Is there anything else you would like to add?"
  },
  {
    label: "Interviewer didn't show up",
    value: "other_no_show",
    question: "Is there anything else you would like to add?"
  }
];

export class CandidateFeedback extends Component {
  constructor(props) {
    super(props);

    this.state = {
      type: "positive",
      text: "",
      interviewer: {},
      onLoadFinished: false
    };

    this._onUpdateText = this._onUpdateText.bind(this);
    this._onUpdateType = this._onUpdateType.bind(this);
    this._submit = this._submit.bind(this);
  }

  UNSAFE_componentWillMount() {
    this.setState({
      type: querystringParse().type || "positive"
    });

    return this._fetch().then(res => {
      if (res === null) {
        this.props.history.push("/not-found");
      } else if (res.hasOwnProperty("interviewer")) {
        feedbackTypes.find(type => type.value === "other_no_show").label =
          res.interviewer.name + " didn't show up";

        this.setState({
          interviewer: res.interviewer
        });
      } else {
        this.props.history.push("/anonymous-feedback");
      }

      this.setState({
        onLoadFinished: true
      });
    });
  }

  _onUpdateText({ target: { value } }) {
    this.setState({
      text: value
    });
  }

  _onUpdateType(type) {
    this.setState({
      type: type
    });
  }

  _submit() {
    if (!this.state.onLoadFinished) return;

    this.props
      .onSubmitCandidateFeedback(
        this.props.match.params.uuid,
        this.state.type,
        this.state.text
      )
      .then(() => {
        this.props.history.push("/anonymous-feedback?first=true");
      });
  }

  _fetch() {
    return this.props.onFetchJob(this.props.match.params.uuid);
  }

  render() {
    let typeOptions = feedbackTypes.map(type => {
      return (
        <div className="CandidateFeedback-typeOption" key={type.value}>
          <RadioButton value={type.value} />
          <div className="CandidateFeedback-typeOptionLabel">{type.label}</div>
        </div>
      );
    });

    return (
      <div className="CandidateFeedback">
        <Header alwaysVisible={true} />
        <div className="CandidateFeedback-container">
          <div className="CandidateFeedback-heroContainer">
            <h2>
              How did your meeting with {this.state.interviewer.name} from{" "}
              {this.state.interviewer.organization_name} go?
            </h2>
          </div>
          <FormInputContainer>
            <RadioGroup onChange={this._onUpdateType} value={this.state.type}>
              {typeOptions}
            </RadioGroup>
          </FormInputContainer>

          <div className="CandidateFeedback-feedbackContainer">
            <h4>
              {
                feedbackTypes.find(type => type.value === this.state.type)
                  .question
              }
            </h4>
            <TextField
              className="CandidateFeedback-feedback"
              onChange={this._onUpdateText}
              value={this.state.text}
              label=""
              autoFocus="true"
              onEnterKeyPressed={this._submit}
            />
          </div>

          <Button
            className="CandidateFeedback-submitFeedback"
            onClick={this._submit}
            loading={this.props.isLoading}
            loadingAnimation={this.state.onLoadFinished}
          >
            Submit
          </Button>
        </div>
      </div>
    );
  }
}

CandidateFeedback.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  onFetchJob: PropTypes.func.isRequired,
  onSubmitCandidateFeedback: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  isLoading: getIsSubmitting(state)
});

const mapDispatchToProps = dispatch => ({
  onSubmitCandidateFeedback(uuid, type, text) {
    return dispatch(submitCandidateFeedback(uuid, type, text));
  },
  onFetchJob(uuid) {
    return dispatch(fetchJob(uuid));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CandidateFeedback);
