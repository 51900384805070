import PropTypes from "prop-types";
import React from "react";

import { PreferenceContentBlock } from "../layout/block.react";
import Section from "../layout/section.react";
import BooleanPreference from "../dashboard/preferences/boolean.react";
import BufferTimes from "../dashboard/preferences/buffer-times.react";
import AvailabilityCalendar from "../dashboard/preferences/availability-calendar.react";
import ConnectedCalendars from "../dashboard/account/connected-calendars.react";
import ConnectedEmails from "../dashboard/account/connected-emails.react";
import { TcrShape } from "../../utils/shapes";

const CalendarPlanning = (props) => {
  const renderConnectedAccounts = () => {
    const description =
      "Link all email addresses and calendars Clara should use to know who you are " +
      "and when you are available. To link an assistant's or colleague's email, please contact " +
      "support@claralabs.com.";

    return (
      <div className="Settings-sectionContainer">
        <div className="Settings-sectionContainerDetails">
          <div className="Settings-sectionContainerTitle">Connected calendars</div>
          <div className="Settings-sectionContainerSubtitle">{description}</div>
        </div>
        <Section>
          <div className="row">
            <div className="col-xs-12 col-sm-6">
              <ConnectedCalendars
                contactId={props.customer.contact_id}
                bordered={true}
              />
            </div>
            <div className="col-xs-12 col-sm-6">
              <ConnectedEmails
                emailAddresses={props.emailAddresses}
                addingEmailAddress={props.addingEmailAddress}
                bordered={true}
              />
            </div>
          </div>
        </Section>
      </div>
    );
  }

  const renderPreferredMeetingTimes = () => {
    const description =
      "Build your idealized schedule so Clara knows when you like and dislike " +
      "certain types of meetings. You can always make specific requests to Clara via email.";

      return (
        <div className="Settings-sectionContainer">
          <div className="Settings-sectionContainerDetails">
            <div className="Settings-sectionContainerTitle">Preferred meeting times</div>
            <div className="Settings-sectionContainerSubtitle">{description}</div>
          </div>
          <Section>
            <div className="row">
              <div className="col-xs-12">
                <AvailabilityCalendar tcrs={props.tcrs} />
              </div>
            </div>
          </Section>
        </div>
      )
  }

  const renderTravelBufferTimes = () => {
    const description =
      "Choose how much time you want between meetings, and whether you want Clara " +
      "to create travel events for in-person meetings.";

    return (
      <div className="Settings-sectionContainer">
        <div className="Settings-sectionContainerDetails">
          <div className="Settings-sectionContainerTitle">Travel & buffer times</div>
          <div className="Settings-sectionContainerSubtitle">{description}</div>
        </div>
        <Section>
          <div className="row">
            <div className="col-xs-12 col-sm-6">
              <BufferTimes
                bufferKey="InPerson"
                title="In-person buffer times"
                subtitle="How much time should Clara leave between in-person meetings?"
                value={props.preference.bufferTimes}
                bordered={true}
              />
            </div>
            <div className="col-xs-12 col-sm-6">
              <BufferTimes
                bufferKey="Virtual"
                title="Virtual buffer times"
                subtitle="How much time should Clara leave between virtual meetings?"
                value={props.preference.bufferTimes}
                bordered={true}
              />
            </div>
            <div className="col-xs-12 col-sm-6">
              <PreferenceContentBlock
                title="Travel time events"
                subtitle="Should Clara add travel time events to your calendar? Note: Travel time does not overlap with buffer times."
                supportLink="https://support.claralabs.com/article/114-travel-time-events"
                bordered={true}
              >
                <BooleanPreference
                  attribute="wantTravel"
                  checked={props.preference.wantTravel}
                />
              </PreferenceContentBlock>
            </div>
          </div>
        </Section>
      </div>
    )
  }

  if (!props.isLoading) {
    return (
      <div className="Settings">
        {renderConnectedAccounts()}
        {renderPreferredMeetingTimes()}
        {renderTravelBufferTimes()}
      </div>
    );
  } else {
    return null
  }
}

CalendarPlanning.propTypes = {
  tcrs: PropTypes.arrayOf(TcrShape).isRequired,
  preference: PropTypes.shape({
    wantTravel: PropTypes.string.isRequired,
    bufferTimes: PropTypes.string.isRequired
  }).isRequired
};

export default CalendarPlanning;
