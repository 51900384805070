/** @format */
import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import { Icon } from "vogue";

import { getRelativeTime } from "../../../../utils/time";
import ButtonInput from "../button-input.react";
import IntegrationPageBase from "./page-base.react";

export const LeverStatus = ({ isValid }) => (
  <div
    className={classNames("LeverIntegration-status", {
      "LeverIntegration-status--connected": isValid,
      "LeverIntegration-status--disconnected": !isValid
    })}
  >
    <Icon
      name={isValid ? "green-check" : "error"}
      color={isValid ? "green" : "red"}
      className="LeverIntegration-statusIcon"
    />
    {isValid ? <span>Active</span> : <span>Disconnected</span>}
  </div>
);

LeverStatus.propTypes = {
  isValid: PropTypes.bool.isRequired
};

export const LeverDetails = ({ leverDetails }) => (
  <div className="LeverIntegration-connectionDetails">
    <div>
      <span className="u-textWeight500">API Key</span>
      <div>
        <span className="LeverIntegration-apiKeyMask">
          xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
        </span>
        <span>{leverDetails.apiKeyLast4}</span>
        <span className="LeverIntegration-timeSince">
          (added {getRelativeTime(leverDetails.connectedDate)})
        </span>
      </div>
    </div>
    {!leverDetails.isValid && (
      <div className="LeverIntegration-invalidText">
        Contact <a href="mailto:support@claralabs.com">customer support</a> to
        reconnect Lever.
      </div>
    )}
  </div>
);

LeverDetails.propTypes = {
  leverDetails: PropTypes.shape({
    isEnabled: PropTypes.bool.isRequired,
    isValid: PropTypes.bool.isRequired,
    apiKeyLast4: PropTypes.string,
    connectedDate: PropTypes.string
  }).isRequired
};

const Lever = props => {
  const {
    leverDetails,
    isConfiguringLever,
    onConfigureLever,
    isLeverAvailable
  } = props;

  const subtitle =
    "Bring Clara in with an email, and let it handle all your schedule coordination. " +
    "Once it schedules the interview, Clara will sync the details in your " +
    "candidateâs profile.";

  return (
    <IntegrationPageBase
      title="Lever"
      subtitle={subtitle}
      image="/images/lever-logo.png"
    >
      {leverDetails.isEnabled && <LeverDetails leverDetails={leverDetails} />}
      {leverDetails.isEnabled && <LeverStatus isValid={leverDetails.isValid} />}

      {!leverDetails.isEnabled &&
        isLeverAvailable && (
          <ButtonInput
            isLoading={isConfiguringLever}
            onSubmit={onConfigureLever}
            buttonText="Add API Key"
            submitText="Connect Lever"
            placeholder="Lever API Key"
          />
        )}
      {!leverDetails.isEnabled &&
        !isLeverAvailable && (
          <div>
            Lever is not setup for your team, contact{" "}
            <a href="mailto:support@claralabs.com">customer support</a> to start
            using Lever with Clara.
          </div>
        )}
    </IntegrationPageBase>
  );
};

Lever.propTypes = {
  leverDetails: PropTypes.shape({
    isEnabled: PropTypes.bool.isRequired,
    isValid: PropTypes.bool.isRequired,
    apiKeyLast4: PropTypes.string,
    connectedDate: PropTypes.string
  }).isRequired,
  onConfigureLever: PropTypes.func.isRequired,
  isConfiguringLever: PropTypes.bool.isRequired,
  isLeverAvailable: PropTypes.bool.isRequired
};

export default Lever;
