/** @format */
import PropTypes from "prop-types";
import React, { Component } from "react";
import { Button, Icon } from "vogue";
import _ from "lodash";
import classNames from "classnames";
import querystring from "querystring";

import { generateShape } from "../../../../collections/shapes";
import { ContactSyncedCalendar } from "../../../../collections/schema";
import FlexLoader from "../../shared/flex-loader.react";
import CalendarList from "../../shared/calendar-list.react";
import TextButton from "../../shared/text-button.react";

const NO_PRIMARY_CONFIRMATION_MESSAGE =
  "You are about to remove your primary calendar from " +
  "being used to determine your availability. Are you sure you want to continue?";
const NO_CALENDARS_SELECTED_ERROR_MESSAGE =
  "Must have at least one calendar selected";
const ERROR_LOADING_CALENDARS = (
  <span>
    We&#39;re unable to load your calendars. Please reload the page to try
    again, or if this error persists,&nbsp;
    <a href="mailto:support@claralabs.com">contact support</a>.
  </span>
);

export default class AccountCalendarList extends Component {
  constructor(props) {
    super(props);

    this._onChange = this._onChange.bind(this);
    this._onSave = this._onSave.bind(this);

    this.state = this._getInitialState(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState(this._getInitialState(nextProps));
  }

  _getInitialState(props) {
    return {
      checkedIds: props.contactSyncedCalendars
        ? props.contactSyncedCalendars
            .filter(c => c.informs_availability)
            .map(c => c.id)
        : [],
      errorMessage: props.isError ? ERROR_LOADING_CALENDARS : null
    };
  }

  _onChange(checkedIds) {
    this.setState({ checkedIds, errorMessage: null });
  }

  _onSave() {
    const { checkedIds } = this.state;
    const { contactSyncedCalendars, onSetCalendars } = this.props;

    if (checkedIds.length === 0) {
      this.setState({ errorMessage: NO_CALENDARS_SELECTED_ERROR_MESSAGE });
      return;
    }

    const primaryContactSyncedCalendars = contactSyncedCalendars.filter(
      c => c.primary
    );
    if (primaryContactSyncedCalendars.length !== 0) {
      const primary = primaryContactSyncedCalendars[0]; // assume only 1 primary
      if (
        primary.informs_availability &&
        !checkedIds.includes(primary.id) &&
        !window.confirm(NO_PRIMARY_CONFIRMATION_MESSAGE) // eslint-disable-line no-alert
      ) {
        return;
      }
    }

    onSetCalendars(checkedIds);
  }

  render() {
    const { checkedIds, errorMessage } = this.state;
    const {
      name,
      isError,
      isLoading,
      isValid,
      email,
      reconnectUrl,
      contactSyncedCalendars
    } = this.props;
    const dirty = !_.isEqual(
      checkedIds.sort(),
      this._getInitialState(this.props).checkedIds.sort()
    );

    const saveButton = dirty ? (
      <TextButton
        key="save-button"
        className="AccountCalendarList-save"
        onClick={this._onSave}
      >
        Save
      </TextButton>
    ) : null;

    const reconnectButton = !isValid ? (
      <div className="AccountCalendarList-reconnect">
        <Button
          className="AccountCalendarList-reconnectButton"
          modifiers={["secondary"]}
          href={`${reconnectUrl}?${querystring.stringify({ email })}`}
        >
          Reconnect
        </Button>
        <Icon name="error" />
      </div>
    ) : null;

    const errorMessageAside = errorMessage ? (
      <aside className="AccountCalendarList-errorMessage">{errorMessage}</aside>
    ) : null;

    const classes = classNames("AccountCalendarList", {
      "AccountCalendarList--invalid": !isValid
    });

    let calendarList = null;
    if (isLoading) {
      calendarList = <FlexLoader />;
    } else if (isValid && !isError) {
      calendarList = (
        <CalendarList
          checkedIds={checkedIds}
          contactSyncedCalendars={contactSyncedCalendars}
          onChange={this._onChange}
        />
      );
    }

    return (
      <li className={classes}>
        <div className="AccountCalendarList-subheader">
          <div>
            <aside className="AccountCalendarList-accountName">{name}</aside>
            {errorMessageAside}
          </div>
          {saveButton}
          {reconnectButton}
        </div>
        {calendarList}
      </li>
    );
  }
}
AccountCalendarList.propTypes = {
  name: PropTypes.string.isRequired,
  email: PropTypes.string,
  isError: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isValid: PropTypes.bool.isRequired,
  reconnectUrl: PropTypes.string.isRequired,
  contactSyncedCalendars: PropTypes.arrayOf(
    generateShape(ContactSyncedCalendar, {
      synced_calendar: {}
    })
  ),
  onSetCalendars: PropTypes.func.isRequired
};
