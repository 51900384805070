/** @format */
import React, { Component } from "react";
import { VirtualKind } from "../../../../utils/enums";
import {
  VIRTUAL_KIND_TO_UI_DETAIL,
  VIRTUAL_KIND_USES_DYNAMIC_LINK
} from "../../../../utils/virtual";
import { VirtualDetailShape } from "../../../utils/shapes";
import { Block, Content, Head } from "../../layout/block.react";
import ExpandableTextDisplay from "../../shared/expandable-text-display.react";
import VirtualMeetingDetailEditor from "../../../containers/virtual-details/editor.react";

const _VIRTUAL_KIND_TO_UI_DETAIL = {
  ...VIRTUAL_KIND_TO_UI_DETAIL,
  [VirtualKind.phone]: { label: "Phone" },
  [VirtualKind.conference_line]: { label: "Conference line" }
};

export default class VirtualMeetingDetail extends Component {
  constructor(props) {
    super(props);

    this.state = { isEditing: false };

    this._closeEditor = this._closeEditor.bind(this);
    this._openEditor = this._openEditor.bind(this);
  }

  render() {
    const { additional_info, kind, value } = this.props.virtualDetail;

    let additionalInfo;
    if (!additional_info || additional_info.trim().length === 0) {
      additionalInfo = (
        <span className="PropertyDetail-value--empty">
          No additional details
        </span>
      );
    } else {
      additionalInfo = <ExpandableTextDisplay content={additional_info} />;
    }

    const subtitle = VIRTUAL_KIND_USES_DYNAMIC_LINK.includes(kind)
      ? "Dynamically generated link"
      : value;

    return (
      <Block bordered={this.props.bordered}>
        <Head
          title={_VIRTUAL_KIND_TO_UI_DETAIL[kind].label}
          subtitle={subtitle}
        />
        <Content onEdit={this._openEditor}>
          <div className="VirtualMeetingDetail-content">
            <div className="PropertyDetail">
              <div className="PropertyDetail-name">Additional call details</div>
              <div className="PropertyDetail-value">{additionalInfo}</div>
            </div>
          </div>
        </Content>
        <VirtualMeetingDetailEditor
          isEditing={this.state.isEditing}
          virtualDetailId={this.props.virtualDetail.id}
          meetingType={this.props.meetingType}
          onEditorClose={this._closeEditor}
        />
      </Block>
    );
  }

  _openEditor() {
    this.setState({ isEditing: true });
  }

  _closeEditor() {
    this.setState({ isEditing: false });
  }
}

VirtualMeetingDetail.propTypes = {
  virtualDetail: VirtualDetailShape.isRequired
};
