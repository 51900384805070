/** @format */
import React from "react";
import { connect } from "react-redux";

import MicrosoftTeams from "../../components/dashboard/integrations/microsoft-teams.react";
import FlexLoader from "../../components/shared/flex-loader.react";
import {
  getActiveMicrosoftTeamsBool,
  getIsLoading as getIsLoadingSession
} from "../../modules/session";

const MicrosoftTeamsContainer = ({ isLoading, ...props }) =>
  isLoading ? <FlexLoader /> : <MicrosoftTeams {...props} />;

const mapStateToProps = state => ({
  activeMicrosoftTeamsBool: getActiveMicrosoftTeamsBool(state),
  isLoading: getIsLoadingSession(state),
});

export default connect(
  mapStateToProps
)(MicrosoftTeamsContainer);
