/** @format */
import PropTypes from "prop-types";

import React, { Component } from "react";

import { LocationShape } from "../../../utils/shapes";
import PlaceholderBlock from "../../shared/placeholder-block.react";
import LocationDetailCreator from "./creator.react";

export default class LocationPlaceholder extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isCreating: false
    };

    this._closeCreator = this._closeCreator.bind(this);
    this._openCreator = this._openCreator.bind(this);
  }

  render() {
    return (
      <div className="LocationPlaceholder">
        <PlaceholderBlock
          detail="Clara will pick a location given the type of meeting requested"
          heading="Add new location"
          icon="location"
          onClick={this._openCreator}
        />
        <LocationDetailCreator
          onEditorClose={this._closeCreator}
          isCreating={this.state.isCreating}
          existingLocations={this.props.existingLocations}
        />
      </div>
    );
  }

  _openCreator() {
    this.setState({ isCreating: true });
  }

  _closeCreator() {
    this.setState({ isCreating: false });
  }
}

LocationPlaceholder.propTypes = {
  existingLocations: PropTypes.arrayOf(LocationShape).isRequired
};
