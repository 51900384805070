/* eslint no-use-before-define: 0 */
import React, { useEffect, useRef, useState, useMemo } from 'react';
import MeetingItem from '../meeting-item.react';
import MeetingRequest from "../meeting-request.react";
import MeetingRequestLoader from '../meeting-request-loader.react';
import { get } from "lodash";

export const useMeetingsInfiniteLoading = (props) => {
  const { fetchMeetings, isLoading } = props;
  const [meetings, setMeetings] = useState([]);

  const initialPage = useRef(1);
  const initialPageLoaded = useRef(isLoading);

  const [hasNext, setHasNext] = useState(false);
  const [loaded, setLoaded] = useState(false);

  const highestPageLoaded = useRef(initialPage.current);

  const loadMeetings = async (page, combineMethod) => {
    const data = await fetchMeetings(page)
    setHasNext(data.paging.last > page);
    setLoaded(true);

    setMeetings((prevMeetings) => {
      return combineMethod === "prepend" ?
        [...data.body.meetings, ...prevMeetings]
        :
        [...prevMeetings, ...data.body.meetings]
    });
  };

  const loadNext = () => {
    const nextPage = highestPageLoaded.current + 1;
    loadMeetings(nextPage, "append");
    setLoaded(true);
    highestPageLoaded.current = nextPage;
  }

  useEffect(() => {
    if (initialPageLoaded.current) {
      return;
    }

    loadMeetings(initialPage.current, "append");
    initialPageLoaded.current = true;
  }, [loadMeetings])

  return {
    meetings,
    hasNext,
    loaded,
    loadNext,
  };
}

export const useIsInViewport = (ref) => {
  const [isIntersecting, setIsIntersecting] = useState(false);

  const observer = useMemo(
    () =>
      new IntersectionObserver(([entry]) =>
        setIsIntersecting(entry.isIntersecting),
      ),
    [],
  );

  useEffect(() => {
    observer.observe(ref.current);

    return () => {
      observer.disconnect();
    };
  }, [ref, observer]);

  return isIntersecting;
}

export const observeNextLoad = (isInViewport, hasNext, loadNext) => {
  return (
    useEffect(() => {
      if (isInViewport && hasNext) {
        loadNext()
      }
    }, [isInViewport])
  )
}

export const renderUpcomingMeetings = (meetings, loaded) => {

  let today = new Date().setHours(0, 0, 0, 0)
  let lastDate = null

  if (meetings.length !== 0) {
    return (
      <div className="Meetings-itemContainer">
        {
          meetings.map((meeting) => {
            let isCompleted = meeting.status !== "cancelled" && new Date() > new Date(meeting.confirmed_event.end_time)
            let start_time = meeting.confirmed_event ? meeting.confirmed_event.start_time : meeting.last_assistant_email_time

            let meetingItem = (
              <MeetingItem
                confirmedEvent={meeting.confirmed_event && meeting.confirmed_event}
                title={meeting.confirmed_event ? meeting.confirmed_event.title : null}
                participants={getParticipants(meeting)}
                participantCount={meeting.participants.length}
                startTime={meeting.confirmed_event ? meeting.confirmed_event.start_time : meeting.last_assistant_email_time}
                endTime={meeting.confirmed_event ? meeting.confirmed_event.end_time : meeting.last_assistant_email_time}
                isCancelled={meeting.status === "cancelled"}
                isCompleted={isCompleted} // or meeting.status === "completed"
              />
            )
            if (new Date(start_time).setHours(0, 0, 0, 0) === lastDate) {
              return (
                <div>
                  {meetingItem}
                </div>
              )
            } else {
              lastDate = new Date(start_time).setHours(0, 0, 0, 0)

              let todayIndicator
              if (today === new Date(start_time).setHours(0, 0, 0, 0)) {
                todayIndicator = (
                  <div className="Meetings-todayIndicator">Today</div>
                )
              }

              return (
                <div>
                  <div className="Meetings-dateHeader">
                    {todayIndicator}
                    <div className="Meetings-dateHeaderBold">
                      {formatDateHeader(start_time)}
                    </div>
                    <div className="Meetings-dateHeaderDot" />
                    {formatDateYear(start_time)}
                  </div>
                  {meetingItem}
                </div>
              )
            }
          })}
      </div>
    )
  }

  if (!loaded) {
    return (
      <div className="MeetingRequestLoader-padding">
        <div className="MeetingRequestLoader-page">
          {[...Array(24)].map(() => {
            return <MeetingRequestLoader />
          })}
        </div>
      </div>
    )
  }

  return (
    <div className="LibraryContent-scrollable">
      <div className="LibraryContent-noMatch">
        No upcoming meetings found.
      </div>
    </div>
  )
}

export const renderRequestedMeetings = (meetings, loaded) => {
  if (meetings.length !== 0) {
    return (
      <div className="Meetings-itemContainer">
        {meetings.map((meeting) => {
          return (
            <MeetingRequest
              title={meeting.subject}
              participants={getParticipants(meeting)}
              participantCount={meeting.participants.length}
              waitingOn={meeting.waiting_on}
              waitingSince={meeting.waiting_since}
              created={meeting.created}
            />
          )
        })}
      </div>
    )
  }

  if (!loaded) {
    return (
      <div className="MeetingRequestLoader-page">
        {[...Array(24)].map(() => {
          return <MeetingRequestLoader />
        })}
      </div>
    )
  }

  return (
    <div className="LibraryContent-scrollable">
      <div className="LibraryContent-noMatch">
        No requested meetings found.
      </div>
    </div>
  )
}

export const renderHistoryMeetings = (meetings, loaded) => {
  let today = new Date().setHours(0, 0, 0, 0)
  let lastDate = null

  if (meetings.length !== 0) {
    return (
      <div className="Meetings-itemContainer">
        {meetings.map((meeting) => {
          let isCompleted = meeting.status !== "cancelled" && new Date() > new Date(meeting.confirmed_event.end_time)
          let start_time = meeting.confirmed_event ? meeting.confirmed_event.start_time : meeting.last_assistant_email_time

          let meetingItem = (
            <MeetingItem
              confirmedEvent={meeting.confirmed_event && meeting.confirmed_event}
              title={meeting.confirmed_event ? meeting.confirmed_event.title : null}
              participants={getParticipants(meeting)}
              participantCount={meeting.participants.length}
              startTime={meeting.confirmed_event ? meeting.confirmed_event.start_time : meeting.last_assistant_email_time}
              endTime={meeting.confirmed_event ? meeting.confirmed_event.end_time : meeting.last_assistant_email_time}
              isCancelled={meeting.status === "cancelled"}
              isCompleted={isCompleted} // or meeting.status === "completed"
            />
          )

          if (new Date(start_time).setHours(0, 0, 0, 0) === lastDate) {
            return (
              <div>
                {meetingItem}
              </div>
            )
          } else {
            lastDate = new Date(start_time).setHours(0, 0, 0, 0)

            let todayIndicator
            if (today === new Date(start_time).setHours(0, 0, 0, 0)) {
              todayIndicator = (
                <div className="Meetings-todayIndicator">Today</div>
              )
            }

            return (
              <div>
                <div className="Meetings-dateHeader">
                  {todayIndicator}
                  <div className="Meetings-dateHeaderBold">
                    {formatDateHeader(start_time)}
                  </div>
                  <div className="Meetings-dateHeaderDot" />
                  {formatDateYear(start_time)}
                </div>
                {meetingItem}
              </div>
            )
          }
        })}
      </div>
    )
  }

  if (!loaded) {
    return (
      <div className="MeetingRequestLoader-padding">
        <div className="MeetingRequestLoader-page">
          {[...Array(24)].map(() => {
            return <MeetingRequestLoader />
          })}
        </div>
      </div>
    )
  }

  return (
    <div className="LibraryContent-scrollable">
      <div className="LibraryContent-noMatch">
        No previous meetings found.
      </div>
    </div>
  )
}

export const renderLoadMore = (observer, isLoading, hasNext) => {
  if (!isLoading) {
    return (
      <div className="Meetings-loadMore" ref={observer}>
        {hasNext && "Load more meetings..."}
      </div>
    )
  }
  return
}

// helper functions and consts

const days = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday'
];

const months = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'July',
  'Aug',
  'Sept',
  'Oct',
  'Nov',
  'Dec'
];

const formatDateHeader = (date) => {
  let d = new Date(date)
  const dow = d.getDay()
  const month = d.getMonth()
  const day = d.getDate()

  return `${days[dow]}, ${months[month]} ${day}`
}

const formatDateYear = (date) => {
  let d = new Date(date)
  return d.getFullYear();
}

const getParticipants = (meeting) => {
  const numParticipants = meeting.participants.length;
  if (numParticipants > 3) {
    return meeting.participants
      .map(p => p.first_name || p.email_address)
  } else if (numParticipants === 0) {
    return [get(meeting, "customer_contact.name")];
  } else {
    return meeting.participants.map(p => p.name || p.email_address)
  }
};