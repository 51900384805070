/** @format */
import PropTypes from "prop-types";
import querystring from "querystring";
import React,{ useState } from "react";
import { Link } from "react-router-dom";
import { Icon } from "vogue";


import config from "../../../config";
import OnboardingHeader from "./onboarding-header.react";

const REGISTER_URL_BASE = `${config.api.host}/auth/register/`;

const OAuthProvider = ({
  location: {
    search: search
  }
}) => {
  const searchParams = new URLSearchParams(search);
  const team_code = searchParams.get("team_code");
  const code = searchParams.get("code");

  if (team_code || code) {
    return (
      <div className="OAuthProvider">
        <div className="OAuthProvider-expiredHeader">
          <Icon
            name="error"
            color="red"
            className="OAuthProvider-expiredIcon"
          />
          <h4 className="OAuthProvider-expiredTitle">Invitation expired</h4>
        </div>
        <div>
          This invite link has expired. Please ask the person who invited you to
          use their Clara dashboard to send you a new invite.
        </div>
        <div className="OAuthProvider-expiredSupport">
          If this issue persists,{" "}
          <a
            className="OAuthProvider-expiredLink"
            href="mailto:support@claralabs.com"
          >
            let us know
          </a>.
        </div>
      </div>
    );
  }

  const queryParams = querystring.stringify(
    Object.fromEntries(
      Object.entries({
        token: searchParams.get("token"),
        referral_code: searchParams.get("referral_code"),
        referral: searchParams.get("referral"),
        simple: searchParams.get("simple"),
        next: `${config.services.endo.host}/start/callback`,
        next_error: window.location.href
        // eslint-disable-next-line no-unused-vars
      }).filter(([k, v]) => v)
    )
  );


  const [isCorrectPassword, setIsCorrectPassword] = useState(false);

  const handlePromptPassword = () => {
    if (!isCorrectPassword) {
      const password = prompt("Enter your password:");
      setIsCorrectPassword(password === "ClaraGPT2023");
    }
  };

  const renderPage = () => {
    if (isCorrectPassword) {
      return (
        <div>
          <OnboardingHeader
            title="Get started with Clara"
            subtext="To start, you'll need to sync your calendar."
          />
    
          <div className="OAuthProvider-buttons">
            <div className="OAuthProvider-button">
              <a href={`${REGISTER_URL_BASE}google?${queryParams}`}>
                <img
                  src="/images/sign-up-with-google.png"
                  className="OAuthProvider-buttonImage"
                />
              </a>
            </div>
            <div className="OAuthProvider-button">
              <a href={`${REGISTER_URL_BASE}microsoft?${queryParams}`}>
                <img
                  src="/images/sign-up-with-microsoft.png"
                  className="OAuthProvider-buttonImage"
                />
              </a>
            </div>
          </div>
    
          <div className="OAuthProvider-login">
            Already have a Clara account? <Link to="/login">Sign in</Link>
          </div>
        </div>
      );
    } else {
      return <h1>Your password is incorrect!</h1>;
    }
  };

  return (
    <div>
      {handlePromptPassword()}
      <div>{renderPage()}</div>
    </div>
  );
};

OAuthProvider.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string.isRequired
  }).isRequired
};

export default OAuthProvider;
