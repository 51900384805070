export const AvailableTypes = {
  AVAILABLE: 'AVAILABLE',
  UNAVAILABLE: 'UNAVAILABLE',
  SUGGESTION: 'SUGGESTION',
  MOVE_TO: 'MOVE_TO',
  IRRELEVANT: 'IRRELEVANT',
  TRAVELING: 'TRAVELING',
};

export const SeatType = {
  data: 'data',
  essential: 'essential',
  professional: 'professional',
  team: 'team',
  exec: 'exec',
  custom: 'custom',
};

export const CustomerRoles = {
  ADMIN: 'ADMIN',
  SCHEDULEE: 'SCHEDULEE',
};

export const LocationCategory = {
  coffee: 'coffee',
  breakfast: 'breakfast',
  lunch: 'lunch',
  dinner: 'dinner',
  drinks: 'drinks',
  office: 'office',
  other: 'other',
};

export const DefaultLocationCategory = {
  ...LocationCategory,
  ask_participant: 'ask_participant',
};

export const VirtualWhoContactsWho = {
  customer: 'customer',
  participant: 'participant',
};

export const WhenToBookConferenceRoom = {
  customer_request: 'customer_request',
  office: 'office',
  virtual: 'virtual',
  office_virtual: 'office_virtual',
};

export const MeetingPermissionKind = {
  schedule: 'schedule',
  initiate: 'initiate',
};

export const MeetingStatus = {
  canceled: 'cancelled',
  scheduled: 'scheduled',
  no_response: 'no_response',
  coordinating: 'coordinating',
};

export const VirtualKind = {
  skype: 'skype',
  googlehangout: 'googlehangout',
  phone: 'phone',
  conference_line: 'conference_line',
  zoom: 'zoom',
  googlemeet: 'googlemeet',
  coderpad: 'coderpad',
  hackerrank: 'hackerrank',
  microsoft_teams: 'microsoft_teams',
};

export const VirtualCategory = {
  direct_call: 'direct_call',
  direct_call_default: 'direct_call_default',
  group_call: 'group_call',
  group_call_default: 'group_call_default',
};

export const TimeConstraintConditions = {
  IN_PERSON: 'IN_PERSON',
  VIRTUAL: 'VIRTUAL',
  PANEL: 'PANEL',
  LocationCategory_coffee: 'LocationCategory_coffee',
  LocationCategory_breakfast: 'LocationCategory_breakfast',
  LocationCategory_lunch: 'LocationCategory_lunch',
  LocationCategory_dinner: 'LocationCategory_dinner',
  LocationCategory_drinks: 'LocationCategory_drinks',
  LocationCategory_office: 'LocationCategory_office',
};

export const SequenceTimeslotKind = {
  interviewer: "interviewer",
  candidate: "candidate"
 };

export const EventParticipantResponse = {
  needsAction: 'needsAction',
  declined: 'declined',
  tentative: 'tentative',
  accepted: 'accepted',
};

export const CallerType = {
  customer: 'customer',
  participants: 'participants',
};

export const HighLevelChannelType = {
  virtual: "virtual",
  inPerson: "inPerson",
};

export const RequestorSettingOptions = {
  do_not_include: 'do_not_include',
  include: 'include',
  on_coordinators_calendar: 'on_coordinators_calendar'
};
