/** @format */
import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import { Icon } from "vogue";
import warning from "warning";

import TextButton from "../shared/text-button.react";

export const Block = ({ anchor, bare, children, className, bordered, backgroundColor}) => {
  const id = anchor ? anchor.toLowerCase().replace(/\s/g, "-") : null;
  const classes = classNames("Block", className, { "Block--bare": bare });

  let style = {}
  if (backgroundColor) style["backgroundColor"] = backgroundColor
  if (bordered) {
    style["borderWidth"] = 1;
    style["borderStyle"] = "solid"
    style["borderColor"] = "#E0E0E0";
  }

  return (
    <div className={classes} id={id} style={style}>
      {children}
    </div>
  );
};

Block.propTypes = {
  anchor: PropTypes.string,
  bare: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.node
  ]).isRequired,
  className: PropTypes.string
};
Block.displayName = "Block";

export const Head = ({ icon, image, subtitle, title, noSeparator }) => {
  warning(!icon || !image, "Cannot set both icon and image props for Head");

  let iconContent;
  if (icon) {
    iconContent = (
      <div className="BlockHead-icon">
        <Icon name={icon} />
      </div>
    );
  }

  let imageContent;
  if (image) {
    imageContent = <img src={image} className="BlockHead-image" />;
  }

  return (
    <div className={noSeparator ? "BlockHead-noSeparator" : "BlockHead"}>
      {iconContent}
      {imageContent}

      <div className="BlockHead-titleContainer">
        <div className="BlockHead-title">{title}</div>
        <div className="BlockHead-subtitle">{subtitle}</div>
      </div>
    </div>
  );
};

Head.propTypes = {
  icon: PropTypes.string,
  subtitle: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.element
  ]).isRequired,
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.element
  ]).isRequired,
  image: PropTypes.string
};
Head.displayName = "Head";

export const Content = ({ children, className, onEdit }) => {
  let editButton;
  if (onEdit) {
    editButton = (
      <TextButton
        href={typeof onEdit === "string" ? onEdit : null}
        target="_blank"
        onClick={typeof onEdit === "function" ? onEdit : null}
        className="BlockContent-edit"
      >
        Edit
      </TextButton>
    );
  }

  return (
    <div className={classNames("BlockContent", className)}>
      {children}
      {editButton}
    </div>
  );
};

Content.propTypes = {
  onEdit: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.node
  ]).isRequired,
  className: PropTypes.string
};
Content.displayName = "Content";

export const ContentBlock = props => (
  <Block
    anchor={props.anchor}
    bordered={props.bordered}
    backgroundColor={props.backgroundColor}
    className={props.className}
  >
    <Head
      icon={props.icon}
      image={props.image}
      subtitle={props.subtitle}
      title={props.title}
      noSeparator={props.noSeparator}
    />
    <Content
      className={props.subClassName ? props.subClassName : null}
      children={props.children}
      onEdit={props.onEdit}
    />
  </Block>
);

ContentBlock.propTypes = {
  ...Block.propTypes,
  ...Head.propTypes,
  ...Content.propTypes
};
ContentBlock.displayName = "ContentBlock";

export const PreferenceContentBlock = ({ subtitle, supportLink, ...props }) => {
  // let supportLinkEl;
  // if (supportLink) {
  //   supportLinkEl = [
  //     <span key="nbsp">&nbsp;</span>,
  //     <TextButton
  //       className="PreferenceContentBlock-learnMore"
  //       href={supportLink}
  //       target="_blank"
  //       key="support-link"
  //     >
  //       Learn more
  //     </TextButton>
  //   ];
  // }

  const subtitleEl = (
    <div>
      {subtitle}
    </div>
  );

  return <ContentBlock subtitle={subtitleEl} {...props} />;
};

PreferenceContentBlock.propTypes = {
  ...ContentBlock.propTypes,
  subtitle: PropTypes.string.isRequired,
  supportLink: PropTypes.string
};
PreferenceContentBlock.displayName = "PreferenceContentBlock";

export const TextPreferenceContentBlock = ({ className, ...props }) => (
  <PreferenceContentBlock
    {...props}
    className={classNames("TextPreferenceContentBlock", className)}
  />
);

TextPreferenceContentBlock.propTypes = PreferenceContentBlock.propTypes;
TextPreferenceContentBlock.displayName = "TextPreferenceContentBlock";
