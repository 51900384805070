/** @format */
import PropTypes from "prop-types";

import React, { Component } from "react";
import { CheckboxGroup, CheckboxWithLabel } from "vogue";
import { RRule } from "rrule";
import { RRULE_WEEKDAY_MAP } from "./availability-calendar.react";
import { TcrShape } from "../../../utils/shapes";

const WEEK_NAME_MAP = {
  S: "Sunday",
  M: "Monday",
  T: "Tuesday",
  W: "Wednesday",
  H: "Thursday",
  F: "Friday",
  Sa: "Saturday"
};
const RRULE_KEY_MAP = {
  S: "SU",
  M: "MO",
  T: "TU",
  W: "WE",
  H: "TH",
  F: "FR",
  Sa: "SA"
};

export default class AvailabilityEditorDays extends Component {
  constructor(props) {
    super(props);

    this._onCheckDay = this._onCheckDay.bind(this);
  }

  render() {
    let checkedWeekdays = [];
    if (this.props.tcr.rrule) {
      const rrule = RRule.fromString(this.props.tcr.rrule);
      checkedWeekdays = rrule.options.byweekday.map(
        dayIndex => RRULE_KEY_MAP[RRULE_WEEKDAY_MAP[dayIndex]]
      );
    }

    const dayCheckboxes = Object.keys(WEEK_NAME_MAP).map(day => (
      <CheckboxWithLabel value={RRULE_KEY_MAP[day]} key={day}>
        <span>{WEEK_NAME_MAP[day]}</span>
      </CheckboxWithLabel>
    ));

    return (
      <CheckboxGroup values={checkedWeekdays} onChange={this._onCheckDay}>
        {dayCheckboxes}
      </CheckboxGroup>
    );
  }

  _onCheckDay(newValues) {
    let newRrule;
    if (newValues.length) {
      newRrule = `FREQ=WEEKLY;BYDAY=${newValues.join(",")}`;
    } else {
      newRrule = "";
    }
    this.props.onChangeTcr({
      ...this.props.tcr,
      rrule: newRrule
    });
  }
}
AvailabilityEditorDays.propTypes = {
  tcr: TcrShape.isRequired,
  onChangeTcr: PropTypes.func.isRequired
};
