import Raven from 'raven-js';

const asyncActionSubtype = action => action.error ? 'error' : (action.meta && action.meta.sequence);
const IGNORED_META_KEYS = ['sequence'];

const ravenBreadcrumbsMiddleware = () => next => action => {
  const actionSubtype = asyncActionSubtype(action);
  const actionSubtypeDisplay = actionSubtype ? ` (${ actionSubtype })` : '';

  const passedMeta = action.meta ? Object.entries(action.meta).reduce((map, [key, value]) => {
    if (!IGNORED_META_KEYS.includes(key)) {
      map[key] = value;
    }

    return map;
  }, {}) : {};

  Raven.captureBreadcrumb({
    message: `${ action.type }${ actionSubtypeDisplay }`,
    category: 'fsa',
    data: {
      payload: !action.error ? JSON.stringify(action.payload) : undefined,
      error: action.error ? action.payload.message : undefined,
      meta: Object.keys(passedMeta).length ? JSON.stringify(passedMeta) : undefined,
    },
    level: action.error ? 'error' : 'info',
  });

  next(action);
};

export default ravenBreadcrumbsMiddleware;
