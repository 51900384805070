/** @format */
import PropTypes from "prop-types";

import React, { Component } from "react";
import { connect } from "react-redux";
import { getIfFetched, fetchIfNeeded } from "../../../collections/module";
import REPORT_ERROR from "../../modules/error";
import FlexLoader from "./flex-loader.react";

export default function withSchema(schema, expansions, getId) {
  return function(WrappedComponent) {
    class WithSchema extends Component {
      UNSAFE_componentWillMount() {
        this.props.onMount();
      }

      render() {
        if (this.props.data === null) {
          return <FlexLoader />;
        }

        const props = { ...this.props };
        delete props.onMount;

        return <WrappedComponent {...props} />;
      }
    }

    WithSchema.propTypes = {
      onMount: PropTypes.func.isRequired,
      data: PropTypes.object
    };

    const mapStateToProps = (state, ownProps) => {
      const id = getId(ownProps);
      return {
        data: getIfFetched(schema, id, expansions, state)
      };
    };

    const mapDispatchToProps = (dispatch, ownProps) => {
      const id = getId(ownProps);
      return {
        onMount() {
          return dispatch(fetchIfNeeded(REPORT_ERROR)(schema, id, expansions));
        }
      };
    };

    return connect(
      mapStateToProps,
      mapDispatchToProps
    )(WithSchema);
  };
}
