/** @format */
import classNames from "classnames";
import React from "react";
import { Link } from "react-router-dom";

import { ContentBlock } from "../../layout/block.react";
import Section from "../../layout/section.react";

const IntegrationPageBase = ({ className, ...props }) => (
  <Section>
    <div className="row">
      <div className="col-xs-12 col-sm-10 col-md-8">
        <div className="IntegrationPageBase-navigation">
          <span>&#9001;&nbsp;</span>
          <Link
            className="IntegrationPageBase-navigationLink"
            to="/settings/integrations"
          >
            All Integrations
          </Link>
        </div>
        <ContentBlock
          className={classNames("IntegrationPageBase-block", className)}
          {...props}
        />
      </div>
    </div>
  </Section>
);

IntegrationPageBase.propTypes = ContentBlock.propTypes;

export default IntegrationPageBase;
