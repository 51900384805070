import PropTypes from "prop-types";
import React from "react";

import { PreferenceContentBlock } from "../layout/block.react";
import Section from "../layout/section.react";
import BooleanPreference from "../dashboard/preferences/boolean.react";
import { BCC, CC } from "../dashboard/preferences/forwarding.react";
import { SelectPreference } from "../shared/attribute-select.react";

const ClarasEmails = (props) => {
  const {
    preference,
    customerContact
  } = props;

  const renderFollowups = () => {
    const description =
      "Adjust Clara's persistence when someone is unresponsive, and whether Clara " +
      "should send meeting reminders before an event.";

    const meetingRemindersSubtitle =
      "Should Clara send out a reminder email to all participants 24 " +
      "hours before each meeting?";

    return (
      <div className="Settings-sectionContainer">
        <div className="Settings-sectionContainerDetails">
          <div className="Settings-sectionContainerTitle">Followups & reminders</div>
          <div className="Settings-sectionContainerSubtitle">{description}</div>
        </div>
        <Section>
          <div className="row">
            <div className="col-xs-12 col-sm-6">
              <PreferenceContentBlock
                title="Follow-up limit"
                subtitle="How many times should Clara follow up with you or a participant?"
                supportLink="https://support.claralabs.com/article/113-follow-up-frequency-limit"
                bordered={true}
              >
                <SelectPreference
                  selectedOption={preference.followupMax}
                  attribute="followupMax"
                  options={[
                    { label: "1", value: "1" },
                    { label: "2", value: "2" },
                    { label: "3", value: "3" }
                  ]}
                  isLarge={true}
                />
              </PreferenceContentBlock>
            </div>
            <div className="col-xs-12 col-sm-6">
              <PreferenceContentBlock
                title="Follow-up frequency"
                subtitle="How many days should Clara wait before following up again?"
                supportLink="https://support.claralabs.com/article/113-follow-up-frequency-limit"
                bordered={true}
              >
                <SelectPreference
                  selectedOption={preference.followupFrequency}
                  attribute="followupFrequency"
                  options={[
                    { label: "1", value: "1" },
                    { label: "2", value: "2" },
                    { label: "3", value: "3" },
                    { label: "4", value: "4" }
                  ]}
                  isLarge={true}
                />
              </PreferenceContentBlock>
            </div>
            <div className="col-xs-12 col-sm-6">
              <PreferenceContentBlock
                title="Meeting reminders"
                subtitle={meetingRemindersSubtitle}
                supportLink="https://support.claralabs.com/article/116-meeting-confirmations-reminders"
                bordered={true}
              >
                <BooleanPreference
                  attribute="send_meeting_confirmation"
                  checked={preference.send_meeting_confirmation}
                />
              </PreferenceContentBlock>
            </div>
          </div>
        </Section>
      </div>
    )
  }

  const renderUpdatesAndVisibility = () => {
    const description =
      "Set the level of visibility you have on scheduling threads, and whether you " +
      "prefer to receive weekly summaries to stay updated on Clara's work.";

    const weeklySummarySubtitle =
      "Your dashboard will have real-time reporting. Would you also like a " +
      "weekly summary email?";
    
    return (
      <div className="Settings-sectionContainer">
        <div className="Settings-sectionContainerDetails">
          <div className="Settings-sectionContainerTitle">Updates & visibility</div>
          <div className="Settings-sectionContainerSubtitle">{description}</div>
        </div>
        <Section>
          <div className="row">
            <div className="col-xs-12 col-sm-6">
              <BCC
                customerContact={customerContact}
                preference={preference}
                bordered={true}
              />
            </div>
            <div className="col-xs-12 col-sm-6">
              <CC
                customerContact={customerContact}
                preference={preference}
                bordered={true}
              />
            </div>
            <div className="col-xs-12 col-sm-6">
              <PreferenceContentBlock
                title="Weekly summary"
                subtitle={weeklySummarySubtitle}
                supportLink="https://support.claralabs.com/article/108-real-time-reporting"
                bordered={true}
              >
                <BooleanPreference
                  attribute="wantWeekly"
                  checked={preference.wantWeekly}
                />
              </PreferenceContentBlock>
            </div>
          </div>
        </Section>
      </div>
    )
  }

  return (
    <div className="Settings">
      {renderFollowups()}
      {renderUpdatesAndVisibility()}
    </div>
  );
}

ClarasEmails.propTypes = {
  preference: PropTypes.shape({
    followupFrequency: PropTypes.number.isRequired,
    followupMax: PropTypes.number.isRequired,
    send_meeting_confirmation: PropTypes.bool.isRequired
  }).isRequired
};

export default ClarasEmails;
