/** @format */
import cookies from "browser-cookies";

export const getInitialFeatureFlagState = preloadedState => {
  const cookieFeatureFlags =
    JSON.parse(cookies.get("Clara-Feature-Flags")) || {};

  return {
    ...preloadedState,
    ...cookieFeatureFlags
  };
};

export const FEATURE_FLAGS = {
  CLARA_SERVES_VALUE: "clara_serves_value",
  LEVER: "lever",
  BACKTOBACK: "backtoback",
  NEW_LOCATIONS: "new_locations",
};
