/** @format */
import PropTypes from "prop-types";

import React, { Component } from "react";

import {
  ConferenceRoomSyncedCalendarShape,
  ResourceSyncedCalendarShape
} from "../../../utils/shapes";
import { PreferenceContentBlock } from "../../layout/block.react";
import ConfigurationBlock from "./configuration/block.react";
import ConfigurationModal from "./configuration/modal.react";
import ConferenceRoomList from "./list.react";
import TextButton from "../../shared/text-button.react";
import FlexLoader from "../../shared/flex-loader.react";

export default class ConferenceRoomsPreferenceBlock extends Component {
  constructor(props) {
    super(props);

    this.state = { isConfiguring: false };

    this._closeModal = this._closeModal.bind(this);
    this._openModal = this._openModal.bind(this);
  }

  render() {
    const {
      conferenceRooms,
      connectedEmailAddress,
      isConfigLoading,
      isLoading,
      onAddScopeAndSyncResources,
      onAuthError
    } = this.props;

    let content;
    if (isLoading) {
      content = <FlexLoader />;
    } else if (conferenceRooms.length > 0) {
      content = <ConferenceRoomList conferenceRooms={conferenceRooms} />;
    } else {
      content = (
        <ConfigurationBlock
          connectedEmailAddress={connectedEmailAddress}
          isLoading={isConfigLoading}
          onAddScopeAndSyncResources={onAddScopeAndSyncResources}
          onAuthError={onAuthError}
          onConfigure={this._openModal}
        />
      );
    }

    let configurationModal;
    if (!isLoading && connectedEmailAddress) {
      configurationModal = (
        <ConfigurationModal
          isOpen={this.state.isConfiguring}
          onClose={this._closeModal}
          availableResources={this.props.availableResources}
          onUpdateResources={this.props.onUpdateResources}
        />
      );
    }

    let configureButton;
    if (!isLoading && conferenceRooms.length > 0) {
      configureButton = (
        <TextButton
          className="ConferenceRoomsPreferenceBlock-configure"
          onClick={this._openModal}
        >
          Configure
        </TextButton>
      );
    }

    const subtitle =
      "Clara will use this list of configured rooms and capacity information to" +
      " intelligently book rooms for anyone on your Clara team.";
    return (
      <PreferenceContentBlock
        title="Conference rooms configuration"
        subtitle={subtitle}
        supportLink="https://support.claralabs.com/hc/en-us/articles/115014268448"
      >
        <div className="ConferenceRoomsPreferenceBlock">
          {content}
          {configureButton}
          {configurationModal}
        </div>
      </PreferenceContentBlock>
    );
  }

  _closeModal() {
    this.setState({ isConfiguring: false });
  }

  _openModal() {
    this.setState({ isConfiguring: true });
  }
}

ConferenceRoomsPreferenceBlock.propTypes = {
  connectedEmailAddress: PropTypes.string,
  isLoading: PropTypes.bool.isRequired,
  isConfigLoading: PropTypes.bool.isRequired,
  conferenceRooms: PropTypes.arrayOf(ConferenceRoomSyncedCalendarShape),
  availableResources: PropTypes.arrayOf(ResourceSyncedCalendarShape),
  onAuthError: PropTypes.func.isRequired,
  onAddScopeAndSyncResources: PropTypes.func.isRequired,
  onUpdateResources: PropTypes.func.isRequired
};
