/** @format */
import PropTypes from "prop-types";

import React, { Component } from "react";
import { Icon, Loader, TextField } from "vogue";
import classnames from "classnames";

import TextButton from "../shared/text-button.react";

export class ButtonInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      editing: false,
      value: "",
      error: false,
      helperText: ""
    };
    this._setEditingTrue = this._setEditingTrue.bind(this);
    this._onChange = this._onChange.bind(this);
    this._onSubmit = this._onSubmit.bind(this);
    this._onCancel = this._onCancel.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.refs.input && !prevState.editing && this.state.editing) {
      this.refs.input.focus();
    }
  }

  _onChange(e) {
    this.setState({
      value: e.target.value
    });
  }

  _setEditingTrue() {
    this.setState({ editing: true });
  }

  _onSubmit() {
    const { value, error, helperText } = this.state;

    if (this.props.validator(value)) {
      if (error || helperText) {
        this.setState({
          error: false,
          helperText: ""
        });
      }

      this.props.onSubmit(value)
      this.setState({
        editing: false,
        value: ""
      });
    } else {
      this.setState({
        error: true,
        helperText: this.props.validationMessage
      });
    }
  }

  _onCancel() {
    this.setState({
      editing: false,
      value: "",
      error: false,
      helperText: ""
    });
  }

  render() {
    const { editing, value } = this.state;
    const { isLoading } = this.props;

    const iconClasses = classnames("ButtonInput-icon", {
      "ButtonInput-icon--editing": editing
    });

    let onClickIcon = () => {};
    if (!isLoading) {
      onClickIcon = editing ? this._onCancel : this._setEditingTrue;
    }

    const error = !!this.props.errorMessage || this.state.error;
    const helperText = this.props.errorMessage || this.state.helperText;

    return (
      <div className="ButtonInput">
        <Icon
          name="close"
          className={iconClasses}
          disabled={isLoading}
          onClick={onClickIcon}
        />
        {editing ? (
          [
            <TextField
              ref="input"
              key="input"
              className="ButtonInput-input"
              modifiers={["hidesLabel"]}
              disabled={isLoading}
              value={value}
              onChange={this._onChange}
              onEnterKeyPressed={this._onSubmit}
              placeholder={this.props.placeholder}
              error={error}
              helperText={helperText}
              spellCheck={false}
            />,
            isLoading ? (
              <span key="loader" className="ButtonInput-loader">
                <Loader />
              </span>
            ) : (
              <TextButton
                className="ButtonInput-button"
                onClick={this._onSubmit}
                key="button"
              >
                {this.props.submitText}
              </TextButton>
            )
          ]
        ) : (
          <TextButton
            className="ButtonInput-button"
            onClick={this._setEditingTrue}
          >
            {this.props.buttonText}
          </TextButton>
        )}
      </div>
    );
  }
}

ButtonInput.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  validator: PropTypes.func,
  validationMessage: PropTypes.string,
  submitText: PropTypes.string,
  buttonText: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  errorMessage: PropTypes.string
};

ButtonInput.defaultProps = {
  validator: () => true,
  validationMessage: "An error occured",
  submitText: "Done"
};

export default ButtonInput;
