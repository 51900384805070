/** @format */
import PropTypes from "prop-types";

import React, { Component } from "react";
import { Button, CheckboxGroup, CheckboxWithLabel, Icon, Modal } from "vogue";

export default class RemoveZoomAccount extends Component {
  constructor(props) {
    super(props);

    this.state = { isOpen: false, confirmationIds: [] };

    this._onOpen = this._onOpen.bind(this);
    this._onClose = this._onClose.bind(this);
    this._onRemove = this._onRemove.bind(this);
    this._onChangeConfirmationIds = this._onChangeConfirmationIds.bind(this);
  }

  render() {
    const modalActions = [
      {
        key: "remove",
        label: "Remove",
        modifier: "reject",
        handler: this._onRemove
      },
      {
        key: "cancel",
        label: "Cancel",
        handler: this._onClose
      }
    ];

    let error;
    if (this.props.deauthorizeZoomError) {
      error = (
        <div className="RemoveZoomAccount-error">
          <Icon
            name="error"
            color="red"
            className="RemoveZoomAccount-errorIcon"
          />
          <div>
            <span className="u-textWeight500">
              We couldn't remove your Zoom account.{" "}
            </span>
            <span className="RemoveZoomAccount-errorDetail">
              Please try again.
            </span>
          </div>
        </div>
      );
    }

    const modal = (
      <Modal
        actions={modalActions}
        open={this.state.isOpen}
        onRequestClose={this._onClose}
        loading={this.props.isDeauthorizingZoom}
        contentLabel="Remove Zoom account modal"
        ref="removeZoomAccountModal"
      >
        <div className="Modal-heading">Remove Zoom account?</div>
        {error}
        <div>Please check each box below to confirm this action:</div>
        <div className="RemoveZoomAccount-checkboxGroup">
          <CheckboxGroup
            values={this.state.confirmationIds}
            onChange={this._onChangeConfirmationIds}
          >
            <CheckboxWithLabel
              value="team"
              className="RemoveZoomAccount-checkbox"
            >
              Clara will stop scheduling Zoom meetings for your entire team.
            </CheckboxWithLabel>
            <CheckboxWithLabel
              value="unset"
              className="RemoveZoomAccount-checkbox"
            >
              Zoom virtual details and keywords will be deleted for your entire
              team.
            </CheckboxWithLabel>
            <CheckboxWithLabel
              value="data"
              className="RemoveZoomAccount-checkbox"
            >
              Clara will not update existing Zoom meetings, even if you
              reconnect this account in the future.
            </CheckboxWithLabel>
          </CheckboxGroup>
        </div>
        <div className="u-textWeight500">You cannot undo this action.</div>
      </Modal>
    );

    return (
      <div className={this.props.className}>
        <div className="u-textWeight500">Remove Zoom</div>
        <div className="RemoveZoomAccount-subtitle">
          This will remove the connected Zoom account and all related data.
        </div>
        <Button
          className="RemoveZoomAccount-button"
          modifiers={["reject", "small"]}
          onClick={this._onOpen}
        >
          Remove Zoom
        </Button>
        {modal}
      </div>
    );
  }

  _onOpen() {
    this.setState({ isOpen: true });
  }

  _onClose() {
    this.setState({ isOpen: false, confirmationIds: [] });
  }

  _onRemove() {
    if (this.state.confirmationIds.length === 3) {
      return this.props.onDeauthorizeZoom();
    }

    if (this.refs.removeZoomAccountModal) {
      this.refs.removeZoomAccountModal.shake();
    }
  }

  _onChangeConfirmationIds(confirmationIds) {
    this.setState({ confirmationIds });
  }
}

RemoveZoomAccount.propTypes = {
  className: PropTypes.string,
  isDeauthorizingZoom: PropTypes.bool.isRequired,
  deauthorizeZoomError: PropTypes.shape({
    message: PropTypes.string.isRequired,
    requestId: PropTypes.string
  }),
  onDeauthorizeZoom: PropTypes.func.isRequired
};
