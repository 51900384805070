/** @format */
import PropTypes from "prop-types";

import React, { Component } from "react";
import classnames from "classnames";
import { Icon } from "vogue";

class Header extends Component {
  render() {
    const headerClassNames = classnames("Header", "container", {
      "Header--alwaysVisible": this.props.alwaysVisible
    });
    return (
      <div className={headerClassNames}>
        <div className="Header-left">{this.props.leftChildren}</div>

        <div className="Header-logo">
          <Icon name="logo" />
        </div>

        <div className="Header-right">{this.props.rightChildren}</div>
      </div>
    );
  }
}
Header.propTypes = {
  alwaysVisible: PropTypes.bool
};
Header.defaultProps = {
  alwaysVisible: false
};

export default Header;
