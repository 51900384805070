/** @format */
import { Modal } from "vogue";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { PreferenceContentBlock } from "../../layout/block.react";
import TextButton from "../../shared/text-button.react";
import { connect } from "react-redux";
import { getCurrentBillingGroup } from "../../../modules/session";
import { SeatType } from "../../../../utils/enums";

export class AssistantProfile extends Component {
  constructor(props) {
    super(props);

    this.state = { isModalOpen: false };

    this._closeModal = this._closeModal.bind(this);
    this._openModal = this._openModal.bind(this);
  }

  render() {
    const modalActions = [
      {
        key: "close",
        label: "Close",
        handler: this._closeModal
      }
    ];

    const {
      contact: { default_email, name },
      siteHost
    } = this.props;

    return (
      <PreferenceContentBlock
        title="Get scheduling support"
        subtitle="Make scheduling requests by email at any time."
        supportLink="https://support.claralabs.com/article/125-how-do-i-start-a-new-scheduling-request"
        className={this.props.className}
        subClassName={this.props.subClassName}
      >
        <div className="AssistantProfile-details">
          <div className="AssistantProfile-name">{name}</div>
          <div className="AssistantProfile-email">{default_email}</div>
        </div>

        <div className="AssistantProfile-customize">
          <TextButton onClick={this._openModal}>Customize</TextButton>
        </div>

        <Modal
          actions={modalActions}
          open={this.state.isModalOpen}
          onRequestClose={this._closeModal}
        >
          <h2>Customize Clara</h2>
          <p>
            Changing Clara's name and domain is a feature that is available on
            some{" "}
            {this.props.showPricingLink ? (
              <a href={`${siteHost}/monthly_pricing`}>paid plans</a>
            ) : (
              <span>paid plans</span>
            )}. To learn more please{" "}
            <a href="mailto:support@claralabs.com">contact support</a>.
          </p>
        </Modal>
      </PreferenceContentBlock>
    );
  }

  _closeModal() {
    this.setState({
      isModalOpen: false
    });
  }

  _openModal() {
    this.setState({
      isModalOpen: true
    });
  }
}
AssistantProfile.propTypes = {
  contact: PropTypes.object.isRequired,
  siteHost: PropTypes.string.isRequired
};

const mapStateToProps = state => {
  const billingGroup = getCurrentBillingGroup(state);
  return {
    showPricingLink: billingGroup.seat_type !== SeatType.custom
  };
};

export default connect(mapStateToProps)(AssistantProfile);
