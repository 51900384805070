/** @format */
import PropTypes from "prop-types";

import React from "react";
import { Icon } from "vogue";

import { Block } from "../layout/block.react";

const PlaceholderBlock = ({ detail, heading, icon, onClick }) => (
  <Block className="PlaceholderBlock" bare={true}>
    <div className="PlaceholderBlock-contents" onClick={onClick}>
      <Icon name={icon} className="PlaceholderBlock-icon" />
      <h2 className="PlaceholderBlock-heading">{heading}</h2>
      <div className="PlaceholderBlock-detail">{detail}</div>
    </div>
  </Block>
);

PlaceholderBlock.propTypes = {
  detail: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired
};

export default PlaceholderBlock;
