/** @format */
import React from "react";
import createReactClass from "create-react-class";
import Header from "./shared/header.react";

import querystringParse from "../../utils/querystring";

const ThankYouForFeedback = createReactClass({
  render() {
    const text =
      querystringParse().first === "true"
        ? "Thanks for the feedback!"
        : "Feedback already submitted.";

    return (
      <div>
        <Header />
        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-md-10 col-md-offset-2">
              <div className="NotFound">
                <h1 className="NotFound-hero">{text}</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
});

export default ThankYouForFeedback;
