/** @format */
import PropTypes from "prop-types";

import React, { Component } from "react";
import { connect } from "react-redux";

import classNames from "classnames";

import { ContentBlock } from "../../layout/block.react";
import FlexLoader from "../../shared/flex-loader.react";
import AddEmailAddress from "./add-email-address-input.react";
import { addEmailAddress } from "../../../modules/verify-email";

class ConnectedEmails extends Component {
  constructor(props) {
    super(props);
    this.state = { emailAddresses: this.props.emailAddresses };
    this._onAddEmailAddress = this._onAddEmailAddress.bind(this)
  }

  render() {
    const contents = this.props.addingEmailAddress ? (
      <FlexLoader />
    ) : (
      <div className="ConnectedEmails-content">
        <ul className="ConnectedEmails-list">{this._emailAddressElements()}</ul>
        <AddEmailAddress onAddEmailAddress={this._onAddEmailAddress}/>
      </div>
    );

    return (
      <ContentBlock
        title="Your email accounts"
        subtitle="Clara recognizes the following email addresses as you"
        icon="mail"
        anchor="emails"
        bordered={this.props.bordered}
      >
        {contents}
      </ContentBlock>
    );
  }

  _emailAddressElements() {
    const userEmailAddresses = this.state.emailAddresses.filter(
      emailAddress => {
        // Remove any "Calendar Address" entries from the list of email addresses.
        return !emailAddress.id.endsWith("group.calendar.google.com");
      }
    );

    const sortedEmailAddresses = userEmailAddresses.sort(emailAddress => {
      return emailAddress.is_default ? -1 : 1;
    });

    return sortedEmailAddresses.map(emailAddress => {
      return this._emailAddressElement(emailAddress);
    });
  }

  _emailAddressElement(emailAddress) {
    const defaultEl = !emailAddress.is_default ? null : (
      <aside className="ConnectedEmails-aside ConnectedEmails-aside--default">
        (default)
      </aside>
    );

    const verifiedEl = emailAddress.is_verified ? null : (
      <aside className="ConnectedEmails-aside ConnectedEmails-aside--unverified">
        (unverified)
      </aside>
    );

    const emailClasses = classNames("ConnectedEmails-email", {
      "ConnectedEmails-email--unverified": !emailAddress.is_verified
    });

    return (
      <li className={emailClasses} key={emailAddress.id}>
        <span className="ConnectedEmails-emailAddressGroup">
          <span
            className="ConnectedEmails-emailAddress"
            title={emailAddress.id}
          >
            {emailAddress.id}
          </span>
          {defaultEl}
          {verifiedEl}
        </span>
      </li>
    );
  }

  _onAddEmailAddress(emailAddress) {
    this.props.onAddEmailAddress(emailAddress)
    let updatedEmailAddress = this.state.emailAddresses
    updatedEmailAddress.push({"id": emailAddress, "is_default": false, "is_verified": false})
    this.setState({ emailAddresses: updatedEmailAddress })
  }
}

ConnectedEmails.propTypes = {
  emailAddresses: PropTypes.arrayOf(PropTypes.object),
  addingEmailAddress: PropTypes.bool
};

ConnectedEmails.defaultProps = {
  emailAddresses: [],
  addingEmailAddress: false
};

const mapDispatchToProps = (dispatch) => ({
  onAddEmailAddress(emailAddress) {
    return dispatch(addEmailAddress(emailAddress))
  }
});


export default connect(
  null,
  mapDispatchToProps
)(ConnectedEmails);
