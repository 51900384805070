import { VirtualKind } from './enums';

const ChannelUtil = {

  channelType(highLevelChannelType, virtualDetail = null) {
    if (highLevelChannelType === 'inPerson') {
      return 'inPerson';
    } else if (highLevelChannelType === 'virtual') {
      if (virtualDetail && virtualDetail.kind) {
        return ChannelUtil.virtualToChannelType[virtualDetail.kind];
      } else {
        return 'call';
      }
    }
  },

  virtualToChannelType: {
    [VirtualKind.phone]: 'call',
    [VirtualKind.conference_line]: 'call',
    [VirtualKind.zoom]: 'call',
    [VirtualKind.microsoft_teams]: 'call',
    [VirtualKind.googlemeet]: 'call',
    [VirtualKind.skype]: 'skype',
    [VirtualKind.googlehangout]: 'hangout',
    [VirtualKind.coderpad]: 'call',
    [VirtualKind.hackerrank]: 'call',
  },

  channelTypeDisplayData: {
    inPerson: { displayName: 'In Person', icon: 'location' },
    unknown: { displayName: 'Unknown', icon: 'location' },
    virtual: { displayName: 'Call', icon: 'phone' },
    direct_call: { displayName: 'Direct Call', icon: 'direct-call' },
    group_call: { displayName: 'Group Call', icon: 'group-call' },
    [VirtualKind.googlehangout]: { displayName: 'Google Hangout', icon: 'hangouts' },
    [VirtualKind.skype]: { displayName: 'Skype', icon: 'skype' },
    [VirtualKind.phone]: { displayName: 'Phone', icon: 'phone' },
    [VirtualKind.conference_line]: { displayName: 'Conference Call', icon: 'phone' },
    [VirtualKind.zoom]: { displayName: 'Zoom', icon: 'phone' },
    [VirtualKind.microsoft_teams]: { displayName: 'Microsoft Teams', icon: 'phone' },
    [VirtualKind.googlemeet]: { displayName: 'Google Meet', icon: 'hangouts' },
    [VirtualKind.coderpad]: { displayName: 'CoderPad', icon: 'phone' },
    [VirtualKind.hackerrank]: { displayName: 'HackerRank', icon: 'phone' },
  },

  locationCategoryDisplayData: {
    office: { displayName: 'Office', icon: 'inperson-office' },
    coffee: { displayName: 'Coffee', icon: 'inperson-coffee' },
    breakfast: { displayName: 'Breakfast', icon: 'inperson-breakfast' },
    lunch: { displayName: 'Lunch', icon: 'inperson-lunch' },
    dinner: { displayName: 'Dinner', icon: 'inperson-dinner' },
    drinks: { displayName: 'Drinks', icon: 'inperson-drinks' },
    other: { displayName: 'Other', icon: 'inperson-other' },
  },
};

export default ChannelUtil;
