/** @format */
import React from "react";
import { connect } from "react-redux";

import Zoom from "../../components/dashboard/integrations/zoom.react";
import FlexLoader from "../../components/shared/flex-loader.react";
import {
  deauthorizeZoom,
  getDeauthorizeZoomError,
  getIsDeauthorizingZoom
} from "../../modules/integrations";
import {
  getActiveZoomAccount,
  getIsLoading as getIsLoadingSession
} from "../../modules/session";

const ZoomContainer = ({ isLoading, ...props }) =>
  isLoading ? <FlexLoader /> : <Zoom {...props} />;

const mapStateToProps = state => ({
  activeZoomAccount: getActiveZoomAccount(state),
  isLoading: getIsLoadingSession(state),
  isDeauthorizingZoom: getIsDeauthorizingZoom(state),
  deauthorizeZoomError: getDeauthorizeZoomError(state)
});

const mapDispatchToProps = dispatch => ({
  onDeauthorizeZoom(zoomAccountId, organizationId) {
    return dispatch(deauthorizeZoom(zoomAccountId, organizationId));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ZoomContainer);
