/** @format */
import { connect } from "react-redux";

import TeamBlock from "../../components/live-v2/team-block.react";
import {
  fetchInvitations,
  fetchTeamCustomers,
  getIsFetchingInvitations,
  getIsFetchingTeamCustomers,
  getInvitations,
  getInvitationsPaging,
  getResendingInvitations,
  getTeamCustomers,
  getTeamCustomersPaging,
  resendInvitation
} from "../../modules/team";


import { isAdmin } from "../../utils/permissions";
import {
  getIsMicrosoft,
  getIsGoogle,
  getActiveZoomAccount,
  getActiveAssistantContact,
} from "../../modules/session";
import { createAsyncAction, wrapPromiseToThunk } from "../../../utils/redux-actions";
import { fetchApi } from "../../../utils/request";

const setDefault = wrapPromiseToThunk(
  createAsyncAction('ORGANIZATION/SET/DEFAULT_VIRTUAL_DETAIL'),
  (_, value) => {
    return fetchApi(`/organizations/set_default_virtual_detail`, {
      method: "POST",
      json: {"default": value}
    })
  }
)

const mapStateToProps = (state, { customer }) => {
  const isLoading =
    getIsFetchingInvitations(state) || getIsFetchingTeamCustomers(state);

  let invitations;
  let teamCustomers;
  if (!isLoading) {
    invitations = getInvitations(state);
    teamCustomers = getTeamCustomers(state);
  }

  return {
    paging: getTeamCustomersPaging(state),
    invitationPaging: getInvitationsPaging(state),
    customerIsAdmin: isAdmin(customer.roles),
    organizationId: customer.organization_id,
    organization: customer.organization,
    isMicrosoft: getIsMicrosoft(state),
    isGoogle: getIsGoogle(state),
    getActiveZoomAccount: getActiveZoomAccount(state),
    activeAssistant: getActiveAssistantContact(state),
    isLoading,
    invitations,
    teamCustomers,
    resendingInvitations: getResendingInvitations(state),
    customer: customer
  };
};

const mapDispatchToProps = dispatch => ({
  onGetTeamCustomers(organizationId, page, status) {
    return dispatch(fetchTeamCustomers(organizationId, page, status));
  },
  onGetInvitations(page) {
    return dispatch(fetchInvitations(page));
  },
  onResendInvitation(invitationId) {
    return dispatch(resendInvitation(invitationId));
  },
  onSetDefault(value) {
    return dispatch(setDefault(value));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TeamBlock);
