/** @format */
import React, { Component } from "react";

import PlaceholderBlock from "../../shared/placeholder-block.react";
import VirtualDetailsCreator from "../../../containers/virtual-details/creator.react";

export default class VirtualDetailsPlaceholder extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isEditing: false
    };

    this._closeEditor = this._closeEditor.bind(this);
    this._openEditor = this._openEditor.bind(this);
  }

  render() {
    return (
      <div className="VirtualDetailsPlaceholder">
        <PlaceholderBlock
          detail="Clara will pick contact details given the type of call requested"
          heading="Add contact details"
          icon="phone"
          onClick={this._openEditor}
        />
        <VirtualDetailsCreator
          onEditorClose={this._closeEditor}
          isCreating={this.state.isEditing}
          virtualDetails={this.props.virtualDetails}
          meetingType={this.props.meetingType}
          customer={this.props.customer}
        />
      </div>
    );
  }

  _openEditor() {
    this.setState({ isEditing: true });
  }

  _closeEditor() {
    this.setState({ isEditing: false });
  }
}
