/** @format */
import React, { Component } from "react";
import FlexLoader from "../shared/flex-loader.react";

class SideNavigationLoading extends Component {
  constructor(props) {
    super(props);
  }

  renderTabs() {
    return (
      <div className="SideNavigationMinimized-tabs">
        <FlexLoader />
      </div>
    )
  }

  renderTrial() {
    let trial = true
    if (trial) {
      return (
        <div className="SideNavigationMinimized-trial">
          {/* @ALEX TODO: RE-ENABLE TRIAL LATER. */}
          
          {/* <div className="SideNavigationMinimized-trialBox">
            <div className="SideNavigationMinimized-trialUpgradeRow">
              <div className="trialText">Free trial ends in 12 days</div>
              <div className="upgradeText">Upgrade</div>
            </div>
            <div className="SideNavigationMinimized-trialProgressRow">
              <div className="SideNavigationMinimized-trialProgressBar">

              </div>
            </div>
          </div> */}
        </div>
      )
    }
  }

  renderLoadingLogo() {
    if (this.props.minimized) {
      return (
        <div className="SideNavigationMinimized-logoContainer">
          <div className="SideNavigationMinimized-logoRow">
            <img src={"/images/clara-minimized.svg"} />
          </div>
        </div>
      )
    } else {
      return (
          <div className="SideNavigation-logoContainer">
            <div className="SideNavigation-logoRow">
              <img src="/images/clara-logo-v2.svg" />
              <div className="SideNavigation-arrow" onClick={() => this.props.handleToggle()}>
                <img src="/images/side-nav-minimize.svg" />
              </div>
            </div>
          </div>
      )
    }
  }

  render() {
    return (
      <div className={this.props.minimized ? "SideNavigationMinimized" : "SideNavigation"}>
        {/* {this.renderLoadingLogo()} */}
        {this.renderTabs()}
        {this.renderTrial()}
        <div className="SideNavigationMinimized-help">
          <a className="SideNavigationMinimized-tab" href={"https://support.claralabs.com/"}>
            <div className="SideNavigationMinimized-tabIcon">
              {/* <img src="/images/help-resources.svg" /> */}
            </div>
          </a>
        </div>
      </div>
    )
  }
}

export default SideNavigationLoading;
