/** @format */
import PropTypes from "prop-types";
import React, { Component } from "react";
import Confetti from "react-confetti";
import { connect } from "react-redux";

import FlexLoader from "../shared/flex-loader.react";
import MeetingsMenu from "./meetings-menu.react";
import TopBanner from "./top-banner.react";
import { renderSideNavigation } from "./side-navigation-config.react";
import { CustomerShape } from "../../utils/shapes";
import PartyModal from "../dashboard/party-modal.react";
import authRequired from "../../components/shared/auth-required.react"


class MeetingsWrapper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sideNavigationMinimized: localStorage.getItem("side-nav-minimized") === "true",
      isPartyModalOpen: window.location.hash === "#success",
    };

    this.toggleSideNavigation = this.toggleSideNavigation.bind(this);
    this._closePartyModal = this._closePartyModal.bind(this);
  }

  toggleSideNavigation() {
    localStorage.setItem("side-nav-minimized", !this.state.sideNavigationMinimized)
    this.setState({
      sideNavigationMinimized: !this.state.sideNavigationMinimized
    })
  }

  render() {
    const {
      children,
      customer,
      isLoading,
      seatType
    } = this.props;
    const { isPartyModalOpen } = this.state;

    let confetti;
    if (isPartyModalOpen) {
      confetti = <Confetti style={{ zIndex: "1000" }} />;
    }

    let sideNavigation = renderSideNavigation(
      this.props.isLoading,
      false,
      this.state.sideNavigationMinimized,
      this.props.customer,
      this.toggleSideNavigation,
      "Meetings"
    )

    if (isLoading) {
      return (
        <div className="DashboardWrapper">
          <FlexLoader />
        </div>
      );
    }

    return (
      <div className="Library">
        <div className="Library-container">
          {sideNavigation}
          <div className="Library-rightHandSide">
            <TopBanner title="Meetings" />
            <div className="LibraryContent">
              <div className="LibraryContent-textColumnNoScroll">
                <MeetingsMenu customer={customer}>
                  {React.Children.map(children, child => (
                    React.isValidElement(child) && (child.props.component !== undefined)
                      ? React.cloneElement(
                        child,
                        {
                          component: undefined,
                          render: (routerProps) => React.cloneElement(child.props.component, { ...routerProps, ...child.props, ref: null, customer })
                        }
                      )
                      : child
                  ))}
                </MeetingsMenu>
              </div>
            </div>
          </div>
        </div>
        <PartyModal
          onClose={this._closePartyModal}
          isOpen={isPartyModalOpen}
          seatType={seatType}
        />
        {confetti}
      </div>
    );
  }

  _closePartyModal() {
    this.setState({ isPartyModalOpen: false });
  }
}

MeetingsWrapper.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.node
  ]),
  customer: CustomerShape,
  customerIsAdmin: PropTypes.bool,
  customerProfile: PropTypes.shape({
    fullName: PropTypes.string.isRequired,
    company: PropTypes.string
  }),
  onLogout: PropTypes.func.isRequired,
  seatType: PropTypes.string,
  isPaying: PropTypes.bool,
  trialDaysRemaining: PropTypes.number
};

export default authRequired({ onboarded: true })(
  connect(
    null,
    null
  )(MeetingsWrapper)
)