/** @format */

import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Icon } from "vogue";

import IntegrationPageBase from "./page-base.react";
import IcimsButtonInput from "../icims-button-input.react";
import { IntegrationAccountShape } from "../../../utils/shapes";
import { getRelativeTime } from "../../../../utils/time";


export const IcimsStatus = ({ isValid }) => (
  <div
    className={classNames("IcimsIntegration-status", {
      "CoderpadIntegration-status--connected": isValid,
      "CoderpadIntegration-status--disconnected": !isValid
    })}
  >
    <Icon
      name={isValid ? "green-check" : "error"}
      color={isValid ? "green" : "red"}
      className="CoderpadIntegration-statusIcon"
    />
    {isValid ? <span>Active</span> : <span>Disconnected</span>}
  </div>
);

IcimsStatus.propTypes = {
  isValid: PropTypes.bool.isRequired
};

export const IcimsDetails = ({ icimsDetails }) => (
  <div className="CoderpadIntegration-connectionDetails">
    <div>
      <span className="u-textWeight500">Customer ID</span>
      <div>
        <span className="CoderpadIntegration-apiKeyMask">
          {icimsDetails.customerId}
        </span>
        <span>{icimsDetails.apiKeyLast4}</span>
        <span className="CoderpadIntegration-timeSince">
          (added {getRelativeTime(icimsDetails.connectedDate)})
        </span>
      </div>
    </div>
    {!icimsDetails.isValid && (
      <div className="CoderpadIntegration-invalidText">
        Contact <a href="mailto:support@claralabs.com">customer support</a> to
        reconnect iCIMS.
      </div>
    )}
  </div>
);

IcimsDetails.propTypes = {
  icimsDetails: PropTypes.shape({
    isEnabled: PropTypes.bool.isRequired,
    isValid: PropTypes.bool.isRequired,
    apiKeyLast4: PropTypes.string,
    connectedDate: PropTypes.string
  }).isRequired
};

const Icims = props => {
  const { icimsDetails, isConfiguringIcims, onConfigureIcims } = props;

  const subtitle = "Connect Clara with your organization's iCIMS account!";
  return (
    <IntegrationPageBase
      title="iCIMS"
      subtitle={subtitle}
      image="/images/icims-i-logo.png"
    >
      {icimsDetails.isEnabled && (
        <IcimsDetails icimsDetails={icimsDetails} />
      )}
      {icimsDetails.isEnabled && (
        <IcimsStatus isValid={icimsDetails.isValid} />
      )}

      {!icimsDetails.isEnabled && (
        <IcimsButtonInput
          isLoading={isConfiguringIcims}
          onSubmit={onConfigureIcims}
          buttonText="Input iCIMS Customer ID"
          submitText="Connect iCIMS"
          placeholder="iCIMS Customer ID"
        />
      )}
    </IntegrationPageBase>
  );
};

Icims.propTypes = {
  activeIntegrationAccount: IntegrationAccountShape,
  icimsDetails: PropTypes.shape({
    isEnabled: PropTypes.bool.isRequired,
    isValid: PropTypes.bool.isRequired,
    apiKeyLast4: PropTypes.string,
    connectedDate: PropTypes.string
  }).isRequired,
  onConfigureIcims: PropTypes.func.isRequired,
  isConfiguringIcims: PropTypes.bool.isRequired
};

export default Icims;
