/** @format */
import { handleActions } from "../../../utils/redux-actions";

/**
 * Creates a simple boolean value reducer for an asynchronous action. Use this when you need to
 * track if an asynchronous action has begun but not yet completed.
 */
export default action =>
  handleActions(
    { [action]: { begin: () => true, next: () => false, throw: () => false } },
    false
  );
