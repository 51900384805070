/** @format */
import React, { Component } from "react"
import { Link } from "react-router-dom";

class MeetingsBanner extends Component {
  constructor(props) {
    super(props)

    this.state = {
      links: [
        {text: "Requested", href: "/meetings/requested"},
        {text: "Upcoming", href: "/meetings/upcoming"},
        {text: "History", href: "/meetings/history"},
      ]
    }
  }

  render() {
    return (
      <div className="LibraryBanner">
        <div className="LibraryBanner-tabs">
          {this.state.links.map((link) => {
            let linkClass = this.props.selected === link.text ?
              "LibraryBanner-tabSelected" : "LibraryBanner-tab"

            return (
              <Link
                key={link.text}
                to={link.href}
                className={linkClass}
                onClick={() => this.props.onClick(link.text)}
              >
                {link.text}
              </Link>
            )
          })}
        </div>
      </div>
    )
  }
};

export default MeetingsBanner