/** @format */
import { isEmpty, isEqual, pick } from "lodash";
import PropTypes from "prop-types";
import React from "react";
import createReactClass from "create-react-class";
import LinkedStateMixin from "react-addons-linked-state-mixin";

import { LocationCategory } from "../../../utils/enums";
import { LocationShapePropType } from "../../../utils/location";
import OnboardingLoader from "./onboarding-loader.react";
import config from "../../../config";


const Registration = createReactClass({
  propTypes: {
    nextStep: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,

    // state to props
    isSubmitting: PropTypes.bool.isRequired,
    hasCurrentUser: PropTypes.bool.isRequired,
    companyName: PropTypes.string,
    phoneNumber: PropTypes.string,
    preferredLocation: LocationShapePropType,

    // dispatch to props
    onSubmit: PropTypes.func.isRequired,
    onError: PropTypes.func.isRequired,
    onLoad: PropTypes.func.isRequired
  },

  mixins: [LinkedStateMixin],

  getInitialState() {
    return {
      companyName: this.props.companyName,
      phoneNumber: this.props.phoneNumber,
      preferredLocation: this.props.preferredLocation,
      createAccountTimeout: true,
      hardRefreshTimeout: true,
      errors: {}
    };
  },

  componentDidMount: function() {
    this.props.onLoad();

    const t = window.setTimeout(() => {
      this.setState({ createAccountTimeout: false })
      clearTimeout(t)
    }, 2500)

    const hardRefresh = window.setTimeout(() => {
      this.setState({ createAccountTimeout: false })
      clearTimeout(hardRefresh)
    }, 10000)
  },

  componentDidUpdate: function() {
    if (!this.state.hardRefreshTimeout) {
      window.location = `${config.services.endo.host}${window.location.pathname}`
    }

    let userLoaded = this.props.hasCurrentUser && !this.props.isLoading

    if (!this.state.createAccountTimeout && userLoaded) {
      let tz = Intl.DateTimeFormat().resolvedOptions().timeZone
      this.props
      .onSubmit(tz)
      .then(this.props.nextStep);
    }
  },

  UNSAFE_componentWillReceiveProps(nextProps) {
    const beforeAttrs = pick(this.props, [
      "companyName",
      "phoneNumber",
      "preferredLocation"
    ]);
    const afterAttrs = pick(nextProps, [
      "companyName",
      "phoneNumber",
      "preferredLocation"
    ]);
    if (!isEqual(beforeAttrs, afterAttrs)) {
      this.setState({ ...this.state, ...afterAttrs });
    }
  },

  onLocationSet(input) {
    const { formattedAddress, googlePlaceId, lat, lng, name } = input;

    this.setState({
      preferredLocation: {
        formatted_address: formattedAddress,
        google_place_id: googlePlaceId,
        is_default: true,
        name,
        lat,
        lng
      }
    });
  },

  onLocationTextChange() {
    // We require that `preferredLocation` always be a full Google Places location.
    this.setState({
      preferredLocation: null
    });
  },

  updateCompanyName(e) {
    this.setState({
      companyName: e.target.value
    })
  },

  updatePhoneNumber(e) {
    this.setState({
      phoneNumber: e.target.value
    })
  },

  submitCallback() {
    if (!this.onValidate()) {
      return;
    }

    const location = {
      ...this.state.preferredLocation,
      category: LocationCategory.office,
      is_default: true
    };
    this.props
      .onSubmit(this.state.phoneNumber, this.state.companyName, location)
      .then(this.props.nextStep);
  },

  onValidate() {
    const _validateLocation = () => {
      // try to find bad addresses
      return (
        this.state.preferredLocation &&
        this.state.preferredLocation.formatted_address &&
        this.state.preferredLocation.google_place_id &&
        this.state.preferredLocation.lat &&
        this.state.preferredLocation.lng
      );
    };

    let errors = {};

    if (!this.state.companyName) {
      errors.companyName = "Company name cannot be empty";
    }
    if (!this.state.phoneNumber) {
      errors.phoneNumber = "Phone number cannot be empty";
    }
    if (!this.state.preferredLocation) {
      errors.preferredLocation = "Office address cannot be empty";
    } else if (!_validateLocation(this.state.preferredLocation)) {
      errors.preferredLocation = "Please select a valid office address";
    }

    this.setState({ errors });

    return isEmpty(errors);
  },

  render() {
    return <OnboardingLoader loadingText="Creating your account..." />
  }
});

export default Registration;
