/** @format */
import PropTypes from "prop-types";

import React from "react";
import { Icon } from "vogue";

import IntegrationPageBase from "./page-base.react";

const GoogleMeet = ({ googleMeetDetails: { isEnabled } }) => {
  let content;
  if (isEnabled) {
    content = [
      <div key="details">
        <div>Google Meet is active for your team.</div>
      </div>,
      <div
        key="status"
        className="GoogleMeetIntegration-status GoogleMeetIntegration-status--connected"
      >
        <Icon
          name="green-check"
          color="green"
          className="GoogleMeetIntegration-statusIcon"
        />
        <span>Active</span>
      </div>
    ];
  } else {
    content = (
      <div>
        Google Meet is not supported for your team. To use Google Meet, you'll
        need to set Clara up on your G Suite account with an email address that
        matches your domain.
        <div className="GoogleMeetIntegration-configurationDetails">
          Contact <a href="mailto:support@claralabs.com">customer support</a>{" "}
          for more information.
        </div>
      </div>
    );
  }

  return (
    <IntegrationPageBase
      title="Google Meet"
      subtitle="Clara can add a custom Google Meet link to any invitation."
      image="/images/google-meet-logo.png"
    >
      {content}
    </IntegrationPageBase>
  );
};

GoogleMeet.propTypes = {
  googleMeetDetails: PropTypes.shape({
    isEnabled: PropTypes.bool.isRequired
  }).isRequired
};

export default GoogleMeet;
