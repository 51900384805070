/** @format */
import PropTypes from "prop-types";

import React, { Component } from "react";
import { Toggle } from "vogue";

import { TimeConstraintConditions } from "../../../../utils/enums";
import { rruleTimeToMoment } from "../../../utils/rrule";
import { TcrShape } from "../../../utils/shapes";
import { AVAILABLE_TYPES } from "../../../utils/time_constraints.js";
import FormInputContainer from "../../shared/form-input-container.react";
import SelectMenu from "../../shared/select-menu.react";
import AvailabilityEditorDays from "./availability-editor-days.react";
import AvailabilityEditorTime from "./availability-editor-time.react";

const CONDITION_OPTIONS = [
  { value: "ANY", label: "Any" },
  { value: TimeConstraintConditions.IN_PERSON, label: "In Person" },
  { value: TimeConstraintConditions.VIRTUAL, label: "Virtual" },
  { value: TimeConstraintConditions.PANEL, label: "Panel" },
  { value: TimeConstraintConditions.LocationCategory_coffee, label: "Coffee" },
  {
    value: TimeConstraintConditions.LocationCategory_breakfast,
    label: "Breakfast"
  },
  { value: TimeConstraintConditions.LocationCategory_lunch, label: "Lunch" },
  { value: TimeConstraintConditions.LocationCategory_dinner, label: "Dinner" },
  { value: TimeConstraintConditions.LocationCategory_drinks, label: "Drinks" },
  { value: TimeConstraintConditions.LocationCategory_office, label: "Office" }
];

const getStartTimeValidationError = ({ end_time, start_time }) => {
  if (rruleTimeToMoment(start_time) >= rruleTimeToMoment(end_time)) {
    return "Start time must be before end time";
  }
};

const getDaysValidationError = ({ rrule }) => {
  if (!rrule || rrule.trim().length === 0)
    return "You must choose at least one day";
};

export const getValidationError = tcr =>
  getStartTimeValidationError(tcr) || getDaysValidationError(tcr);

export default class AvailabilityForm extends Component {
  constructor(props) {
    super(props);

    this._onConditionSet = this._onConditionSet.bind(this);
    this._onToggleAvailable = this._onToggleAvailable.bind(this);
  }

  render() {
    const { forceValidation, onChange, tcr } = this.props;

    let startTimeValidation;
    const startTimeValidationError = getStartTimeValidationError(tcr);
    if (forceValidation && startTimeValidationError) {
      startTimeValidation = (
        <div className="AvailabilityForm-validationError">
          {startTimeValidationError}
        </div>
      );
    }

    let daysValidation;
    const daysValidationError = getDaysValidationError(tcr);
    if (forceValidation && daysValidationError) {
      daysValidation = (
        <div className="AvailabilityForm-validationError">
          {daysValidationError}
        </div>
      );
    }

    return (
      <div>
        <FormInputContainer heading="Available" horizontal={true}>
          <Toggle
            onChange={this._onToggleAvailable}
            checked={tcr.available_type === AVAILABLE_TYPES.AVAILABLE}
            className="AvailabilityForm-toggle"
          />
        </FormInputContainer>
        <FormInputContainer heading="Meeting type" horizontal={true}>
          <SelectMenu
            className="AvailabilityForm-condition"
            options={CONDITION_OPTIONS}
            onChange={this._onConditionSet}
            value={tcr.condition}
          />
        </FormInputContainer>
        <FormInputContainer heading="Days" horizontal={true}>
          <AvailabilityEditorDays tcr={tcr} onChangeTcr={onChange} />
        </FormInputContainer>
        {daysValidation}
        <FormInputContainer heading="Start time" horizontal={true}>
          <AvailabilityEditorTime
            tcr={tcr}
            onChangeTcr={onChange}
            start={true}
          />
        </FormInputContainer>
        {startTimeValidation}
        <FormInputContainer heading="End time" horizontal={true}>
          <AvailabilityEditorTime
            tcr={tcr}
            onChangeTcr={onChange}
            start={false}
          />
        </FormInputContainer>
      </div>
    );
  }

  _onConditionSet({ target: { value } }) {
    this.props.onChange({
      condition: value
    });
  }

  _onToggleAvailable() {
    const newAvailableType =
      this.props.tcr.available_type === AVAILABLE_TYPES.AVAILABLE
        ? AVAILABLE_TYPES.UNAVAILABLE
        : AVAILABLE_TYPES.AVAILABLE;

    this.props.onChange({
      available_type: newAvailableType
    });
  }
}

AvailabilityForm.defaultProps = {
  forceValidation: false
};

AvailabilityForm.propTypes = {
  tcr: TcrShape.isRequired,
  onChange: PropTypes.func.isRequired,
  forceValidation: PropTypes.bool
};
