/** @format */
import React, { Component } from "react"
import { Link } from "react-router-dom";
import config from "../../../config";


class SettingsBannerAdmin extends Component {
  constructor(props) {
    super(props)

    this.state = {
      links: [
        {text: "Profile", href: "profile"},
        {text: "Team", href: "team"},
        {text: "Calendar Planning", href: "calendar-planning"},
        {text: "Meeting Details", href: "meeting-details"},
        {text: "Clara's Emails", href: "claras-emails"},
        {text: "Special Features", href: "special-features"},
        {text: "Integrations", href: "integrations"},
        {text: "Billing", href: "billing"},
      ]
    }
  }

  render() {
    return (
      <div className="LibraryBanner">
        <div className="LibraryBanner-tabs">
          {this.state.links.map((link) => {
            let linkClass = this.props.selected === link.text ?
              "LibraryBanner-tabSelected" : "LibraryBanner-tab"

            if (
              this.props.selected !== link.text
              && window.location.pathname === "/"
              && link.text === "Profile"
            ) {
              window.location = `${config.services.endo.host}/settings/profile`
            }

            return (
              <Link
                key={link.text}
                to={link.href}
                className={linkClass}
                onClick={() => this.props.onClick(link.text)}
              >
                {link.text}
              </Link>
            )
          })}
        </div>
      </div>
    )
  }
};

export default SettingsBannerAdmin