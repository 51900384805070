import React, { Component } from 'react';
import { connect } from "react-redux";
import { Loader } from 'vogue';
import {createAsyncAction, wrapPromiseToThunk} from "../../../utils/redux-actions";
import {fetchApi} from "../../../utils/request";
import PropTypes from 'prop-types';
import SuggestTimeConfirmation from "./suggest-time-confirmation.react";


class SuggestTime extends Component {
  constructor(props) {
    super(props);
    this.state = {
      suggestedTimeUUID: null,
      timeSpan: null,
      message: null,
      selectedTime: null,
      isCancelled: null,
      isStrikeout: false,
    };
  }

  componentDidMount() {
    if (!this.state.suggestedTimeUUID) {
      let method = this.props.onSelectedJobAndPosition;  // Dynamic Email
      let argArray = [this.props.match.params.jobUUID, this.props.match.params.position];
      if (this.props.match.params.suggestedTimeUUID) {  // Strikeout Email
        method = this.props.onSelectSuggestedTime;
        argArray = [this.props.match.params.suggestedTimeUUID];
        this.setState({ isStrikeout: true });
      }

      method.apply(null, argArray)
        .then((response) => {
          const body = response.body;
          if (body) {
            this.setState({
              suggestedTimeUUID: body.uuid,
              timeSpan: body.time_span,
              selectedTime: body.selected_time,
              isCancelled: body.is_cancelled,
            })
          }
        })
        .catch((err) => {
          this.setState({
            message: err.body.message,
          });
        })
    }
  }

  render() {
    let element = null;
    if (this.state.isCancelled) {
      element = <p>This event has been cancelled. Please email me to reschedule it.</p>
    } else if (!this.state.suggestedTimeUUID) {
      if (this.state.message) {
        element = <p>{this.state.message.split('<br />').map((item, key) => {
          return <span key={key}>{item}<br/></span>
        })}</p>
      } else {
        element = <Loader />;
      }
    } else {
      element = <SuggestTimeConfirmation
        uuid={this.state.suggestedTimeUUID}
        timeSpan={this.state.timeSpan}
        selectedTime={this.state.selectedTime}
        isStrikeout={this.state.isStrikeout}
      />
    }

    return (
      <div className="suggest-times-centered">
        {element}
      </div>
    );
  }
}


SuggestTime.propTypes = {
  jobUUID: PropTypes.string,
  position: PropTypes.number,
  suggestedTimeUUID: PropTypes.string,
}


const onSelectedJobAndPositionPromise = wrapPromiseToThunk(
  createAsyncAction('SUGGESTTIME/DYNAMICSUGGESTION'),
  (_, args) => {
    return fetchApi(
      "/suggested_times/dynamic_suggested_time_for_job/"
      + args.jobUUID
      + "/" + args.position, {
      method: "get"
    })
  }
)

const onSelectSuggestedTimePromise = wrapPromiseToThunk(
  createAsyncAction('SUGGESTTIME/FETCHSUGGESTEDTIME'),
  (_, suggestedTimeUUID) => {
    return fetchApi(
      "/suggested_times/suggested_time_status/" + suggestedTimeUUID,
      {
        method: "get"
      }
    )
  }
)

const mapDispatchToProps = dispatch => ({
  onSelectedJobAndPosition(jobUUID, position) {
    return dispatch(onSelectedJobAndPositionPromise({
      "jobUUID": jobUUID, "position": position
    }))
  },
  onSelectSuggestedTime(suggestedTimeUUID) {
    return dispatch(onSelectSuggestedTimePromise(suggestedTimeUUID))
  }
});

export default connect(null, mapDispatchToProps)(SuggestTime);
