/** @format */
import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import { Icon } from "vogue";

import { getRelativeTime } from "../../../../utils/time";
import ButtonInput from "../button-input.react";
import IntegrationPageBase from "./page-base.react";

export const HackerrankStatus = ({ isValid }) => (
  <div
    className={classNames("HackerrankIntegration-status", {
      "HackerrankIntegration-status--connected": isValid,
      "HackerrankIntegration-status--disconnected": !isValid
    })}
  >
    <Icon
      name={isValid ? "green-check" : "error"}
      color={isValid ? "green" : "red"}
      className="HackerrankIntegration-statusIcon"
    />
    {isValid ? <span>Active</span> : <span>Disconnected</span>}
  </div>
);

HackerrankStatus.propTypes = {
  isValid: PropTypes.bool.isRequired
};

export const HackerrankDetails = ({ hackerrankDetails }) => (
  <div className="HackerrankIntegration-connectionDetails">
    <div>
      <span className="u-textWeight500">API Key</span>
      <div>
        <span className="HackerrankIntegration-apiKeyMask">
          xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
        </span>
        <span>{hackerrankDetails.apiKeyLast4}</span>
        <span className="HackerrankIntegration-timeSince">
          (added {getRelativeTime(hackerrankDetails.connectedDate)})
        </span>
      </div>
    </div>
    {!hackerrankDetails.isValid && (
      <div className="HackerrankIntegration-invalidText">
        Contact <a href="mailto:support@claralabs.com">customer support</a> to
        reconnect HackerRank.
      </div>
    )}
  </div>
);

HackerrankDetails.propTypes = {
  hackerrankDetails: PropTypes.shape({
    isEnabled: PropTypes.bool.isRequired,
    isValid: PropTypes.bool.isRequired,
    apiKeyLast4: PropTypes.string,
    connectedDate: PropTypes.string
  }).isRequired
};

const Hackerrank = props => {
  const {
    hackerrankDetails,
    isConfiguringHackerrank,
    onConfigureHackerrank
  } = props;

  const subtitle = "Clara can add custom HackerRank links to any invitation.";

  return (
    <IntegrationPageBase
      title="HackerRank"
      subtitle={subtitle}
      image="/images/hackerrank-logo.png"
    >
      {hackerrankDetails.isEnabled && (
        <HackerrankDetails hackerrankDetails={hackerrankDetails} />
      )}
      {hackerrankDetails.isEnabled && (
        <HackerrankStatus isValid={hackerrankDetails.isValid} />
      )}

      {!hackerrankDetails.isEnabled && (
        <ButtonInput
          isLoading={isConfiguringHackerrank}
          onSubmit={onConfigureHackerrank}
          buttonText="Add API Key"
          submitText="Connect HackerRank"
          placeholder="HackerRank API Key"
        />
      )}
    </IntegrationPageBase>
  );
};

Hackerrank.propTypes = {
  hackerrankDetails: PropTypes.shape({
    isEnabled: PropTypes.bool.isRequired,
    isValid: PropTypes.bool.isRequired,
    apiKeyLast4: PropTypes.string,
    connectedDate: PropTypes.string
  }).isRequired,
  onConfigureHackerrank: PropTypes.func.isRequired,
  isConfiguringHackerrank: PropTypes.bool.isRequired
};

export default Hackerrank;
