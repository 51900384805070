/** @format */
import { connect } from "react-redux";

import VirtualMeetingDetailEditor from "../../components/dashboard/virtual-details/editor.react";
import {
  getCurrentCustomerContact,
  getActiveZoomAccount,
  getIsCoderpadActive,
  getIsHackerrankActive,
  getIsMicrosoft
} from "../../modules/session";
import {
  deleteVirtualDetail,
  updateVirtualDetail,
  getVirtualDetail,
  getAvailableVirtualKinds,
  updateMeetingType
} from "../../modules/virtual-details";

const mapStateToProps = (state, { virtualDetailId }) => ({
  hasActiveZoomAccount: !!getActiveZoomAccount(state),
  isCoderpadActive: getIsCoderpadActive(state),
  isHackerrankActive: getIsHackerrankActive(state),
  isMicrosoft: getIsMicrosoft(state),
  virtualDetail: getVirtualDetail(state, virtualDetailId),
  availableVirtualKinds: getAvailableVirtualKinds(
    state,
    getCurrentCustomerContact(state).id
  )
});

const mapDispatchToProps = dispatch => ({
  onUpdateVirtualDetail(virtualDetail) {
    return dispatch(updateVirtualDetail(virtualDetail));
  },
  onDeleteVirtualDetail(virtualDetail) {
    return dispatch(deleteVirtualDetail(virtualDetail));
  },
  onUpdateMeetingType(meetingType) {
    return dispatch(updateMeetingType(meetingType));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VirtualMeetingDetailEditor);
