/** @format */
import { get, identity, isArray, omit, snakeCase } from "lodash";
import { combineReducers } from "redux";
import { createAction } from "redux-actions";

import fetchGroup, { handleFetchGroupActions } from "../utils/fetch-group";
import {
  handleActions,
  createAsyncAction,
  wrapPromiseToThunk
} from "../../utils/redux-actions";
import { fetchApi } from "../../utils/request";
import { REPORT_ERROR } from "./error";

export const MOUNT_POINT = "meetings";

const FETCH_MEETINGS = createAsyncAction(
  "MEETINGS/FETCH_MEETINGS",
  identity,
  (payload, args) => ({ key: args && args[0] })
);
const FETCH_STATS = createAsyncAction("MEETINGS/FETCH_STATS");
export const RESET_MEETINGS = createAction("MEETINGS/RESET_MEETINGS");
const SET_OPTIONS = createAction("MEETINGS/SET_OPTIONS");

export const fetchMeetings = wrapPromiseToThunk(
  FETCH_MEETINGS,
  ({ dispatch, getState }, key, { page, ...restOptions } = {}) => {
    const originalOptions = getOptions(getState(), key); // eslint-disable-line no-use-before-define
    const options = {
      // Populate existing options for this key.
      ...originalOptions,

      // Override with new options.
      ...restOptions
    };

    dispatch(SET_OPTIONS({ key, options }));

    const queryParams = Object.keys(options)
      .filter(name => options[name])
      .map(name => {
        const value = options[name];

        const formattedValue = isArray(value)
          ? value.map(encodeURIComponent).join("|")
          : encodeURIComponent(value);

        return `${snakeCase(name)}=${formattedValue}`;
      });

    const queryParamsURI = queryParams.length
      ? `?${queryParams.join("&")}`
      : "";
    const url = `/endo/meetings/${queryParamsURI}`;

    return fetchApi(url, { page })
      .then(({ body, paging }) => ({ ...body, paging }))
      .catch(error => {
        dispatch(SET_OPTIONS({ key, options: originalOptions }));
        throw error;
      });
  },
  REPORT_ERROR
);

export const fetchStats = wrapPromiseToThunk(
  FETCH_STATS,
  () =>
    fetchApi("/endo/meetings/stats")
      .then(({ body }) => body)
      .catch(error => {
        // If the customer has no assistants (e.g. they load the dashboard home before completing
        // onboarding) then handle the error and return an empty stats payload instead of throwing up
        // an error.
        if (
          get(error, "res.status") === 400 &&
          get(error, "body.message") === "Customer has no assistants"
        ) {
          return {};
        }
        throw error;
      }),
  REPORT_ERROR
);

const meetings = handleActions(
  {
    [FETCH_MEETINGS]: {
      begin: state => state,
      next: (state, action) => ({
        ...state,
        [action.meta.key]: action.payload.meetings
      }),
      throw: state => state
    },
    [RESET_MEETINGS]: (state, action) => omit(state, action.payload)
  },
  {}
);

const stats = handleActions(
  {
    [FETCH_STATS]: {
      begin: state => state,
      next: (_, action) => action.payload,
      throw: state => state
    }
  },
  {}
);

const paging = handleActions(
  {
    [FETCH_MEETINGS]: {
      begin: state => state,
      next: (state, action) => ({
        ...state,
        [action.meta.key]: action.payload.paging
      }),
      throw: state => state
    },
    [RESET_MEETINGS]: (state, action) => omit(state, action.payload)
  },
  {}
);

const fetchGroups = combineReducers({
  fetchStats: handleFetchGroupActions(FETCH_STATS),
  meetings: handleActions(
    {
      [FETCH_MEETINGS]: {
        begin: (state, action) => ({
          ...state,
          [action.meta.key]: (
            state[action.meta.key] || fetchGroup()
          ).startFetch()
        }),
        next: (state, action) => ({
          ...state,
          [action.meta.key]: state[action.meta.key].completeFetch()
        }),
        throw: (state, action) => ({
          ...state,
          [action.meta.key]: state[action.meta.key].completeFetch()
        })
      },
      [RESET_MEETINGS]: (state, action) => omit(state, action.payload)
    },
    {}
  )
});

const options = handleActions(
  {
    [SET_OPTIONS]: (state, action) => ({
      ...state,
      [action.payload.key]: action.payload.options
    }),
    [RESET_MEETINGS]: (state, action) => omit(state, action.payload)
  },
  {}
);

export const reducer = combineReducers({
  paging,
  meetings,
  stats,
  options,
  fetchGroups
});

export const getMeetings = (state, key) =>
  state[MOUNT_POINT].meetings[key] || [];

export const getIsMeetingsLoading = (state, key) =>
  get(state[MOUNT_POINT].fetchGroups.meetings[key], "isFetching", false);

export const getPaging = (state, key) => state[MOUNT_POINT].paging[key];

export const getOptions = (state, key) => state[MOUNT_POINT].options[key] || {};

export const getIsLoadingStats = state =>
  state[MOUNT_POINT].fetchGroups.fetchStats.isFetching;

export const getStats = state => state[MOUNT_POINT].stats;
