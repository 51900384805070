/** @format */
import { connect } from "react-redux";

import { fetchIfNeeded, getIfFetched } from "../../collections/module";
import { Customer } from "../../collections/schema";
import REPORT_ERROR from "../modules/error";
import EventCalendar from "../components/dashboard/account/event-calendar.react";
import {
  fetchEventCalendarOptions,
  getEventCalendarOptions,
  getIsError,
  getIsUpdatingEventCalendars,
  setEventCalendar,
  unsetEventCalendar
} from "../modules/connected-calendars";
import createInitiallyLoadingContainer from "./create-initially-loading-container.react";

const expansions = { active_assistant: { contact: {} } };

const mapStateToProps = (state, { customer }) => {
  const expandedCustomer = getIfFetched(
    Customer,
    customer.id,
    expansions,
    state
  );
  const isError = getIsError(state);
  const options = getEventCalendarOptions(state);
  return {
    isError,
    isLoading: !isError && (expandedCustomer === null || options === null),
    isUpdating: getIsUpdatingEventCalendars(state),
    assistantName: expandedCustomer
      ? expandedCustomer.active_assistant.contact.name
      : null,
    options
  };
};

const mapDispatchToProps = (dispatch, { customer }) => ({
  onLoad() {
    dispatch(fetchEventCalendarOptions());
    dispatch(fetchIfNeeded(REPORT_ERROR)(Customer, customer.id, expansions));
  },

  onSetEventCalendar(syncedCalendarId) {
    return dispatch(setEventCalendar(syncedCalendarId));
  },

  onUnsetEventCalendar() {
    return dispatch(unsetEventCalendar());
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(createInitiallyLoadingContainer(EventCalendar));
