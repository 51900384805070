/** @format */
import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import { Icon } from "vogue";

import { getRelativeTime } from "../../../../utils/time";
import ButtonInput from "../button-input.react";
import IntegrationPageBase from "./page-base.react";

export const CoderpadStatus = ({ isValid }) => (
  <div
    className={classNames("CoderpadIntegration-status", {
      "CoderpadIntegration-status--connected": isValid,
      "CoderpadIntegration-status--disconnected": !isValid
    })}
  >
    <Icon
      name={isValid ? "green-check" : "error"}
      color={isValid ? "green" : "red"}
      className="CoderpadIntegration-statusIcon"
    />
    {isValid ? <span>Active</span> : <span>Disconnected</span>}
  </div>
);

CoderpadStatus.propTypes = {
  isValid: PropTypes.bool.isRequired
};

export const CoderpadDetails = ({ coderpadDetails }) => (
  <div className="CoderpadIntegration-connectionDetails">
    <div>
      <span className="u-textWeight500">API Key</span>
      <div>
        <span className="CoderpadIntegration-apiKeyMask">
          xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
        </span>
        <span>{coderpadDetails.apiKeyLast4}</span>
        <span className="CoderpadIntegration-timeSince">
          (added {getRelativeTime(coderpadDetails.connectedDate)})
        </span>
      </div>
    </div>
    {!coderpadDetails.isValid && (
      <div className="CoderpadIntegration-invalidText">
        Contact <a href="mailto:support@claralabs.com">customer support</a> to
        reconnect CoderPad.
      </div>
    )}
  </div>
);

CoderpadDetails.propTypes = {
  coderpadDetails: PropTypes.shape({
    isEnabled: PropTypes.bool.isRequired,
    isValid: PropTypes.bool.isRequired,
    apiKeyLast4: PropTypes.string,
    connectedDate: PropTypes.string
  }).isRequired
};

const Coderpad = props => {
  const { coderpadDetails, isConfiguringCoderpad, onConfigureCoderpad } = props;

  const subtitle = "Clara can add custom CoderPad links to any invitation.";

  return (
    <IntegrationPageBase
      title="CoderPad"
      subtitle={subtitle}
      image="/images/coderpad-logo.png"
    >
      {coderpadDetails.isEnabled && (
        <CoderpadDetails coderpadDetails={coderpadDetails} />
      )}
      {coderpadDetails.isEnabled && (
        <CoderpadStatus isValid={coderpadDetails.isValid} />
      )}

      {!coderpadDetails.isEnabled && (
        <ButtonInput
          isLoading={isConfiguringCoderpad}
          onSubmit={onConfigureCoderpad}
          buttonText="Add API Key"
          submitText="Connect Coderpad"
          placeholder="Coderpad API Key"
        />
      )}
    </IntegrationPageBase>
  );
};

Coderpad.propTypes = {
  coderpadDetails: PropTypes.shape({
    isEnabled: PropTypes.bool.isRequired,
    isValid: PropTypes.bool.isRequired,
    apiKeyLast4: PropTypes.string,
    connectedDate: PropTypes.string
  }).isRequired,
  onConfigureCoderpad: PropTypes.func.isRequired,
  isConfiguringCoderpad: PropTypes.bool.isRequired
};

export default Coderpad;
