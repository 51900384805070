/** @format */
import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import { Icon } from "vogue";
import CustomerMenu from "./customer-menu.react";

const Header = ({ hideCustomerMenu, ...props }) => (
  <header className="NewHeader">
    <div className="NewHeader-content">
      <Link to="/" className="NewHeader-logoLink">
        <Icon name="logo" className="NewHeader-logo" />
        <span className="NewHeader-name">clara</span>
      </Link>

      <div className="NewHeader-customerMenu">
        {hideCustomerMenu ? null : <CustomerMenu {...props} />}
      </div>
    </div>
  </header>
);

Header.propTypes = {
  fullName: PropTypes.string,
  company: PropTypes.string,
  customerIsAdmin: PropTypes.bool,
  onLogout: PropTypes.func,
  hideCustomerMenu: PropTypes.bool,
  customer: PropTypes.object
};

export default Header;
