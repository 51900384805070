/** @format */
import _ from "lodash";
import { combineReducers } from "redux";
import { createAction } from "redux-actions";
import {
  handleActions,
  createAsyncAction,
  wrapPromiseToThunk
} from "../../utils/redux-actions";
import { fetchApi } from "../../utils/request";
import { handleFetchGroupActions } from "../utils/fetch-group";
import { TimeConstraintRecurring } from "../../collections/schema";
import { consumePayload, expand } from "../../collections/module";
import { REPORT_ERROR } from "./error";
import { getCurrentCustomerContact } from "./session";

const FETCH_AVAILABILITY = createAsyncAction("AVAILABILITY/FETCH_AVAILABILITY");
const CREATE_AVAILABILITY = createAsyncAction(
  "AVAILABILITY/CREATE_AVAILABILITY"
);
const UPDATE_AVAILABILITY = createAsyncAction(
  "AVAILABILITY/UPDATE_AVAILABILITY"
);
const DELETE_AVAILABILITY = createAsyncAction(
  "AVAILABILITY/DELETE_AVAILABILITY"
);

export const CREATE_TCR = createAction("AVAILABILITY/CREATE_TCR");
export const DELETE_TCR = createAction("AVAILABILITY/DELETE_TCR");

const UPDATEABLE_PROPERTIES = [
  "kind",
  "reason",
  "available_type",
  "condition",
  "rrule",
  "start_time",
  "end_time",
  "timezone",
  "dtstart",
  "until"
];

export const MOUNT_POINT = "availability";

export const fetchAvailability = wrapPromiseToThunk(
  FETCH_AVAILABILITY,
  ({ dispatch }, contactId) => {
    return fetchApi(
      `/time_constraint_recurrings?per_page=-1&mandate=contact_id:${contactId},kind:PREFERENCE,until:null`
    ).then(({ body }) =>
      body.map(tcr => {
        dispatch(consumePayload(tcr, TimeConstraintRecurring));
        return tcr.id;
      })
    );
  },
  REPORT_ERROR
);

const cleanTCR = tcr =>
  _.chain(tcr)
    .pick(UPDATEABLE_PROPERTIES)
    .omit((v, k) => {
      // the "any" value in API is set with null, so we need to remove
      // the key entirely if the customer chose any
      return k === "condition" && v === "ANY";
    })
    .value();

export const createAvailability = wrapPromiseToThunk(
  CREATE_AVAILABILITY,
  ({ dispatch, getState }, tcr) => {
    const contact = getCurrentCustomerContact(getState());

    return fetchApi(`/contacts/${contact.id}/time_constraint_recurrings`, {
      method: "post",
      json: {
        ...cleanTCR(tcr),
        kind: "PREFERENCE",
        timezone: contact.timezone
      }
    }).then(({ body }) => {
      dispatch(consumePayload(body, TimeConstraintRecurring));
      dispatch(CREATE_TCR(body));
    });
  },
  REPORT_ERROR
);

export const deleteAvailability = wrapPromiseToThunk(
  DELETE_AVAILABILITY,
  ({ dispatch }, tcr) => {
    return fetchApi(`/time_constraint_recurrings/${tcr.id}/actions/delete`, {
      method: "post"
    }).then(({ body }) => {
      // HACK(gavin): If the TCR was scheduled to start in the future then the model is deleted and
      // this endpoint returns an empty response body. Ideally we'd be able to remove it from the
      // collections store instead of using this hack.
      if (!body) {
        body = { ...tcr, until: new Date().toISOString() };
      }
      dispatch(DELETE_TCR(body));
      return dispatch(consumePayload(body, TimeConstraintRecurring));
    });
  },
  REPORT_ERROR
);

export const updateAvailability = wrapPromiseToThunk(
  UPDATE_AVAILABILITY,
  ({ dispatch }, tcr) => {
    return fetchApi(`/time_constraint_recurrings/${tcr.id}/actions/update`, {
      method: "put",
      json: cleanTCR(tcr)
    }).then(({ body }) => {
      const updatedTcr = body[0];

      dispatch(consumePayload(updatedTcr, TimeConstraintRecurring));
      dispatch(CREATE_TCR(updatedTcr));
      dispatch(DELETE_TCR(tcr));
    });
  },
  REPORT_ERROR
);

const fetchGroups = combineReducers({
  fetchAvailability: handleFetchGroupActions(FETCH_AVAILABILITY, true),
  createAvailability: handleFetchGroupActions(CREATE_AVAILABILITY),
  updateAvailability: handleFetchGroupActions(UPDATE_AVAILABILITY),
  deleteAvailability: handleFetchGroupActions(DELETE_AVAILABILITY)
});

const timeConstraintRecurrings = handleActions(
  {
    [FETCH_AVAILABILITY]: {
      begin: () => [],
      next: (state, action) => action.payload,
      throw: () => []
    },
    [CREATE_TCR]: (state, { payload }) => [...state, payload.id],
    [DELETE_TCR]: (state, { payload }) =>
      state.filter(tcrId => tcrId !== payload.id)
  },
  []
);

export const reducer = combineReducers({
  fetchGroups,
  timeConstraintRecurrings
});

export const getIsLoading = state =>
  state[MOUNT_POINT].fetchGroups.fetchAvailability.isFetching;

export const getIsEditorLoading = state =>
  state[MOUNT_POINT].fetchGroups.createAvailability.isFetching ||
  state[MOUNT_POINT].fetchGroups.updateAvailability.isFetching ||
  state[MOUNT_POINT].fetchGroups.deleteAvailability.isFetching;

export const getAvailability = state =>
  expand(
    TimeConstraintRecurring,
    state[MOUNT_POINT].timeConstraintRecurrings,
    {},
    state
  );
