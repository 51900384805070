import { VirtualKind } from './enums';

export const VIRTUAL_KIND_NEEDS_CALLER = new Set([
  VirtualKind.skype,
  VirtualKind.phone,
]);

// The order of these values determines the order in which we show virtual detail options.
export const VIRTUAL_KINDS = [
  { label: 'Phone Call', value: VirtualKind.phone, iconName: 'phone' },
  { label: 'Skype', value: VirtualKind.skype, iconName: 'skype' },
  { label: 'Google Hangout', value: VirtualKind.googlehangout, iconName: 'hangouts' },
  { label: 'Google Meet', value: VirtualKind.googlemeet, iconName: 'hangouts' },
  { label: 'Conference Line', value: VirtualKind.conference_line, iconName: 'phone' },
  { label: 'Zoom', value: VirtualKind.zoom, iconName: 'video' },
  { label: 'CoderPad', value: VirtualKind.coderpad, iconName: 'video' },
  { label: 'HackerRank', value: VirtualKind.hackerrank, iconName: 'video' },
  { label: 'Microsoft Teams', value: VirtualKind.microsoft_teams, iconName: 'video' },
];

// Mapping between a virtual detail kind and the relevant UI details needed to render it.
export const VIRTUAL_KIND_TO_UI_DETAIL = VIRTUAL_KINDS.reduce((acc, { label, value, iconName }) => {
  acc[value] = { iconName, label };
  return acc;
}, {});

export const VIRTUAL_KIND_USES_DYNAMIC_LINK = [
  VirtualKind.zoom,
  VirtualKind.googlemeet,
  VirtualKind.coderpad,
  VirtualKind.hackerrank,
  VirtualKind.microsoft_teams
];
