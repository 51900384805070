/** @format */
import React from "react";
import { Loader } from "vogue";

export default ({ loadingText }) => (
  <div className="OnboardingLoader">
    <h4 className="OnboardingLoader-loadingText">{loadingText}</h4>
    <Loader />
  </div>
);
