import { createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import createLogger from 'redux-logger';
import ravenBreadcrumbsMiddleware from './raven-breadcrumbs';
import { composeWithDevTools } from 'redux-devtools-extension';

const IS_PRODUCTION_BUILD = process.env.NODE_ENV === 'production';

const middleware = [thunkMiddleware, ravenBreadcrumbsMiddleware];
if (!IS_PRODUCTION_BUILD) {
  middleware.push(createLogger({
    // if the FSA is an error, we should prefer that log-level
    level: ({ error = false }) => error ? 'error' : 'log',
  }));
}

const composeEnhancers = composeWithDevTools({});

export default function configureStore(reducer, preloadedState) {
  const store = createStore(
    reducer,
    preloadedState,
    composeEnhancers(
      applyMiddleware(...middleware),
    ),
  );

  return store;
}
