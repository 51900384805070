/** @format */
import "@babel/polyfill";
import React from "react";
import { Provider } from "react-redux";
import { render } from "react-dom";
import Routes from "./router.react";
import { BrowserRouter as Router } from "react-router-dom";
import rootReducer from "./modules/reducer";
import configureStore from "../utils/configure-redux-store";
import { getInitialFeatureFlagState } from "./utils/feature-flags";
import BaseView from "./base.react";
import { setup as setupErrorReporting } from "./error-reporting";
const store = configureStore(rootReducer, {
  featureFlags: getInitialFeatureFlagState(window.PRELOAD.FEATURE_FLAGS)
});

setupErrorReporting(store);


render(
  <Provider store={store}>
    <BaseView>
      <Router>
        <Routes store={store} />
      </Router>
    </BaseView>
  </Provider>,
  document.getElementById("endo-client")
);
