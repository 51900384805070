/** @format */
import PropTypes from "prop-types";

import React from "react";

import { Contact, MeetingPermission } from "../../../../collections/schema";
import { generateShape } from "../../../../collections/shapes";
import { PreferenceContentBlock } from "../../layout/block.react";
import { innerCircle as innerCircleMailto } from "./mailto-templates";

// TODO(gavin): Display domain aliases for this permission kind.
const DomainPermission = ({ domain, meeting_permission_kind }) => (
  <div>
    Anyone from the domain <strong>{domain}</strong> can{" "}
    {meeting_permission_kind}.
  </div>
);

const ContactPermission = ({ contact, meeting_permission_kind }) => (
  <div>
    <strong>{contact.name}</strong> can {meeting_permission_kind}.
  </div>
);

const subtitle =
  "Who can tell Clara to schedule time with you, without waiting for Clara to get " +
  "your approval?";

const InnerCircle = ({ customerContact, meetingPermissions, bordered }) => {
  const permissionListItems = meetingPermissions.map(
    permission =>
      permission.domain ? (
        <DomainPermission {...permission} key={permission.id} />
      ) : (
        <ContactPermission {...permission} key={permission.id} />
      )
  );

  return (
    <PreferenceContentBlock
      title="Pre-approved contacts"
      subtitle={subtitle}
      onEdit={innerCircleMailto(customerContact.first_name)}
      supportLink="https://support.claralabs.com/article/117-approved-meeting-contacts"
      bordered={bordered}
    >
      <div className="InnerCircle">
        {permissionListItems.length ? permissionListItems : <div>Not set</div>}
      </div>
    </PreferenceContentBlock>
  );
};

InnerCircle.propTypes = {
  customerContact: generateShape(Contact).isRequired,
  meetingPermissions: PropTypes.arrayOf(
    generateShape(MeetingPermission, {
      contact: {}
    })
  ).isRequired
};

export default InnerCircle;
