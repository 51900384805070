/** @format */
import { pick, values } from "lodash";
import { combineReducers } from "redux";
import {
  createAsyncAction,
  wrapPromiseToThunk
} from "../../utils/redux-actions";
import { fetchApi } from "../../utils/request";
import { reportAndSetError } from "./error";
import { getCurrentCustomer } from "./session";
import {
  consumePayload,
  expand,
  updateCollection
} from "../../collections/module";
import { Location, Preference } from "../../collections/schema";
import { handleFetchGroupActions } from "../utils/fetch-group";

export const MOUNT_POINT = "locations";

const CREATE_LOCATION = createAsyncAction("LOCATIONS/CREATE_LOCATION");
const UPDATE_LOCATION = createAsyncAction("LOCATIONS/UPDATE_LOCATION");
const DELETE_LOCATION = createAsyncAction("LOCATIONS/DELETE_LOCATION");

const USER_WRITABLE_PROPERTIES = [
  "name",
  "lat",
  "lng",
  "formatted_address",
  "google_place_id",
  "additional_info",
  "category",
  "is_default"
];

const consumeLocationRecord = (record, meta) =>
  consumePayload(
    {
      ...record,
      meta: {
        ...record.meta,
        ...meta
      }
    },
    Location
  );

const handleDefaultLocation = (
  dispatch,
  state,
  { category, id, is_default, preference }
) => {
  if (!is_default) return;

  // This location is set as the default, make sure no other locations connected to the same
  // preference and with the same category are set as the default.
  values(state.collections.locations)
    .filter(
      loc =>
        loc.category === category &&
        loc.id !== id &&
        loc.preference === preference
    )
    .forEach(loc =>
      dispatch(consumeLocationRecord({ ...loc, is_default: false }))
    );
};

export const createLocation = wrapPromiseToThunk(
  CREATE_LOCATION,
  ({ dispatch, getState }, location) => {
    const customer = getCurrentCustomer(getState());
    return fetchApi(`/preferences/${customer.preference}/locations`, {
      method: "post",
      json: pick(location, USER_WRITABLE_PROPERTIES)
    }).then(({ body }) => {
      dispatch(consumeLocationRecord(body));
      dispatch(updateCollection(Preference, "locations", body.id));
      handleDefaultLocation(
        dispatch,
        getState(),
        expand(Location, body.id, {}, getState())
      );
    });
  },
  reportAndSetError
);

export const updateLocation = wrapPromiseToThunk(
  UPDATE_LOCATION,
  ({ dispatch, getState }, location) => {
    const preUpdateLocation = getState().collections.locations[location.id];

    dispatch(consumeLocationRecord(location, { loading: true }));

    return fetchApi(`/locations/${location.id}`, {
      method: "put",
      json: pick(location, USER_WRITABLE_PROPERTIES)
    })
      .then(() => {
        dispatch(consumeLocationRecord(location, { loading: false }));
        handleDefaultLocation(dispatch, getState(), location);
      })
      .catch(error => {
        dispatch(consumeLocationRecord(preUpdateLocation, { loading: false }));
        throw error;
      });
  },
  reportAndSetError
);

export const deleteLocation = wrapPromiseToThunk(
  DELETE_LOCATION,
  ({ dispatch, getState }, location) => {
    const preDeleteLocation = getState().collections.locations[location.id];

    dispatch(consumeLocationRecord(location, { loading: true }));

    return fetchApi(`/locations/${location.id}`, {
      method: "delete"
    })
      .then(() => {
        dispatch(consumeLocationRecord(location, { loading: false }));
        dispatch(updateCollection(Preference, "locations", location.id, true));
      })
      .catch(error => {
        dispatch(consumeLocationRecord(preDeleteLocation, { loading: false }));
        throw error;
      });
  },
  reportAndSetError
);

export const reducer = combineReducers({
  creatingLocation: handleFetchGroupActions(CREATE_LOCATION)
});

export const getIsCreatingLocation = state =>
  state[MOUNT_POINT].creatingLocation.isFetching;
