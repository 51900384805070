/** @format */
import React from "react";
import moment from "moment-timezone";

import { Customer } from "../../../collections/schema";
import { generateShape } from "../../../collections/shapes";
import { isAdmin } from "../../utils/permissions";
import { TextPreferenceContentBlock } from "../layout/block.react";
import Section from "../layout/section.react";
import withSchema from "../shared/with-schema.react";
import BillingPointOfContact from "../dashboard/account/billing-point-of-contact.react";
import SendEmailReceipts from "../dashboard/account/send-email-receipts.react";

const SEAT_TYPE_MAPPING = {
  data: "Data",
  essential: "Essential",
  professional: "Professional",
  team: "Team",
  exec: "Executive",
  custom: "Custom"
};

export const Billing = ({ data: customer }) => {
  const customerIsAdmin = isAdmin(customer.roles);
  const billingGroup = customer.organization.billing_group;

  let trialEnd;
  let description;
  if (billingGroup.is_trial) {
    trialEnd = (
      <div className="col-xs-12 col-sm-6">
        <TextPreferenceContentBlock
          title="Trial end date"
          subtitle="Clara will accept new meetings until this date"
          bordered={true}
        >
          {moment(billingGroup.trial_end_date).format("MMMM Do YYYY")}
        </TextPreferenceContentBlock>
      </div>
    );

    let selectPlan = (
      <div
        className="Billing-upgradePlan"
        onClick={() => {window.location=`https://claralabs.com/pricing?c=true`}}
      >
        Upgrade from the free trial
      </div>
    );

    description = (
      <span>
        {selectPlan} and boost your team's Clara experience.
      </span>
    );
  } else {
    const stripePortal = (
      <a target="_blank" href="https://billing.stripe.com/p/login/6oEbJmaFD8EocOQbII">Update Your Plan or Credit Card on Stripe</a>
    );

    description = (
      <span>
        Setup billing preferences for your team. When needed, {stripePortal}.
      </span>
    );
  }

  let billingPointOfContact;
  let sendEmailReceipts;
  if (customerIsAdmin) {
    let emailAddress;
    if (billingGroup.billing_point_of_contact) {
      emailAddress = billingGroup.billing_point_of_contact.default_email.id;
      sendEmailReceipts = (
        <div className="col-xs-12 col-sm-6">
          <SendEmailReceipts
            sendEmailReceipts={billingGroup.send_email_receipts}
            billingGroupId={billingGroup.id}
            bordered={true}
          />
        </div>
      );
    }

    billingPointOfContact = (
      <div className="col-xs-12 col-sm-6">
        <BillingPointOfContact
          emailAddress={emailAddress}
          billingGroupId={billingGroup.id}
          bordered={true}
        />
      </div>
    );
  }

  let seatType;
  if (billingGroup.seat_type) {
    seatType = (
      <div className="col-xs-12 col-sm-6">
        <TextPreferenceContentBlock
          title="Your plan"
          subtitle="Your team's current Clara plan"
          supportLink="https://claralabs.com/pricing/"
          subClassName="TextPreferenceContentBlock-paddingTop"
          bordered={true}
        >
          {SEAT_TYPE_MAPPING[billingGroup.seat_type]}
        </TextPreferenceContentBlock>
      </div>
    );
  }

  const referralDescriptionLink = "Refer other busy professionals to our Priority Waitlist";
  const referralDescription = (
    "While we revamp our referral program, you can send any referrals to the link above and they will be added " +
    "to our Priority Waitlist. Have them mention your name and company in the 'How did you hear about us?' section " + 
    "and we will prioritize granting them Early Access to Clara 3.0!"
  );

  const waitlistReferralLink = (
      <a 
        target="_blank" 
        href="https://forms.gle/tnYvvVQGDqZUTPaw7"
      >
        {referralDescriptionLink}
      </a>
    );

  if (customerIsAdmin) {
    return (
      <div className="Settings">
        <div className="Settings-sectionContainer">
          <div className="Settings-sectionContainerDetails">
            <div className="Settings-sectionContainerTitle">Billing</div>
            <div className="Settings-sectionContainerSubtitle">{description}</div>
          </div>
          <Section>
            <div className="row">
              {trialEnd}
              {seatType}
              {billingPointOfContact}
              {sendEmailReceipts}
            </div>
          </Section>
        </div>

        <div className="Settings-sectionContainer">
          <div className="Settings-sectionContainerDetails">
            <div className="Settings-sectionContainerTitle">Referrals</div>
            <div className="Settings-textContainer">{waitlistReferralLink}</div>
            <div className="Settings-sectionContainerSubtitle">{referralDescription}</div>
          </div>
        </div>
        
      </div>
    );
  } else {
    return (
      <ErrorMessage message={"Only organization admins can view or edit billing details."} />
    )
  }
};

const expansions = {
  organization: {
    billing_group: {
      billing_point_of_contact: {
        default_email: {}
      }
    }
  }
};

Billing.propTypes = { data: generateShape(Customer, expansions) };

export default withSchema(Customer, expansions, props => props.customer.id)(
  Billing
);
