/** @format */
import React, { Component } from "react";
import { Loader } from "vogue";

class SharePopup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      primed: false,
      iconSrc: "/images/share-copy.svg",
      copied: false
    }

    this.copyBypassLink = this.copyBypassLink.bind(this);
    this.clickListener = this.clickListener.bind(this);
  }

  componentDidMount() {
    window.addEventListener('click', this.clickListener)
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.clickListener)
  }

  // clickListener(e) is called once by default when compDidMount's 
  // event listener is added, thus we prime it before using it and
  // unprime it when we close the dropdown
  clickListener(e) {
    if (!this.state.primed) {
      this.setState({ primed: true })
    } else if (!document.getElementById('SharePopup').contains(e.target)) {
      if (this.state.primed) {        
        this.setState({ primed: false })
        this.props.toggleShare()
      }
    }
  }

  copyBypassLink(event) {
    this.setState({ copied: true })
    setTimeout(() => {
      this.setState({ copied: false})
    }, 5000);
    let bypassText = document.getElementById("bypassId");
    navigator.clipboard.writeText(bypassText["textContent"].trim());
    event.stopPropagation()
  }

  render() {
    let copyButtonContent
    if (this.state.copied) {
      copyButtonContent = (
        <div className="SharePopup-bookmarkEditButtonCopied" onClick={(event) => this.copyBypassLink(event)}>
          Copied!
        </div>
      )
    } else {
      copyButtonContent = (
        <div className="SharePopup-bookmarkEditButton" onClick={(event) => this.copyBypassLink(event)}>
          <div className="SharePopup-buttonContainer">
            <img className="bookmarkEditButton" src={this.state.iconSrc} />
          </div>
          <div className="bookmarkEditText">
            Copy
          </div>
        </div>
      )
    }
    return (
      <div className="SharePopup" id="SharePopup">
        <div className="SharePopup-titleContainer">
          <div className="SharePopup-titleText">
            Share this meeting
          </div>
        </div>

        {this.props.shareLink ?
        <div className="SharePopup-linkContainer">
          <div className="SharePopup-linkText" id="bypassId">
            {this.props.shareLink}
          </div>
          {copyButtonContent}
        </div>
        :
        <div className="SharePopup-linkContainer">
          <div className="SharePopup-linkLoader">
            <Loader />
          </div>
        </div>
        }
      </div>
    )
  }
};

SharePopup.PropTypes = {
};

export default SharePopup;