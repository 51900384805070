import React from 'react';
import createReactClass from 'create-react-class';
import config from "../../config";
import moment from 'moment';

import { GroupTimepicker } from './group-timepicker.react';
import { Loader } from 'vogue';
import { connect } from "react-redux";
import { createAsyncAction, wrapPromiseToThunk } from "../../utils/redux-actions";
import { fetchApi } from "../../utils/request";

export const GroupTimeSelect = createReactClass({
  _toTimeZone(time, zone) {
    let format = 'YYYY/MM/DD HH:mm:ss ZZ';
    return new Date(moment(time, format).tz(zone).format(format));
  },

  getInitialState() {
    const urlParams = new URLSearchParams(window.location.search);

    return {
      jobUUID: this.props.match.params.jobUUID,
      localTimezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      duration: 0,
      availableTimes: [],
      originalAvailableTimes: [],
      internalParticipantNames: [],
      externalParticipantNames: [],
      title: null,
      loading: true,
      selectedDateTime: null,
      confirmed: false,
      updating: false,
      phoneRequired: false,
      daysToFetch: 30,
      isMobile: false,

      // .../suggested-times/...
      suggestedTimeUUID: null,
      timeSpan: null,
      message: null,
      selectedTime: null,
      isCancelled: null,
      isStrikeout: false,
      suggestBypass: false,
      skipDetails: urlParams.get('reschedule') === 'true' ? true : false,
    };
  },

  _onAccept(acceptedEvent, details) {
    if (acceptedEvent) {
      this.setState({
        selectedDateTime: acceptedEvent.start,
        updating: true,
      })
    } else {
      this.setState({
        selectedDateTime: null,
        updating: true,
      })
    }
    
    let jobUUID = this.state.jobUUID
    let acceptUrl = `${config.api.host}/jobs/${jobUUID}/confirm_meeting_scheduling`;

    if (acceptedEvent) {
      fetch(acceptUrl, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          start_time: acceptedEvent.start,
          details: details
        })
      })
      .then((response) => {
        if (response.ok) {
          this.setState({
            updating: false,
            confirmed: true,
          })
        }
      })
    }
  },

  UNSAFE_componentWillMount() {
    let jobUUID = this.state.jobUUID

    this.props.getJobDetails(jobUUID)
    .then((response) => {
      let body = response.body
        this.setState({
          title: body.event_title,
          duration: body.duration,
          internalParticipantNames: body.internal_participant_names ? body.internal_participant_names : [],
          externalParticipantNames: body.external_participant_names ? body.external_participant_names : [],
        })

    this._fetchJobInfo(jobUUID, this.state.localTimezone)
    })
  },

  _fetchJobInfo(jobUUID, localTimezone) {
    let today = new Date()

    let start_iso = today.toISOString()
    let end_iso = today.toISOString()

    let timesUrl = 
      `${config.api.host}/customers/times_for_job/${jobUUID}?tz=${localTimezone}&start_time=${start_iso}&end_time=${end_iso}`

    fetch(timesUrl)
    .then((r) => {
      if (r.ok) {
        return r.json()
      }
    })
    .then((b) => {
      let suggested_times = b.suggested_times
      suggested_times.sort((x, y) => (x.start_time > y.start_time) ? 1 : -1)

      if (b.selected_time) {
        this.setState({
          selectedDateTime: {
            "datetime": b.selected_time
          },
          confirmed: true,
        })
      }

      this.setState({
        suggested_times: suggested_times,
        title: b.event_title,
        duration: b.duration,
        internalParticipantNames: b.internal_participant_names,
        externalParticipantNames: b.external_participant_names,
        loading: false,
      })
    })
  },

  render() {
    if (this.state.loading) {
      return (
        <div className="centered-loader">
          <Loader />
        </div>
      )
    }

    return (
      <div className="Timepicker-background">
        <GroupTimepicker
          title={this.state.title}
          duration={this.state.duration}
          internalParticipantNames={this.state.internalParticipantNames}
          externalParticipantNames={this.state.externalParticipantNames}
          selectedDateTime={this.state.selectedDateTime}
          localTimezone={this.state.localTimezone}
          updating={this.state.updating}
          eventConfirmed={this.state.confirmed}
          _onAccept={this._onAccept}
        />
      </div>
    )
  },
})

const getJobDetailsPromise = wrapPromiseToThunk(
  createAsyncAction("JOB/GET-DETAILS"),
  (_, uuid) => {
    return fetchApi(
      `/jobs/${uuid}/get_details`,
      { method: "POST" }
    )
  }
)

const getSelectedSuggestedTimePromise = wrapPromiseToThunk(
  createAsyncAction('SUGGESTED-TIME/SUGGESTED-TIME-STATUS'),
  (_, suggestedTimeUUID) => {
    return fetchApi(
      "/suggested_times/suggested_time_status/" + suggestedTimeUUID, { method: "GET" }
    )
  }
)

const mapDispatchToProps = (dispatch) => ({
  getJobDetails(uuid) {
    return dispatch(getJobDetailsPromise(uuid))
  },
  getSelectedSuggestedTime(suggestedTimeUUID) {
    return dispatch(getSelectedSuggestedTimePromise(suggestedTimeUUID))
  }
})

export default connect(null, mapDispatchToProps)(GroupTimeSelect);
