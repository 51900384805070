/** @format */
import PropTypes from "prop-types";

import React, { Component } from "react";
import { Button } from "vogue";
import config from "../../../config";
import TextButton from "./text-button.react";

let cachedListener;

class GoogleLoginButton extends Component {
  constructor() {
    super();
    this.handleAuthClick = this.handleAuthClick.bind(this);
    this.onGoogleApiLoaded = this.onGoogleApiLoaded.bind(this);
    this.state = {
      loading: true,
      client: null
    };
  }

  componentDidMount() {
    if (window.gapi) {
      this.onGoogleApiLoaded();
    } else {
      cachedListener = this.onGoogleApiLoaded;
      window.document.addEventListener("gapiLoaded", this.onGoogleApiLoaded);
    }
  }

  componentWillUnmount() {
    window.document.removeEventListener("gapiLoaded", cachedListener);
  }

  onGoogleApiLoaded() {
    window.gapi.load("client:auth2", () => {
      const client = window.gapi.auth2.getAuthInstance();
      if (client) {
        this.setState({
          client: client,
          loading: false
        });
      } else {
        window.gapi.client.setApiKey(config.google.browserKey);
        window.gapi.auth2.init({
          client_id: config.google.clientId,
          scope: "https://www.googleapis.com/auth/calendar"
        });
        let count = 0;
        const waitingForAuth = setInterval(() => {
          const authInstance = window.gapi.auth2.getAuthInstance();
          if (authInstance) {
            this.setState({
              client: authInstance,
              loading: false
            });
            clearInterval(waitingForAuth);
          } else {
            count += 1;
            if (count === 50) {
              throw new Error("Google API failed to load.");
            }
          }
        }, 100);
      }
    });
  }

  handleAuthClick() {
    if (this.props.onValidate && !this.props.onValidate()) {
      return;
    }

    const options = {
      redirect_uri: "postmessage"
    };

    if (this.props.forceApproval) {
      options.approval_prompt = "force";
    }

    if (this.props.emailAddress) {
      options.login_hint = this.props.emailAddress;
    }

    if (this.props.scope) {
      options.scope = this.props.scope;
    }

    this.state.client
      .grantOfflineAccess(options)
      .then(this.props.onLogin, ({ error }) => {
        // Per https://developers.google.com/identity/sign-in/web/reference#googleauthgrantofflineaccessoptions
        // there are three potential error codes that could be returned but `immediate_failed` does
        // apply to `grantOfflineAccess`.
        let errorMessage = {
          access_denied: "Could not get required permissions from Google",
          popup_closed_by_user:
            "Google window was closed before login was completed"
        }[error];
        this.props.onAuthError(errorMessage || "Could not sign in with Google");
      });
  }

  render() {
    const props = {
      ...this.props,
      loading: this.props.loading || this.state.loading,
      onClick: this.handleAuthClick
    };
    const ButtonKlass = this.props.textButton ? TextButton : Button;

    delete props.href;
    delete props.textButton;

    return <ButtonKlass {...props} />;
  }
}

GoogleLoginButton.propTypes = {
  onLogin: PropTypes.func.isRequired,
  onAuthError: PropTypes.func.isRequired,
  onValidate: PropTypes.func,
  forceApproval: PropTypes.bool,
  emailAddress: PropTypes.string,
  textButton: PropTypes.bool,
  scope: PropTypes.string,
  ...Button.propTypes
};

export default GoogleLoginButton;
