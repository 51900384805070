/** @format */
import { connect } from "react-redux";

import { fetchStats, getIsLoadingStats, getStats } from "../../modules/meetings";
import {
  getActiveAssistant,
  getActiveAssistantContact,
  getCurrentBillingGroup,
  getCurrentCustomer,
  getIsLoading as getIsLoadingSession
} from "../../modules/session";
import UpcomingMeetings from "../../components/live-v2/upcoming-meetings.react";
import { FEATURE_FLAGS } from "../../utils/feature-flags";
import { isAdmin } from "../../utils/permissions";

const mapStateToProps = state => {
  if (getIsLoadingStats(state) || getIsLoadingSession(state)) {
    return { isLoading: true };
  }

  const customer = getCurrentCustomer(state, {
    contact: {
      google_accounts: {
        email_address: {}
      },
      microsoft_accounts: {
        email_address: {}
      }
    }
  });
  const billingGroup = getCurrentBillingGroup(state);

  const googleAccount = customer.contact.google_accounts[0];
  let customerGoogleAccountEmail = null;
  if (googleAccount) {
    customerGoogleAccountEmail = googleAccount.email_address
      ? googleAccount.email_address.id
      : `Google Account ${googleAccount.id}`;
  }

  const microsoftAccount = customer.contact.microsoft_accounts[0];
  let customerMicrosoftAccountEmail
  if (microsoftAccount && microsoftAccount.email_address) {
    customerMicrosoftAccountEmail = microsoftAccount.email_address.id
  }

  const activeAssistant = getActiveAssistant(state);
  const dataExportEnabled =
    activeAssistant &&
    activeAssistant.feature_flags.includes(FEATURE_FLAGS.CLARA_SERVES_VALUE);

  return {
    isLoading: false,
    assistantContact: getActiveAssistantContact(state),
    customerContact: customer.contact,
    totalRequestsCount: getStats(state).total_requests_count,
    coordinatingMeetingsCount: getStats(state).coordinating_meetings_count,
    finishedMeetingsCount: getStats(state).finished_meetings_count,
    customerGoogleAccountEmail,
    customerMicrosoftAccountEmail,
    trialEndDate: billingGroup.trial_end_date,
    isPaying: billingGroup.treat_as_paying,
    offboardingEnd: customer.offboarding_end,
    offboardingRetirement: customer.offboarding_retirement,
    isTrial: billingGroup.is_trial,
    dataExportEnabled,
    customerIsAdmin: isAdmin(customer.roles)
  };
};

const mapDispatchToProps = dispatch => ({
  onLoad() {
    dispatch(fetchStats());
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UpcomingMeetings);
