/** @format */
import { connect } from "react-redux";

import Coderpad from "../../components/dashboard/integrations/coderpad.react";
import {
  configureCoderpad,
  fetchCoderpad,
  getIsConfiguringCoderpad,
  getIsLoadingCoderpad,
  getCoderpadDetails
} from "../../modules/integrations";
import createInitiallyLoadingContainer from "../create-initially-loading-container.react";

const mapStateToProps = state => ({
  coderpadDetails: getCoderpadDetails(state),
  isLoading: getIsLoadingCoderpad(state),
  isConfiguringCoderpad: getIsConfiguringCoderpad(state)
});

const mapDispatchToProps = dispatch => ({
  onLoad() {
    return dispatch(fetchCoderpad());
  },

  onConfigureCoderpad(coderpadApiKey) {
    return dispatch(configureCoderpad(coderpadApiKey));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(createInitiallyLoadingContainer(Coderpad));
