/** @format */
import PropTypes from "prop-types";

import React, { Component } from "react";

import { Loader } from "vogue";
import { PreferenceContentBlock } from "../../layout/block.react";
import SelectMenu from "../../shared/select-menu.react";
import TextButton from "../../shared/text-button.react";

const UNSET_VALUE = "unset";

export default class EventCalendar extends Component {
  constructor(props) {
    super(props);

    this._initialValue = this._initialValue.bind(this);
    this._onFinishAnimating = this._onFinishAnimating.bind(this);
    this._onSave = this._onSave.bind(this);
    this._onChange = this._onChange.bind(this);

    this.state = {
      loaderAnimating: false,
      error: false,
      value: this._initialValue(props)
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({ value: this._initialValue(nextProps) });
  }

  render() {
    const { assistantName, isError, isUpdating, options } = this.props;
    const { error, loaderAnimating, value } = this.state;
    const isLoading = isUpdating || loaderAnimating;
    const dirty = value !== this._initialValue(this.props);

    const saveButton =
      dirty && !isLoading ? (
        <TextButton className="EventCalendar-saveButton" onClick={this._onSave}>
          Save
        </TextButton>
      ) : null;

    const loader = isLoading ? (
      <Loader
        animating={loaderAnimating}
        onFinishAnimating={this._onFinishAnimating}
        error={error}
      />
    ) : null;

    let menu;
    if (options) {
      const selectOptions = [
        {
          label: `${assistantName}'s Calendar`,
          value: UNSET_VALUE
        },
        ...options.map(o => ({
          label: o.name,
          value: `${o.id}`
        }))
      ];
      menu = (
        <SelectMenu
          disabled={isLoading}
          value={`${value}`}
          onChange={this._onChange}
          options={selectOptions}
          noUnderline={true}
        />
      );
    }

    const errorMessage = isError ? (
      <div className="EventCalendar-errorMessage">
        <span>
          We&#39;re unable to load your calendars. Please reload the page to try
          again, or if this error persists,&nbsp;
          <a href="mailto:support@claralabs.com">contact support</a>.
        </span>
      </div>
    ) : null;

    return (
      <PreferenceContentBlock
        title="Calendar"
        subtitle="Which calendar would you like Clara to send meeting invitations from?"
        anchor="event-calendar"
        supportLink="https://support.claralabs.com/article/121-calendar"
        bordered={this.props.bordered}
        className={this.props.className}
        subClassName={this.props.subClassName}
      >
        <div className="EventCalendar-selectContainer">
          {menu}
          {errorMessage}
        </div>
        <div className="EventCalendar-rightContainer">
          {saveButton}
          {loader}
        </div>
      </PreferenceContentBlock>
    );
  }

  _onFinishAnimating() {
    this.setState({
      loaderAnimating: false,
      error: false
    });
  }

  _onChange({ target: { value } }) {
    this.setState({
      value: value === UNSET_VALUE ? value : parseInt(value, 10)
    });
  }

  _onSave() {
    const { value } = this.state;
    const { onSetEventCalendar, onUnsetEventCalendar } = this.props;

    (value === UNSET_VALUE ? onUnsetEventCalendar() : onSetEventCalendar(value))
      .then(() => {
        this.setState({ loaderAnimating: true });
      })
      .catch(() => {
        this.setState({ loaderAnimating: true, error: true });
      });
  }

  _initialValue({ options }) {
    if (!options) {
      return UNSET_VALUE;
    }

    const selected = options.find(o => o.selected);
    return selected ? selected.id : UNSET_VALUE;
  }
}

EventCalendar.propTypes = {
  assistantName: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      selected: PropTypes.bool.isRequired
    })
  ),
  isError: PropTypes.bool.isRequired,
  isUpdating: PropTypes.bool.isRequired,
  onSetEventCalendar: PropTypes.func.isRequired,
  onUnsetEventCalendar: PropTypes.func.isRequired
};
