/** @format */
import { connect } from "react-redux";

import authRequired from "../../components/shared/auth-required.react";
import { reportAndSetError } from "../../modules/error";

import Registration from "../../components/onboarding/registration.react";
import {
  fetchBeginOnboard,
  fetchMeetingDetails,
  getIsSubmitting,
  getIsLoadingMeetingDetails,
  getMeetingDetails
} from "../../modules/onboard";
import { getCurrentUser } from "../../modules/session";

const mapStateToProps = state => ({
  isSubmitting: getIsSubmitting(state),
  hasCurrentUser: !!getCurrentUser(state),
  isLoading: getIsLoadingMeetingDetails(state),
  ...getMeetingDetails(state)
});

const mapDispatchToProps = dispatch => ({
  onSubmit(timezone) {
    return dispatch(fetchBeginOnboard(timezone));
  },
  onError(errorMessage) {
    return dispatch(reportAndSetError(new Error(errorMessage)));
  },
  onLoad() {
    return dispatch(fetchMeetingDetails());
  }
});

export default authRequired({ onboarded: false, onboarding: false })(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Registration)
);
