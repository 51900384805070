/** @format */
import PropTypes from "prop-types";

import querystring from "querystring";
import React from "react";

import config from "../../../../config";
import { ZoomAccountShape } from "../../../utils/shapes";

import TextButton from "../../shared/text-button.react";
import IntegrationPageBase from "./page-base.react";
import RemoveZoomAccount from "./zoom/remove-zoom-account.react";
import ZoomAccountDetail from "./zoom/zoom-account-detail.react";
import ZoomAccountStatus from "./zoom/zoom-account-status.react";

const Zoom = ({ activeZoomAccount, ...restProps }) => {
  const queryParams = querystring.stringify({
    next: window.location.href,
    is_marketplace_app: true
  });
  const authUrl = `${
    config.api.host
  }/zoom_accounts/actions/update_or_create?${queryParams}`;

  const props = {
    title: "Zoom",
    subtitle:
      "Clara can add custom Zoom links or personal meeting links to any invitation.",
    image: "/images/zoom-logo.png",
    className: "ZoomIntegration"
  };

  if (!activeZoomAccount) {
    return (
      <IntegrationPageBase {...props}>
        <div className="ZoomIntegration-preapproval">
          Pre-approve the{" "}
          <a href="https://marketplace.zoom.us/apps/KK-cLSQbQAm5nYuFfYSGzQ">
            Clara Labs app
          </a>{" "}
          in the Zoom Marketplace. Once it's pre-approved, you'll be able to
          connect your team's Zoom account here.
        </div>
        <TextButton href={authUrl}>Connect Zoom Account</TextButton>
      </IntegrationPageBase>
    );
  }

  return (
    <IntegrationPageBase {...props}>
      <div className="ZoomIntegration-content">
        <ZoomAccountDetail zoomAccount={activeZoomAccount} />
        <ZoomAccountStatus
          isValid={activeZoomAccount.is_valid}
          authUrl={authUrl}
          className="ZoomIntegration-accountStatus"
        />
      </div>
      <RemoveZoomAccount
        className="ZoomIntegration-removeAccount"
        isDeauthorizingZoom={restProps.isDeauthorizingZoom}
        deauthorizeZoomError={restProps.deauthorizeZoomError}
        onDeauthorizeZoom={() =>
          restProps.onDeauthorizeZoom(
            activeZoomAccount.id,
            activeZoomAccount.organization_id
          )
        }
      />
    </IntegrationPageBase>
  );
};

Zoom.propTypes = {
  activeZoomAccount: ZoomAccountShape,
  isDeauthorizingZoom: PropTypes.bool.isRequired,
  deauthorizeZoomError: PropTypes.shape({
    message: PropTypes.string.isRequired,
    requestId: PropTypes.string
  }),
  onDeauthorizeZoom: PropTypes.func.isRequired
};

export default Zoom;
