/** @format */
import classnames from "classnames";
import React from "react";

export default ({ children, heading, horizontal = false }) => (
  <div
    className={classnames("FormInputContainer", {
      "FormInputContainer--horizontal": horizontal
    })}
  >
    <div className="FormInputContainer-heading">{heading}</div>
    {children}
  </div>
);
