/** @format */
import PropTypes from "prop-types";

import React, { Component } from "react";
import { Modal } from "vogue";

import { VirtualCategory, VirtualKind } from "../../../../utils/enums";
import VirtualMeetingDetailForm, { getValidationError } from "./form.react";

const DEFAULT_VIRTUAL_DETAIL = {
  meta: {},
  categories: [
    VirtualCategory.direct_call_default,
    VirtualCategory.group_call_default,
    VirtualCategory.direct_call,
    VirtualCategory.group_call,
  ],
  kind: VirtualKind.phone,
  value: ""
};

const NON_DEFAULT_VIRTUAL_DETAIL = {
  meta: {},
  categories: [VirtualCategory.direct_call, VirtualCategory.group_call],
  kind: VirtualKind.phone,
  value: ""
}

export default class VirtualMeetingDetailCreator extends Component {
  constructor(props) {
    super(props);

    this.state = {
      virtualDetail: this.props.virtualDetails.length === 0 ?
        DEFAULT_VIRTUAL_DETAIL
        :
        NON_DEFAULT_VIRTUAL_DETAIL,
      meetingType: {
        id: this.props.meetingType.id,
        who_contacts_who: this.props.meetingType.who_contacts_who
      },
      forceValidation: false
    };

    this._handleAdd = this._handleAdd.bind(this);
    this._onChange = this._onChange.bind(this);
    this._onChangeMeetingType = this._onChangeMeetingType.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.isCreating !== this.props.isCreating) {
      this.setState({
        virtualDetail: nextProps.virtualDetails.length === 0 ?
          DEFAULT_VIRTUAL_DETAIL
          :
          NON_DEFAULT_VIRTUAL_DETAIL,
        forceValidation: false,
      });
    }
  }

  _handleAdd() {
    if (getValidationError(this.state.virtualDetail, this.state.meetingType)) {
      this.setState({ forceValidation: true });
      if (this.refs.creatorModal) this.refs.creatorModal.shake();
    } else {
      return this.props
        .onCreateVirtualDetail(this.state.virtualDetail)
        .then(this.props.onUpdateMeetingType(this.state.meetingType))
        .then(this.props.onEditorClose);
    }
  }

  _showGoogleMicrosoftOption() {
    let assistant = this.props.activeAssistant
    if (!assistant) return false

    let orgDomains = this.props.customer.organization.domains
    if (!orgDomains) return false

    let assistantEmail = assistant.default_email
    let assistantEmailDomain = assistantEmail.split("@")[1]

    return orgDomains.includes(assistantEmailDomain)
  }

  render() {
    const modalActions = [
      {
        key: "add",
        label: "Add",
        modifier: "primary",
        handler: this._handleAdd
      },
      {
        key: "cancel",
        label: "Cancel",
        handler: this.props.onEditorClose
      }
    ];

    const virtualKinds = this.props.availableVirtualKinds.filter(kind => {
      if (kind === VirtualKind.googlehangout) {
        // Don't allow the creation of any new Google Hangout virtual details.
        return false;
      } else if (kind === VirtualKind.zoom) {
        // Don't allow creation of a Zoom virtual detail if there's no active Zoom account.
        return !!this.props.hasActiveZoomAccount;
      } else if (kind === VirtualKind.coderpad) {
        return this.props.isCoderpadActive;
      } else if (kind === VirtualKind.hackerrank) {
        return this.props.isHackerrankActive;
      } else if (kind === VirtualKind.microsoft_teams) {
        return this.props.isMicrosoft && this._showGoogleMicrosoftOption();
      } else if (kind === VirtualKind.googlemeet) {
        return this.props.isGoogle && this._showGoogleMicrosoftOption();
      }
      return true;
    });

    return (
      <Modal
        actions={modalActions}
        open={this.props.isCreating}
        loading={this.props.creatingVirtualDetail}
        onRequestClose={this.props.onEditorClose}
        ref="creatorModal"
        classNames={{
          modal: ["VirtualMeetingDetailCreator-modal"]
        }}
      >
        <VirtualMeetingDetailForm
          onChange={this._onChange}
          onChangeMeetingType={this._onChangeMeetingType}
          forceValidation={this.state.forceValidation}
          virtualMeetingDetail={this.state.virtualDetail}
          meetingType={this.state.meetingType}
          virtualKinds={virtualKinds}
        />
      </Modal>
    );
  }

  _onChange(key, { value }) {
    this.setState({
      virtualDetail: {
        ...this.state.virtualDetail,
        [key]: value
      }
    });
  }

  _onChangeMeetingType(key, { value }) {
    this.setState({
      meetingType: {
        ...this.state.meetingType,
        [key]: value
      }
    })
  }
}

VirtualMeetingDetailCreator.propTypes = {
  onCreateVirtualDetail: PropTypes.func.isRequired,
  onEditorClose: PropTypes.func.isRequired,
  creatingVirtualDetail: PropTypes.bool.isRequired,
  isCreating: PropTypes.bool.isRequired,
  hasActiveZoomAccount: PropTypes.bool.isRequired,
  isCoderpadActive: PropTypes.bool.isRequired,
  isHackerrankActive: PropTypes.bool.isRequired,
  isMicrosoft: PropTypes.bool.isRequired,
  availableVirtualKinds: PropTypes.arrayOf(
    PropTypes.oneOf(Object.keys(VirtualKind))
  ).isRequired
};
