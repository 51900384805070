/** @format */
import PropTypes from "prop-types";
import React, { Component } from "react";

import { CustomerShape } from "../../utils/shapes";
import FlexLoader from "../shared/flex-loader.react";
import TopBanner from "./top-banner.react";
import { renderSideNavigation } from "./side-navigation-config.react";

export default class LibraryWrapper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sideNavigationMinimized: localStorage.getItem("side-nav-minimized") === "true",
      queryParams: {}
    };

    this.handleSideNavigationToggle = this.handleSideNavigationToggle.bind(this);
    this.debounce = this.debounce.bind(this);
    this.searchEnter = this.searchEnter.bind(this);
    this.formatQueryParams = this.formatQueryParams.bind(this);
  }

  debounce(callback, delay) {
    let timeout
    return () => {
      clearTimeout(timeout)
      timeout = setTimeout(callback, delay)
    }
  }

  searchEnter() {
    var searchQuery = document.getElementById('search').value      
    this.formatQueryParams(searchQuery)
  }

  formatQueryParams(searchQuery) {
    // simplified for now, no syntax
    if (searchQuery === "") {
      this.setState({ queryParams: {} })
    } else {
      this.setState({
        queryParams: {
          "all_text": searchQuery,
          "title": searchQuery,
          "description": searchQuery,
        }
      })
    }
  }

  handleSideNavigationToggle() {
    localStorage.setItem("side-nav-minimized", !this.state.sideNavigationMinimized)
    this.setState({
      sideNavigationMinimized: !this.state.sideNavigationMinimized
    })
  }

  render() {
    const {
      children,
      customer,
      isLoading,
    } = this.props;

    let sideNavigation = renderSideNavigation(
      isLoading,
      false,
      this.state.sideNavigationMinimized,
      this.props.customer,
      this.handleSideNavigationToggle,
      "Library"
    )

    if (isLoading) {
      return (
        <div className="Library">
          <div className="Library-container">
            {sideNavigation}
            <div className="Library-rightHandSide">
              <TopBanner title="Library" />
              <div className="DashboardWrapper">
                <FlexLoader />
              </div>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="Library">
        <div className="Library-container">
          {sideNavigation}
          <div className="Library-rightHandSide">
            <TopBanner
              title={"Library"}
              handleOnKeyDown={this.debounce(this.searchEnter, 500)}  
            />
              {React.Children.map(children, child => (
                React.isValidElement(child) && (child.props.component !== undefined)
                  ? React.cloneElement(
                    child,
                    {
                      component: undefined,
                      render: (routerProps) => React.cloneElement(
                        child.props.component,
                        {
                          ...routerProps,
                          ...child.props,
                          ref: null,
                          customer,
                          queryParams: this.state.queryParams
                        }
                      )
                    }
                  )
                  : child
            ))}
          </div>
        </div>
      </div>
    );
  }
}

LibraryWrapper.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.node
  ]),
  customer: CustomerShape,
  customerIsAdmin: PropTypes.bool,
  customerProfile: PropTypes.shape({
    fullName: PropTypes.string.isRequired,
    company: PropTypes.string
  }),
  onLogout: PropTypes.func.isRequired,
  seatType: PropTypes.string,
  isPaying: PropTypes.bool,
  trialDaysRemaining: PropTypes.number
};
